export const ADD_CONTEXT_SETTINGS_DATA_OBJECTS = 'ADD_CONTEXT_SETTINGS_DATA_OBJECTS'
export const addContextDataObjects = (data) => ({
  type: ADD_CONTEXT_SETTINGS_DATA_OBJECTS,
  payload: data,
})

export const ADD_CONTEXT_SETTINGS_DATA_OBJECT = 'ADD_CONTEXT_SETTINGS_DATA_OBJECT'
export const addContextDataObject = (data) => ({
  type: ADD_CONTEXT_SETTINGS_DATA_OBJECT,
  payload: data,
})

export const STORE_DATA_OBJECT_VALIDATION_ERRORS = 'STORE_DATA_OBJECT_VALIDATION_ERRORS'
export const storeDataObjectvalidation_errors = (data) => ({
  type: STORE_DATA_OBJECT_VALIDATION_ERRORS,
  payload: data,
})
