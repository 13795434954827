import { barData, alphaMap, publicationColors } from '../barData'

const binSize = 1
const maxHotelNights = 20

const makeHotelNightsData = (activeReports, filter) => {
  const out = []

  for (let report in activeReports) {
    var baseData = activeReports[report].payload.memberStatistics.filter(
      (member) =>
        (filter.scheduled && member.scheduledHotelNights) || (filter.preAssigned && member.preAssignedHotelNights)
    )
    var histogram = baseData.map((member) => {
      const sum =
        (filter.scheduled ? member.scheduledHotelNights || 0 : 0) +
        (filter.preAssigned ? member.preAssignedHotelNights || 0 : 0)

      return sum
    })

    const graphData = {
      y: histogram,
      label: 'Hotel Nights Away',
      customdata: baseData,
      meta: { publication: activeReports[report].title, histogram: histogram },
      marker: { color: activeReports[report].color, pattern: {} },
      hoverinfo: 'none',
      type: 'histogram',
      yaxis: `y`,
      xaxis: `x${+2 * report + 2}`,
      opacity: 0.7,
      name: activeReports[report].title,
      ybins: { start: -0.5, size: binSize, end: maxHotelNights },
    }
    out.push(graphData)
    out.push({
      ...graphData,
      hovertemplate: null,
      type: 'box',
      xaxis: `x${+2 * report + 1}`,
    })
  }
  return out
}

export default makeHotelNightsData
