import { DateTime, Interval } from 'luxon'

const lux = (date, zone = 'UTC') => {
  if (!date) return
  if (date instanceof Date) {
    return DateTime.fromJSDate(date)
  }
  if (typeof date === 'number') {
    return DateTime.fromMillis(date, { zone })
  }
  if (typeof date === 'string') {
    return DateTime.fromISO(date, { zone })
  }
}

const endOf = (date, zone, unit) => {
  return lux(date, zone).endOf(unit).toMillis()
}
const startOf = (date, zone, unit) => {
  return lux(date, zone).startOf(unit).toMillis()
}
const format = (date, zone, format) => {
  return lux(date, zone).toFormat(format)
}
const getTicksInInterval = (interval, unit) => {
  let dur = {}
  switch (unit) {
    case 'day':
      dur = { days: 1 }
      break
    case 'month':
      dur = { months: 1 }
      break
  }
  function* days(ival) {
    let cursor = ival.start.startOf(unit)
    while (cursor < ival.end) {
      yield cursor
      cursor = cursor.plus(dur)
    }
  }
  return Array.from(days(interval)).map((d) => d.toJSDate())
}

export { lux, endOf, startOf, format, getTicksInInterval }
