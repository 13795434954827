import { Button, Card, ControlGroup, NumericInput } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import FlightBid from './FlightBid'
import FlyTogetherBid from './FlyTogetherBid'
import NightStopBid from './NightStopBid'
import SignInTimeBid from './SignInTimeBid'
import SignOutTimeBid from './SignOutTimeBid'
import TimeOffBid from './TimeOffBid'
import WorkingDaysBid from './WorkingDaysBid'

const mapStateToProps = (state) => {
  return {
    bidTypes: state.bidding.bidTypes,
  }
}

const Bid = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const showBidType = () => {
    const { data } = props
    switch (data.type) {
      case 'TimeOffBid':
        return <TimeOffBid data={data} onChange={(d) => props.onChange(d)} />
      case 'FlightBid':
        return <FlightBid data={data} onChange={(d) => props.onChange(d)} />
      case 'NightStopBid':
        return <NightStopBid data={data} onChange={(d) => props.onChange(d)} />
      case 'WorkingDaysBid':
        return <WorkingDaysBid data={data} onChange={(d) => props.onChange(d)} />
      case 'SignInTimeBid':
        return <SignInTimeBid data={data} onChange={(d) => props.onChange(d)} />
      case 'SignOutTimeBid':
        return <SignOutTimeBid data={data} onChange={(d) => props.onChange(d)} />
      case 'FlyTogetherBid':
        return <FlyTogetherBid data={data} onChange={(d) => props.onChange(d)} />
      default:
    }
  }

  const { data, bidTypes, max } = props

  return (
    <Card style={{ padding: 0, marginBottom: 10 }}>
      <ControlGroup fill>
        <SelectDropdown
          fill
          hideFilter
          disabled
          activeItem={data.type}
          items={bidTypes}
          onChange={(key) => {
            const d = { ...data }

            d.type = key

            if (!d.selectedTime) d.selectedTime = d.startTime
            props.onChange(d)
          }}
        />
        <NumericInput
          min={0}
          max={max}
          stepSize={1}
          majorStepSize={1}
          minorStepSize={null}
          clampValueOnBlur={true}
          value={data.credits}
          buttonPosition="none"
          style={{
            width: 50,
            textAlign: 'right',
            color: data.credits > max ? 'red' : 'white',
          }}
          onValueChange={(val) => {
            let d = { ...data }
            d.credits = parseInt(val)
            if (isNaN(d.credits)) d.credits = 0

            props.onChange(d)
          }}
        />
        <Button minimal icon="cross" onClick={() => props.onDelete(data.id)} />
      </ControlGroup>
      {showBidType()}
    </Card>
  )
}
export default connect(mapStateToProps, null)(Bid)
