export const STORE_EMPLOYEE_DATA = 'STORE_EMPLOYEE_DATA'
export const storeEmployeeData = (data) => ({
  type: STORE_EMPLOYEE_DATA,
  payload: data,
})

export const STORE_EMPLOYEE_BIDS = 'STORE_EMPLOYEE_BIDS'
export const storeEmployeeBids = (data) => ({
  type: STORE_EMPLOYEE_BIDS,
  payload: data,
})

export const CREATE_NEW_BID = 'CREATE_NEW_BID'
export const createNewBid = (data) => ({
  type: CREATE_NEW_BID,
  payload: data,
})

export const DELETE_BID = 'DELETE_BID'
export const deleteBid = (data) => ({
  type: DELETE_BID,
  payload: data,
})
export const STORE_BID_TYPES = 'STORE_BID_TYPES'
export const storeBidTypes = (data) => ({
  type: STORE_BID_TYPES,
  payload: data,
})
