import React from 'react'
import { useSelector } from 'react-redux'
import { MpPlot, MpPlotWrapper } from '../MpPlot'
import { makeTotalViolations, ViolationTypes, makeTotalViolationsRadar } from './makeViolationsData'
import { Card } from '@blueprintjs/core'
import * as d3 from 'd3'
import { BarTooltip } from 'dashboard/Tooltip'
import makeViolationsAnnotation from './makeViolationsAnnotation'

const Violations = (props) => {
  const reports = useSelector((state) => state.reports)
  const { activeReports, filter } = reports

  const totalData = makeTotalViolations(activeReports, filter)
  const radarData = makeTotalViolationsRadar(activeReports, filter)
  const annotations = makeViolationsAnnotation(activeReports, filter)

  /*
  const t = activeReports.map((report) =>
    ViolationTypes.reduce((sum, field) => {
      return (
        sum +
        (filter.preAssigned
          ? totalData[field].isSoft && filter.soft
            ? report.payload.crewStatistics[costDetails[field].preAssigned]
            : 0
          : 0)
      )
    })
  )
*/
  return (
    <div>
      <MpPlotWrapper>
        {activeReports.map((d, i) => (
          <Card style={{ fontWeight: 'bold', fontSize: 30, marginRight: 15, color: d.color }} key={i}>
            <div style={{ color: '#aaa', fontSize: 14, marginBottom: 10 }}>
              <span
                style={{
                  display: 'inline-block',
                  backgroundColor: d.color,
                  width: 12,

                  height: 12,
                  marginRight: 10,
                  borderRadius: 8,
                }}></span>
              {d.title}
            </div>
          </Card>
        ))}
      </MpPlotWrapper>
      <MpPlotWrapper>
        <MpPlot
          title="Violations Details"
          tooltip={BarTooltip}
          data={totalData}
          layout={{
            autosize: true,
            height: 300,
            bargap: 0.1,
            grid: {
              columns: ViolationTypes.length,
              rows: 1,
              yaxes: ['y'],
              roworder: 'bottom to top',
              pattern: 'independent',
            },
            annotations: annotations,
            yaxis: { fixedrange: true },
            ...Object.fromEntries(
              ViolationTypes.map((d, i) => [
                'xaxis' + (i + 1),
                {
                  title: { font: { size: 12 }, text: d.label },
                  showticklabels: false,
                  visible: true,
                  fixedrange: true,
                  zeroline: false,
                  showgrid: false,
                },
              ])
            ),
          }}
        />
      </MpPlotWrapper>

      {false && (
        <MpPlotWrapper>
          <MpPlot
            title="Violations Details"
            tooltip={BarTooltip}
            data={radarData}
            layout={{
              polar: {
                radialaxis: {
                  visible: true,
                },
              },
              showlegend: false,
            }}
          />
        </MpPlotWrapper>
      )}
    </div>
  )
}

export default Violations
