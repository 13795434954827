import { CHANGE_FILTER, STORE_REPORT, CLEAR_REPORT, REMOVE_REPORT, STORE_ALL_REPORT_IDS } from './actions'
import { publicationColors } from './barData'
import costDetails from './Cost/costDetails'
import activityDetails from './Distributions/activityDetails'

const initialState = {
  activeReports: [],
  publications: [],
  filter: {
    business: true,
    soft: true,
    scheduled: true,
    preAssigned: true,
    costFields: Object.keys(costDetails),
    activityFields: Object.keys(activityDetails),
    employee: '',
  },
}
export default function rosterReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTER: {
      const newfilter = { ...state.filter }

      return {
        ...state,
        filter: { ...newfilter, ...action.payload },
      }
    }
    case STORE_REPORT: {
      let newArray = state.activeReports.slice()
      if (newArray.findIndex((d) => d.id === action.payload.id) >= 0) return state
      const usedColors = newArray.map((d) => d.color)
      const ununsedColors = publicationColors.filter((x) => !usedColors.includes(x))
      action.payload.color = ununsedColors[0]
      newArray.push(action.payload)
      return {
        ...state,
        activeReports: newArray,
      }
    }
    case REMOVE_REPORT: {
      let newArray = state.activeReports.slice()
      let i = state.activeReports.findIndex((d) => d.id === action.payload)
      newArray.splice(i, 1)
      return {
        ...state,
        activeReports: newArray,
      }
    }
    case CLEAR_REPORT:
      return {
        ...state,
        activeReports: [],
      }
    case STORE_ALL_REPORT_IDS:
      return {
        ...state,
        publications: action.payload,
      }
    default:
      return state
  }
}
