import React, { useState, useEffect } from 'react'
import store from 'store'
import { connect } from 'react-redux'
import moment from 'moment'
import { saveFlight } from '../actions'
import { Button, MenuItem } from '@blueprintjs/core'
import { DateInput2, TimePicker2 } from '@blueprintjs/datetime2'
import { ControlGroup, FormGroup } from '@blueprintjs/core'

const EditFlight = (props) => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  const [originalStart, setOriginalStart] = useState(0)
  const [originalEnd, setOriginalEnd] = useState(0)

  const makeDataObject = () => {
    let diffStart = (start - originalStart) / 60000
    let diffEnd = (end - originalEnd) / 60000
    let id = this.props.id

    let doPayload = { id, diffStart, diffEnd, start, end }
    let undoPayload = { id, diffStart: -diffStart, diffEnd: -diffEnd, start: originalStart, end: originalEnd }
    return { doPayload, undoPayload }
  }
  const saveClicked = () => {
    const { doPayload, undoPayload } = makeDataObject()
    setOriginalStart(start)
    setOriginalEnd(end)
    store.dispatch({
      type: 'USER_ADD',
      payload: {
        undo: { type: 'USER_CHANGED_FLIGHT', payload: undoPayload },
        redo: { type: 'USER_CHANGED_FLIGHT', payload: doPayload },
      },
    })
  }

  const updateState = () => {
    let { flights } = props
    let f = flights.find((d) => d.id === props.id)
    setStart(parseInt(f.departureAndArrivalTimestamp.start))
    setOriginalStart(parseInt(f.departureAndArrivalTimestamp.start))
    setEnd(parseInt(f.departureAndArrivalTimestamp.end))
    setOriginalEnd(parseInt(f.departureAndArrivalTimestamp.end))
  }

  useEffect(() => {
    updateState()
  }, [props.flights, props.id])

  const handleStartDateChange = (date) => {
    setStart(parseInt(moment(date).format('x')) + 1) // + 1ms to force TimePicker to update
  }
  const handleEndDateChange = (date) => {
    setEnd(parseInt(moment(date).format('x')) + 1)
  }

  let s = moment(start).toDate()
  let e = moment(end).toDate()

  return (
    <div>
      <FormGroup label="Departure timestamp">
        <ControlGroup fill={true} vertical={false}>
          <DateInput2
            fill
            formatDate={(date) => moment(date).format('LL')}
            onChange={handleStartDateChange}
            parseDate={(str) => moment(str, 'LL').toDate()}
            value={s}
          />
        </ControlGroup>
      </FormGroup>
      <FormGroup label="Arrival timestamp">
        <ControlGroup fill={true} vertical={false}>
          <DateInput2
            fill
            formatDate={(date) => moment(date).format('LL')}
            onChange={handleEndDateChange}
            parseDate={(str) => moment(str, 'LL').toDate()}
            value={e}
          />
        </ControlGroup>
      </FormGroup>
      <Button text="save" intent="primary" fill onClick={() => saveClicked()}></Button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  flights: state.motassign.Repository.flights,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EditFlight)
