module.exports = {
  hotels: { preAssigned: 'preAssignedHotelCost', scheduled: 'scheduledHotelCost', isSoft: false },
  taxi: { preAssigned: 'preAssignedTaxiCost', scheduled: 'scheduledTaxiCost', isSoft: false },
  iata: { preAssigned: 'preAssignedIataCost', scheduled: 'scheduledIataCost', isSoft: false },
  deadhead: { preAssigned: 'preAssignedDeadheadCost', scheduled: 'scheduledDeadheadCost', isSoft: false },
  'nights away': {
    preAssigned: 'preAssignedNightsAwayFromBaseCost',
    scheduled: 'scheduledNightsAwayFromBaseCost',
    isSoft: false,
  },
  'below rank': {
    preAssigned: 'preAssignedFlightsBelowRankCost',
    scheduled: 'scheduledFlightsBelowRankCost',
    isSoft: true,
  },
  '1 hour margin': {
    preAssigned: 'preAssigned1HourMarginOnRestCost',
    scheduled: 'scheduled1HourMarginOnRestCost',
    isSoft: true,
  },
  'long taxi': {
    preAssigned: 'preAssignedLongTaxiRidesCost',
    scheduled: 'scheduledLongTaxiRidesCost',
    isSoft: true,
  },
  freelancer: {
    preAssigned: 'preAssignedFreelancerCost',
    scheduled: 'scheduledFreelancerCost',
    isSoft: false,
  },
  'course request': {
    preAssigned: 'preAssignedCourseRequestCost',
    scheduled: 'scheduledCourseRequestCost',
    isSoft: true,
  },
}
