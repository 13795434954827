// Import our depdendency
import { myFetch, myPost, myPut, myDelete } from 'api'
import store from 'store/index'
import config from 'config'
import { ReEventTypes, ReEvents } from './constants'
import {
  addResourceToRepository,
  addActivityToRepository,
  addAssignmentToRepository,
  addViolationToRepository,
  deleteAssignmentFromRepository,
  changeActivityTimeframeInRepository,
  addReEventToHistory,
} from './actions'
const RE_PING_EVENT = (clientId) =>
  JSON.stringify({
    type: ReEventTypes.PING,
    reference: clientId,
  })

const RE_SESSION_CREATED_EVENT = (clientId) =>
  JSON.stringify({
    type: ReEventTypes.SESSION_CREATED,
    session: {
      ref: clientId,
      rulesSetName: 'tuipoc',
    },
  })

const servicesFactory = (myFetch, myPost, myDelete, myPut) => ({
  keepAlive(clientId, interval) {
    setInterval(() => {
      this.postRulesMessageToServer(RE_PING_EVENT(clientId))
    }, interval * 1000)
  },
  addToRepository(data) {
    const { Activities, Resources, Assignments, DeletedAssignments, ChangedActivities } = data
    if (Activities) Activities.forEach((d) => store.dispatch(addActivityToRepository(d)))
    if (Resources) Resources.forEach((d) => store.dispatch(addResourceToRepository(d)))
    if (Assignments) Assignments.forEach((d) => store.dispatch(addAssignmentToRepository(d)))
    if (DeletedAssignments) DeletedAssignments.forEach((d) => store.dispatch(deleteAssignmentFromRepository(d)))
    if (ChangedActivities) ChangedActivities.forEach((d) => store.dispatch(changeActivityTimeframeInRepository(d)))
  },

  toResourceEvent(d) {
    return {
      type: ReEventTypes.RESOURCE_CREATED,
      resource: d,
    }
  },
  toActivityEvent(d) {
    return {
      type: ReEventTypes.ACTIVITY_CREATED,
      activity: d,
    }
  },
  toAssignmentEvent(d) {
    return {
      type: ReEventTypes.ASSIGNMENT_CREATED,
      assignment: d,
    }
  },
  toDeletedAssignmentEvent(d) {
    return {
      type: ReEventTypes.ASSIGNMENT_DELETED,
      reference: d,
    }
  },
  toChangedFlightEvent(d) {
    return {
      type: ReEventTypes.ACTIVITY_TIMEFRAME_CHANGED,
      ...d,
    }
  },
  createRulesEngineListOfEvents(delta) {
    const { Resources, Activities, Assignments, DeletedAssignments, ChangedActivities } = delta
    console.log(delta)
    return {
      type: 3,
      events: [
        ...Resources.map((d) => this.toResourceEvent(d)),
        ...Activities.map((d) => this.toActivityEvent(d)),
        ...Assignments.map((d) => this.toAssignmentEvent(d)),
        ...DeletedAssignments.map((d) => this.toDeletedAssignmentEvent(d)),
        ...ChangedActivities.map((d) => this.toChangedFlightEvent(d)),
      ],
    }
  },

  async checkViolations(rules) {
    let msg = this.createRulesEngineListOfEvents(rules)
    this.postRulesMessageToServer(JSON.stringify(msg))
  },

  async postRulesMessageToServer(body) {
    let result = await fetch('http://localhost:8099/rules', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        RulesEngineSession: this.clientId,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body,
    })
    let json = await result.json()

    if (json.violations) {
      json.violations.forEach((v) => {
        store.dispatch(addViolationToRepository(v))
      })
    }
  },
  initializeConnection(clientId, interval) {
    this.clientId = clientId
    this.postRulesMessageToServer(RE_SESSION_CREATED_EVENT(clientId))
    this.keepAlive(clientId, interval)
  },
})

const api = servicesFactory(myFetch, myPost, myDelete, myPut)
export default api
