export const ADD_EVENT_TO_HISTORY = 'ADD_EVENT_TO_HISTORY'
export const addEventToHistory = (data) => ({
  type: ADD_EVENT_TO_HISTORY,
  payload: data,
})

export const ADD_CREWMEMBER_TO_REPOSITORY = 'ADD_CREWMEMBER_TO_REPOSITORY'
export const addCrewMemberToRepository = (data) => ({
  type: ADD_CREWMEMBER_TO_REPOSITORY,
  payload: data,
})

export const ADD_AIRLINE_TO_REPOSITORY = 'ADD_AIRLINE_TO_REPOSITORY'
export const addAirlineToRepository = (data) => ({
  type: ADD_AIRLINE_TO_REPOSITORY,
  payload: data,
})
export const ADD_AIRPORT_TO_REPOSITORY = 'ADD_AIRPORT_TO_REPOSITORY'
export const addAirportToRepository = (data) => ({
  type: ADD_AIRPORT_TO_REPOSITORY,
  payload: data,
})
export const ADD_AIRCRAFT_TO_REPOSITORY = 'ADD_AIRCRAFT_TO_REPOSITORY'
export const addAircraftToRepository = (data) => ({
  type: ADD_AIRCRAFT_TO_REPOSITORY,
  payload: data,
})

export const ADD_AIRCRAFTTYPE_TO_REPOSITORY = 'ADD_AIRCRAFTTYPE_TO_REPOSITORY'
export const addAircrafTypeToRepository = (data) => ({
  type: ADD_AIRCRAFTTYPE_TO_REPOSITORY,
  payload: data,
})

export const ADD_FLIGHT_TO_REPOSITORY = 'ADD_FLIGHT_TO_REPOSITORY'
export const addFlightToRepository = (data) => ({
  type: ADD_FLIGHT_TO_REPOSITORY,
  payload: data,
})
export const ADD_RANK_TO_REPOSITORY = 'ADD_RANK_TO_REPOSITORY'
export const addRankToRepository = (data) => ({
  type: ADD_RANK_TO_REPOSITORY,
  payload: data,
})

export const ADD_CREWMEMBER_FLIGHT_ASSIGNMENT_TO_REPOSITORY = 'ADD_CREWMEMBER_FLIGHT_ASSIGNMENT_TO_REPOSITORY'
export const addCrewMemberFlightAssignmentToRepository = (data) => ({
  type: ADD_CREWMEMBER_FLIGHT_ASSIGNMENT_TO_REPOSITORY,
  payload: data,
})

export const ADD_CREWMEMBER_ABSENCE_TO_REPOSITORY = 'ADD_CREWMEMBER_ABSENCE_TO_REPOSITORY'
export const addCrewMemberAbsenceToRepository = (data) => ({
  type: ADD_CREWMEMBER_ABSENCE_TO_REPOSITORY,
  payload: data,
})

export const ADD_FLIGHT_DEPARR_CHANGED_TO_REPOSITORY = 'ADD_FLIGHT_DEPARR_CHANGED_TO_REPOSITORY'
export const addFlightDepArrChangedToRepository = (data) => ({
  type: ADD_FLIGHT_DEPARR_CHANGED_TO_REPOSITORY,
  payload: data,
})

export const DELETE_FLIGHT_ASSIGNMENT_FROM_REPOSITORY = 'DELETE_FLIGHT_ASSIGNMENT_FROM_REPOSITORY'
export const deleteFlightAssignmentFromRepository = (data) => ({
  type: DELETE_FLIGHT_ASSIGNMENT_FROM_REPOSITORY,
  payload: data,
})

export const DELETE_CREWMEMBER_FROM_REPOSITORY = 'DELETE_CREWMEMBER_FROM_REPOSITORY'
export const deleteCrewMemberFromRepository = (data) => ({
  type: DELETE_CREWMEMBER_FROM_REPOSITORY,
  payload: data,
})

export const DELETE_FLIGHT_FROM_TO_REPOSITORY = 'DELETE_FLIGHT_FROM_TO_REPOSITORY'
export const deleteFlightFromRepository = (data) => ({
  type: DELETE_FLIGHT_FROM_TO_REPOSITORY,
  payload: data,
})

export const INITIALIZE_CM_REPOSITORY = 'INITIALIZE_CM_REPOSITORY'
export const initializeCmRepository = (data) => ({
  type: INITIALIZE_CM_REPOSITORY,
  payload: data,
})

export const SAVE_FLIGHT = 'SAVE_FLIGHT'
export const saveFlight = (payload) => ({
  type: SAVE_FLIGHT,
  payload,
})
