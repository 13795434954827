import React, { useState } from 'react'
import TooltipButton from 'shared/TooltipButton'

const Component = (props) => {
  const [fileInputRef, setFileInputRef] = useState(undefined)
  const { onChange, upload } = props
  return (
    <>
      <input
        hidden
        accept={`.${upload}`}
        type="file"
        onChange={(event) => {
          onChange(event)
          fileInputRef.value = ''
        }}
        ref={(ref) => setFileInputRef(ref)}
        style={{ display: 'none' }}
      />
      <TooltipButton
        tooltip={`Upload ${upload?.toUpperCase()} file`}
        minimal
        icon="document-open"
        onClick={() => fileInputRef.click()}
      />
    </>
  )
}

export default Component
