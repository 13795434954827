import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styles from './RosterGantt.module.scss'
import activityStyles from './CrewMember/Activity/Activity.module.scss'
import crewMemberstyles from './CrewMember/CrewMember.module.scss'
import { changeFilter } from '../actions'
import store from 'store'
import { Card, RadioGroup, Radio, Menu, MenuItem, Checkbox, MenuDivider } from '@blueprintjs/core'

import HeatmapLabel from './CrewMember/CrewMemberLabel/HeatmapLabel'
import Pane from './Pane'

const HeatmapRow = (props) => {
  const { xScale, x } = props
  const { collapsed, setCollaped } = false
  return (
    <div
      style={{ position: 'relative', height: 20 }}
      className={[props.data.odd ? crewMemberstyles.odd : crewMemberstyles.even].join(' ')}>
      {props.data.values.map((d, i) => (
        <div
          key={props.data.title + d.date}
          className={activityStyles.activity}
          style={{
            top: 0,
            position: 'absolute',
            lineHeight: '20px',
            fontWeight: 'bold',
            color: 'black',
            transform: `translateX(${xScale(d.start - props.x)}px)`,
            height: 20,
            width: xScale(d.end) - xScale(d.start),
            backgroundColor: `rgba(255,174,118,${d.value / 10})`,
          }}>
          <span style={{ mixBlendMode: 'color-burn' }}>{d.value}</span>
        </div>
      ))}
    </div>
  )
}
const Scroller = (props) => {
  const [dragStart, setDragStart] = useState(null)

  const { data, LabelElement } = props

  const filter = useSelector((state) => state.rosters.filter)
  const { filterHeatmap } = filter
  const timezone = filter.timezone
  const expanded = useSelector((state) => state.rosters.expandedCrewMembers)
  const toggleSplitPreAssigned = useSelector((state) => state.rosters.filter.toggleSplitPreAssigned)
  const [hover, setHover] = useState(false)

  // select data in scrollY view
  const datalist = React.useMemo(
    () =>
      data
        .filter((d) =>
          filter.searchHomeBase.length > 0 ? filter.searchHomeBase.some((base) => d.title.indexOf(base) >= 0) : true
        )
        .filter((d) =>
          filter.searchFunction.length > 0 ? filter.searchFunction.some((func) => d.title.indexOf(func) >= 0) : true
        ),
    [filter, data]
  )

  return (
    <Pane
      {...props}
      onDragInProgress={props.onDragInProgress}
      onZoomChange={props.onZoomChange}
      scrollChange={props.scrollChange}
      zoom={props.zoom}
      x={props.x}
      menu={
        <Menu>
          <MenuItem
            shouldDismissPopover={true}
            icon={<Checkbox checked={filterHeatmap === 'IgnoredPreAssignedPairing'} style={{ marginTop: 10 }} />}
            onClick={() => {
              store.dispatch(changeFilter({ filterHeatmap: 'IgnoredPreAssignedPairing' }))
            }}
            text="IgnoredPreAssignedPairing"
          />
          <MenuItem
            shouldDismissPopover={true}
            icon={<Checkbox checked={filterHeatmap === 'IgnoredPreAssignedOffDay'} style={{ marginTop: 10 }} />}
            onClick={() => store.dispatch(changeFilter({ filterHeatmap: 'IgnoredPreAssignedOffDay' }))}
            text="IgnoredPreAssignedOffDay"
          />
          <MenuItem
            shouldDismissPopover={true}
            icon={<Checkbox checked={filterHeatmap === 'Both'} style={{ marginTop: 10 }} />}
            onClick={() => store.dispatch(changeFilter({ filterHeatmap: 'Both' }))}
            text="Both"
          />
        </Menu>
      }
      labels={(d) => (
        <HeatmapLabel
          expanded={expanded.includes(d.title) || toggleSplitPreAssigned}
          data={d}
          issues={props.issues}
          key={d.title}
          hover={d.title === hover}
          onHover={setHover}
          onClick={
            props.onLabelAdd
              ? () => {
                  props.onLabelAdd(d)
                }
              : undefined
          }
        />
      )}
      data={datalist}
      row={(d, x, xScale, width) => (
        <HeatmapRow
          expanded={expanded.includes(d.title) || toggleSplitPreAssigned}
          edit={props.edit}
          key={d.title}
          //  onDoubleClick={onDoubleClick}
          onClick={props.onClick}
          onActivityClick={props.onActivityClick}
          timezone={timezone}
          hover={d.title === hover}
          onHover={setHover}
          droppable={props.droppable}
          draggable={props.draggable}
          draggedItem={props.draggedItem}
          dragging={dragStart}
          issues={props.issues}
          data={d}
          x={x}
          xScale={xScale}
          width={width}
          displayAirportTimes={props.displayAirportTimes}
          displaySafeCare={props.safeCare}
          displayDutyTimes={props.dutyTimes}
        />
      )}
    />
  )
}

export default React.memo(Scroller)
