import * as d3 from 'd3'

import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import styles from './RosterGantt.module.scss'
import activityStyles from './CrewMember/Activity/Activity.module.scss'
import { Tag, Button, Icon } from '@blueprintjs/core'
import PaneHeader from './PaneHeader'
import DaySeparators from './Cursor/DaySeparators'
import Weekends from './Cursor/Weekends'
import TimezoneDropdown from 'shared/TimezoneDropdown/TimezoneDropdown'
import store from 'store'
import { resetFilter, changeFilter, setSelectedCrewMembers, setExpanded } from '../actions'
import { useDrag } from './useDrag'
import OutsidePublication from './Cursor/OutsidePublication'
import { Console } from '@blueprintjs/icons/lib/esm/generated-icons/16px/paths'

function useTraceUpdate(props) {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps)
    }
    prev.current = props
  })
}

const Scroller = (props) => {
  const ref = useRef()
  const verticalScrollerRef = useRef()
  const scrollRef = useRef(props.scroll)
  scrollRef.current = props.scroll
  const { height } = props
  const width = props.width - 340
  const xScale = props.scroll.xScale
  const oldScroll = useRef(0)
  const [scrollState, setScrollState] = useState({ y: 0 })
  const [dragStart, setDragStart] = useState(null)
  const { data, rowHeight } = props

  const { collapsed, onCollapseChange } = props

  const [y, _setY] = useState(0)
  const yRef = useRef(y)

  const onDragStart = useCallback(() => {
    props.onDragStart()
    oldScroll.current = ref.current.scrollLeft
  }, [ref])

  const onDragEnd = useCallback(() => {
    props.onDragEnd()
    oldScroll.current = ref.current.scrollLeft
  }, [ref])

  const onDrag = (e) => {
    if (e.movementX === 0) return
    let x = Math.max(0, oldScroll.current - e.dx)
    x = Math.min(x, scrollRef.current.xScale.range()[1] - scrollRef.current.width)

    props.onChangeScroll({
      source: 'drag',
      xScale: scrollRef.current.xScale,
      zoom: scrollRef.current.zoom,
      width: scrollRef.current.width,
      x,
    })
  }

  const drag = useDrag(ref, onDragStart, onDrag, onDragEnd)

  const setY = (y) => {
    yRef.current = y
    _setY(y)
  }

  const timezone = useSelector((state) => state.rosters.filter.timezone)
  const expanded = useSelector((state) => state.rosters.expandedCrewMembers)

  useEffect(() => {
    if (props.draggedItem) setDragStart(null)
  }, [props.draggedItem])

  useEffect(() => {
    if (!props.dragInProgress) setDragStart(null)
  }, [props.dragInProgress])

  useEffect(() => {
    if (props.resetSavedScroll) ref.current.scrollTop = scrollState.y
  }, [props.resetSavedScroll])

  //sync internal scrollbar with external request scroll
  useLayoutEffect(() => {
    ref.current.scrollLeft = props.scroll.x
  }, [props.scroll.x])

  useLayoutEffect(() => {
    if (!verticalScrollerRef?.current) return
    const verticalScroll = () => {
      setY(verticalScrollerRef.current.scrollTop)
    }

    //  ref.current.addEventListener('scroll', horizontalScroll)
    verticalScrollerRef.current.addEventListener('scroll', verticalScroll)
    return () => verticalScrollerRef?.current?.removeEventListener('scroll', verticalScroll)
  }, [])
  // console.log('render ' + props.pane + ' ' + JSON.stringify(drag))
  // select data in scrollY view
  const overDraw = 200
  const dataEnriched = React.useMemo(() => data.map((d, i) => ({ ...d, top: i * rowHeight, odd: i % 2 })), [data])
  const dataList = React.useMemo(
    () => dataEnriched.filter((d) => d.top + rowHeight > y - overDraw).filter((d) => d.top < y + height + overDraw),
    [dataEnriched, y, height]
  )

  const totalHeight = React.useMemo(() => props.data?.reduce((sum, v) => (sum += rowHeight), 0), [props.data])

  //  () => (collapsed ? 30 : props.data.length * 34 + expanded?.length * 34 + height / 2 || 0),
  //  [props.data, expanded, height]
  // )

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', top: 0, bottom: 0, position: 'relative' }}>
      <PaneHeader
        title={props.title}
        disableCollapse={props.disableCollapse}
        onLabelAdd={props.onLabelAdd}
        menu={props.menu}
        onCollapseChange={onCollapseChange}
        collapsed={collapsed}
      />
      <div
        ref={verticalScrollerRef}
        style={{
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',

          display: 'flex',
          overflowY: collapsed ? 'hidden' : 'scroll',
          overflowX: 'hidden',
        }}>
        <div
          className={styles.rosterLabels}
          style={{
            top: 0,
            bottom: 0,

            height: Math.max(height - 3, totalHeight),
          }}>
          <div style={{ height: dataList[0]?.top }} />
          {!collapsed && dataList.map((d) => props.labels(d))}
        </div>

        <div
          ref={ref}
          className={styles.rosterViewport}
          style={{
            width: '100%',

            height: Math.max(height - 3, totalHeight),
          }}>
          <div style={{ width: xScale.range()[1] }}>
            <DaySeparators timezone={timezone} xScale={xScale} x={props.scroll.x} width={width} />
            <Weekends timezone={timezone} xScale={xScale} x={props.scroll.x} width={width} />
            <div style={{ height: dataList[0]?.top }} />
            {!collapsed && dataList.map((d) => props.row(d, props.scroll.x, xScale, width, drag))}

            <OutsidePublication
              timezone={timezone}
              period={[props.pubStart, props.pubEnd]}
              xScale={xScale}
              x={props.scroll.x}
              width={width}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Scroller)
