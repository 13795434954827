import React, { useState, useEffect } from 'react'
import Divider from 'shared/Divider/Divider'
import { connect, useSelector } from 'react-redux'
import { Navbar, Alignment, Button, ButtonGroup, MultiSlider, Switch, Icon } from '@blueprintjs/core'
import api from './api'
import styles from './Publications.module.scss'
import Search from 'shared/search'
import MultiSearchDropdown from 'shared/SearchDropdown/MultiSearchDropdown'
import { Rows, Row } from 'shared/PanelLayout/PanelLayout'
import { InputDate } from 'shared/Forms'
import store from 'store'
import { PubState, PubEventType, changeFilter, changePublicationSearch } from './actions'
import { useNavigate } from 'react-router-dom'
import { lux } from 'shared/LuxonHelpers'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'

//dirty conversion from key to text
const labels = {
  last_modified_at: 'Last modified date',
  name: 'Name',
  created_at: 'Created date',
  first_day: 'Start day',
  calculation_mode: 'Calculation mode',
  last_day: 'End day',
}
const Filter = (props) => {
  const navigate = useNavigate()
  const { filter } = props
  const { search, showArchived, startDate, endDate, sortBy, sortDir, showManual, showFlex } = filter
  useEffect(() => {
    props.onFilterChanged({ search, showArchived, showManual, showFlex, startDate, endDate, sortBy, sortDir })
  }, [search, showArchived, showManual, showFlex, startDate, endDate, sortBy, sortDir])

  return (
    <Rows>
      <Row fixed>
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading>Filter</Navbar.Heading>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button minimal icon="cross" onClick={() => props.onClose()} style={{ right: 10, position: 'absolute' }} />
          </Navbar.Group>
        </Navbar>
      </Row>
      <Row padding={10}>
        <Search
          fill
          value={search}
          placeholder="Search publication name"
          onChange={(value) => {
            console.log(value)
            props.onFilterChanged({ ...filter, search: value })
          }}
          style={{ marginBottom: 10 }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputDate
            canClearSelection={true}
            placeholder="From"
            fill
            value={startDate}
            onChange={(date) => {
              props.onFilterChanged({ ...filter, startDate: date })
            }}
          />
          <span style={{ margin: 5 }}></span>
          <InputDate
            placeholder="To"
            canClearSelection={true}
            fill
            value={endDate}
            onChange={(date) => {
              props.onFilterChanged({ ...filter, endDate: date })
            }}
          />
        </div>
        <Divider text="Sort by" style={{ marginTop: 30, marginBottom: 10 }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SelectDropdown
            hideFilter
            activeItem={filter.sortBy}
            usePortal={false}
            style={{ display: 'inline-block', flex: 3 }}
            items={['last_modified_at', 'name', 'calculation_mode', 'first_day', 'last_day', 'created_at']}
            text={(e) => labels[e]}
            onChange={(date) => {
              props.onFilterChanged({ ...filter, sortBy: date })
            }}
          />
          <Button
            icon={filter.sortDir === 'asc' ? 'sort-asc' : 'sort-desc'}
            minimal
            onClick={() => props.onFilterChanged({ ...filter, sortDir: filter.sortDir === 'asc' ? 'desc' : 'asc' })}
          />
        </div>
        <Divider text="Show/Hide" style={{ marginTop: 30, marginBottom: 10 }} />
        <Switch
          alignIndicator={Alignment.RIGHT}
          intent="primary"
          labelElement={
            <span style={{ fontSize: 13 }}>
              <Icon icon="airplane" intent="primary" style={{ marginRight: 10 }}></Icon> Only flex publications
            </span>
          }
          checked={showFlex}
          onChange={() => {
            props.onFilterChanged({ ...filter, showFlex: !showFlex })
          }}
        />
        <Switch
          alignIndicator={Alignment.RIGHT}
          intent="danger"
          labelElement={
            <span style={{ fontSize: 13 }}>
              <Icon icon="lab-test" intent="warning" style={{ marginRight: 10 }}></Icon> Only manual data publications
            </span>
          }
          checked={showManual}
          onChange={() => {
            props.onFilterChanged({ ...filter, showManual: !showManual })
          }}
        />
        <Switch
          alignIndicator={Alignment.RIGHT}
          intent="danger"
          labelElement={
            <span style={{ fontSize: 13 }}>
              <Icon icon="trash" intent="danger" style={{ marginRight: 10 }}></Icon> Show archived
            </span>
          }
          checked={showArchived}
          onChange={() => {
            props.onFilterChanged({ ...filter, showArchived: !showArchived })
          }}
        />
      </Row>
    </Rows>
  )
}

export default Filter
