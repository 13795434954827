import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { Cell, setCellStyle } from '../AgGridCellValidator'
import { FormGroup, Collapse, Tabs, Tab, H5, H6, Tag, Icon, Classes, Button, Divider, Switch } from '@blueprintjs/core'
import InputText from 'shared/Forms/InputText'
import InputDate from 'shared/Forms/InputDate'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import { DatePicker } from '@blueprintjs/datetime2'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import { useSelector } from 'react-redux'
const newPersonal = {
  personalID: null,
  pLcode: null,
  crewFunction: null,
  homeBase: null,
  dateOfBirth: null,
  seniority: 0,
  slack: false,
  specialFunctions: [],
  baseSwitches: [],
  aircraftQualifications: [],
  crewContracts: [],
  airportQualifications: [],
  historicalMonthlyData: [],
  visa: [],
  parentalLeave: [],
  expiries: [],
  activities: [],
  employeeCode: '',
  flight_duties: [],
  rosterIssues: [],
  dataIssues: [],
  contractBase: [],
  labels: [],
  flightDutyLabels: [],
}

const CellDateEditor = forwardRef((props, ref) => {
  const [date, setDate] = useState(props.value)
  const timezone = [props.timezone]
  const [editing, setEditing] = useState(true)
  const refContainer = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return date
      },
    }
  })

  const focus = () => {
    window.setTimeout(() => {
      if (refContainer.current) {
        refContainer.current.select()
      }
    })
  }

  useEffect(() => {
    focus()
  }, [])
  useEffect(() => {
    if (!editing) {
      props.stopEditing()
    }
  }, [editing])

  return <InputDate forwardRef={refContainer} value={date} onChange={setDate} />
})

const CellDateEditorSelector = (params) => {
  return { component: CellDateEditor }
}

const Table = (props) => {
  const timezone = props.timezone

  const colDefs = {
    aircraftQualifications: [
      { field: 'aircraftType', editable: true, width: 75 },
      { field: 'function', editable: true, width: 75 },
      { field: 'fromDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'newUntilDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
    ],
    specialFunctions: [
      {
        field: 'fromDate',
        editable: true,

        width: 100,
        cellEditor: CellDateEditor,
        cellEditorParams: { timezone },
      },
      { field: 'untilDate', editable: true, width: 100, cellEditor: CellDateEditor, cellEditorParams: { timezone } },
      { field: 'specialFunction', editable: true, width: 75 },
    ],
    expiries: [
      { field: 'code', editable: true, width: 75 },
      { field: 'type', editable: true, width: 75 },
      { field: 'validFrom', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'validUntilCalculated', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'validUntilManual', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
    ],
    baseSwitches: [
      { field: 'base', editable: true, width: 75 },
      { field: 'fromDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'untilDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
    ],
    crewContracts: [
      { field: 'pctAbbreviation', editable: true, width: 75 },
      { field: 'pctKz', editable: true, width: 100 },
      { field: 'startDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'endDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'maximumFlightHoursPerYear', editable: true, width: 100 },
    ],
    visa: [
      { field: 'country', editable: true, width: 75 },
      { field: 'startDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'endDate', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
    ],
    specialAirportQualifications: [
      { field: 'airport', editable: true, width: 75 },
      { field: 'lastTraining', editable: true, width: 100 },
      { field: 'validUntil', editable: true, width: 100, cellEditorSelector: CellDateEditorSelector },
      { field: 'aircraftIATAType', editable: true, width: 100 },
      { field: 'function', editable: true, width: 100 },
    ],
  }
  const [field, setField] = useState('specialFunctions')
  const [data, setData] = useState(props.data)
  const [gridApi, setGridApi] = useState(null)
  const [selected, setSelected] = useState(0)
  const frameworkComponents = {
    Cell: Cell,
  }

  const defaultColDef = {
    cellRenderer: (props) => {
      const isEmpty = props.value === '' || props.value === null
      return (
        <span style={{ fontStyle: isEmpty ? 'italic' : undefined, opacity: isEmpty ? 0.5 : undefined }}>
          {isEmpty ? props.placeholder || '-' : props.value}
        </span>
      )
    },
  }
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
    setGridApi(params.api)
  }

  useEffect(() => {
    setSelected(0)
    if (gridApi) gridApi.sizeColumnsToFit()
  }, [field])

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs[field])
    if (gridApi) gridApi.redrawRows({ force: true })
    if (gridApi) gridApi.sizeColumnsToFit()
    if (props.onChange) props.onChange(data)
  }, [data])

  return (
    <div style={{ height: 200 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'flex-end', marginBottom: 10 }}>
        <SelectDropdown
          style={{ justifySelf: 'start-end' }}
          width={200}
          items={[
            'specialFunctions',
            'baseSwitches',
            'aircraftQualifications',
            'expiries',
            'crewContacts',
            'airportQualifications',
            'visa',
          ]}
          activeItem={field}
          onChange={(d) => setField(d)}
          hideFilter
        />
        <span style={{ flex: 1 }} />
        <Button
          minimal
          intent={'primary'}
          text="Add Row"
          icon="plus"
          onClick={() => {
            let newData = JSON.parse(JSON.stringify(data))
            let o = {}
            const keys = colDefs[field].map((d) => d.field).filter((d) => d !== 'checkboxBtn')

            keys.forEach((k) => {
              o[k] = colDefs[field]?.find((d) => d.field === k)?.default || ''
            })
            if (newData[field]?.length >= 0) newData[field].splice(0, 0, o)
            else {
              newData[field] = [o]
            }

            setData(newData)
          }}
        />
        <Button
          minimal
          intent={'danger'}
          disabled={selected === 0}
          text={selected !== 0 ? `Delete ${selected} rows` : ''}
          icon="trash"
          onClick={(e) => {
            const selectedRows = gridApi.getSelectedRows()

            const json = selectedRows.map((d) => JSON.stringify(d)) || []
            let newData = JSON.parse(JSON.stringify(data[field]?.filter((d) => !json.includes(JSON.stringify(d)))))
            setData({ ...data, [field]: newData })
          }}
        />
      </div>
      <div className="mp-ag-grid" style={{ height: '150px ', width: '100%' }}>
        <AgGridReact
          onGridReady={onGridReady}
          onSelectionChanged={(e) => setSelected(e.api.getSelectedRows().length)}
          //    frameworkComponents={frameworkComponents}
          onCellValueChanged={(e) => {
            let newData = JSON.parse(JSON.stringify(data))
            newData[e.rowIndex] = e.data

            setData(newData)
          }}
          //immutableData={true}
          //getRowNodeId={(data) => data.date}
          rowData={data?.[field]}
          defaultColDef={defaultColDef}
          columnDefs={colDefs?.[field]}
          rowSelection="multiple"
        />
      </div>
    </div>
  )
}
const CrewDialog = (props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(null)
  const [field, setField] = useState('specialFunctions')
  const timezone = useSelector((state) => state.rosters.filter.timezone)

  useEffect(() => {
    props.data ? setIsOpen(true) : setIsOpen(null)
  }, [props.data])

  return (
    <CreateOrSaveDialog
      style={{ width: 800 }}
      usePortal={true}
      //   payload={personalData}
      //   id={personalData}
      icon="person"
      title="Personal Data"
      edit={props.data === 'new' ? null : props.data}
      resetObject={newPersonal}
      onClose={props.onClose}
      onCreate={(data) => {
        setIsOpen(null)
        const newData = []

        for (let i = 0; i < (data.count || 1); i++)
          newData.push({
            ...newPersonal,
            crewFunction: data.crewFunction,
            homeBase: data.homeBase,
            pLcode: data.employeeCode + (i + 1),
            employeeCode: data.employeeCode + (i + 1),
          })
        props.onCreate(newData)
      }}
      onDelete={props.onDelete}
      onSave={props.onSave}
      isOpen={isOpen}>
      {(data, setData) => {
        return (
          <div style={{ display: 'flex' }}>
            <span>
              <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Function</span>} inline>
                <SelectDropdown
                  activeItem={data?.crewFunction}
                  width={100}
                  items={['CP', 'FO', 'CCM', 'SCC']}
                  onChange={(c) => setData({ ...data, crewFunction: c })}
                  hideFilter
                />
              </FormGroup>
              <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Code</span>} inline>
                <InputText
                  style={{ width: 100 }}
                  value={data?.pLcode}
                  onChange={(val) => {
                    setData({ ...data, employeeCode: val, pLcode: val })
                  }}
                />
              </FormGroup>
              <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Home Base</span>} inline>
                <AirportDropdown
                  width={100}
                  activeItem={data?.homeBase}
                  onChange={(base) => setData({ ...data, homeBase: base.iata })}
                />
              </FormGroup>
              {props.data === 'new' && (
                <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Count</span>} inline>
                  <InputText
                    style={{ width: 30 }}
                    value={data?.count}
                    onChange={(c) => setData({ ...data, count: c })}
                  />
                </FormGroup>
              )}
            </span>
            <span style={{ width: 500, marginLeft: 20 }}>
              <div style={{ display: 'flex' }}></div>
              <Table data={data} onChange={(data) => setData(data)} timezone={timezone} />
            </span>
          </div>
        )
      }}
    </CreateOrSaveDialog>
  )
}

export default CrewDialog
