import React, { useState } from 'react'
import { SizeMe } from 'react-sizeme'

import styles from './PanelLayout.module.scss'

const View = ({ id, mockup, children, className }) => (
  <div
    className={className}
    id={id}
    style={{
      width: '100vw',
      height: '100vh',
    }}>
    {React.Children.map(children, (c, index) => {
      return React.cloneElement(c, {
        mockup,
      })
    })}
  </div>
)

const Rows = ({ children, mockup, className, z }) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      zIndex: z,
    }}
    className={className}>
    {React.Children.map(children, (c, index) => {
      if (c)
        return React.cloneElement(c, {
          verticalorientation: true,
          mockup,
        })
      return undefined
    })}
  </div>
)

const Cols = ({ children, mockup, className, z }) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      zIndex: z,
    }}
    className={className}>
    {React.Children.map(children, (c, index) => {
      if (c)
        return React.cloneElement(c, {
          horizontalorientation: true,
          mockup,
        })
      return undefined
    })}
  </div>
)

const Panel = (props) => {
  const [rgba, setRgba] = useState(`rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.1)`)

  const {
    bgColor,
    dark,
    style,
    fixed,
    responsive,
    duration = 100,
    padding,
    mockup,
    shadow,
    children,
    collapse,
    verticalorientation,
    horizontalorientation,
    noscroll,
    collapsed,
    width,
    height,
    muted,
    content,
    z,
  } = props

  const orientation = verticalorientation ? 'vertical' : 'horizontal'

  let activeStyles = []
  if (dark) activeStyles.push('theme-alternate')
  if (shadow) activeStyles.push(styles.shadow)
  if (shadow) activeStyles.push(styles[`shadow-${orientation}-${collapse}`])
  if (collapsed) activeStyles.push(styles.collapsed)
  if (muted) activeStyles.push(styles.muted)
  return (
    <SizeMe monitorHeight={false}>
      {({ size }) => (
        <>
          {responsive && !collapsed && <div className={styles.backdrop} />}
          <div
            className={[
              styles.panel,
              content ? styles.content : undefined,
              responsive ? styles.responsive : undefined,
              ...activeStyles,
              props.className,
            ].join(' ')}
            height={size.height}
            style={{
              ...style,
              right: responsive && collapse === 'right' ? 0 : undefined,
              left: responsive && collapse === 'left' ? 0 : undefined,
              transition: `margin ${duration}ms ease-out 0s`,
              position: 'relative',
              cursor: 'default',
              zIndex: z,
              flex: collapse || fixed ? '0 0 auto' : '1 1 0%',
              marginLeft: collapse === 'left' && collapsed && horizontalorientation ? -width || -size.width : 0,
              marginTop: collapse === 'top' && collapsed && verticalorientation ? -height || -size.height : 0,
              marginRight: collapse === 'right' && collapsed && horizontalorientation ? -width || -size.width : 0,
              marginBottom: collapse === 'bottom' && collapsed && verticalorientation ? -height || -size.height : 0,
              overflow: collapse || fixed ? 'visible' : noscroll ? 'hidden' : 'auto',
              width: width ? width : 'auto',
              height: height && verticalorientation ? height : 'auto',
              backgroundColor: mockup ? rgba : dark ? '#1b2630' : bgColor ? bgColor : 'auto',
              padding: padding,
            }}>
            {children}
          </div>
        </>
      )}
    </SizeMe>
  )
}

const Col = Panel
const Row = Panel
export { View, Rows, Cols, Panel, Col, Row }
