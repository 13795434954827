import React, { useEffect, useState } from 'react'

import { Routes, Route, Navigate, matchPath, matchRoutes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrivateRoute from '../PrivateRoute'
import { Rows, Row, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import { Navbar, Alignment, Button } from '@blueprintjs/core'
import MpNavBar from './NavBar'
import Menu from './Menu'
import Cost from './Cost'
import Summary from './Cost/summary'
import Distributions from './Distributions'
import Violations from './Violations'
import Equal from './Equal'

import api from './api'

import pubApi from '../publication/api'

const Dashboard = (props) => {
  const [filterOpen, setFilterOpen] = useState(true)
  const [tabId, setTabId] = useState('')
  const activeContext = useSelector((state) => state.user.profile.active_context)

  const location = useLocation()
  let res = matchPath('/dashboard/:tab', location.pathname)
  const { tab } = res?.params || 'cost'

  if (tab !== tabId) setTabId(tab)
  useEffect(() => {
    api.storeAllPublications(activeContext)
  }, [activeContext])

  return (
    <Cols>
      <Col>
        <Rows>
          <Row fixed>
            <MpNavBar
              tabId={tabId}
              onChangeTab={(id) => setTabId(id)}
              onChange={() => {
                setFilterOpen(!filterOpen)
              }}
            />
          </Row>
          <Row>
            <Routes>
              <Route path="/" element={<Navigate to={'cost'} />} />
              <Route path="cost" element={<Cost />} />
              <Route path="distributions" element={<Distributions />} />
              <Route path="violations" element={<Violations />} />
              <Route path="summary" element={<Summary />} />
              <Route path="equal" element={<Equal />} />
            </Routes>
          </Row>
        </Rows>
      </Col>
      <Col collapse="right" collapsed={!filterOpen} dark fixed width={400}>
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading>Settings</Navbar.Heading>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                <Button minimal icon="cross" onClick={() => setFilterOpen(!filterOpen)} />
              </Navbar.Group>
            </Navbar>
          </Row>
          <Row>
            <Menu tabId={tabId} />
          </Row>
        </Rows>
      </Col>
    </Cols>
  )
}

export default Dashboard
