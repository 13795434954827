import { HotkeysTarget2 } from '@blueprintjs/core'
import * as d3 from 'd3'
import { debounce } from 'lodash'
import * as moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import styles from './RosterGantt.module.scss'
import styles2 from './RosterResource.module.scss'
import { useNavigate } from 'react-router-dom'

const mapStateToProps = (state) => {
  return {
    loadingRoster: state.rosters.loadingRoster,
    activePublication: state.rosters.activePublication || { rosterData: [] },
    employeeData: state.bidding.employeeData,
  }
}

class Comp extends Component {
  state = {}

  constructor(props) {
    super(props)
    this.el = React.createRef()
  }

  resize() {
    const { width, height } = this.calcDimensions()

    if (!this.el) return false

    this.x.range([150, width])

    this.xAxis = d3
      .axisTop(this.x)
      .ticks(((width + 2) / (height + 2)) * 10)
      .tickSize(-height)
      .tickSizeOuter(0)

    this.draw(this.props)
  }

  hotkeys = [{ combo: 'escape', global: true, onKeyDown: () => history.back() }]

  createDatumCursor(height) {
    this.datumCursor = this.svg // datumCursor line
      .append('line')
      .attr('y1', 20)
      .attr('y2', height)
      .attr('stroke', 'red')
    this.datumCursorText = this.svg
      .append('text')
      .attr('y', 15)
      .attr('x', 0)
      .attr('text-anchor', 'middle')
      .attr('fill', '#ff000088')
    this.hideDatumCursor()
  }
  updateDatumCursor(x) {
    if (x < 150) this.hideDatumCursor()
    else this.showDatumCursor()
    if (!x) x = this.datumCursor.attr('x1') // take previous position if no new position specified
    const t = this.x.invert(x)
    this.datumCursor.attr('x1', x).attr('x2', x)
    this.datumCursorText.attr('x', x).html(moment.utc(t).format('HH:mm'))
  }

  hideDatumCursor() {
    this.datumCursorText.style('display', 'none')
    this.datumCursor.style('display', 'none')
  }

  showDatumCursor() {
    this.datumCursor.style('display', 'block')
    this.datumCursorText.style('display', 'block')
  }

  tooltip(el) {
    d3.select('.' + styles.tooltip).remove()
    el.on('mouseleave', () => {
      if (el.tool) el.tool.remove()
      //  d3.select(this).classed(styles.hovering, false).classed(styles.not_hovering, true)
    }).on('mouseenter mousemove', function (event, d2) {
      if (event.type === 'mouseenter') {
        var tooltip = d3.select('body')
        el.classed(styles.hovering, true).classed(styles.not_hovering, false)
        el.tool = tooltip.append('div').attr('class', styles.tooltip)
      }
      d3.select(d3.select(this).node().parentNode).datum()
      if (el.tool)
        el.tool
          .style('top', event.pageY + 'px')
          .style('left', event.pageX + 15 + 'px')
          .html((e) => {
            switch (d2.typeOfActivity) {
              case 'Standby':
              case 'Weekend':
                return `<b>${d2.typeOfActivity}</b><br/>from ${moment
                  .utc(d2.realStartTime)
                  .format('MMMM, Do HH:mm')}<br/>to ${moment.utc(d2.realEndTime).format('MMMM, Do HH:mm')}`
              case 'Hotel':
                return `<b>${d2.typeOfActivity} - ${d2.location}</b><br/>from ${moment
                  .utc(d2.realStartTime)
                  .format('MMMM, Do HH:mm')}<br/>to ${moment.utc(d2.realEndTime).format('MMMM, Do HH:mm')}`
              case 'PreAssigned':
                return `<b>${d2.typeOfActivity} - ${d2.activityCode}</b><br/>from ${moment
                  .utc(d2.realStartTime)
                  .format('MMMM, Do HH:mm')}<br/>to ${moment.utc(d2.realEndTime).format('MMMM, Do HH:mm')}`

              case 'Flight':
                return `<b>${d2.preAssigned ? 'Preassigned ' : ''}${d2.typeOfActivity} from ${d2.from} to ${
                  d2.to
                }</b><br/>from ${moment.utc(d2.realStartTime).format('MMMM, Do HH:mm')}<br/>to ${moment
                  .utc(d2.realEndTime)
                  .format('MMMM, Do HH:mm')}`

              case 'Deadhead':
                return `<b>${d2.typeOfActivity} [${d2.flightNumber}] from ${d2.from} to ${d2.to}</b><br/>from ${moment
                  .utc(d2.realStartTime)
                  .format('MMMM, Do HH:mm')}<br/>to ${moment.utc(d2.realEndTime).format('MMMM, Do HH:mm')}`
              case 'IataFlight':
                return `<b>${d2.typeOfActivity} [${d2.flightNumbers.join(', ')}] from ${d2.from} to ${
                  d2.to
                }</b><br/>from ${moment.utc(d2.realStartTime).format('MMMM, Do HH:mm')}<br/>to ${moment
                  .utc(d2.realEndTime)
                  .format('MMMM, Do HH:mm')}`
              default:
                return `<b>${d2.typeOfActivity} from ${d2.from} to ${d2.to}</b><br/>from ${moment
                  .utc(d2.realStartTime)
                  .format('MMMM, Do HH:mm')}<br/>to ${moment.utc(d2.realEndTime).format('MMMM, Do HH:mm')}`
            }
          })
    })
  }

  getActivityHtml(d) {
    let s = ''
    switch (d.typeOfActivity) {
      case 'Flight':
      case 'Deadhead':
        s += `${d.from} - ${d.to} [${d.flightNumber}]`
        if (d.crewRole) s += ` as ${d.crewRole}`
        break
      case 'Taxi':
      case 'TaxiOwnMeans':
      case 'IataFlight':
        s += `${d.from} - ${d.to}`
        break
      case 'Standby':
        s += `${d.location} [${d.standbyCode}]`
        break
      case 'Hotel':
        s += `Hotel [${d.location}]`
        break
      case 'Weekend':
        s = d.activityCode // Show calculator-assigned code for scheduled weekends
        break
      case 'BaseSwitch':
        s = ''
        break
      default:
        s = '<span>' + d.activityCode + '</span>'
    }
    return s
  }
  calcDimensions() {
    if (this.el) {
      this.width = this.el.clientWidth
      this.height = this.el.clientHeight
    }

    return {
      width: this.width,
      height: this.height,
    }
  }
  findMinMax(data) {
    return [
      moment.utc(Math.min(...data.activities.map((d) => d.startTime))).startOf('d'),
      moment.utc(Math.max(...data.activities.map((d) => d.endTime))).endOf('d'),
    ]
  }

  split(dates, data) {
    let splittedActivities = []

    data.forEach((d) => {
      let startTime = moment.utc(d.startTime)
      d.endTime = moment.utc(d.endTime)
      let first = true
      dates.some((date, day) => {
        if (date.clone().endOf('d').isBefore(startTime)) return false

        if (d.endTime.isAfter(date.clone().endOf('d'))) {
          splittedActivities.push({
            ...d,
            realStartTime: d.startTime,
            realEndTime: d.endTime,
            startTime: startTime.diff(startTime.clone().startOf('d')).valueOf(),
            endTime: date.clone().endOf('d').valueOf(),
            day: day,
            first: first,
            last: false,
            duration: date.clone().endOf('d').diff(startTime),
          })
          first = false
          startTime = date.clone().add(1, 'd')
        } else {
          if (d.endTime.valueOf() - startTime.valueOf() <= 1) return true
          splittedActivities.push({
            ...d,
            realStartTime: d.startTime,
            realEndTime: d.endTime,
            startTime: startTime.diff(startTime.clone().startOf('d')).valueOf(),
            endTime: d.endTime.clone().diff(d.endTime.clone().startOf('d')).valueOf(),
            day: day,
            first: first,
            last: true,
            duration: d.endTime.diff(startTime),
          })
          first = false
          return true
        }
        return false
      })
    })

    return splittedActivities
  }

  drawBids(data) {
    this.calcDimensions()
    let self = this
    let timer

    let bids = d3
      .select('#roster')

      .selectAll('.' + styles.bid)
      .data(data, (d) => d.id)
      .join(
        (enter) => {
          let newBids = enter
            .append('div')
            .attr('class', (d) => styles.bid)

            .on('mouseenter', function () {
              d3.select(this).classed(styles2.hover, true)
            })
            .on('mouseleave', function () {
              d3.select(this).classed(styles2.hover, false)
            })

          newBids.on('click', (event) => {
            event.stopImmediatePropagation()
            event.stopPropagation()

            event.preventDefault()
          })
          newBids
            .append('div')
            .attr('class', styles2.label)
            .html((d) => d.type + ' - ' + d.credits)

          newBids
            .append('div')
            .attr('class', styles2.handleLeft)
            .call(
              d3
                .drag()
                .container(function () {
                  return this.parentNode.parentNode
                })
                .on('drag', (event, d) => {
                  let startTime =
                    this.x.invert(event.x) +
                    Math.floor(event.y / 26) * 24 * 3600 * 1000 +
                    this.date0.startOf('d').valueOf()

                  let item = this.bids.find((e) => e.id === event.subject.id)
                  item.startTime = startTime.valueOf()

                  let split = this.split(this.dates, this.bids)
                  clearTimeout(timer)
                  timer = setTimeout(() => this.props.onChange(item), 10)
                  this.drawBids(split)
                  self.updateDatumCursor(event.x)
                })
                .on('end', (event) => this.props.onChange(this.bids.find((e) => e.id === event.subject.id)))
            )

          newBids
            .append('div')
            .attr('class', styles2.closeButton)
            .html('&times;')
            .on('click', (d) => {
              this.props.onDelete(d.id)
            })

          newBids
            .append('div')
            .attr('class', styles2.handleRight)
            .call(
              d3
                .drag()
                .container(function () {
                  return this.parentNode.parentNode
                })
                .on('drag', (event, d) => {
                  let endTime =
                    this.x.invert(event.x) +
                    Math.floor(event.y / 26) * 24 * 3600 * 1000 +
                    this.date0.startOf('d').valueOf()

                  let item = this.bids.find((e) => e.id === event.subject.id)
                  item.endTime = endTime
                  clearTimeout(timer)
                  timer = setTimeout(() => this.props.onChange(item), 10)
                  let split = this.split(this.dates, this.bids)
                  this.drawBids(split)
                  self.updateDatumCursor(event.x)
                })
                .on('end', (event) => this.props.onChange(this.bids.find((e) => e.id === event.subject.id)))
            )
          return newBids
        },
        (update) => {
          update.select('.' + styles2.label).html((d) => d.type + ' - ' + d.credits)
          return update
        },
        (exit) => exit.remove()
      )
      .classed(styles.first, (d) => d.first)
      .classed(styles.last, (d) => d.last)

      .style('top', (d) => d.day * 26 + 'px')
      .style('left', (d) => this.x(d.startTime) + 'px')
      .style('width', (d) => this.x(d.duration) - 150 + 'px')
      .style('height', 25 + 'px')
      .on('click', (d) => {
        console.log(d)
      })
  }
  draw(props) {
    const { employeeData } = props

    if (!this.el) return null
    if (!employeeData) return null
    let data = employeeData
    const { width, height } = this.calcDimensions()

    const [min, max] = this.findMinMax(data)
    let cur = min
    let dates = [cur.clone().startOf('d')]
    while (cur.add(1, 'd').diff(max) < 0) {
      dates.push(cur.clone())
    }

    this.dates = dates
    this.date0 = dates[0]
    let splittedActivities = this.split(dates, data.activities)
    let splittedBids = this.split(dates, this.bids)
    let startTime
    let endTime
    let id

    let self = this

    d3.select('#roster').call(
      d3
        .drag()
        .container(function () {
          return this
        })
        .on('start', function () {
          startTime = null
        })
        .on('drag', function (event) {
          if (!startTime) {
            startTime =
              self.x.invert(event.x) + Math.floor(event.y / 26) * 24 * 3600 * 1000 + dates[0].startOf('d').valueOf()
            let endTime = startTime + 3600000
            id = Math.random()
            self.bids.push({
              id,
              type: 'TimeOffBid',
              startTime,
              endTime,
              credits: 0,
            })
          }

          if (startTime) {
            endTime =
              self.x.invert(event.x) + Math.floor(event.y / 26) * 24 * 3600 * 1000 + dates[0].startOf('d').valueOf()
            let item = self.bids.find((e) => e.id === id)
            item.startTime = startTime
            item.endTime = endTime

            let split = self.split(self.dates, self.bids)
            self.updateDatumCursor(event.x)
            self.drawBids(split)
          }
        })

        .on('end', () => {
          if (startTime) this.props.onCreate(this.bids.find((e) => e.id === id))
        })
    )

    let days = d3
      .select('#roster')
      .style('height', dates.length * 26 + 'px')
      .selectAll('.' + styles.day)
      .data(dates)

    days
      .enter()
      .append('div')
      .merge(days)
      .attr('class', (d) => [styles.day, d.isoWeekday() > 5 ? styles.weekend : ''].join(' '))
      .style('top', (d, i) => i * 26 + 'px')
      .style('left', 0)
      .style('width', width + 'px')
      .style('height', 25 + 'px')
      .style('border-bottom', '0px solid #0000000d')
      .on('mouseenter', function () {
        d3.select(this).classed(styles2.hover, true)
      })
      .on('mouseleave', function () {
        d3.select(this).classed(styles2.hover, false)
      })

    let labels = d3
      .select('#roster')
      .selectAll('.' + styles.label)
      .data(dates)

    labels
      .enter()
      .append('div')
      .merge(labels)
      .attr('class', (d) => [styles.label, d.isoWeekday() > 5 ? styles.weekend : ''].join(' '))
      .style('padding', '0px 10px')
      .style('font-size', '12px')
      .style('top', (d, i) => i * 26 + 'px')
      .style('left', 0)
      .style('width', '150px')
      .style('height', 25 + 'px')
      .style('line-height', 25 + 'px')
      .style('border-bottom', '0px solid #0000000d')
      .html(
        (d) =>
          `<span style="color:#ccc"'>${
            d.date() === 1 ? d.format('MMMM') : ''
          }</span><span style='float:right'>${d.format('ddd D')}</span>`
      )

    let slots = d3
      .select('#roster')
      .selectAll('.' + styles.slot)
      .data(splittedActivities)

    slots
      .enter()
      .append('div')
      .merge(slots)
      .attr('class', (d) => [styles.slot, styles[d.typeOfActivity]].join(' '))
      .attr('aria-id', (d) => d.activityID)
      .style('top', (d) => d.day * 26 + 'px')
      .style('left', (d) => this.x(d.startTime) + 'px')
      .style('width', (d) => this.x(d.duration) - 150 + 'px')
      .style('height', 25 + 'px')
      .html((d) => this.getActivityHtml(d))
      .call(this.tooltip)

    this.drawBids(splittedBids)
    this.svg.style('height', height + 'px')

    this.xAxis = d3
      .axisTop(this.x)

      .tickValues([0, 3, 6, 9, 12, 15, 18, 21, 24].map((d) => d * 1000 * 3600))
      .tickFormat((d) => (d > 0 && d < 24 * 3600 * 1000 ? moment.utc(d).format('HH:mm') : ''))
      .tickSize(-height - 35)
      .tickSizeOuter(0)
    this.gX.call(this.xAxis)
  }
  componentDidMount() {
    this._detectElementResize.addResizeListener(
      this.el,
      debounce(() => this.resize(), 100)
    )
    const { width, height } = this.calcDimensions()

    this.x = d3
      .scaleLinear()
      .domain([0, 24 * 3600 * 1000])
      .range([150, width])

    let self = this
    this.gX = this.svg

      .append('g')
      .style('transform', 'translate(0,35px)')
      .attr('class', ['xaxis axis--x', styles.daygrid].join(' '))

    this.createDatumCursor(height)
    d3.select('#roster')
      .on('mouseleave', () => this.hideDatumCursor())
      .on('mouseenter', () => this.showDatumCursor())
      .on('mousemove', function (e) {
        self.updateDatumCursor(d3.pointer(e)[0])
      })

    this.shouldComponentUpdate(this.props)
  }

  shouldComponentUpdate(nextProps) {
    this.bids = nextProps.bids

    this.draw(nextProps)
    return false
  }

  render() {
    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <div
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            ref={(el) => {
              this.el = el
            }}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              overflow: 'hidden',
            }}>
            <div
              style={{
                top: 0,
                width: '100%',
                height: '40px',
                position: 'absolute',
                backgroundColor: 'white',
                boxShadow: '0 0 4px 1px grey',
                zIndex: 1,
              }}></div>
            <div
              style={{
                top: 40,
                width: '100%',
                height: '100%',
                position: 'absolute',
                overflowY: 'scroll',
              }}>
              <div style={{ position: 'absolute', width: '100%' }} id="roster" />
            </div>
            <svg
              ref={(el) => (this.svg = d3.select(el))}
              id="graph"
              style={{
                width: '100%',
                position: 'absolute',
                zIndex: 100,
                pointerEvents: 'none',
              }}
            />
          </div>
        )}
      </HotkeysTarget2>
    )
  }
}

const RosterResource = connect(mapStateToProps, null)(Comp)
export default RosterResource
