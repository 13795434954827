// Import our depdendency
import myFetch from 'api/myFetch'
import myPost from 'api/myPost'
import myPut from 'api/myPut'
import myDelete from 'api/myDelete'
import store from 'store/index'
import { storeEmployeeData, storeEmployeeBids, createNewBid, deleteBid, storeBidTypes } from './actions'
import data from './kvi.json'

import { DateTime } from 'luxon'

const servicesFactory = () => ({
  createNewBid(item) {
    store.dispatch(createNewBid(item))
  },
  deleteBid(item) {
    store.dispatch(deleteBid(item))
  },
  async getEmployeeData() {
    let r = data

    if (!r.activities) r.activities = []
    if (!r.flight_duties) r.flight_duties = []
    if (!r.pairings) r.pairings = []
    if (!r.rosterIssues) r.rosterIssues = []
    if (!r.dataIssues) r.dataIssues = []

    r.preAssignedDuties = []
    r.preAssignedActivities = []

    r.noContract.forEach((d, i) => {
      d.key = i
      d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
      d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
    })
    r.activities.forEach((d, i) => {
      d.key = i
      if (d.crewRole === r.crewFunction) delete d.crewRole
      d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
      d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
    })
    r.flight_duties.forEach((d, i) => {
      d.key = i
      d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
      d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
      d.endOfRest = DateTime.fromISO(d.endOfRest, { zone: 'UTC' }).toMillis()
    })

    r.pairings.forEach((d, i) => {
      d.key = i
      d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
      d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
    })
    r.bids.forEach((d, i) => {
      d.key = i
      d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
      d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
    })
    store.dispatch(storeEmployeeData(r))
    store.dispatch(storeEmployeeBids(r.bids))
  },

  getAllBidTypes() {
    let list = [
      'TimeOffBid',
      'FlightBid',
      'NightStopBid',

      'WorkingDaysBid',
      'SignInTimeBid',
      'SignOutTimeBid',
      'FlyTogetherBid',
    ]
    store.dispatch(storeBidTypes(list))
  },
})

const api = servicesFactory(myFetch, myPost, myDelete, myPut)
export default api
