export const CHANGE_ACTIVE_CONTEXT = 'CHANGE_ACTIVE_CONTEXT'
export const changeActiveContext = (data) => ({
  type: CHANGE_ACTIVE_CONTEXT,
  payload: data,
})
export const CLEAR_PUBLICATION = 'CLEAR_PUBLICATION'
export const clearPublication = (data) => ({
  type: CLEAR_PUBLICATION,
  payload: data,
})
export const STORE_ALL_PUBLICATIONS = 'STORE_ALL_PUBLICATIONS'
export const storeAllPublications = (data) => ({
  type: STORE_ALL_PUBLICATIONS,
  payload: data,
})

export const STORE_ROSTER = 'STORE_ROSTER'
export const storeRoster = (data) => ({
  type: STORE_ROSTER,
  payload: data,
})
export const STORE_COVERAGE_ROSTER = 'STORE_COVERAGE_ROSTER'
export const storeCoverageRoster = (data) => ({
  type: STORE_COVERAGE_ROSTER,
  payload: data,
})
export const STORE_PAIRINGS_ROSTER_FCM = 'STORE_PAIRINGS_ROSTER_FCM'
export const storePairingsRosterFCM = (data) => ({
  type: STORE_PAIRINGS_ROSTER_FCM,
  payload: data,
})
export const STORE_PAIRINGS_ROSTER_CCM = 'STORE_PAIRINGS_ROSTER_CCM'
export const storePairingsRosterCCM = (data) => ({
  type: STORE_PAIRINGS_ROSTER_CCM,
  payload: data,
})
export const STORE_NEW_PUBLICATION = 'STORE_NEW_PUBLICATION'
export const storeNewPublication = (data) => ({
  type: STORE_NEW_PUBLICATION,
  payload: data,
})
export const CHANGE_ROSTER_TYPE = 'CHANGE_ROSTER_TYPE'
export const changeRosterType = (data) => ({
  type: CHANGE_ROSTER_TYPE,
  payload: data,
})
export const STORE_ACTIVE_PUBLICATION = 'STORE_ACTIVE_PUBLICATION'
export const storeActivePublication = (data) => ({
  type: STORE_ACTIVE_PUBLICATION,
  payload: data,
})

export const CHANGE_LOADING = 'CHANGE_LOADING'
export const changeLoading = (data) => ({
  type: CHANGE_LOADING,
  payload: data,
})
export const CREATING_PUBLICATION = 'CREATING_PUBLICATION'
export const creatingPublication = (data) => ({
  type: CREATING_PUBLICATION,
  payload: data,
})
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const changeFilter = (data) => ({
  type: CHANGE_FILTER,
  payload: data,
})
export const RESET_FILTER = 'RESET_FILTER'
export const resetFilter = (data) => ({
  type: RESET_FILTER,
  payload: data,
})
export const UPDATE_HEAVY_DATA_OBJECT = 'UPDATE_HEAVY_DATA_OBJECT'
export const updateHeavyDataObject = (data) => ({
  type: UPDATE_HEAVY_DATA_OBJECT,
  payload: data,
})

export const ADD_CONTEXT_DATA_OBJECT = 'ADD_CONTEXT_DATA_OBJECT'
export const addContextDataObject = (data) => ({
  type: ADD_CONTEXT_DATA_OBJECT,
  payload: data,
})

export const CHANGE_PUBLICATION_SEARCH = 'CHANGE_PUBLICATION_SEARCH'
export const changePublicationSearch = (data) => ({
  type: CHANGE_PUBLICATION_SEARCH,
  payload: data,
})

export const TOGGLE_EXCLUDED_CREWMEMBER_FROM_UPLOAD = 'TOGGLE_EXCLUDED_CREWMEMBER_FROM_UPLOAD'
export const toggleExcludedCrewmemberFromUpload = (data) => ({
  type: TOGGLE_EXCLUDED_CREWMEMBER_FROM_UPLOAD,
  payload: data,
})
export const SET_SELECTED_CREWMEMBERS = 'SET_SELECTED_CREWMEMBERS'
export const setSelectedCrewMembers = (data) => ({
  type: SET_SELECTED_CREWMEMBERS,
  payload: data,
})
export const SET_EXPANDED_CREWMEMBERS = 'SET_EXPANDED_CREWMEMBERS'
export const setExpanded = (data) => ({
  type: SET_EXPANDED_CREWMEMBERS,
  payload: data,
})

// OLD ================== NEW ============
// CONFIG_DATA_PREPARED     -> CONFIG_DATA_READY
// WAITING_FOR_FLIGHT_LIST  -> IDPS_EXPORT_WAITING
// READY_FOR_COV_CALC       -> IDPS_EXPORT_READY
// WAITING_FOR_COV_CALC     -> FCOV_CALC_WAITING
// FINISHED_COV_CALC        -> FCOV_CALC_READY
// WAITING_FOR_CREW_DATA    -> n/a
// READY_FOR_ROSTER_CALC    -> n/a
// WAITING_FOR_ROSTER_CALC  -> ROSTER_CALC_WAITING
// ERRORS_IN_ROSTER_CALC    -> ROSTER_CALC_ERRORS
// FINISHED_ROSTER_CALC     -> ROSTER_CALC_READY
// WAITING_FOR_ROSTER_VAL   -> ROSTER_VAL_WAITING
// ROSTER_VALID             -> ROSTER_VAL_READY

// OLD ================== NEW ============
// ROSTER_VAL_WAITING       -> IDPS_IMPORT_WAITING
// ROSTER_VAL_READY         -> IDPS_IMPORT_READY
// ROSTER_PUBLISHED         -> n/a

export const PubState = Object.freeze({
  INIT: 'INIT',
  CONFIG_DATA_READY: 'CONFIG_DATA_READY',
  IDPS_EXPORT_WAITING: 'IDPS_EXPORT_WAITING',
  IDPS_EXPORT_READY: 'IDPS_EXPORT_READY',
  FCOV_CALC_WAITING: 'FCOV_CALC_WAITING',
  FCOV_CALC_READY: 'FCOV_CALC_READY',
  ROSTER_CALC_WAITING: 'ROSTER_CALC_WAITING',
  ROSTER_CALC_ERRORS: 'ROSTER_CALC_ERRORS',
  ROSTER_CALC_READY: 'ROSTER_CALC_READY',
  IDPS_IMPORT_WAITING: 'IDPS_IMPORT_WAITING',
  IDPS_IMPORT_READY: 'IDPS_IMPORT_READY',
  ABORTED: 'ABORTED',
  END: 'END',
})

// OLD ==================== NEW ================
// REQUEST_DATA_FLIGHT_LIST   -> REQUEST_DATA_IDPS_EXPORT
// RESPONSE_DATA_FLIGHT_LIST  -> RESPONSE_DATA_IDPS_EXPORT
// REQUEST_DATA_CREW_DATA     -> n/a
// RESPONSE_DATA_CREW_DATA    -> n/a

// OLD ==================== NEW ================
// REQUEST_DATA_IDPS_EXPORT   -> IDPS_EXPORT_START
// RESPONSE_DATA_IDPS_EXPORT  -> IDPS_EXPORT_READY
// n/a                        -> IDPS_EXPORT_STATUS
// n/a                        -> IDPS_EXPORT_ERROR

// OLD ==================== NEW ================
// POST_ROSTER_UPLOAD         -> IDPS_IMPORT_START
// ROSTER_VALID               -> IDPS_IMPORT_READY
// n/a                        -> IDPS_IMPORT_STATUS
// n/a                        -> IDPS_IMPORT_ERROR
// ROSTER_LOADED              -> n/a

export const PubEventType = Object.freeze({
  IDPS_EXPORT_START: 'idps_export:start',
  IDPS_EXPORT_STOP: 'idps_export:stop',
  IDPS_EXPORT_BYPASS: 'idps_export:bypass',
  IDPS_EXPORT_READY: 'idps_export:ready',
  IDPS_EXPORT_STATUS: 'idps_export:status',
  IDPS_EXPORT_ERROR: 'idps_export:error',
  IDPS_EXPORT_RETRY: 'idps_export:retry',
  CALC_FLIGHT_COV_START: 'calc:flight_cov:start',
  CALC_FLIGHT_COV_RETRY: 'calc:flight_cov:retry',
  CALC_FLIGHT_COV_RETRY_IDPS_EXPORT_RETRY: 'calc:flight_cov:retry:idps_export:retry',
  CALC_FLIGHT_COV_STOP: 'calc:flight_cov:stop',
  CALC_FLIGHT_COV_DONE: 'calc:flight_cov:ready',
  CALC_ROSTER_START: 'calc:roster:start',
  CALC_ROSTER_STOP: 'calc:roster:stop',
  CALC_ROSTER_ERROR: 'calc:roster:error:crew_data',
  CALC_ROSTER_ERROR_RETRY: 'calc:roster:error:retry',
  CALC_ROSTER_RETRY_FLIGHT_COV_RETRY: 'calc:roster:retry:flight_cov:retry',
  CALC_ROSTER_READY: 'calc:roster:ready',
  IDPS_IMPORT_START: 'idps_import:start',
  IDPS_IMPORT_READY: 'idps_import:ready',
  IDPS_IMPORT_STATUS: 'idps_import:status',
  IDPS_IMPORT_ERROR: 'idps_import:error',
  IDPS_IMPORT_RETRY: 'idps_import:retry',
  IDPS_IMPORT_STOP: 'idps_import:stop',
  END: 'end',
})
