import React from 'react'
import Spinner from './Spinner'
import styles from './Loader.module.scss'
import FoldingCube from './FoldingCube'
import ThreeDots from './ThreeDots'
import Comets from './Comets'

const Loader = ({ active, size = 10, type, color = '#ffffff', absolute, className, ...props }) => {
  if (!active) return null
  switch (type) {
    case 'dots':
      return <ThreeDots width={size} height={size / 2} color={color} />
    case 'foldingCube':
      return <FoldingCube color={color} width={20} height={20} />
    case 'comets':
      return <Comets className={absolute ? styles.spinner : undefined} color={color} />
    default:
      return <Spinner size={size} color={color} className={absolute ? styles.spinner : undefined} />
  }
}

export default Loader
