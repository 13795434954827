import React, { useState, useEffect } from 'react'
import { InputGroup, Position, Icon } from '@blueprintjs/core'
import { DateInput2 } from '@blueprintjs/datetime2'

import { LocaleUtils } from 'react-day-picker'
const myLocaleUtils = { ...LocaleUtils, getFirstDayOfWeek: () => 1 }

import Validate from './Validate'
import { lux } from 'shared/LuxonHelpers'

const InputDate = (props) => {
  const { error, onChange, placeholder, value, popoverProps } = props

  return (
    <Validate validation_errors={error} fill={props.fill}>
      <DateInput2
        {...props}
        popoverProps={
          popoverProps || {
            usePortal: true,
            position: 'bottom',
            portalContainer: document.getElementById('main'),
          }
        }
        shortcuts={false}
        showActionsBar
        placeholder={placeholder ?? 'Date'}
        inputProps={{
          inputRef: props.forwardRef,
          intent: error ? 'danger' : undefined,
          style: { ...props.style, width: props.fill ? '100%' : 100 },
        }}
        contiguousCalendarMonths={false}
        defaultTimezone="UTC"
        onChange={(date, isUserChange) => {
          if (!isUserChange) return

          onChange(date)
        }}
        localeUtils={myLocaleUtils}
        maxDate={props.maxDate || new Date(Date.now() + 365 * 86400000)}
        value={value ? lux(value, props.timezone)?.toFormat('yyyy-MM-dd') : null}
        formatDate={(date) => {
          return lux(date, 'Europe/Brussels')?.toFormat('yyyy-MM-dd')
        }}
        parseDate={(str) => {
          return lux(str, 'UTC')?.toJSDate()
        }}
      />
    </Validate>
  )
}

export default InputDate
