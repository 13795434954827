import React, { useState, useEffect } from 'react'
import api from './api'
import { useAuth } from './auth'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Unauthorized from './Unauthorized'

import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import App from '../App'
import ReactPiwik from 'react-piwik'

const Login = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const location = useLocation()
  const code = new URLSearchParams(location.search).get('code')
  const state = new URLSearchParams(location.search).get('state')
  const [loginInProgress, setLoginInProgress] = useState(true)
  const user = useSelector((state) => state.user.profile)
  //initiate Oauth flow on first login

  useEffect(() => {
    let redirectUrl
    switch (location.pathname) {
      case '/callback':
        redirectUrl = sessionStorage.getItem('redirectUrl')
        sessionStorage.removeItem('redirectUrl')
        break
      case '/login':
        break
      case '/logout':
        sessionStorage.removeItem('redirectUrl')
        break
      default:
        sessionStorage.setItem('redirectUrl', location.pathname)
        redirectUrl = sessionStorage.getItem('redirectUrl')
    }

    if (auth && !code) {
      api.trySilentRefresh().then(async () => {
        await api.getMyProfile()
        setLoginInProgress(false)
        navigate(redirectUrl || 'publications')
      }) // try to use refresh token
    } else if (code) {
      api.exchangeCode(code).then(async () => {
        await api.getMyProfile()
        setLoginInProgress(false)
        navigate(redirectUrl || 'publications')
      }) //STEP 5: exchange returned code for tokens and try to proceed to app
      //STEP 6: tokens received. Token ID is currently not verified by the SPA
      //STEP 7: proceed to rest of app
    } else {
      api.getMyProfile().then(() => setLoginInProgress(false))
    }
  }, [])

  if (!auth || loginInProgress) return <div>Authenticating...</div>
  if (!user) return <Unauthorized />
  return <App />
}
export default Login
