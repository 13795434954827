const alphaMap = { 0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H' }

const publicationColors = ['#46a1b3', '#46b36f', '#b3468a', '#b39e46', '#b34646']
const barColors = [
  {
    scheduled: '#162166',
    preAssigned: '#00a3ff',
    total: '#00a3ff',
    preAssignedSoft: '#E20000',
    preAssignedBusiness: '#28A262',
    scheduledSoft: '#E20000',
    scheduledBusiness: '#28A262',
    business: '#28A2C2',
    soft: '#E20000',
  },
  {
    scheduled: '#ac45ba',
    preAssigned: '#5ea493',
    total: '#5ea493',
    preAssignedSoft: '#B8A200',
    preAssignedBusiness: '#B8A200',
    scheduledSoft: '#F8A200',
    scheduledBusiness: '#F8A200',
    business: '#00CC44',
    soft: '#221133',
  },
  {
    scheduled: '#d91604',
    preAssigned: '#f2a007',
    total: '#f2a007',
    preAssignedSoft: '#B8A200',
    preAssignedBusiness: '#B8A200',
    scheduledSoft: '#F8A200',
    scheduledBusiness: '#F8A200',
    business: '#00CC44',
    soft: '#221133',
  },
  {
    scheduled: '#8c0410',
    preAssigned: '#78aa00',
    total: '#78aa00',
    preAssignedSoft: '#B8A200',
    preAssignedBusiness: '#B8A200',
    scheduledSoft: '#F8A200',
    scheduledBusiness: '#F8A200',
    business: '#00CC44',
    soft: '#221133',
  },
]

const typeMap = {
  preAssigned: 'pre-ass',
  scheduled: 'sched',
  preAssignedSoft: 'pre-ass soft',
  preAssignedBusiness: 'pre-ass business',
  scheduledSoft: 'sched soft',
  scheduledBusiness: 'sched business',
  total: '',
}

const totalTypes = ['preAssignedBusiness', 'preAssignedSoft', 'scheduledBusiness', 'scheduledSoft']

const barData = (report, activeReports, type, orientation = 'v') => {
  return {
    x: [],
    y: [],
    name: totalTypes.includes(type) ? typeMap[type] : `${typeMap[type]}`,
    type: 'bar',
    barmode: 'stack',
    meta: { publication: activeReports[report].title },
    hoverinfo: 'none',
    orientation,
    height: 10,
    width: 0.6,
    constraintext: 'none',
    textangle: 0,
    marker: {
      color: publicationColors[report],
      opacity: 0.7,
      pattern:
        type === 'scheduledBusiness' || type === 'scheduledSoft' || type == 'scheduled'
          ? {
              shape: '/',
              fillmode: 'overlay',
              fgopacity: 0.4,
              solidity: 0.5,
            }
          : undefined,
    },
    text: [],
  }
}

module.exports = { barData, alphaMap, publicationColors }
