/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal'

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

export const crewmgmt = ($root.crewmgmt = (() => {
  /**
   * Namespace crewmgmt.
   * @exports crewmgmt
   * @namespace
   */
  const crewmgmt = {}

  crewmgmt.Event = (function () {
    /**
     * Properties of an Event.
     * @memberof crewmgmt
     * @interface IEvent
     * @property {number|null} [type] Event type
     * @property {string|null} [ref] Event ref
     * @property {crewmgmt.IFlightDepartureAndArrivalUpdate|null} [flightDepartureAndArrivalUpdate] Event flightDepartureAndArrivalUpdate
     * @property {crewmgmt.ICrewMgmtSession|null} [session] Event session
     * @property {crewmgmt.IAircraft|null} [aircraft] Event aircraft
     * @property {crewmgmt.IAircraftType|null} [aircraftType] Event aircraftType
     * @property {crewmgmt.IAirline|null} [airline] Event airline
     * @property {crewmgmt.IAirport|null} [airport] Event airport
     * @property {crewmgmt.ICrewMember|null} [crewMember] Event crewMember
     * @property {crewmgmt.IRank|null} [rank] Event rank
     * @property {crewmgmt.IFlight|null} [flight] Event flight
     * @property {crewmgmt.ICrewMemberFlightAssignment|null} [crewMemberFlightAssignment] Event crewMemberFlightAssignment
     * @property {Array.<crewmgmt.IEvent>|null} [events] Event events
     */

    /**
     * Constructs a new Event.
     * @memberof crewmgmt
     * @classdesc Represents an Event.
     * @implements IEvent
     * @constructor
     * @param {crewmgmt.IEvent=} [properties] Properties to set
     */
    function Event(properties) {
      this.events = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Event type.
     * @member {number} type
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.type = 0

    /**
     * Event ref.
     * @member {string} ref
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.ref = ''

    /**
     * Event flightDepartureAndArrivalUpdate.
     * @member {crewmgmt.IFlightDepartureAndArrivalUpdate|null|undefined} flightDepartureAndArrivalUpdate
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.flightDepartureAndArrivalUpdate = null

    /**
     * Event session.
     * @member {crewmgmt.ICrewMgmtSession|null|undefined} session
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.session = null

    /**
     * Event aircraft.
     * @member {crewmgmt.IAircraft|null|undefined} aircraft
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.aircraft = null

    /**
     * Event aircraftType.
     * @member {crewmgmt.IAircraftType|null|undefined} aircraftType
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.aircraftType = null

    /**
     * Event airline.
     * @member {crewmgmt.IAirline|null|undefined} airline
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.airline = null

    /**
     * Event airport.
     * @member {crewmgmt.IAirport|null|undefined} airport
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.airport = null

    /**
     * Event crewMember.
     * @member {crewmgmt.ICrewMember|null|undefined} crewMember
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.crewMember = null

    /**
     * Event rank.
     * @member {crewmgmt.IRank|null|undefined} rank
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.rank = null

    /**
     * Event flight.
     * @member {crewmgmt.IFlight|null|undefined} flight
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.flight = null

    /**
     * Event crewMemberFlightAssignment.
     * @member {crewmgmt.ICrewMemberFlightAssignment|null|undefined} crewMemberFlightAssignment
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.crewMemberFlightAssignment = null

    /**
     * Event events.
     * @member {Array.<crewmgmt.IEvent>} events
     * @memberof crewmgmt.Event
     * @instance
     */
    Event.prototype.events = $util.emptyArray

    /**
     * Creates a new Event instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Event
     * @static
     * @param {crewmgmt.IEvent=} [properties] Properties to set
     * @returns {crewmgmt.Event} Event instance
     */
    Event.create = function create(properties) {
      return new Event(properties)
    }

    /**
     * Encodes the specified Event message. Does not implicitly {@link crewmgmt.Event.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Event
     * @static
     * @param {crewmgmt.IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.type)
      if (message.ref != null && Object.hasOwnProperty.call(message, 'ref'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.ref)
      if (
        message.flightDepartureAndArrivalUpdate != null &&
        Object.hasOwnProperty.call(message, 'flightDepartureAndArrivalUpdate')
      )
        $root.crewmgmt.FlightDepartureAndArrivalUpdate.encode(
          message.flightDepartureAndArrivalUpdate,
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim()
      if (message.session != null && Object.hasOwnProperty.call(message, 'session'))
        $root.crewmgmt.CrewMgmtSession.encode(
          message.session,
          writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
        ).ldelim()
      if (message.aircraft != null && Object.hasOwnProperty.call(message, 'aircraft'))
        $root.crewmgmt.Aircraft.encode(message.aircraft, writer.uint32(/* id 5, wireType 2 =*/ 42).fork()).ldelim()
      if (message.aircraftType != null && Object.hasOwnProperty.call(message, 'aircraftType'))
        $root.crewmgmt.AircraftType.encode(
          message.aircraftType,
          writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
        ).ldelim()
      if (message.airline != null && Object.hasOwnProperty.call(message, 'airline'))
        $root.crewmgmt.Airline.encode(message.airline, writer.uint32(/* id 7, wireType 2 =*/ 58).fork()).ldelim()
      if (message.airport != null && Object.hasOwnProperty.call(message, 'airport'))
        $root.crewmgmt.Airport.encode(message.airport, writer.uint32(/* id 8, wireType 2 =*/ 66).fork()).ldelim()
      if (message.crewMember != null && Object.hasOwnProperty.call(message, 'crewMember'))
        $root.crewmgmt.CrewMember.encode(message.crewMember, writer.uint32(/* id 9, wireType 2 =*/ 74).fork()).ldelim()
      if (message.rank != null && Object.hasOwnProperty.call(message, 'rank'))
        $root.crewmgmt.Rank.encode(message.rank, writer.uint32(/* id 10, wireType 2 =*/ 82).fork()).ldelim()
      if (message.flight != null && Object.hasOwnProperty.call(message, 'flight'))
        $root.crewmgmt.Flight.encode(message.flight, writer.uint32(/* id 11, wireType 2 =*/ 90).fork()).ldelim()
      if (
        message.crewMemberFlightAssignment != null &&
        Object.hasOwnProperty.call(message, 'crewMemberFlightAssignment')
      )
        $root.crewmgmt.CrewMemberFlightAssignment.encode(
          message.crewMemberFlightAssignment,
          writer.uint32(/* id 12, wireType 2 =*/ 98).fork()
        ).ldelim()
      if (message.events != null && message.events.length)
        for (let i = 0; i < message.events.length; ++i)
          $root.crewmgmt.Event.encode(message.events[i], writer.uint32(/* id 13, wireType 2 =*/ 106).fork()).ldelim()
      return writer
    }

    /**
     * Encodes the specified Event message, length delimited. Does not implicitly {@link crewmgmt.Event.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Event
     * @static
     * @param {crewmgmt.IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Event message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Event()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.type = reader.int32()
            break
          case 2:
            message.ref = reader.string()
            break
          case 3:
            message.flightDepartureAndArrivalUpdate = $root.crewmgmt.FlightDepartureAndArrivalUpdate.decode(
              reader,
              reader.uint32()
            )
            break
          case 4:
            message.session = $root.crewmgmt.CrewMgmtSession.decode(reader, reader.uint32())
            break
          case 5:
            message.aircraft = $root.crewmgmt.Aircraft.decode(reader, reader.uint32())
            break
          case 6:
            message.aircraftType = $root.crewmgmt.AircraftType.decode(reader, reader.uint32())
            break
          case 7:
            message.airline = $root.crewmgmt.Airline.decode(reader, reader.uint32())
            break
          case 8:
            message.airport = $root.crewmgmt.Airport.decode(reader, reader.uint32())
            break
          case 9:
            message.crewMember = $root.crewmgmt.CrewMember.decode(reader, reader.uint32())
            break
          case 10:
            message.rank = $root.crewmgmt.Rank.decode(reader, reader.uint32())
            break
          case 11:
            message.flight = $root.crewmgmt.Flight.decode(reader, reader.uint32())
            break
          case 12:
            message.crewMemberFlightAssignment = $root.crewmgmt.CrewMemberFlightAssignment.decode(
              reader,
              reader.uint32()
            )
            break
          case 13:
            if (!(message.events && message.events.length)) message.events = []
            message.events.push($root.crewmgmt.Event.decode(reader, reader.uint32()))
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Event message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Event message.
     * @function verify
     * @memberof crewmgmt.Event
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Event.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.type != null && message.hasOwnProperty('type'))
        if (!$util.isInteger(message.type)) return 'type: integer expected'
      if (message.ref != null && message.hasOwnProperty('ref'))
        if (!$util.isString(message.ref)) return 'ref: string expected'
      if (
        message.flightDepartureAndArrivalUpdate != null &&
        message.hasOwnProperty('flightDepartureAndArrivalUpdate')
      ) {
        let error = $root.crewmgmt.FlightDepartureAndArrivalUpdate.verify(message.flightDepartureAndArrivalUpdate)
        if (error) return 'flightDepartureAndArrivalUpdate.' + error
      }
      if (message.session != null && message.hasOwnProperty('session')) {
        let error = $root.crewmgmt.CrewMgmtSession.verify(message.session)
        if (error) return 'session.' + error
      }
      if (message.aircraft != null && message.hasOwnProperty('aircraft')) {
        let error = $root.crewmgmt.Aircraft.verify(message.aircraft)
        if (error) return 'aircraft.' + error
      }
      if (message.aircraftType != null && message.hasOwnProperty('aircraftType')) {
        let error = $root.crewmgmt.AircraftType.verify(message.aircraftType)
        if (error) return 'aircraftType.' + error
      }
      if (message.airline != null && message.hasOwnProperty('airline')) {
        let error = $root.crewmgmt.Airline.verify(message.airline)
        if (error) return 'airline.' + error
      }
      if (message.airport != null && message.hasOwnProperty('airport')) {
        let error = $root.crewmgmt.Airport.verify(message.airport)
        if (error) return 'airport.' + error
      }
      if (message.crewMember != null && message.hasOwnProperty('crewMember')) {
        let error = $root.crewmgmt.CrewMember.verify(message.crewMember)
        if (error) return 'crewMember.' + error
      }
      if (message.rank != null && message.hasOwnProperty('rank')) {
        let error = $root.crewmgmt.Rank.verify(message.rank)
        if (error) return 'rank.' + error
      }
      if (message.flight != null && message.hasOwnProperty('flight')) {
        let error = $root.crewmgmt.Flight.verify(message.flight)
        if (error) return 'flight.' + error
      }
      if (message.crewMemberFlightAssignment != null && message.hasOwnProperty('crewMemberFlightAssignment')) {
        let error = $root.crewmgmt.CrewMemberFlightAssignment.verify(message.crewMemberFlightAssignment)
        if (error) return 'crewMemberFlightAssignment.' + error
      }
      if (message.events != null && message.hasOwnProperty('events')) {
        if (!Array.isArray(message.events)) return 'events: array expected'
        for (let i = 0; i < message.events.length; ++i) {
          let error = $root.crewmgmt.Event.verify(message.events[i])
          if (error) return 'events.' + error
        }
      }
      return null
    }

    /**
     * Creates an Event message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Event
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Event} Event
     */
    Event.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Event) return object
      let message = new $root.crewmgmt.Event()
      if (object.type != null) message.type = object.type | 0
      if (object.ref != null) message.ref = String(object.ref)
      if (object.flightDepartureAndArrivalUpdate != null) {
        if (typeof object.flightDepartureAndArrivalUpdate !== 'object')
          throw TypeError('.crewmgmt.Event.flightDepartureAndArrivalUpdate: object expected')
        message.flightDepartureAndArrivalUpdate = $root.crewmgmt.FlightDepartureAndArrivalUpdate.fromObject(
          object.flightDepartureAndArrivalUpdate
        )
      }
      if (object.session != null) {
        if (typeof object.session !== 'object') throw TypeError('.crewmgmt.Event.session: object expected')
        message.session = $root.crewmgmt.CrewMgmtSession.fromObject(object.session)
      }
      if (object.aircraft != null) {
        if (typeof object.aircraft !== 'object') throw TypeError('.crewmgmt.Event.aircraft: object expected')
        message.aircraft = $root.crewmgmt.Aircraft.fromObject(object.aircraft)
      }
      if (object.aircraftType != null) {
        if (typeof object.aircraftType !== 'object') throw TypeError('.crewmgmt.Event.aircraftType: object expected')
        message.aircraftType = $root.crewmgmt.AircraftType.fromObject(object.aircraftType)
      }
      if (object.airline != null) {
        if (typeof object.airline !== 'object') throw TypeError('.crewmgmt.Event.airline: object expected')
        message.airline = $root.crewmgmt.Airline.fromObject(object.airline)
      }
      if (object.airport != null) {
        if (typeof object.airport !== 'object') throw TypeError('.crewmgmt.Event.airport: object expected')
        message.airport = $root.crewmgmt.Airport.fromObject(object.airport)
      }
      if (object.crewMember != null) {
        if (typeof object.crewMember !== 'object') throw TypeError('.crewmgmt.Event.crewMember: object expected')
        message.crewMember = $root.crewmgmt.CrewMember.fromObject(object.crewMember)
      }
      if (object.rank != null) {
        if (typeof object.rank !== 'object') throw TypeError('.crewmgmt.Event.rank: object expected')
        message.rank = $root.crewmgmt.Rank.fromObject(object.rank)
      }
      if (object.flight != null) {
        if (typeof object.flight !== 'object') throw TypeError('.crewmgmt.Event.flight: object expected')
        message.flight = $root.crewmgmt.Flight.fromObject(object.flight)
      }
      if (object.crewMemberFlightAssignment != null) {
        if (typeof object.crewMemberFlightAssignment !== 'object')
          throw TypeError('.crewmgmt.Event.crewMemberFlightAssignment: object expected')
        message.crewMemberFlightAssignment = $root.crewmgmt.CrewMemberFlightAssignment.fromObject(
          object.crewMemberFlightAssignment
        )
      }
      if (object.events) {
        if (!Array.isArray(object.events)) throw TypeError('.crewmgmt.Event.events: array expected')
        message.events = []
        for (let i = 0; i < object.events.length; ++i) {
          if (typeof object.events[i] !== 'object') throw TypeError('.crewmgmt.Event.events: object expected')
          message.events[i] = $root.crewmgmt.Event.fromObject(object.events[i])
        }
      }
      return message
    }

    /**
     * Creates a plain object from an Event message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Event
     * @static
     * @param {crewmgmt.Event} message Event
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Event.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.events = []
      if (options.defaults) {
        object.type = 0
        object.ref = ''
        object.flightDepartureAndArrivalUpdate = null
        object.session = null
        object.aircraft = null
        object.aircraftType = null
        object.airline = null
        object.airport = null
        object.crewMember = null
        object.rank = null
        object.flight = null
        object.crewMemberFlightAssignment = null
      }
      if (message.type != null && message.hasOwnProperty('type')) object.type = message.type
      if (message.ref != null && message.hasOwnProperty('ref')) object.ref = message.ref
      if (message.flightDepartureAndArrivalUpdate != null && message.hasOwnProperty('flightDepartureAndArrivalUpdate'))
        object.flightDepartureAndArrivalUpdate = $root.crewmgmt.FlightDepartureAndArrivalUpdate.toObject(
          message.flightDepartureAndArrivalUpdate,
          options
        )
      if (message.session != null && message.hasOwnProperty('session'))
        object.session = $root.crewmgmt.CrewMgmtSession.toObject(message.session, options)
      if (message.aircraft != null && message.hasOwnProperty('aircraft'))
        object.aircraft = $root.crewmgmt.Aircraft.toObject(message.aircraft, options)
      if (message.aircraftType != null && message.hasOwnProperty('aircraftType'))
        object.aircraftType = $root.crewmgmt.AircraftType.toObject(message.aircraftType, options)
      if (message.airline != null && message.hasOwnProperty('airline'))
        object.airline = $root.crewmgmt.Airline.toObject(message.airline, options)
      if (message.airport != null && message.hasOwnProperty('airport'))
        object.airport = $root.crewmgmt.Airport.toObject(message.airport, options)
      if (message.crewMember != null && message.hasOwnProperty('crewMember'))
        object.crewMember = $root.crewmgmt.CrewMember.toObject(message.crewMember, options)
      if (message.rank != null && message.hasOwnProperty('rank'))
        object.rank = $root.crewmgmt.Rank.toObject(message.rank, options)
      if (message.flight != null && message.hasOwnProperty('flight'))
        object.flight = $root.crewmgmt.Flight.toObject(message.flight, options)
      if (message.crewMemberFlightAssignment != null && message.hasOwnProperty('crewMemberFlightAssignment'))
        object.crewMemberFlightAssignment = $root.crewmgmt.CrewMemberFlightAssignment.toObject(
          message.crewMemberFlightAssignment,
          options
        )
      if (message.events && message.events.length) {
        object.events = []
        for (let j = 0; j < message.events.length; ++j)
          object.events[j] = $root.crewmgmt.Event.toObject(message.events[j], options)
      }
      return object
    }

    /**
     * Converts this Event to JSON.
     * @function toJSON
     * @memberof crewmgmt.Event
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Event.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Event
  })()

  crewmgmt.FlightDepartureAndArrivalUpdate = (function () {
    /**
     * Properties of a FlightDepartureAndArrivalUpdate.
     * @memberof crewmgmt
     * @interface IFlightDepartureAndArrivalUpdate
     * @property {string|null} [flightId] FlightDepartureAndArrivalUpdate flightId
     * @property {number|null} [departureUpdateInMinutes] FlightDepartureAndArrivalUpdate departureUpdateInMinutes
     * @property {number|null} [arrivalUpdateInMinutes] FlightDepartureAndArrivalUpdate arrivalUpdateInMinutes
     */

    /**
     * Constructs a new FlightDepartureAndArrivalUpdate.
     * @memberof crewmgmt
     * @classdesc Represents a FlightDepartureAndArrivalUpdate.
     * @implements IFlightDepartureAndArrivalUpdate
     * @constructor
     * @param {crewmgmt.IFlightDepartureAndArrivalUpdate=} [properties] Properties to set
     */
    function FlightDepartureAndArrivalUpdate(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * FlightDepartureAndArrivalUpdate flightId.
     * @member {string} flightId
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @instance
     */
    FlightDepartureAndArrivalUpdate.prototype.flightId = ''

    /**
     * FlightDepartureAndArrivalUpdate departureUpdateInMinutes.
     * @member {number} departureUpdateInMinutes
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @instance
     */
    FlightDepartureAndArrivalUpdate.prototype.departureUpdateInMinutes = 0

    /**
     * FlightDepartureAndArrivalUpdate arrivalUpdateInMinutes.
     * @member {number} arrivalUpdateInMinutes
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @instance
     */
    FlightDepartureAndArrivalUpdate.prototype.arrivalUpdateInMinutes = 0

    /**
     * Creates a new FlightDepartureAndArrivalUpdate instance using the specified properties.
     * @function create
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {crewmgmt.IFlightDepartureAndArrivalUpdate=} [properties] Properties to set
     * @returns {crewmgmt.FlightDepartureAndArrivalUpdate} FlightDepartureAndArrivalUpdate instance
     */
    FlightDepartureAndArrivalUpdate.create = function create(properties) {
      return new FlightDepartureAndArrivalUpdate(properties)
    }

    /**
     * Encodes the specified FlightDepartureAndArrivalUpdate message. Does not implicitly {@link crewmgmt.FlightDepartureAndArrivalUpdate.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {crewmgmt.IFlightDepartureAndArrivalUpdate} message FlightDepartureAndArrivalUpdate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FlightDepartureAndArrivalUpdate.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.flightId != null && Object.hasOwnProperty.call(message, 'flightId'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.flightId)
      if (message.departureUpdateInMinutes != null && Object.hasOwnProperty.call(message, 'departureUpdateInMinutes'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.departureUpdateInMinutes)
      if (message.arrivalUpdateInMinutes != null && Object.hasOwnProperty.call(message, 'arrivalUpdateInMinutes'))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.arrivalUpdateInMinutes)
      return writer
    }

    /**
     * Encodes the specified FlightDepartureAndArrivalUpdate message, length delimited. Does not implicitly {@link crewmgmt.FlightDepartureAndArrivalUpdate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {crewmgmt.IFlightDepartureAndArrivalUpdate} message FlightDepartureAndArrivalUpdate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FlightDepartureAndArrivalUpdate.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a FlightDepartureAndArrivalUpdate message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.FlightDepartureAndArrivalUpdate} FlightDepartureAndArrivalUpdate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FlightDepartureAndArrivalUpdate.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.FlightDepartureAndArrivalUpdate()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.flightId = reader.string()
            break
          case 2:
            message.departureUpdateInMinutes = reader.int32()
            break
          case 3:
            message.arrivalUpdateInMinutes = reader.int32()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a FlightDepartureAndArrivalUpdate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.FlightDepartureAndArrivalUpdate} FlightDepartureAndArrivalUpdate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FlightDepartureAndArrivalUpdate.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a FlightDepartureAndArrivalUpdate message.
     * @function verify
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FlightDepartureAndArrivalUpdate.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.flightId != null && message.hasOwnProperty('flightId'))
        if (!$util.isString(message.flightId)) return 'flightId: string expected'
      if (message.departureUpdateInMinutes != null && message.hasOwnProperty('departureUpdateInMinutes'))
        if (!$util.isInteger(message.departureUpdateInMinutes)) return 'departureUpdateInMinutes: integer expected'
      if (message.arrivalUpdateInMinutes != null && message.hasOwnProperty('arrivalUpdateInMinutes'))
        if (!$util.isInteger(message.arrivalUpdateInMinutes)) return 'arrivalUpdateInMinutes: integer expected'
      return null
    }

    /**
     * Creates a FlightDepartureAndArrivalUpdate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.FlightDepartureAndArrivalUpdate} FlightDepartureAndArrivalUpdate
     */
    FlightDepartureAndArrivalUpdate.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.FlightDepartureAndArrivalUpdate) return object
      let message = new $root.crewmgmt.FlightDepartureAndArrivalUpdate()
      if (object.flightId != null) message.flightId = String(object.flightId)
      if (object.departureUpdateInMinutes != null)
        message.departureUpdateInMinutes = object.departureUpdateInMinutes | 0
      if (object.arrivalUpdateInMinutes != null) message.arrivalUpdateInMinutes = object.arrivalUpdateInMinutes | 0
      return message
    }

    /**
     * Creates a plain object from a FlightDepartureAndArrivalUpdate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @static
     * @param {crewmgmt.FlightDepartureAndArrivalUpdate} message FlightDepartureAndArrivalUpdate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FlightDepartureAndArrivalUpdate.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.flightId = ''
        object.departureUpdateInMinutes = 0
        object.arrivalUpdateInMinutes = 0
      }
      if (message.flightId != null && message.hasOwnProperty('flightId')) object.flightId = message.flightId
      if (message.departureUpdateInMinutes != null && message.hasOwnProperty('departureUpdateInMinutes'))
        object.departureUpdateInMinutes = message.departureUpdateInMinutes
      if (message.arrivalUpdateInMinutes != null && message.hasOwnProperty('arrivalUpdateInMinutes'))
        object.arrivalUpdateInMinutes = message.arrivalUpdateInMinutes
      return object
    }

    /**
     * Converts this FlightDepartureAndArrivalUpdate to JSON.
     * @function toJSON
     * @memberof crewmgmt.FlightDepartureAndArrivalUpdate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FlightDepartureAndArrivalUpdate.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return FlightDepartureAndArrivalUpdate
  })()

  crewmgmt.Aircraft = (function () {
    /**
     * Properties of an Aircraft.
     * @memberof crewmgmt
     * @interface IAircraft
     * @property {string|null} [id] Aircraft id
     * @property {string|null} [registration] Aircraft registration
     * @property {string|null} [aircraftType] Aircraft aircraftType
     */

    /**
     * Constructs a new Aircraft.
     * @memberof crewmgmt
     * @classdesc Represents an Aircraft.
     * @implements IAircraft
     * @constructor
     * @param {crewmgmt.IAircraft=} [properties] Properties to set
     */
    function Aircraft(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Aircraft id.
     * @member {string} id
     * @memberof crewmgmt.Aircraft
     * @instance
     */
    Aircraft.prototype.id = ''

    /**
     * Aircraft registration.
     * @member {string} registration
     * @memberof crewmgmt.Aircraft
     * @instance
     */
    Aircraft.prototype.registration = ''

    /**
     * Aircraft aircraftType.
     * @member {string} aircraftType
     * @memberof crewmgmt.Aircraft
     * @instance
     */
    Aircraft.prototype.aircraftType = ''

    /**
     * Creates a new Aircraft instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {crewmgmt.IAircraft=} [properties] Properties to set
     * @returns {crewmgmt.Aircraft} Aircraft instance
     */
    Aircraft.create = function create(properties) {
      return new Aircraft(properties)
    }

    /**
     * Encodes the specified Aircraft message. Does not implicitly {@link crewmgmt.Aircraft.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {crewmgmt.IAircraft} message Aircraft message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Aircraft.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.registration != null && Object.hasOwnProperty.call(message, 'registration'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.registration)
      if (message.aircraftType != null && Object.hasOwnProperty.call(message, 'aircraftType'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.aircraftType)
      return writer
    }

    /**
     * Encodes the specified Aircraft message, length delimited. Does not implicitly {@link crewmgmt.Aircraft.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {crewmgmt.IAircraft} message Aircraft message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Aircraft.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Aircraft message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Aircraft} Aircraft
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Aircraft.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Aircraft()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.registration = reader.string()
            break
          case 3:
            message.aircraftType = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Aircraft message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Aircraft} Aircraft
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Aircraft.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Aircraft message.
     * @function verify
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Aircraft.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.registration != null && message.hasOwnProperty('registration'))
        if (!$util.isString(message.registration)) return 'registration: string expected'
      if (message.aircraftType != null && message.hasOwnProperty('aircraftType'))
        if (!$util.isString(message.aircraftType)) return 'aircraftType: string expected'
      return null
    }

    /**
     * Creates an Aircraft message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Aircraft} Aircraft
     */
    Aircraft.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Aircraft) return object
      let message = new $root.crewmgmt.Aircraft()
      if (object.id != null) message.id = String(object.id)
      if (object.registration != null) message.registration = String(object.registration)
      if (object.aircraftType != null) message.aircraftType = String(object.aircraftType)
      return message
    }

    /**
     * Creates a plain object from an Aircraft message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Aircraft
     * @static
     * @param {crewmgmt.Aircraft} message Aircraft
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Aircraft.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.registration = ''
        object.aircraftType = ''
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.registration != null && message.hasOwnProperty('registration'))
        object.registration = message.registration
      if (message.aircraftType != null && message.hasOwnProperty('aircraftType'))
        object.aircraftType = message.aircraftType
      return object
    }

    /**
     * Converts this Aircraft to JSON.
     * @function toJSON
     * @memberof crewmgmt.Aircraft
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Aircraft.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Aircraft
  })()

  crewmgmt.AircraftType = (function () {
    /**
     * Properties of an AircraftType.
     * @memberof crewmgmt
     * @interface IAircraftType
     * @property {string|null} [id] AircraftType id
     */

    /**
     * Constructs a new AircraftType.
     * @memberof crewmgmt
     * @classdesc Represents an AircraftType.
     * @implements IAircraftType
     * @constructor
     * @param {crewmgmt.IAircraftType=} [properties] Properties to set
     */
    function AircraftType(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * AircraftType id.
     * @member {string} id
     * @memberof crewmgmt.AircraftType
     * @instance
     */
    AircraftType.prototype.id = ''

    /**
     * Creates a new AircraftType instance using the specified properties.
     * @function create
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {crewmgmt.IAircraftType=} [properties] Properties to set
     * @returns {crewmgmt.AircraftType} AircraftType instance
     */
    AircraftType.create = function create(properties) {
      return new AircraftType(properties)
    }

    /**
     * Encodes the specified AircraftType message. Does not implicitly {@link crewmgmt.AircraftType.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {crewmgmt.IAircraftType} message AircraftType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AircraftType.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      return writer
    }

    /**
     * Encodes the specified AircraftType message, length delimited. Does not implicitly {@link crewmgmt.AircraftType.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {crewmgmt.IAircraftType} message AircraftType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AircraftType.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an AircraftType message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.AircraftType} AircraftType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AircraftType.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.AircraftType()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an AircraftType message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.AircraftType} AircraftType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AircraftType.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an AircraftType message.
     * @function verify
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AircraftType.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      return null
    }

    /**
     * Creates an AircraftType message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.AircraftType} AircraftType
     */
    AircraftType.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.AircraftType) return object
      let message = new $root.crewmgmt.AircraftType()
      if (object.id != null) message.id = String(object.id)
      return message
    }

    /**
     * Creates a plain object from an AircraftType message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.AircraftType
     * @static
     * @param {crewmgmt.AircraftType} message AircraftType
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AircraftType.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.id = ''
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      return object
    }

    /**
     * Converts this AircraftType to JSON.
     * @function toJSON
     * @memberof crewmgmt.AircraftType
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AircraftType.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return AircraftType
  })()

  crewmgmt.Airline = (function () {
    /**
     * Properties of an Airline.
     * @memberof crewmgmt
     * @interface IAirline
     * @property {string|null} [id] Airline id
     */

    /**
     * Constructs a new Airline.
     * @memberof crewmgmt
     * @classdesc Represents an Airline.
     * @implements IAirline
     * @constructor
     * @param {crewmgmt.IAirline=} [properties] Properties to set
     */
    function Airline(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Airline id.
     * @member {string} id
     * @memberof crewmgmt.Airline
     * @instance
     */
    Airline.prototype.id = ''

    /**
     * Creates a new Airline instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Airline
     * @static
     * @param {crewmgmt.IAirline=} [properties] Properties to set
     * @returns {crewmgmt.Airline} Airline instance
     */
    Airline.create = function create(properties) {
      return new Airline(properties)
    }

    /**
     * Encodes the specified Airline message. Does not implicitly {@link crewmgmt.Airline.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Airline
     * @static
     * @param {crewmgmt.IAirline} message Airline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Airline.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      return writer
    }

    /**
     * Encodes the specified Airline message, length delimited. Does not implicitly {@link crewmgmt.Airline.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Airline
     * @static
     * @param {crewmgmt.IAirline} message Airline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Airline.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Airline message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Airline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Airline} Airline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Airline.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Airline()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Airline message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Airline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Airline} Airline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Airline.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Airline message.
     * @function verify
     * @memberof crewmgmt.Airline
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Airline.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      return null
    }

    /**
     * Creates an Airline message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Airline
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Airline} Airline
     */
    Airline.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Airline) return object
      let message = new $root.crewmgmt.Airline()
      if (object.id != null) message.id = String(object.id)
      return message
    }

    /**
     * Creates a plain object from an Airline message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Airline
     * @static
     * @param {crewmgmt.Airline} message Airline
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Airline.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.id = ''
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      return object
    }

    /**
     * Converts this Airline to JSON.
     * @function toJSON
     * @memberof crewmgmt.Airline
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Airline.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Airline
  })()

  crewmgmt.Airport = (function () {
    /**
     * Properties of an Airport.
     * @memberof crewmgmt
     * @interface IAirport
     * @property {string|null} [id] Airport id
     */

    /**
     * Constructs a new Airport.
     * @memberof crewmgmt
     * @classdesc Represents an Airport.
     * @implements IAirport
     * @constructor
     * @param {crewmgmt.IAirport=} [properties] Properties to set
     */
    function Airport(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Airport id.
     * @member {string} id
     * @memberof crewmgmt.Airport
     * @instance
     */
    Airport.prototype.id = ''

    /**
     * Creates a new Airport instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Airport
     * @static
     * @param {crewmgmt.IAirport=} [properties] Properties to set
     * @returns {crewmgmt.Airport} Airport instance
     */
    Airport.create = function create(properties) {
      return new Airport(properties)
    }

    /**
     * Encodes the specified Airport message. Does not implicitly {@link crewmgmt.Airport.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Airport
     * @static
     * @param {crewmgmt.IAirport} message Airport message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Airport.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      return writer
    }

    /**
     * Encodes the specified Airport message, length delimited. Does not implicitly {@link crewmgmt.Airport.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Airport
     * @static
     * @param {crewmgmt.IAirport} message Airport message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Airport.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Airport message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Airport
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Airport} Airport
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Airport.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Airport()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Airport message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Airport
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Airport} Airport
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Airport.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Airport message.
     * @function verify
     * @memberof crewmgmt.Airport
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Airport.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      return null
    }

    /**
     * Creates an Airport message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Airport
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Airport} Airport
     */
    Airport.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Airport) return object
      let message = new $root.crewmgmt.Airport()
      if (object.id != null) message.id = String(object.id)
      return message
    }

    /**
     * Creates a plain object from an Airport message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Airport
     * @static
     * @param {crewmgmt.Airport} message Airport
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Airport.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.id = ''
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      return object
    }

    /**
     * Converts this Airport to JSON.
     * @function toJSON
     * @memberof crewmgmt.Airport
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Airport.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Airport
  })()

  crewmgmt.Rank = (function () {
    /**
     * Properties of a Rank.
     * @memberof crewmgmt
     * @interface IRank
     * @property {string|null} [id] Rank id
     */

    /**
     * Constructs a new Rank.
     * @memberof crewmgmt
     * @classdesc Represents a Rank.
     * @implements IRank
     * @constructor
     * @param {crewmgmt.IRank=} [properties] Properties to set
     */
    function Rank(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Rank id.
     * @member {string} id
     * @memberof crewmgmt.Rank
     * @instance
     */
    Rank.prototype.id = ''

    /**
     * Creates a new Rank instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Rank
     * @static
     * @param {crewmgmt.IRank=} [properties] Properties to set
     * @returns {crewmgmt.Rank} Rank instance
     */
    Rank.create = function create(properties) {
      return new Rank(properties)
    }

    /**
     * Encodes the specified Rank message. Does not implicitly {@link crewmgmt.Rank.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Rank
     * @static
     * @param {crewmgmt.IRank} message Rank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Rank.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      return writer
    }

    /**
     * Encodes the specified Rank message, length delimited. Does not implicitly {@link crewmgmt.Rank.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Rank
     * @static
     * @param {crewmgmt.IRank} message Rank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Rank.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Rank message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Rank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Rank} Rank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Rank.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Rank()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Rank message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Rank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Rank} Rank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Rank.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Rank message.
     * @function verify
     * @memberof crewmgmt.Rank
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Rank.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      return null
    }

    /**
     * Creates a Rank message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Rank
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Rank} Rank
     */
    Rank.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Rank) return object
      let message = new $root.crewmgmt.Rank()
      if (object.id != null) message.id = String(object.id)
      return message
    }

    /**
     * Creates a plain object from a Rank message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Rank
     * @static
     * @param {crewmgmt.Rank} message Rank
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Rank.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) object.id = ''
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      return object
    }

    /**
     * Converts this Rank to JSON.
     * @function toJSON
     * @memberof crewmgmt.Rank
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Rank.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Rank
  })()

  crewmgmt.CrewMember = (function () {
    /**
     * Properties of a CrewMember.
     * @memberof crewmgmt
     * @interface ICrewMember
     * @property {string|null} [id] CrewMember id
     * @property {string|null} [homeBase] CrewMember homeBase
     * @property {string|null} [birthDate] CrewMember birthDate
     * @property {Array.<string>|null} [crewFunctions] CrewMember crewFunctions
     */

    /**
     * Constructs a new CrewMember.
     * @memberof crewmgmt
     * @classdesc Represents a CrewMember.
     * @implements ICrewMember
     * @constructor
     * @param {crewmgmt.ICrewMember=} [properties] Properties to set
     */
    function CrewMember(properties) {
      this.crewFunctions = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * CrewMember id.
     * @member {string} id
     * @memberof crewmgmt.CrewMember
     * @instance
     */
    CrewMember.prototype.id = ''

    /**
     * CrewMember homeBase.
     * @member {string} homeBase
     * @memberof crewmgmt.CrewMember
     * @instance
     */
    CrewMember.prototype.homeBase = ''

    /**
     * CrewMember birthDate.
     * @member {string} birthDate
     * @memberof crewmgmt.CrewMember
     * @instance
     */
    CrewMember.prototype.birthDate = ''

    /**
     * CrewMember crewFunctions.
     * @member {Array.<string>} crewFunctions
     * @memberof crewmgmt.CrewMember
     * @instance
     */
    CrewMember.prototype.crewFunctions = $util.emptyArray

    /**
     * Creates a new CrewMember instance using the specified properties.
     * @function create
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {crewmgmt.ICrewMember=} [properties] Properties to set
     * @returns {crewmgmt.CrewMember} CrewMember instance
     */
    CrewMember.create = function create(properties) {
      return new CrewMember(properties)
    }

    /**
     * Encodes the specified CrewMember message. Does not implicitly {@link crewmgmt.CrewMember.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {crewmgmt.ICrewMember} message CrewMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMember.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.homeBase != null && Object.hasOwnProperty.call(message, 'homeBase'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.homeBase)
      if (message.birthDate != null && Object.hasOwnProperty.call(message, 'birthDate'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.birthDate)
      if (message.crewFunctions != null && message.crewFunctions.length)
        for (let i = 0; i < message.crewFunctions.length; ++i)
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.crewFunctions[i])
      return writer
    }

    /**
     * Encodes the specified CrewMember message, length delimited. Does not implicitly {@link crewmgmt.CrewMember.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {crewmgmt.ICrewMember} message CrewMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMember.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a CrewMember message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.CrewMember} CrewMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMember.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.CrewMember()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.homeBase = reader.string()
            break
          case 3:
            message.birthDate = reader.string()
            break
          case 4:
            if (!(message.crewFunctions && message.crewFunctions.length)) message.crewFunctions = []
            message.crewFunctions.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a CrewMember message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.CrewMember} CrewMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMember.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a CrewMember message.
     * @function verify
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CrewMember.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.homeBase != null && message.hasOwnProperty('homeBase'))
        if (!$util.isString(message.homeBase)) return 'homeBase: string expected'
      if (message.birthDate != null && message.hasOwnProperty('birthDate'))
        if (!$util.isString(message.birthDate)) return 'birthDate: string expected'
      if (message.crewFunctions != null && message.hasOwnProperty('crewFunctions')) {
        if (!Array.isArray(message.crewFunctions)) return 'crewFunctions: array expected'
        for (let i = 0; i < message.crewFunctions.length; ++i)
          if (!$util.isString(message.crewFunctions[i])) return 'crewFunctions: string[] expected'
      }
      return null
    }

    /**
     * Creates a CrewMember message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.CrewMember} CrewMember
     */
    CrewMember.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.CrewMember) return object
      let message = new $root.crewmgmt.CrewMember()
      if (object.id != null) message.id = String(object.id)
      if (object.homeBase != null) message.homeBase = String(object.homeBase)
      if (object.birthDate != null) message.birthDate = String(object.birthDate)
      if (object.crewFunctions) {
        if (!Array.isArray(object.crewFunctions)) throw TypeError('.crewmgmt.CrewMember.crewFunctions: array expected')
        message.crewFunctions = []
        for (let i = 0; i < object.crewFunctions.length; ++i) message.crewFunctions[i] = String(object.crewFunctions[i])
      }
      return message
    }

    /**
     * Creates a plain object from a CrewMember message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.CrewMember
     * @static
     * @param {crewmgmt.CrewMember} message CrewMember
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CrewMember.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.crewFunctions = []
      if (options.defaults) {
        object.id = ''
        object.homeBase = ''
        object.birthDate = ''
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.homeBase != null && message.hasOwnProperty('homeBase')) object.homeBase = message.homeBase
      if (message.birthDate != null && message.hasOwnProperty('birthDate')) object.birthDate = message.birthDate
      if (message.crewFunctions && message.crewFunctions.length) {
        object.crewFunctions = []
        for (let j = 0; j < message.crewFunctions.length; ++j) object.crewFunctions[j] = message.crewFunctions[j]
      }
      return object
    }

    /**
     * Converts this CrewMember to JSON.
     * @function toJSON
     * @memberof crewmgmt.CrewMember
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CrewMember.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return CrewMember
  })()

  crewmgmt.CrewMemberAbsence = (function () {
    /**
     * Properties of a CrewMemberAbsence.
     * @memberof crewmgmt
     * @interface ICrewMemberAbsence
     * @property {string|null} [crewMember] CrewMemberAbsence crewMember
     * @property {string|null} [date] CrewMemberAbsence date
     */

    /**
     * Constructs a new CrewMemberAbsence.
     * @memberof crewmgmt
     * @classdesc Represents a CrewMemberAbsence.
     * @implements ICrewMemberAbsence
     * @constructor
     * @param {crewmgmt.ICrewMemberAbsence=} [properties] Properties to set
     */
    function CrewMemberAbsence(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * CrewMemberAbsence crewMember.
     * @member {string} crewMember
     * @memberof crewmgmt.CrewMemberAbsence
     * @instance
     */
    CrewMemberAbsence.prototype.crewMember = ''

    /**
     * CrewMemberAbsence date.
     * @member {string} date
     * @memberof crewmgmt.CrewMemberAbsence
     * @instance
     */
    CrewMemberAbsence.prototype.date = ''

    /**
     * Creates a new CrewMemberAbsence instance using the specified properties.
     * @function create
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {crewmgmt.ICrewMemberAbsence=} [properties] Properties to set
     * @returns {crewmgmt.CrewMemberAbsence} CrewMemberAbsence instance
     */
    CrewMemberAbsence.create = function create(properties) {
      return new CrewMemberAbsence(properties)
    }

    /**
     * Encodes the specified CrewMemberAbsence message. Does not implicitly {@link crewmgmt.CrewMemberAbsence.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {crewmgmt.ICrewMemberAbsence} message CrewMemberAbsence message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMemberAbsence.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.crewMember != null && Object.hasOwnProperty.call(message, 'crewMember'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.crewMember)
      if (message.date != null && Object.hasOwnProperty.call(message, 'date'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.date)
      return writer
    }

    /**
     * Encodes the specified CrewMemberAbsence message, length delimited. Does not implicitly {@link crewmgmt.CrewMemberAbsence.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {crewmgmt.ICrewMemberAbsence} message CrewMemberAbsence message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMemberAbsence.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a CrewMemberAbsence message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.CrewMemberAbsence} CrewMemberAbsence
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMemberAbsence.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.CrewMemberAbsence()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.crewMember = reader.string()
            break
          case 2:
            message.date = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a CrewMemberAbsence message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.CrewMemberAbsence} CrewMemberAbsence
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMemberAbsence.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a CrewMemberAbsence message.
     * @function verify
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CrewMemberAbsence.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.crewMember != null && message.hasOwnProperty('crewMember'))
        if (!$util.isString(message.crewMember)) return 'crewMember: string expected'
      if (message.date != null && message.hasOwnProperty('date'))
        if (!$util.isString(message.date)) return 'date: string expected'
      return null
    }

    /**
     * Creates a CrewMemberAbsence message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.CrewMemberAbsence} CrewMemberAbsence
     */
    CrewMemberAbsence.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.CrewMemberAbsence) return object
      let message = new $root.crewmgmt.CrewMemberAbsence()
      if (object.crewMember != null) message.crewMember = String(object.crewMember)
      if (object.date != null) message.date = String(object.date)
      return message
    }

    /**
     * Creates a plain object from a CrewMemberAbsence message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.CrewMemberAbsence
     * @static
     * @param {crewmgmt.CrewMemberAbsence} message CrewMemberAbsence
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CrewMemberAbsence.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.crewMember = ''
        object.date = ''
      }
      if (message.crewMember != null && message.hasOwnProperty('crewMember')) object.crewMember = message.crewMember
      if (message.date != null && message.hasOwnProperty('date')) object.date = message.date
      return object
    }

    /**
     * Converts this CrewMemberAbsence to JSON.
     * @function toJSON
     * @memberof crewmgmt.CrewMemberAbsence
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CrewMemberAbsence.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return CrewMemberAbsence
  })()

  crewmgmt.CrewMemberFlightAssignment = (function () {
    /**
     * Properties of a CrewMemberFlightAssignment.
     * @memberof crewmgmt
     * @interface ICrewMemberFlightAssignment
     * @property {string|null} [crewMember] CrewMemberFlightAssignment crewMember
     * @property {string|null} [flight] CrewMemberFlightAssignment flight
     */

    /**
     * Constructs a new CrewMemberFlightAssignment.
     * @memberof crewmgmt
     * @classdesc Represents a CrewMemberFlightAssignment.
     * @implements ICrewMemberFlightAssignment
     * @constructor
     * @param {crewmgmt.ICrewMemberFlightAssignment=} [properties] Properties to set
     */
    function CrewMemberFlightAssignment(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * CrewMemberFlightAssignment crewMember.
     * @member {string} crewMember
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @instance
     */
    CrewMemberFlightAssignment.prototype.crewMember = ''

    /**
     * CrewMemberFlightAssignment flight.
     * @member {string} flight
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @instance
     */
    CrewMemberFlightAssignment.prototype.flight = ''

    /**
     * Creates a new CrewMemberFlightAssignment instance using the specified properties.
     * @function create
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {crewmgmt.ICrewMemberFlightAssignment=} [properties] Properties to set
     * @returns {crewmgmt.CrewMemberFlightAssignment} CrewMemberFlightAssignment instance
     */
    CrewMemberFlightAssignment.create = function create(properties) {
      return new CrewMemberFlightAssignment(properties)
    }

    /**
     * Encodes the specified CrewMemberFlightAssignment message. Does not implicitly {@link crewmgmt.CrewMemberFlightAssignment.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {crewmgmt.ICrewMemberFlightAssignment} message CrewMemberFlightAssignment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMemberFlightAssignment.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.crewMember != null && Object.hasOwnProperty.call(message, 'crewMember'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.crewMember)
      if (message.flight != null && Object.hasOwnProperty.call(message, 'flight'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.flight)
      return writer
    }

    /**
     * Encodes the specified CrewMemberFlightAssignment message, length delimited. Does not implicitly {@link crewmgmt.CrewMemberFlightAssignment.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {crewmgmt.ICrewMemberFlightAssignment} message CrewMemberFlightAssignment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMemberFlightAssignment.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a CrewMemberFlightAssignment message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.CrewMemberFlightAssignment} CrewMemberFlightAssignment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMemberFlightAssignment.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.CrewMemberFlightAssignment()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.crewMember = reader.string()
            break
          case 2:
            message.flight = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a CrewMemberFlightAssignment message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.CrewMemberFlightAssignment} CrewMemberFlightAssignment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMemberFlightAssignment.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a CrewMemberFlightAssignment message.
     * @function verify
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CrewMemberFlightAssignment.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.crewMember != null && message.hasOwnProperty('crewMember'))
        if (!$util.isString(message.crewMember)) return 'crewMember: string expected'
      if (message.flight != null && message.hasOwnProperty('flight'))
        if (!$util.isString(message.flight)) return 'flight: string expected'
      return null
    }

    /**
     * Creates a CrewMemberFlightAssignment message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.CrewMemberFlightAssignment} CrewMemberFlightAssignment
     */
    CrewMemberFlightAssignment.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.CrewMemberFlightAssignment) return object
      let message = new $root.crewmgmt.CrewMemberFlightAssignment()
      if (object.crewMember != null) message.crewMember = String(object.crewMember)
      if (object.flight != null) message.flight = String(object.flight)
      return message
    }

    /**
     * Creates a plain object from a CrewMemberFlightAssignment message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @static
     * @param {crewmgmt.CrewMemberFlightAssignment} message CrewMemberFlightAssignment
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CrewMemberFlightAssignment.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.crewMember = ''
        object.flight = ''
      }
      if (message.crewMember != null && message.hasOwnProperty('crewMember')) object.crewMember = message.crewMember
      if (message.flight != null && message.hasOwnProperty('flight')) object.flight = message.flight
      return object
    }

    /**
     * Converts this CrewMemberFlightAssignment to JSON.
     * @function toJSON
     * @memberof crewmgmt.CrewMemberFlightAssignment
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CrewMemberFlightAssignment.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return CrewMemberFlightAssignment
  })()

  crewmgmt.Flight = (function () {
    /**
     * Properties of a Flight.
     * @memberof crewmgmt
     * @interface IFlight
     * @property {string|null} [id] Flight id
     * @property {string|null} [aircraft] Flight aircraft
     * @property {string|null} [airline] Flight airline
     * @property {string|null} [flightNumber] Flight flightNumber
     * @property {string|null} [departureAirport] Flight departureAirport
     * @property {string|null} [arrivalAirport] Flight arrivalAirport
     * @property {crewmgmt.ITimeframe|null} [departureAndArrivalTimestamp] Flight departureAndArrivalTimestamp
     */

    /**
     * Constructs a new Flight.
     * @memberof crewmgmt
     * @classdesc Represents a Flight.
     * @implements IFlight
     * @constructor
     * @param {crewmgmt.IFlight=} [properties] Properties to set
     */
    function Flight(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Flight id.
     * @member {string} id
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.id = ''

    /**
     * Flight aircraft.
     * @member {string} aircraft
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.aircraft = ''

    /**
     * Flight airline.
     * @member {string} airline
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.airline = ''

    /**
     * Flight flightNumber.
     * @member {string} flightNumber
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.flightNumber = ''

    /**
     * Flight departureAirport.
     * @member {string} departureAirport
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.departureAirport = ''

    /**
     * Flight arrivalAirport.
     * @member {string} arrivalAirport
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.arrivalAirport = ''

    /**
     * Flight departureAndArrivalTimestamp.
     * @member {crewmgmt.ITimeframe|null|undefined} departureAndArrivalTimestamp
     * @memberof crewmgmt.Flight
     * @instance
     */
    Flight.prototype.departureAndArrivalTimestamp = null

    /**
     * Creates a new Flight instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Flight
     * @static
     * @param {crewmgmt.IFlight=} [properties] Properties to set
     * @returns {crewmgmt.Flight} Flight instance
     */
    Flight.create = function create(properties) {
      return new Flight(properties)
    }

    /**
     * Encodes the specified Flight message. Does not implicitly {@link crewmgmt.Flight.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Flight
     * @static
     * @param {crewmgmt.IFlight} message Flight message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Flight.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.aircraft != null && Object.hasOwnProperty.call(message, 'aircraft'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.aircraft)
      if (message.airline != null && Object.hasOwnProperty.call(message, 'airline'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.airline)
      if (message.flightNumber != null && Object.hasOwnProperty.call(message, 'flightNumber'))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.flightNumber)
      if (message.departureAirport != null && Object.hasOwnProperty.call(message, 'departureAirport'))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.departureAirport)
      if (message.arrivalAirport != null && Object.hasOwnProperty.call(message, 'arrivalAirport'))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.arrivalAirport)
      if (
        message.departureAndArrivalTimestamp != null &&
        Object.hasOwnProperty.call(message, 'departureAndArrivalTimestamp')
      )
        $root.crewmgmt.Timeframe.encode(
          message.departureAndArrivalTimestamp,
          writer.uint32(/* id 7, wireType 2 =*/ 58).fork()
        ).ldelim()
      return writer
    }

    /**
     * Encodes the specified Flight message, length delimited. Does not implicitly {@link crewmgmt.Flight.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Flight
     * @static
     * @param {crewmgmt.IFlight} message Flight message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Flight.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Flight message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Flight
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Flight} Flight
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Flight.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Flight()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.aircraft = reader.string()
            break
          case 3:
            message.airline = reader.string()
            break
          case 4:
            message.flightNumber = reader.string()
            break
          case 5:
            message.departureAirport = reader.string()
            break
          case 6:
            message.arrivalAirport = reader.string()
            break
          case 7:
            message.departureAndArrivalTimestamp = $root.crewmgmt.Timeframe.decode(reader, reader.uint32())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Flight message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Flight
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Flight} Flight
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Flight.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Flight message.
     * @function verify
     * @memberof crewmgmt.Flight
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Flight.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.aircraft != null && message.hasOwnProperty('aircraft'))
        if (!$util.isString(message.aircraft)) return 'aircraft: string expected'
      if (message.airline != null && message.hasOwnProperty('airline'))
        if (!$util.isString(message.airline)) return 'airline: string expected'
      if (message.flightNumber != null && message.hasOwnProperty('flightNumber'))
        if (!$util.isString(message.flightNumber)) return 'flightNumber: string expected'
      if (message.departureAirport != null && message.hasOwnProperty('departureAirport'))
        if (!$util.isString(message.departureAirport)) return 'departureAirport: string expected'
      if (message.arrivalAirport != null && message.hasOwnProperty('arrivalAirport'))
        if (!$util.isString(message.arrivalAirport)) return 'arrivalAirport: string expected'
      if (message.departureAndArrivalTimestamp != null && message.hasOwnProperty('departureAndArrivalTimestamp')) {
        let error = $root.crewmgmt.Timeframe.verify(message.departureAndArrivalTimestamp)
        if (error) return 'departureAndArrivalTimestamp.' + error
      }
      return null
    }

    /**
     * Creates a Flight message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Flight
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Flight} Flight
     */
    Flight.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Flight) return object
      let message = new $root.crewmgmt.Flight()
      if (object.id != null) message.id = String(object.id)
      if (object.aircraft != null) message.aircraft = String(object.aircraft)
      if (object.airline != null) message.airline = String(object.airline)
      if (object.flightNumber != null) message.flightNumber = String(object.flightNumber)
      if (object.departureAirport != null) message.departureAirport = String(object.departureAirport)
      if (object.arrivalAirport != null) message.arrivalAirport = String(object.arrivalAirport)
      if (object.departureAndArrivalTimestamp != null) {
        if (typeof object.departureAndArrivalTimestamp !== 'object')
          throw TypeError('.crewmgmt.Flight.departureAndArrivalTimestamp: object expected')
        message.departureAndArrivalTimestamp = $root.crewmgmt.Timeframe.fromObject(object.departureAndArrivalTimestamp)
      }
      return message
    }

    /**
     * Creates a plain object from a Flight message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Flight
     * @static
     * @param {crewmgmt.Flight} message Flight
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Flight.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.aircraft = ''
        object.airline = ''
        object.flightNumber = ''
        object.departureAirport = ''
        object.arrivalAirport = ''
        object.departureAndArrivalTimestamp = null
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.aircraft != null && message.hasOwnProperty('aircraft')) object.aircraft = message.aircraft
      if (message.airline != null && message.hasOwnProperty('airline')) object.airline = message.airline
      if (message.flightNumber != null && message.hasOwnProperty('flightNumber'))
        object.flightNumber = message.flightNumber
      if (message.departureAirport != null && message.hasOwnProperty('departureAirport'))
        object.departureAirport = message.departureAirport
      if (message.arrivalAirport != null && message.hasOwnProperty('arrivalAirport'))
        object.arrivalAirport = message.arrivalAirport
      if (message.departureAndArrivalTimestamp != null && message.hasOwnProperty('departureAndArrivalTimestamp'))
        object.departureAndArrivalTimestamp = $root.crewmgmt.Timeframe.toObject(
          message.departureAndArrivalTimestamp,
          options
        )
      return object
    }

    /**
     * Converts this Flight to JSON.
     * @function toJSON
     * @memberof crewmgmt.Flight
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Flight.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Flight
  })()

  crewmgmt.CrewMgmtSession = (function () {
    /**
     * Properties of a CrewMgmtSession.
     * @memberof crewmgmt
     * @interface ICrewMgmtSession
     * @property {string|null} [clientReference] CrewMgmtSession clientReference
     * @property {string|null} [repositoryName] CrewMgmtSession repositoryName
     * @property {crewmgmt.ICrewMgmtRepositoryFilter|null} [repositoryFilter] CrewMgmtSession repositoryFilter
     */

    /**
     * Constructs a new CrewMgmtSession.
     * @memberof crewmgmt
     * @classdesc Represents a CrewMgmtSession.
     * @implements ICrewMgmtSession
     * @constructor
     * @param {crewmgmt.ICrewMgmtSession=} [properties] Properties to set
     */
    function CrewMgmtSession(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * CrewMgmtSession clientReference.
     * @member {string} clientReference
     * @memberof crewmgmt.CrewMgmtSession
     * @instance
     */
    CrewMgmtSession.prototype.clientReference = ''

    /**
     * CrewMgmtSession repositoryName.
     * @member {string} repositoryName
     * @memberof crewmgmt.CrewMgmtSession
     * @instance
     */
    CrewMgmtSession.prototype.repositoryName = ''

    /**
     * CrewMgmtSession repositoryFilter.
     * @member {crewmgmt.ICrewMgmtRepositoryFilter|null|undefined} repositoryFilter
     * @memberof crewmgmt.CrewMgmtSession
     * @instance
     */
    CrewMgmtSession.prototype.repositoryFilter = null

    /**
     * Creates a new CrewMgmtSession instance using the specified properties.
     * @function create
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {crewmgmt.ICrewMgmtSession=} [properties] Properties to set
     * @returns {crewmgmt.CrewMgmtSession} CrewMgmtSession instance
     */
    CrewMgmtSession.create = function create(properties) {
      return new CrewMgmtSession(properties)
    }

    /**
     * Encodes the specified CrewMgmtSession message. Does not implicitly {@link crewmgmt.CrewMgmtSession.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {crewmgmt.ICrewMgmtSession} message CrewMgmtSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMgmtSession.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.clientReference != null && Object.hasOwnProperty.call(message, 'clientReference'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.clientReference)
      if (message.repositoryName != null && Object.hasOwnProperty.call(message, 'repositoryName'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.repositoryName)
      if (message.repositoryFilter != null && Object.hasOwnProperty.call(message, 'repositoryFilter'))
        $root.crewmgmt.CrewMgmtRepositoryFilter.encode(
          message.repositoryFilter,
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim()
      return writer
    }

    /**
     * Encodes the specified CrewMgmtSession message, length delimited. Does not implicitly {@link crewmgmt.CrewMgmtSession.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {crewmgmt.ICrewMgmtSession} message CrewMgmtSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMgmtSession.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a CrewMgmtSession message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.CrewMgmtSession} CrewMgmtSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMgmtSession.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.CrewMgmtSession()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.clientReference = reader.string()
            break
          case 2:
            message.repositoryName = reader.string()
            break
          case 3:
            message.repositoryFilter = $root.crewmgmt.CrewMgmtRepositoryFilter.decode(reader, reader.uint32())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a CrewMgmtSession message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.CrewMgmtSession} CrewMgmtSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMgmtSession.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a CrewMgmtSession message.
     * @function verify
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CrewMgmtSession.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.clientReference != null && message.hasOwnProperty('clientReference'))
        if (!$util.isString(message.clientReference)) return 'clientReference: string expected'
      if (message.repositoryName != null && message.hasOwnProperty('repositoryName'))
        if (!$util.isString(message.repositoryName)) return 'repositoryName: string expected'
      if (message.repositoryFilter != null && message.hasOwnProperty('repositoryFilter')) {
        let error = $root.crewmgmt.CrewMgmtRepositoryFilter.verify(message.repositoryFilter)
        if (error) return 'repositoryFilter.' + error
      }
      return null
    }

    /**
     * Creates a CrewMgmtSession message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.CrewMgmtSession} CrewMgmtSession
     */
    CrewMgmtSession.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.CrewMgmtSession) return object
      let message = new $root.crewmgmt.CrewMgmtSession()
      if (object.clientReference != null) message.clientReference = String(object.clientReference)
      if (object.repositoryName != null) message.repositoryName = String(object.repositoryName)
      if (object.repositoryFilter != null) {
        if (typeof object.repositoryFilter !== 'object')
          throw TypeError('.crewmgmt.CrewMgmtSession.repositoryFilter: object expected')
        message.repositoryFilter = $root.crewmgmt.CrewMgmtRepositoryFilter.fromObject(object.repositoryFilter)
      }
      return message
    }

    /**
     * Creates a plain object from a CrewMgmtSession message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.CrewMgmtSession
     * @static
     * @param {crewmgmt.CrewMgmtSession} message CrewMgmtSession
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CrewMgmtSession.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.clientReference = ''
        object.repositoryName = ''
        object.repositoryFilter = null
      }
      if (message.clientReference != null && message.hasOwnProperty('clientReference'))
        object.clientReference = message.clientReference
      if (message.repositoryName != null && message.hasOwnProperty('repositoryName'))
        object.repositoryName = message.repositoryName
      if (message.repositoryFilter != null && message.hasOwnProperty('repositoryFilter'))
        object.repositoryFilter = $root.crewmgmt.CrewMgmtRepositoryFilter.toObject(message.repositoryFilter, options)
      return object
    }

    /**
     * Converts this CrewMgmtSession to JSON.
     * @function toJSON
     * @memberof crewmgmt.CrewMgmtSession
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CrewMgmtSession.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return CrewMgmtSession
  })()

  crewmgmt.CrewMgmtRepositoryFilter = (function () {
    /**
     * Properties of a CrewMgmtRepositoryFilter.
     * @memberof crewmgmt
     * @interface ICrewMgmtRepositoryFilter
     */

    /**
     * Constructs a new CrewMgmtRepositoryFilter.
     * @memberof crewmgmt
     * @classdesc Represents a CrewMgmtRepositoryFilter.
     * @implements ICrewMgmtRepositoryFilter
     * @constructor
     * @param {crewmgmt.ICrewMgmtRepositoryFilter=} [properties] Properties to set
     */
    function CrewMgmtRepositoryFilter(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Creates a new CrewMgmtRepositoryFilter instance using the specified properties.
     * @function create
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {crewmgmt.ICrewMgmtRepositoryFilter=} [properties] Properties to set
     * @returns {crewmgmt.CrewMgmtRepositoryFilter} CrewMgmtRepositoryFilter instance
     */
    CrewMgmtRepositoryFilter.create = function create(properties) {
      return new CrewMgmtRepositoryFilter(properties)
    }

    /**
     * Encodes the specified CrewMgmtRepositoryFilter message. Does not implicitly {@link crewmgmt.CrewMgmtRepositoryFilter.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {crewmgmt.ICrewMgmtRepositoryFilter} message CrewMgmtRepositoryFilter message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMgmtRepositoryFilter.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      return writer
    }

    /**
     * Encodes the specified CrewMgmtRepositoryFilter message, length delimited. Does not implicitly {@link crewmgmt.CrewMgmtRepositoryFilter.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {crewmgmt.ICrewMgmtRepositoryFilter} message CrewMgmtRepositoryFilter message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CrewMgmtRepositoryFilter.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a CrewMgmtRepositoryFilter message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.CrewMgmtRepositoryFilter} CrewMgmtRepositoryFilter
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMgmtRepositoryFilter.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.CrewMgmtRepositoryFilter()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a CrewMgmtRepositoryFilter message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.CrewMgmtRepositoryFilter} CrewMgmtRepositoryFilter
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CrewMgmtRepositoryFilter.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a CrewMgmtRepositoryFilter message.
     * @function verify
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CrewMgmtRepositoryFilter.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      return null
    }

    /**
     * Creates a CrewMgmtRepositoryFilter message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.CrewMgmtRepositoryFilter} CrewMgmtRepositoryFilter
     */
    CrewMgmtRepositoryFilter.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.CrewMgmtRepositoryFilter) return object
      return new $root.crewmgmt.CrewMgmtRepositoryFilter()
    }

    /**
     * Creates a plain object from a CrewMgmtRepositoryFilter message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @static
     * @param {crewmgmt.CrewMgmtRepositoryFilter} message CrewMgmtRepositoryFilter
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CrewMgmtRepositoryFilter.toObject = function toObject() {
      return {}
    }

    /**
     * Converts this CrewMgmtRepositoryFilter to JSON.
     * @function toJSON
     * @memberof crewmgmt.CrewMgmtRepositoryFilter
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CrewMgmtRepositoryFilter.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return CrewMgmtRepositoryFilter
  })()

  crewmgmt.Timeframe = (function () {
    /**
     * Properties of a Timeframe.
     * @memberof crewmgmt
     * @interface ITimeframe
     * @property {number|Long|null} [start] Timeframe start
     * @property {number|Long|null} [end] Timeframe end
     */

    /**
     * Constructs a new Timeframe.
     * @memberof crewmgmt
     * @classdesc Represents a Timeframe.
     * @implements ITimeframe
     * @constructor
     * @param {crewmgmt.ITimeframe=} [properties] Properties to set
     */
    function Timeframe(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Timeframe start.
     * @member {number|Long} start
     * @memberof crewmgmt.Timeframe
     * @instance
     */
    Timeframe.prototype.start = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Timeframe end.
     * @member {number|Long} end
     * @memberof crewmgmt.Timeframe
     * @instance
     */
    Timeframe.prototype.end = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Creates a new Timeframe instance using the specified properties.
     * @function create
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {crewmgmt.ITimeframe=} [properties] Properties to set
     * @returns {crewmgmt.Timeframe} Timeframe instance
     */
    Timeframe.create = function create(properties) {
      return new Timeframe(properties)
    }

    /**
     * Encodes the specified Timeframe message. Does not implicitly {@link crewmgmt.Timeframe.verify|verify} messages.
     * @function encode
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {crewmgmt.ITimeframe} message Timeframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Timeframe.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.start != null && Object.hasOwnProperty.call(message, 'start'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.start)
      if (message.end != null && Object.hasOwnProperty.call(message, 'end'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.end)
      return writer
    }

    /**
     * Encodes the specified Timeframe message, length delimited. Does not implicitly {@link crewmgmt.Timeframe.verify|verify} messages.
     * @function encodeDelimited
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {crewmgmt.ITimeframe} message Timeframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Timeframe.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Timeframe message from the specified reader or buffer.
     * @function decode
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {crewmgmt.Timeframe} Timeframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Timeframe.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.crewmgmt.Timeframe()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.start = reader.int64()
            break
          case 2:
            message.end = reader.int64()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Timeframe message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {crewmgmt.Timeframe} Timeframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Timeframe.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Timeframe message.
     * @function verify
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Timeframe.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.start != null && message.hasOwnProperty('start'))
        if (
          !$util.isInteger(message.start) &&
          !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high))
        )
          return 'start: integer|Long expected'
      if (message.end != null && message.hasOwnProperty('end'))
        if (
          !$util.isInteger(message.end) &&
          !(message.end && $util.isInteger(message.end.low) && $util.isInteger(message.end.high))
        )
          return 'end: integer|Long expected'
      return null
    }

    /**
     * Creates a Timeframe message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {crewmgmt.Timeframe} Timeframe
     */
    Timeframe.fromObject = function fromObject(object) {
      if (object instanceof $root.crewmgmt.Timeframe) return object
      let message = new $root.crewmgmt.Timeframe()
      if (object.start != null)
        if ($util.Long) (message.start = $util.Long.fromValue(object.start)).unsigned = false
        else if (typeof object.start === 'string') message.start = parseInt(object.start, 10)
        else if (typeof object.start === 'number') message.start = object.start
        else if (typeof object.start === 'object')
          message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber()
      if (object.end != null)
        if ($util.Long) (message.end = $util.Long.fromValue(object.end)).unsigned = false
        else if (typeof object.end === 'string') message.end = parseInt(object.end, 10)
        else if (typeof object.end === 'number') message.end = object.end
        else if (typeof object.end === 'object')
          message.end = new $util.LongBits(object.end.low >>> 0, object.end.high >>> 0).toNumber()
      return message
    }

    /**
     * Creates a plain object from a Timeframe message. Also converts values to other types if specified.
     * @function toObject
     * @memberof crewmgmt.Timeframe
     * @static
     * @param {crewmgmt.Timeframe} message Timeframe
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Timeframe.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, false)
          object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
        } else object.start = options.longs === String ? '0' : 0
        if ($util.Long) {
          let long = new $util.Long(0, 0, false)
          object.end = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
        } else object.end = options.longs === String ? '0' : 0
      }
      if (message.start != null && message.hasOwnProperty('start'))
        if (typeof message.start === 'number')
          object.start = options.longs === String ? String(message.start) : message.start
        else
          object.start =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.start)
              : options.longs === Number
              ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber()
              : message.start
      if (message.end != null && message.hasOwnProperty('end'))
        if (typeof message.end === 'number') object.end = options.longs === String ? String(message.end) : message.end
        else
          object.end =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.end)
              : options.longs === Number
              ? new $util.LongBits(message.end.low >>> 0, message.end.high >>> 0).toNumber()
              : message.end
      return object
    }

    /**
     * Converts this Timeframe to JSON.
     * @function toJSON
     * @memberof crewmgmt.Timeframe
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Timeframe.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Timeframe
  })()

  return crewmgmt
})())

export const rulesengine = ($root.rulesengine = (() => {
  /**
   * Namespace rulesengine.
   * @exports rulesengine
   * @namespace
   */
  const rulesengine = {}

  rulesengine.Resource = (function () {
    /**
     * Properties of a Resource.
     * @memberof rulesengine
     * @interface IResource
     * @property {string|null} [id] Resource id
     * @property {string|null} [name] Resource name
     * @property {Array.<string>|null} [properties] Resource properties
     */

    /**
     * Constructs a new Resource.
     * @memberof rulesengine
     * @classdesc Represents a Resource.
     * @implements IResource
     * @constructor
     * @param {rulesengine.IResource=} [properties] Properties to set
     */
    function Resource(properties) {
      this.properties = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Resource id.
     * @member {string} id
     * @memberof rulesengine.Resource
     * @instance
     */
    Resource.prototype.id = ''

    /**
     * Resource name.
     * @member {string} name
     * @memberof rulesengine.Resource
     * @instance
     */
    Resource.prototype.name = ''

    /**
     * Resource properties.
     * @member {Array.<string>} properties
     * @memberof rulesengine.Resource
     * @instance
     */
    Resource.prototype.properties = $util.emptyArray

    /**
     * Creates a new Resource instance using the specified properties.
     * @function create
     * @memberof rulesengine.Resource
     * @static
     * @param {rulesengine.IResource=} [properties] Properties to set
     * @returns {rulesengine.Resource} Resource instance
     */
    Resource.create = function create(properties) {
      return new Resource(properties)
    }

    /**
     * Encodes the specified Resource message. Does not implicitly {@link rulesengine.Resource.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Resource
     * @static
     * @param {rulesengine.IResource} message Resource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Resource.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
      if (message.properties != null && message.properties.length)
        for (let i = 0; i < message.properties.length; ++i)
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.properties[i])
      return writer
    }

    /**
     * Encodes the specified Resource message, length delimited. Does not implicitly {@link rulesengine.Resource.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Resource
     * @static
     * @param {rulesengine.IResource} message Resource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Resource.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Resource message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Resource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Resource} Resource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Resource.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Resource()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.name = reader.string()
            break
          case 3:
            if (!(message.properties && message.properties.length)) message.properties = []
            message.properties.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Resource message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Resource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Resource} Resource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Resource.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Resource message.
     * @function verify
     * @memberof rulesengine.Resource
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Resource.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.name != null && message.hasOwnProperty('name'))
        if (!$util.isString(message.name)) return 'name: string expected'
      if (message.properties != null && message.hasOwnProperty('properties')) {
        if (!Array.isArray(message.properties)) return 'properties: array expected'
        for (let i = 0; i < message.properties.length; ++i)
          if (!$util.isString(message.properties[i])) return 'properties: string[] expected'
      }
      return null
    }

    /**
     * Creates a Resource message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Resource
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Resource} Resource
     */
    Resource.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Resource) return object
      let message = new $root.rulesengine.Resource()
      if (object.id != null) message.id = String(object.id)
      if (object.name != null) message.name = String(object.name)
      if (object.properties) {
        if (!Array.isArray(object.properties)) throw TypeError('.rulesengine.Resource.properties: array expected')
        message.properties = []
        for (let i = 0; i < object.properties.length; ++i) message.properties[i] = String(object.properties[i])
      }
      return message
    }

    /**
     * Creates a plain object from a Resource message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Resource
     * @static
     * @param {rulesengine.Resource} message Resource
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Resource.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.properties = []
      if (options.defaults) {
        object.id = ''
        object.name = ''
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
      if (message.properties && message.properties.length) {
        object.properties = []
        for (let j = 0; j < message.properties.length; ++j) object.properties[j] = message.properties[j]
      }
      return object
    }

    /**
     * Converts this Resource to JSON.
     * @function toJSON
     * @memberof rulesengine.Resource
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Resource.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Resource
  })()

  rulesengine.Activity = (function () {
    /**
     * Properties of an Activity.
     * @memberof rulesengine
     * @interface IActivity
     * @property {string|null} [id] Activity id
     * @property {string|null} [activityType] Activity activityType
     * @property {rulesengine.ITimeframe|null} [timeframe] Activity timeframe
     * @property {Array.<string>|null} [properties] Activity properties
     */

    /**
     * Constructs a new Activity.
     * @memberof rulesengine
     * @classdesc Represents an Activity.
     * @implements IActivity
     * @constructor
     * @param {rulesengine.IActivity=} [properties] Properties to set
     */
    function Activity(properties) {
      this.properties = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Activity id.
     * @member {string} id
     * @memberof rulesengine.Activity
     * @instance
     */
    Activity.prototype.id = ''

    /**
     * Activity activityType.
     * @member {string} activityType
     * @memberof rulesengine.Activity
     * @instance
     */
    Activity.prototype.activityType = ''

    /**
     * Activity timeframe.
     * @member {rulesengine.ITimeframe|null|undefined} timeframe
     * @memberof rulesengine.Activity
     * @instance
     */
    Activity.prototype.timeframe = null

    /**
     * Activity properties.
     * @member {Array.<string>} properties
     * @memberof rulesengine.Activity
     * @instance
     */
    Activity.prototype.properties = $util.emptyArray

    /**
     * Creates a new Activity instance using the specified properties.
     * @function create
     * @memberof rulesengine.Activity
     * @static
     * @param {rulesengine.IActivity=} [properties] Properties to set
     * @returns {rulesengine.Activity} Activity instance
     */
    Activity.create = function create(properties) {
      return new Activity(properties)
    }

    /**
     * Encodes the specified Activity message. Does not implicitly {@link rulesengine.Activity.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Activity
     * @static
     * @param {rulesengine.IActivity} message Activity message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Activity.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.activityType != null && Object.hasOwnProperty.call(message, 'activityType'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.activityType)
      if (message.timeframe != null && Object.hasOwnProperty.call(message, 'timeframe'))
        $root.rulesengine.Timeframe.encode(message.timeframe, writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim()
      if (message.properties != null && message.properties.length)
        for (let i = 0; i < message.properties.length; ++i)
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.properties[i])
      return writer
    }

    /**
     * Encodes the specified Activity message, length delimited. Does not implicitly {@link rulesengine.Activity.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Activity
     * @static
     * @param {rulesengine.IActivity} message Activity message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Activity.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Activity message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Activity
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Activity} Activity
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Activity.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Activity()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.activityType = reader.string()
            break
          case 3:
            message.timeframe = $root.rulesengine.Timeframe.decode(reader, reader.uint32())
            break
          case 4:
            if (!(message.properties && message.properties.length)) message.properties = []
            message.properties.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Activity message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Activity
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Activity} Activity
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Activity.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Activity message.
     * @function verify
     * @memberof rulesengine.Activity
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Activity.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.activityType != null && message.hasOwnProperty('activityType'))
        if (!$util.isString(message.activityType)) return 'activityType: string expected'
      if (message.timeframe != null && message.hasOwnProperty('timeframe')) {
        let error = $root.rulesengine.Timeframe.verify(message.timeframe)
        if (error) return 'timeframe.' + error
      }
      if (message.properties != null && message.hasOwnProperty('properties')) {
        if (!Array.isArray(message.properties)) return 'properties: array expected'
        for (let i = 0; i < message.properties.length; ++i)
          if (!$util.isString(message.properties[i])) return 'properties: string[] expected'
      }
      return null
    }

    /**
     * Creates an Activity message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Activity
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Activity} Activity
     */
    Activity.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Activity) return object
      let message = new $root.rulesengine.Activity()
      if (object.id != null) message.id = String(object.id)
      if (object.activityType != null) message.activityType = String(object.activityType)
      if (object.timeframe != null) {
        if (typeof object.timeframe !== 'object') throw TypeError('.rulesengine.Activity.timeframe: object expected')
        message.timeframe = $root.rulesengine.Timeframe.fromObject(object.timeframe)
      }
      if (object.properties) {
        if (!Array.isArray(object.properties)) throw TypeError('.rulesengine.Activity.properties: array expected')
        message.properties = []
        for (let i = 0; i < object.properties.length; ++i) message.properties[i] = String(object.properties[i])
      }
      return message
    }

    /**
     * Creates a plain object from an Activity message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Activity
     * @static
     * @param {rulesengine.Activity} message Activity
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Activity.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.properties = []
      if (options.defaults) {
        object.id = ''
        object.activityType = ''
        object.timeframe = null
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.activityType != null && message.hasOwnProperty('activityType'))
        object.activityType = message.activityType
      if (message.timeframe != null && message.hasOwnProperty('timeframe'))
        object.timeframe = $root.rulesengine.Timeframe.toObject(message.timeframe, options)
      if (message.properties && message.properties.length) {
        object.properties = []
        for (let j = 0; j < message.properties.length; ++j) object.properties[j] = message.properties[j]
      }
      return object
    }

    /**
     * Converts this Activity to JSON.
     * @function toJSON
     * @memberof rulesengine.Activity
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Activity.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Activity
  })()

  rulesengine.Assignment = (function () {
    /**
     * Properties of an Assignment.
     * @memberof rulesengine
     * @interface IAssignment
     * @property {string|null} [id] Assignment id
     * @property {string|null} [activityId] Assignment activityId
     * @property {string|null} [resourceId] Assignment resourceId
     */

    /**
     * Constructs a new Assignment.
     * @memberof rulesengine
     * @classdesc Represents an Assignment.
     * @implements IAssignment
     * @constructor
     * @param {rulesengine.IAssignment=} [properties] Properties to set
     */
    function Assignment(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Assignment id.
     * @member {string} id
     * @memberof rulesengine.Assignment
     * @instance
     */
    Assignment.prototype.id = ''

    /**
     * Assignment activityId.
     * @member {string} activityId
     * @memberof rulesengine.Assignment
     * @instance
     */
    Assignment.prototype.activityId = ''

    /**
     * Assignment resourceId.
     * @member {string} resourceId
     * @memberof rulesengine.Assignment
     * @instance
     */
    Assignment.prototype.resourceId = ''

    /**
     * Creates a new Assignment instance using the specified properties.
     * @function create
     * @memberof rulesengine.Assignment
     * @static
     * @param {rulesengine.IAssignment=} [properties] Properties to set
     * @returns {rulesengine.Assignment} Assignment instance
     */
    Assignment.create = function create(properties) {
      return new Assignment(properties)
    }

    /**
     * Encodes the specified Assignment message. Does not implicitly {@link rulesengine.Assignment.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Assignment
     * @static
     * @param {rulesengine.IAssignment} message Assignment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Assignment.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.id != null && Object.hasOwnProperty.call(message, 'id'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
      if (message.activityId != null && Object.hasOwnProperty.call(message, 'activityId'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.activityId)
      if (message.resourceId != null && Object.hasOwnProperty.call(message, 'resourceId'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.resourceId)
      return writer
    }

    /**
     * Encodes the specified Assignment message, length delimited. Does not implicitly {@link rulesengine.Assignment.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Assignment
     * @static
     * @param {rulesengine.IAssignment} message Assignment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Assignment.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Assignment message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Assignment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Assignment} Assignment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Assignment.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Assignment()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.id = reader.string()
            break
          case 2:
            message.activityId = reader.string()
            break
          case 3:
            message.resourceId = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Assignment message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Assignment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Assignment} Assignment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Assignment.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Assignment message.
     * @function verify
     * @memberof rulesengine.Assignment
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Assignment.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.id != null && message.hasOwnProperty('id'))
        if (!$util.isString(message.id)) return 'id: string expected'
      if (message.activityId != null && message.hasOwnProperty('activityId'))
        if (!$util.isString(message.activityId)) return 'activityId: string expected'
      if (message.resourceId != null && message.hasOwnProperty('resourceId'))
        if (!$util.isString(message.resourceId)) return 'resourceId: string expected'
      return null
    }

    /**
     * Creates an Assignment message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Assignment
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Assignment} Assignment
     */
    Assignment.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Assignment) return object
      let message = new $root.rulesengine.Assignment()
      if (object.id != null) message.id = String(object.id)
      if (object.activityId != null) message.activityId = String(object.activityId)
      if (object.resourceId != null) message.resourceId = String(object.resourceId)
      return message
    }

    /**
     * Creates a plain object from an Assignment message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Assignment
     * @static
     * @param {rulesengine.Assignment} message Assignment
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Assignment.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.id = ''
        object.activityId = ''
        object.resourceId = ''
      }
      if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
      if (message.activityId != null && message.hasOwnProperty('activityId')) object.activityId = message.activityId
      if (message.resourceId != null && message.hasOwnProperty('resourceId')) object.resourceId = message.resourceId
      return object
    }

    /**
     * Converts this Assignment to JSON.
     * @function toJSON
     * @memberof rulesengine.Assignment
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Assignment.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Assignment
  })()

  rulesengine.Violation = (function () {
    /**
     * Properties of a Violation.
     * @memberof rulesengine
     * @interface IViolation
     * @property {string|null} [identifier] Violation identifier
     * @property {string|null} [state] Violation state
     * @property {string|null} [description] Violation description
     * @property {Array.<string>|null} [properties] Violation properties
     */

    /**
     * Constructs a new Violation.
     * @memberof rulesengine
     * @classdesc Represents a Violation.
     * @implements IViolation
     * @constructor
     * @param {rulesengine.IViolation=} [properties] Properties to set
     */
    function Violation(properties) {
      this.properties = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Violation identifier.
     * @member {string} identifier
     * @memberof rulesengine.Violation
     * @instance
     */
    Violation.prototype.identifier = ''

    /**
     * Violation state.
     * @member {string} state
     * @memberof rulesengine.Violation
     * @instance
     */
    Violation.prototype.state = ''

    /**
     * Violation description.
     * @member {string} description
     * @memberof rulesengine.Violation
     * @instance
     */
    Violation.prototype.description = ''

    /**
     * Violation properties.
     * @member {Array.<string>} properties
     * @memberof rulesengine.Violation
     * @instance
     */
    Violation.prototype.properties = $util.emptyArray

    /**
     * Creates a new Violation instance using the specified properties.
     * @function create
     * @memberof rulesengine.Violation
     * @static
     * @param {rulesengine.IViolation=} [properties] Properties to set
     * @returns {rulesengine.Violation} Violation instance
     */
    Violation.create = function create(properties) {
      return new Violation(properties)
    }

    /**
     * Encodes the specified Violation message. Does not implicitly {@link rulesengine.Violation.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Violation
     * @static
     * @param {rulesengine.IViolation} message Violation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Violation.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.identifier != null && Object.hasOwnProperty.call(message, 'identifier'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier)
      if (message.state != null && Object.hasOwnProperty.call(message, 'state'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.state)
      if (message.description != null && Object.hasOwnProperty.call(message, 'description'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.description)
      if (message.properties != null && message.properties.length)
        for (let i = 0; i < message.properties.length; ++i)
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.properties[i])
      return writer
    }

    /**
     * Encodes the specified Violation message, length delimited. Does not implicitly {@link rulesengine.Violation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Violation
     * @static
     * @param {rulesengine.IViolation} message Violation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Violation.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Violation message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Violation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Violation} Violation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Violation.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Violation()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.identifier = reader.string()
            break
          case 2:
            message.state = reader.string()
            break
          case 3:
            message.description = reader.string()
            break
          case 4:
            if (!(message.properties && message.properties.length)) message.properties = []
            message.properties.push(reader.string())
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Violation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Violation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Violation} Violation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Violation.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Violation message.
     * @function verify
     * @memberof rulesengine.Violation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Violation.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.identifier != null && message.hasOwnProperty('identifier'))
        if (!$util.isString(message.identifier)) return 'identifier: string expected'
      if (message.state != null && message.hasOwnProperty('state'))
        if (!$util.isString(message.state)) return 'state: string expected'
      if (message.description != null && message.hasOwnProperty('description'))
        if (!$util.isString(message.description)) return 'description: string expected'
      if (message.properties != null && message.hasOwnProperty('properties')) {
        if (!Array.isArray(message.properties)) return 'properties: array expected'
        for (let i = 0; i < message.properties.length; ++i)
          if (!$util.isString(message.properties[i])) return 'properties: string[] expected'
      }
      return null
    }

    /**
     * Creates a Violation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Violation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Violation} Violation
     */
    Violation.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Violation) return object
      let message = new $root.rulesengine.Violation()
      if (object.identifier != null) message.identifier = String(object.identifier)
      if (object.state != null) message.state = String(object.state)
      if (object.description != null) message.description = String(object.description)
      if (object.properties) {
        if (!Array.isArray(object.properties)) throw TypeError('.rulesengine.Violation.properties: array expected')
        message.properties = []
        for (let i = 0; i < object.properties.length; ++i) message.properties[i] = String(object.properties[i])
      }
      return message
    }

    /**
     * Creates a plain object from a Violation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Violation
     * @static
     * @param {rulesengine.Violation} message Violation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Violation.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.properties = []
      if (options.defaults) {
        object.identifier = ''
        object.state = ''
        object.description = ''
      }
      if (message.identifier != null && message.hasOwnProperty('identifier')) object.identifier = message.identifier
      if (message.state != null && message.hasOwnProperty('state')) object.state = message.state
      if (message.description != null && message.hasOwnProperty('description')) object.description = message.description
      if (message.properties && message.properties.length) {
        object.properties = []
        for (let j = 0; j < message.properties.length; ++j) object.properties[j] = message.properties[j]
      }
      return object
    }

    /**
     * Converts this Violation to JSON.
     * @function toJSON
     * @memberof rulesengine.Violation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Violation.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Violation
  })()

  rulesengine.SetOfViolations = (function () {
    /**
     * Properties of a SetOfViolations.
     * @memberof rulesengine
     * @interface ISetOfViolations
     * @property {Array.<rulesengine.IViolation>|null} [violations] SetOfViolations violations
     */

    /**
     * Constructs a new SetOfViolations.
     * @memberof rulesengine
     * @classdesc Represents a SetOfViolations.
     * @implements ISetOfViolations
     * @constructor
     * @param {rulesengine.ISetOfViolations=} [properties] Properties to set
     */
    function SetOfViolations(properties) {
      this.violations = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * SetOfViolations violations.
     * @member {Array.<rulesengine.IViolation>} violations
     * @memberof rulesengine.SetOfViolations
     * @instance
     */
    SetOfViolations.prototype.violations = $util.emptyArray

    /**
     * Creates a new SetOfViolations instance using the specified properties.
     * @function create
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {rulesengine.ISetOfViolations=} [properties] Properties to set
     * @returns {rulesengine.SetOfViolations} SetOfViolations instance
     */
    SetOfViolations.create = function create(properties) {
      return new SetOfViolations(properties)
    }

    /**
     * Encodes the specified SetOfViolations message. Does not implicitly {@link rulesengine.SetOfViolations.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {rulesengine.ISetOfViolations} message SetOfViolations message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetOfViolations.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.violations != null && message.violations.length)
        for (let i = 0; i < message.violations.length; ++i)
          $root.rulesengine.Violation.encode(
            message.violations[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
          ).ldelim()
      return writer
    }

    /**
     * Encodes the specified SetOfViolations message, length delimited. Does not implicitly {@link rulesengine.SetOfViolations.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {rulesengine.ISetOfViolations} message SetOfViolations message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetOfViolations.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a SetOfViolations message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.SetOfViolations} SetOfViolations
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetOfViolations.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.SetOfViolations()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            if (!(message.violations && message.violations.length)) message.violations = []
            message.violations.push($root.rulesengine.Violation.decode(reader, reader.uint32()))
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a SetOfViolations message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.SetOfViolations} SetOfViolations
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetOfViolations.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a SetOfViolations message.
     * @function verify
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetOfViolations.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.violations != null && message.hasOwnProperty('violations')) {
        if (!Array.isArray(message.violations)) return 'violations: array expected'
        for (let i = 0; i < message.violations.length; ++i) {
          let error = $root.rulesengine.Violation.verify(message.violations[i])
          if (error) return 'violations.' + error
        }
      }
      return null
    }

    /**
     * Creates a SetOfViolations message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.SetOfViolations} SetOfViolations
     */
    SetOfViolations.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.SetOfViolations) return object
      let message = new $root.rulesengine.SetOfViolations()
      if (object.violations) {
        if (!Array.isArray(object.violations))
          throw TypeError('.rulesengine.SetOfViolations.violations: array expected')
        message.violations = []
        for (let i = 0; i < object.violations.length; ++i) {
          if (typeof object.violations[i] !== 'object')
            throw TypeError('.rulesengine.SetOfViolations.violations: object expected')
          message.violations[i] = $root.rulesengine.Violation.fromObject(object.violations[i])
        }
      }
      return message
    }

    /**
     * Creates a plain object from a SetOfViolations message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.SetOfViolations
     * @static
     * @param {rulesengine.SetOfViolations} message SetOfViolations
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetOfViolations.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.violations = []
      if (message.violations && message.violations.length) {
        object.violations = []
        for (let j = 0; j < message.violations.length; ++j)
          object.violations[j] = $root.rulesengine.Violation.toObject(message.violations[j], options)
      }
      return object
    }

    /**
     * Converts this SetOfViolations to JSON.
     * @function toJSON
     * @memberof rulesengine.SetOfViolations
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetOfViolations.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return SetOfViolations
  })()

  rulesengine.Event = (function () {
    /**
     * Properties of an Event.
     * @memberof rulesengine
     * @interface IEvent
     * @property {number|null} [type] Event type
     * @property {rulesengine.IActivity|null} [activity] Event activity
     * @property {rulesengine.IResource|null} [resource] Event resource
     * @property {rulesengine.IAssignment|null} [assignment] Event assignment
     * @property {rulesengine.ITimeframe|null} [newTimeframe] Event newTimeframe
     * @property {string|null} [reference] Event reference
     * @property {rulesengine.IRulesEngineSession|null} [session] Event session
     * @property {Array.<rulesengine.IEvent>|null} [events] Event events
     */

    /**
     * Constructs a new Event.
     * @memberof rulesengine
     * @classdesc Represents an Event.
     * @implements IEvent
     * @constructor
     * @param {rulesengine.IEvent=} [properties] Properties to set
     */
    function Event(properties) {
      this.events = []
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Event type.
     * @member {number} type
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.type = 0

    /**
     * Event activity.
     * @member {rulesengine.IActivity|null|undefined} activity
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.activity = null

    /**
     * Event resource.
     * @member {rulesengine.IResource|null|undefined} resource
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.resource = null

    /**
     * Event assignment.
     * @member {rulesengine.IAssignment|null|undefined} assignment
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.assignment = null

    /**
     * Event newTimeframe.
     * @member {rulesengine.ITimeframe|null|undefined} newTimeframe
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.newTimeframe = null

    /**
     * Event reference.
     * @member {string} reference
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.reference = ''

    /**
     * Event session.
     * @member {rulesengine.IRulesEngineSession|null|undefined} session
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.session = null

    /**
     * Event events.
     * @member {Array.<rulesengine.IEvent>} events
     * @memberof rulesengine.Event
     * @instance
     */
    Event.prototype.events = $util.emptyArray

    /**
     * Creates a new Event instance using the specified properties.
     * @function create
     * @memberof rulesengine.Event
     * @static
     * @param {rulesengine.IEvent=} [properties] Properties to set
     * @returns {rulesengine.Event} Event instance
     */
    Event.create = function create(properties) {
      return new Event(properties)
    }

    /**
     * Encodes the specified Event message. Does not implicitly {@link rulesengine.Event.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Event
     * @static
     * @param {rulesengine.IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.type)
      if (message.activity != null && Object.hasOwnProperty.call(message, 'activity'))
        $root.rulesengine.Activity.encode(message.activity, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim()
      if (message.resource != null && Object.hasOwnProperty.call(message, 'resource'))
        $root.rulesengine.Resource.encode(message.resource, writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim()
      if (message.assignment != null && Object.hasOwnProperty.call(message, 'assignment'))
        $root.rulesengine.Assignment.encode(
          message.assignment,
          writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
        ).ldelim()
      if (message.newTimeframe != null && Object.hasOwnProperty.call(message, 'newTimeframe'))
        $root.rulesengine.Timeframe.encode(
          message.newTimeframe,
          writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
        ).ldelim()
      if (message.reference != null && Object.hasOwnProperty.call(message, 'reference'))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.reference)
      if (message.session != null && Object.hasOwnProperty.call(message, 'session'))
        $root.rulesengine.RulesEngineSession.encode(
          message.session,
          writer.uint32(/* id 7, wireType 2 =*/ 58).fork()
        ).ldelim()
      if (message.events != null && message.events.length)
        for (let i = 0; i < message.events.length; ++i)
          $root.rulesengine.Event.encode(message.events[i], writer.uint32(/* id 8, wireType 2 =*/ 66).fork()).ldelim()
      return writer
    }

    /**
     * Encodes the specified Event message, length delimited. Does not implicitly {@link rulesengine.Event.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Event
     * @static
     * @param {rulesengine.IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Event message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Event()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.type = reader.int32()
            break
          case 2:
            message.activity = $root.rulesengine.Activity.decode(reader, reader.uint32())
            break
          case 3:
            message.resource = $root.rulesengine.Resource.decode(reader, reader.uint32())
            break
          case 4:
            message.assignment = $root.rulesengine.Assignment.decode(reader, reader.uint32())
            break
          case 5:
            message.newTimeframe = $root.rulesengine.Timeframe.decode(reader, reader.uint32())
            break
          case 6:
            message.reference = reader.string()
            break
          case 7:
            message.session = $root.rulesengine.RulesEngineSession.decode(reader, reader.uint32())
            break
          case 8:
            if (!(message.events && message.events.length)) message.events = []
            message.events.push($root.rulesengine.Event.decode(reader, reader.uint32()))
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes an Event message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies an Event message.
     * @function verify
     * @memberof rulesengine.Event
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Event.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.type != null && message.hasOwnProperty('type'))
        if (!$util.isInteger(message.type)) return 'type: integer expected'
      if (message.activity != null && message.hasOwnProperty('activity')) {
        let error = $root.rulesengine.Activity.verify(message.activity)
        if (error) return 'activity.' + error
      }
      if (message.resource != null && message.hasOwnProperty('resource')) {
        let error = $root.rulesengine.Resource.verify(message.resource)
        if (error) return 'resource.' + error
      }
      if (message.assignment != null && message.hasOwnProperty('assignment')) {
        let error = $root.rulesengine.Assignment.verify(message.assignment)
        if (error) return 'assignment.' + error
      }
      if (message.newTimeframe != null && message.hasOwnProperty('newTimeframe')) {
        let error = $root.rulesengine.Timeframe.verify(message.newTimeframe)
        if (error) return 'newTimeframe.' + error
      }
      if (message.reference != null && message.hasOwnProperty('reference'))
        if (!$util.isString(message.reference)) return 'reference: string expected'
      if (message.session != null && message.hasOwnProperty('session')) {
        let error = $root.rulesengine.RulesEngineSession.verify(message.session)
        if (error) return 'session.' + error
      }
      if (message.events != null && message.hasOwnProperty('events')) {
        if (!Array.isArray(message.events)) return 'events: array expected'
        for (let i = 0; i < message.events.length; ++i) {
          let error = $root.rulesengine.Event.verify(message.events[i])
          if (error) return 'events.' + error
        }
      }
      return null
    }

    /**
     * Creates an Event message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Event
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Event} Event
     */
    Event.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Event) return object
      let message = new $root.rulesengine.Event()
      if (object.type != null) message.type = object.type | 0
      if (object.activity != null) {
        if (typeof object.activity !== 'object') throw TypeError('.rulesengine.Event.activity: object expected')
        message.activity = $root.rulesengine.Activity.fromObject(object.activity)
      }
      if (object.resource != null) {
        if (typeof object.resource !== 'object') throw TypeError('.rulesengine.Event.resource: object expected')
        message.resource = $root.rulesengine.Resource.fromObject(object.resource)
      }
      if (object.assignment != null) {
        if (typeof object.assignment !== 'object') throw TypeError('.rulesengine.Event.assignment: object expected')
        message.assignment = $root.rulesengine.Assignment.fromObject(object.assignment)
      }
      if (object.newTimeframe != null) {
        if (typeof object.newTimeframe !== 'object') throw TypeError('.rulesengine.Event.newTimeframe: object expected')
        message.newTimeframe = $root.rulesengine.Timeframe.fromObject(object.newTimeframe)
      }
      if (object.reference != null) message.reference = String(object.reference)
      if (object.session != null) {
        if (typeof object.session !== 'object') throw TypeError('.rulesengine.Event.session: object expected')
        message.session = $root.rulesengine.RulesEngineSession.fromObject(object.session)
      }
      if (object.events) {
        if (!Array.isArray(object.events)) throw TypeError('.rulesengine.Event.events: array expected')
        message.events = []
        for (let i = 0; i < object.events.length; ++i) {
          if (typeof object.events[i] !== 'object') throw TypeError('.rulesengine.Event.events: object expected')
          message.events[i] = $root.rulesengine.Event.fromObject(object.events[i])
        }
      }
      return message
    }

    /**
     * Creates a plain object from an Event message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Event
     * @static
     * @param {rulesengine.Event} message Event
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Event.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.arrays || options.defaults) object.events = []
      if (options.defaults) {
        object.type = 0
        object.activity = null
        object.resource = null
        object.assignment = null
        object.newTimeframe = null
        object.reference = ''
        object.session = null
      }
      if (message.type != null && message.hasOwnProperty('type')) object.type = message.type
      if (message.activity != null && message.hasOwnProperty('activity'))
        object.activity = $root.rulesengine.Activity.toObject(message.activity, options)
      if (message.resource != null && message.hasOwnProperty('resource'))
        object.resource = $root.rulesengine.Resource.toObject(message.resource, options)
      if (message.assignment != null && message.hasOwnProperty('assignment'))
        object.assignment = $root.rulesengine.Assignment.toObject(message.assignment, options)
      if (message.newTimeframe != null && message.hasOwnProperty('newTimeframe'))
        object.newTimeframe = $root.rulesengine.Timeframe.toObject(message.newTimeframe, options)
      if (message.reference != null && message.hasOwnProperty('reference')) object.reference = message.reference
      if (message.session != null && message.hasOwnProperty('session'))
        object.session = $root.rulesengine.RulesEngineSession.toObject(message.session, options)
      if (message.events && message.events.length) {
        object.events = []
        for (let j = 0; j < message.events.length; ++j)
          object.events[j] = $root.rulesengine.Event.toObject(message.events[j], options)
      }
      return object
    }

    /**
     * Converts this Event to JSON.
     * @function toJSON
     * @memberof rulesengine.Event
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Event.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Event
  })()

  rulesengine.Timeframe = (function () {
    /**
     * Properties of a Timeframe.
     * @memberof rulesengine
     * @interface ITimeframe
     * @property {number|Long|null} [start] Timeframe start
     * @property {number|Long|null} [end] Timeframe end
     */

    /**
     * Constructs a new Timeframe.
     * @memberof rulesengine
     * @classdesc Represents a Timeframe.
     * @implements ITimeframe
     * @constructor
     * @param {rulesengine.ITimeframe=} [properties] Properties to set
     */
    function Timeframe(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * Timeframe start.
     * @member {number|Long} start
     * @memberof rulesengine.Timeframe
     * @instance
     */
    Timeframe.prototype.start = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Timeframe end.
     * @member {number|Long} end
     * @memberof rulesengine.Timeframe
     * @instance
     */
    Timeframe.prototype.end = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Creates a new Timeframe instance using the specified properties.
     * @function create
     * @memberof rulesengine.Timeframe
     * @static
     * @param {rulesengine.ITimeframe=} [properties] Properties to set
     * @returns {rulesengine.Timeframe} Timeframe instance
     */
    Timeframe.create = function create(properties) {
      return new Timeframe(properties)
    }

    /**
     * Encodes the specified Timeframe message. Does not implicitly {@link rulesengine.Timeframe.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.Timeframe
     * @static
     * @param {rulesengine.ITimeframe} message Timeframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Timeframe.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.start != null && Object.hasOwnProperty.call(message, 'start'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.start)
      if (message.end != null && Object.hasOwnProperty.call(message, 'end'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.end)
      return writer
    }

    /**
     * Encodes the specified Timeframe message, length delimited. Does not implicitly {@link rulesengine.Timeframe.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.Timeframe
     * @static
     * @param {rulesengine.ITimeframe} message Timeframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Timeframe.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Timeframe message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.Timeframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.Timeframe} Timeframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Timeframe.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.Timeframe()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.start = reader.int64()
            break
          case 2:
            message.end = reader.int64()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a Timeframe message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.Timeframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.Timeframe} Timeframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Timeframe.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a Timeframe message.
     * @function verify
     * @memberof rulesengine.Timeframe
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Timeframe.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.start != null && message.hasOwnProperty('start'))
        if (
          !$util.isInteger(message.start) &&
          !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high))
        )
          return 'start: integer|Long expected'
      if (message.end != null && message.hasOwnProperty('end'))
        if (
          !$util.isInteger(message.end) &&
          !(message.end && $util.isInteger(message.end.low) && $util.isInteger(message.end.high))
        )
          return 'end: integer|Long expected'
      return null
    }

    /**
     * Creates a Timeframe message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.Timeframe
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.Timeframe} Timeframe
     */
    Timeframe.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.Timeframe) return object
      let message = new $root.rulesengine.Timeframe()
      if (object.start != null)
        if ($util.Long) (message.start = $util.Long.fromValue(object.start)).unsigned = false
        else if (typeof object.start === 'string') message.start = parseInt(object.start, 10)
        else if (typeof object.start === 'number') message.start = object.start
        else if (typeof object.start === 'object')
          message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber()
      if (object.end != null)
        if ($util.Long) (message.end = $util.Long.fromValue(object.end)).unsigned = false
        else if (typeof object.end === 'string') message.end = parseInt(object.end, 10)
        else if (typeof object.end === 'number') message.end = object.end
        else if (typeof object.end === 'object')
          message.end = new $util.LongBits(object.end.low >>> 0, object.end.high >>> 0).toNumber()
      return message
    }

    /**
     * Creates a plain object from a Timeframe message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.Timeframe
     * @static
     * @param {rulesengine.Timeframe} message Timeframe
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Timeframe.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, false)
          object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
        } else object.start = options.longs === String ? '0' : 0
        if ($util.Long) {
          let long = new $util.Long(0, 0, false)
          object.end = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
        } else object.end = options.longs === String ? '0' : 0
      }
      if (message.start != null && message.hasOwnProperty('start'))
        if (typeof message.start === 'number')
          object.start = options.longs === String ? String(message.start) : message.start
        else
          object.start =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.start)
              : options.longs === Number
              ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber()
              : message.start
      if (message.end != null && message.hasOwnProperty('end'))
        if (typeof message.end === 'number') object.end = options.longs === String ? String(message.end) : message.end
        else
          object.end =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.end)
              : options.longs === Number
              ? new $util.LongBits(message.end.low >>> 0, message.end.high >>> 0).toNumber()
              : message.end
      return object
    }

    /**
     * Converts this Timeframe to JSON.
     * @function toJSON
     * @memberof rulesengine.Timeframe
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Timeframe.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Timeframe
  })()

  rulesengine.RulesEngineSession = (function () {
    /**
     * Properties of a RulesEngineSession.
     * @memberof rulesengine
     * @interface IRulesEngineSession
     * @property {string|null} [ref] RulesEngineSession ref
     * @property {string|null} [rulesSetName] RulesEngineSession rulesSetName
     */

    /**
     * Constructs a new RulesEngineSession.
     * @memberof rulesengine
     * @classdesc Represents a RulesEngineSession.
     * @implements IRulesEngineSession
     * @constructor
     * @param {rulesengine.IRulesEngineSession=} [properties] Properties to set
     */
    function RulesEngineSession(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
    }

    /**
     * RulesEngineSession ref.
     * @member {string} ref
     * @memberof rulesengine.RulesEngineSession
     * @instance
     */
    RulesEngineSession.prototype.ref = ''

    /**
     * RulesEngineSession rulesSetName.
     * @member {string} rulesSetName
     * @memberof rulesengine.RulesEngineSession
     * @instance
     */
    RulesEngineSession.prototype.rulesSetName = ''

    /**
     * Creates a new RulesEngineSession instance using the specified properties.
     * @function create
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {rulesengine.IRulesEngineSession=} [properties] Properties to set
     * @returns {rulesengine.RulesEngineSession} RulesEngineSession instance
     */
    RulesEngineSession.create = function create(properties) {
      return new RulesEngineSession(properties)
    }

    /**
     * Encodes the specified RulesEngineSession message. Does not implicitly {@link rulesengine.RulesEngineSession.verify|verify} messages.
     * @function encode
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {rulesengine.IRulesEngineSession} message RulesEngineSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RulesEngineSession.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create()
      if (message.ref != null && Object.hasOwnProperty.call(message, 'ref'))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.ref)
      if (message.rulesSetName != null && Object.hasOwnProperty.call(message, 'rulesSetName'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.rulesSetName)
      return writer
    }

    /**
     * Encodes the specified RulesEngineSession message, length delimited. Does not implicitly {@link rulesengine.RulesEngineSession.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {rulesengine.IRulesEngineSession} message RulesEngineSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RulesEngineSession.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a RulesEngineSession message from the specified reader or buffer.
     * @function decode
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rulesengine.RulesEngineSession} RulesEngineSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RulesEngineSession.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rulesengine.RulesEngineSession()
      while (reader.pos < end) {
        let tag = reader.uint32()
        switch (tag >>> 3) {
          case 1:
            message.ref = reader.string()
            break
          case 2:
            message.rulesSetName = reader.string()
            break
          default:
            reader.skipType(tag & 7)
            break
        }
      }
      return message
    }

    /**
     * Decodes a RulesEngineSession message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rulesengine.RulesEngineSession} RulesEngineSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RulesEngineSession.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader)
      return this.decode(reader, reader.uint32())
    }

    /**
     * Verifies a RulesEngineSession message.
     * @function verify
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RulesEngineSession.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected'
      if (message.ref != null && message.hasOwnProperty('ref'))
        if (!$util.isString(message.ref)) return 'ref: string expected'
      if (message.rulesSetName != null && message.hasOwnProperty('rulesSetName'))
        if (!$util.isString(message.rulesSetName)) return 'rulesSetName: string expected'
      return null
    }

    /**
     * Creates a RulesEngineSession message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rulesengine.RulesEngineSession} RulesEngineSession
     */
    RulesEngineSession.fromObject = function fromObject(object) {
      if (object instanceof $root.rulesengine.RulesEngineSession) return object
      let message = new $root.rulesengine.RulesEngineSession()
      if (object.ref != null) message.ref = String(object.ref)
      if (object.rulesSetName != null) message.rulesSetName = String(object.rulesSetName)
      return message
    }

    /**
     * Creates a plain object from a RulesEngineSession message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rulesengine.RulesEngineSession
     * @static
     * @param {rulesengine.RulesEngineSession} message RulesEngineSession
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RulesEngineSession.toObject = function toObject(message, options) {
      if (!options) options = {}
      let object = {}
      if (options.defaults) {
        object.ref = ''
        object.rulesSetName = ''
      }
      if (message.ref != null && message.hasOwnProperty('ref')) object.ref = message.ref
      if (message.rulesSetName != null && message.hasOwnProperty('rulesSetName'))
        object.rulesSetName = message.rulesSetName
      return object
    }

    /**
     * Converts this RulesEngineSession to JSON.
     * @function toJSON
     * @memberof rulesengine.RulesEngineSession
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RulesEngineSession.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return RulesEngineSession
  })()

  return rulesengine
})())

export { $root as default }
