import React, { useState } from 'react'
import moment from 'moment'
import { ControlGroup } from '@blueprintjs/core'
import { DateInput2 } from '@blueprintjs/datetime2'

const Bid = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { data } = props

  return (
    <ControlGroup fill>
      <DateInput2
        placeholder="from"
        value={moment.utc(data.startTime).toDate()}
        formatDate={(date) => moment.utc(date).format('YYYY/MM/DD - HH:mm')}
        parseDate={(str) => new Date(str)}
        timePrecision="minute"
        onChange={(e) => {
          let d = { ...data }
          d.startTime = moment(e).valueOf()
          props.onChange(d)
        }}
      />

      <DateInput2
        placeholder="to"
        value={moment.utc(data.endTime).toDate()}
        formatDate={(date) => moment.utc(date).format('YYYY/MM/DD - HH:mm')}
        parseDate={(str) => new Date(str)}
        timePrecision="minute"
        onChange={(e) => {
          let d = { ...data }
          d.endTime = moment(e).valueOf()
          props.onChange(d)
        }}
      />
    </ControlGroup>
  )
}
export default Bid
