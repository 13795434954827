import React, { useMemo, useState, useCallback } from 'react'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import styles from './EndLabel.module.scss'
import stylesTooltip from '../Activity/Tooltips.module.scss'
import styles2 from '../CrewMember.module.scss'
import styles3 from '../Activity/Activity.module.scss'

import { IssueTag } from '../Issue/Issue'

const EndLabel = (props) => {
  const [open, setOpen] = useState(false)

  const d = props.data

  const onMouseEnter = useCallback(() => {
    if (!props.drag) setOpen(true)
  }, [props.drag])
  const onMouseLeave = useCallback(() => {
    setOpen(false)
  }, [])
  const className = useMemo(
    () =>
      [
        styles.endLabel,
        props.expanded && d.preAssigned ? styles.expanded : undefined,
        d.preAssigned ? styles2.preAssignedLabel : undefined,
        styles3[d.type + '_text'],
        d.type === 'plane' ? styles.PlaneLabel : '',
        d.type === 'plane' && d.standby ? styles3['Standby_text'] : '',
        d.error ? styles['error_' + d.error.severity] : undefined,
      ].join(' '),
    [styles, d, props.expanded]
  )
  const style = useMemo(() => {
    return {
      // marginLeft: d.startTimeX - props.margin,
      // width: d.endTimeX - d.startTimeX,
      //   left: d.startTimeX - props.x,
      transform: `translateX(${d.startTimeX}px)`,
      //  border: d.modified ? '1px solid green' : 'unset',
      borderBottom: d.planeswap ? '1px solid red' : undefined,
    }
  }, [d.startTimeX, props.x, d.planeSwap])

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className} style={style}>
      {d.text}
      {d.error && open && (
        <Tooltip2
          usePortal={true}
          portalContainer={document.getElementById('main')}
          content={
            <div style={{ maxWidth: 300 }}>
              <IssueTag d={d.error} />
            </div>
          }
          autoFocus={false}
          enforceFocus={false}
          placement={Position.TOP}
          defaultIsOpen
          targetClassName={styles.tooltip}
          renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => <div {...tooltipProps} />}
        />
      )}
    </div>
  )
}

export default React.memo(EndLabel)
