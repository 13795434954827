import * as d3 from 'd3'

import React, { useEffect, useState, useCallback } from 'react'

import styles2 from './CrewMember.module.scss'
import store from 'store'
import FlightDuty from './FlightDuty/FlightDuty'
import Activity from './Activity/Activity'
import Issue from './Issue/Issue'
import EndLabel from './EndLabel/EndLabel'
import StartLabel from './EndLabel/StartLabel'
import DutyLabel from './DutyLabel/DutyLabel'
import EmptyRosterDay from './EmptyRosterDay/EmptyRosterDay'
import RosterError from './RosterError/RosterError'
import { format } from 'shared/LuxonHelpers'
import { useDroppable, DragOverlay } from '@dnd-kit/core'
import { restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
import { DraggableActivity } from './Draggable'
import { setExpanded } from 'publication/actions'

const CrewMember = (props) => {
  const [mousePos, setMousePos] = useState(null)
  const [hide, setHide] = useState(false)
  const [hiding, setHiding] = useState(false)
  const { displayAirportTimes, displaySafeCare, displayDutyTimes, timezone } = props
  let setNodeRef, isOver
  if (props.droppable) {
    const a = useDroppable({
      id: 'droppable' + props.data.employeeCode,
      data: props.data,
    })
    setNodeRef = a.setNodeRef
    isOver = a.isOver
  }
  const x = props.xScale

  const preppedActivities = React.useMemo(
    () =>
      props.data.activities.map((d, i) => {
        return { ...d, key: i, endTimeX: x(d.endTime), startTimeX: x(d.startTime) }
      }),
    [props.data.activities, x]
  )
  useEffect(() => {
    if (props.dragging) setHiding(setTimeout(() => setHide(true), 250))
    else {
      clearTimeout(hiding)
      setHide(false)
    }
  }, [props.dragging])

  const overDraw = 0
  const activities = React.useMemo(
    () =>
      preppedActivities
        ?.filter((d) => d.endTimeX - props.x > -overDraw)
        .filter((d) => d.startTimeX - props.x < props.width + overDraw),
    [preppedActivities, props.x, props.width]
  )

  const preppedEndLabels = React.useMemo(() => {
    let p = props.data.labels?.map((d, i) => {
      return { ...d, key: i, pos: x(d.start), startTimeX: x(d.start) - 12, endTimeX: x(d.start) + 12 }
    })
    //calculate overlapping labels

    for (let e = 0; e < 6; e++) {
      p = p.filter((d) => {
        if (d.isHomeBase && (d.overlapAfter || d.overlapBefore)) return false
        return true
      })

      let c = 0
      p.forEach((d, i) => {
        const prev = i > 0 ? p[i - 1] : { endTimeX: -Infinity }
        const next = i < p.length - 1 ? p[i + 1] : { startTimeX: +Infinity }
        d.labelSpaceBefore = d.startTimeX - prev.endTimeX
        d.labelSpaceAfter = next.startTimeX - d.endTimeX
        d.overlapBefore = d.labelSpaceBefore < 0
        d.overlapAfter = d.labelSpaceAfter < 0
      })

      // hide homebase labels that overlap
      p = p.filter((d) => {
        if (d.isHomeBase && (d.overlapBefore || d.overlapAfter)) return false
        return true
      })
      p.forEach((d) => {
        const deltaLeft = Math.abs(d.labelSpaceAfter / 2)
        if (d.overlapAfter) {
          //console.log(d.text, ' shift L ', deltaLeft)
          d.pos -= deltaLeft
          d.startTimeX -= deltaLeft
          d.endTimeX -= deltaLeft
          d.modified = true
          c++
        }
        const deltaAfter = Math.abs(d.labelSpaceBefore / 2)
        if (d.overlapBefore) {
          // console.log(d.text, ' shift R ', deltaAfter)
          d.pos += deltaAfter
          d.startTimeX += deltaAfter
          d.endTimeX += deltaAfter
          d.modified = true
          c++
        }
        return d
      })
    }

    return p
  }, [props.data.labels, x, props.expanded])

  const distance = React.useMemo(() => x(3600000) - x(0), [x])
  let endLabels = React.useMemo(
    () =>
      preppedEndLabels
        ?.filter((d) => !d.activityLabel)
        ?.filter((d) => d.endTimeX - props.x > 0)
        .filter((d) => d.startTimeX - props.x < props.width),
    //.filter((d) => distance > 4),
    [preppedEndLabels, props.x, props.width, distance]
  )
  let activityLabels = React.useMemo(
    () =>
      preppedEndLabels
        ?.filter((d) => d.activityLabel)
        ?.filter((d) => d.endTimeX - props.x > 0)
        .filter((d) => d.startTimeX - props.x < props.width)
        .filter((d) => distance > 4),
    [preppedEndLabels, props.x, props.width, distance]
  )

  const preppedDutyLabels = React.useMemo(() => {
    if (props.data.crewFunction === 'Plane') return []
    if (!displayDutyTimes) return []

    let p = props.data.flightDutyLabels.map((d, i) => {
      let pos = displayAirportTimes ? x(d.activityTime) - 15 : x(d.dutyTime) - 15
      if (d.type === 'endOfRest') pos = x(d.dutyTime) - 30
      return {
        ...d,
        key: i,
        pos,
        dutyTimeX: x(d.dutyTime),
        time: format(displayAirportTimes ? d.activityTime : d.dutyTime, timezone, 'HH:mm'),
      }
    })

    p?.forEach((d, i) => {
      if (i > 0) if (d.pos < p[i - 1].pos + 30) d.pos = p[i - 1].pos + 30
    })

    return p
  }, [displayDutyTimes, displayAirportTimes, props.data.flightDutyLabels, x, timezone])

  const dutyLabels = React.useMemo(() => {
    let p = preppedDutyLabels
      ?.filter((d) => d.dutyTimeX + 15 - props.x > 0)
      ?.filter((d) => d.dutyTimeX - 15 - props.x < props.width)
      ?.filter((d) => distance > 4)

    return p
  }, [displayDutyTimes, preppedDutyLabels, props.x, props.width])

  const preppedFlightDuties = React.useMemo(
    () =>
      props.data.flight_duties.map((d, i) => {
        return { ...d, key: i, endOfRestX: x(d.endOfRest), endTimeX: x(d.endTime), startTimeX: x(d.startTime) }
      }),
    [props.data.flight_duties, x]
  )

  const flightDuties = React.useMemo(
    () =>
      props.data.crewFunction !== 'Plane'
        ? preppedFlightDuties
            ?.filter((d) => d.endOfRestX - props.x > 0)
            .filter((d) => d.startTimeX - props.x < props.width)
        : [],
    [preppedFlightDuties, props.x, props.width]
  )
  const d = props.data
  const allErrors = React.useMemo(
    () => d.rosterIssues?.filter((d) => d.employeeCode === props.data.employeeCode),
    [d.rosterIssues, props.data]
  )

  const errors = React.useMemo(() => allErrors.filter((d) => d.issueType === 'FlightCoverageIssue'), [allErrors])
  const baseSwitch = React.useMemo(() => allErrors.filter((d) => d.issueType === 'ImpossibleBaseSwitch'), [allErrors])

  const emptyRosterDays = React.useMemo(
    () => d.rosterIssues?.filter((d) => d.issueType === 'EmptyRosterDay'),
    [d.rosterIssues]
  )
  const allErrorsExceptEmpty = React.useMemo(
    () => allErrors?.filter((d) => d.issueType !== 'EmptyRosterDay'),
    [d.allErrors]
  )
  baseSwitch?.map((d) => {
    const startPairing = props.data.pairings.filter((l) => l.startTime === d.startTime)
    const endPairing = props.data.pairings.filter((l) => l.endTime === d.endTime)

    const firstActivity = props.data.activities.find(
      (a) => startPairing?.[0]?.activityIDs?.includes(a.activityID) && a.firstActivity
    )
    const lastActivity = props.data.activities.find(
      (a) => endPairing?.[0]?.activityIDs?.includes(a.activityID) && a.lastActivity
    )
    const activity = [firstActivity, lastActivity]

    endLabels.forEach((l) => {
      activity.forEach((a) => {
        if (a) a.endTime === l.start ? (l.error = d) : undefined
      })
      activity.forEach((a) => {
        if (a) a.startTime === l.start ? (l.error = d) : undefined
      })
    })
  })
  const className = React.useMemo(
    () =>
      [
        styles2.CrewMember,
        d.odd ? styles2.odd : styles2.even,
        props.hover ? styles2.hover : undefined,
        props.expanded && d.crewFunction !== 'Plane' ? styles2.expanded : undefined,
      ].join(' '),
    [styles2, d.odd, props.hover, props.expanded, d.crewFunction, props.dragging]
  )
  const onMouseDown = useCallback(
    (e) => {
      if (e.detail === 1) {
        setMousePos(e.clientX)
      }
      if (e.detail === 2) {
        store.dispatch(setExpanded(d.employeeCode))

        return
      }
      //    e.preventDefault()
      //   e.stopPropagation()
    },
    [d.employeeCode, setMousePos]
  )
  const onMouseUp = useCallback(
    (e) => {
      //if (e.target !== e.currentTarget) return

      if (e.button === 0 && e.clientX === mousePos && props.onClick) props.onClick(d, x.invert(e.offsetX + props.x))
      setMousePos(e.clientX)
    },
    [props.onClick, mousePos]
  )

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={setNodeRef}
      className={className}
      style={{
        pointerEvents: props.dragging ? 'none' : 'all',
        //position: 'absolute',
        //top: d.top,
        height: 34,
        width: x.range()[1],

        backgroundColor: isOver ? 'rgba(255,0,0,0.1)' : undefined,
      }}
      //   onMouseEnter={!props.drag ? () => props.onHover(d.employeeCode) : undefined}
      //   onMouseLeave={!props.drag ? () => props.onHover(null) : undefined}
    >
      <div style={{ position: 'absolute', top: 2, width: '100%', display: 'flex' }}>
        {activities.map((d, i) => (
          <Activity
            key={d.key}
            expanded={props.expanded}
            errors={errors}
            edit={props.edit}
            rosterData={props.rosterData}
            zone={timezone}
            dragging={props.dragging}
            onClick={props.onActivityClick ? (a) => props.onActivityClick(a, props.data) : undefined}
            onDoubleClick={props.onDoubleClick}
            data={d}
            drag={props.drag}
            draggedItem={props.draggedItem}
          />
        ))}
      </div>
      <div style={{ position: 'absolute', top: 2, width: '100%', display: 'flex' }}>
        {flightDuties?.map((d, i) => (
          <FlightDuty
            key={d.key}
            expanded={props.expanded}
            zone={timezone}
            data={d}
            xScale={x}
            x={props.x}
            displaySafeCare={displaySafeCare}
            drag={props.drag}
          />
        ))}
      </div>
      {emptyRosterDays?.map((d, i) => (
        <EmptyRosterDay key={i} data={d} xScale={x} />
      ))}
      {allErrorsExceptEmpty.map((d, i) => (
        <RosterError key={i} data={d} xScale={x} />
      ))}
      <div style={{ position: 'absolute', top: 2, width: '100%', display: 'flex' }}>
        {endLabels?.map((d, i) => (
          <EndLabel expanded={props.expanded} key={d.key} drag={props.drag} data={d} x={props.x} />
        ))}
        {activityLabels?.map((d) => (
          <EndLabel expanded={props.expanded} key={d.key} drag={props.drag} data={d} x={props.x} />
        ))}
      </div>
      {displayDutyTimes && (
        <div style={{ position: 'absolute', top: 2, width: '100%', display: 'flex' }}>
          {dutyLabels?.map((d, i) => (
            <DutyLabel expanded={props.expanded} key={d.key} drag={props.drag} data={d} x={props.x} />
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(CrewMember)
