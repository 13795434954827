import React, { useState } from 'react'
import { Rows, Row } from 'shared//PanelLayout/PanelLayout'
import { Navbar, Alignment, Button } from '@blueprintjs/core'

const NotificationBar = (props) => {
  const [showNotification, setShowNotification] = useState(false)

  return (
    <Rows>
      <Row collapse="top" collapsed={!props.visible} orientation="vertical" height={40}>
        <Navbar style={{ backgroundColor: 'orange' }}>
          <Navbar.Group>
            <Navbar.Heading>{props.text}</Navbar.Heading>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            {!props.permanent && (
              <Button minimal icon="cross" text="Close" onClick={() => setShowNotification(false)} />
            )}
          </Navbar.Group>
        </Navbar>
      </Row>

      <Row>{props.children}</Row>
    </Rows>
  )
}

export default NotificationBar
