import { ADD_ERROR } from './actions'

const initialState = {
  errors: [],
}

export default function logReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ERROR: {
      const errors = [...state.errors]
      errors.push(action.payload)
      return {
        ...state,
        errors: errors,
      }
    }
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
