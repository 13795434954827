import React, { useState, useEffect } from 'react'
import styles from './Data.module.scss'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import { Cell, setCellStyle } from './AgGridCellValidator'
import { AgGridReact } from 'ag-grid-react'

const AdditionalWBADaysTable = (props) => {
  const { data } = props
  const { is_editable, changedCells, validation_errors } = data
  const [gridApi, setGridApi] = useState(null)

  const rowData =
    props.data.payload.items?.map((d) => {
      return {
        employeeCode: d.employeeCode,
        startDate: d.startDate,
        endDate: d.endDate,
        value: d.value,
      }
    }) || []
  const frameworkComponents = {
    Cell: Cell,
  }
  const onGridReady = (params) => {
    setGridApi(params.api)
  }
  let colDefs = [
    {
      headerName: 'Employee',
      field: 'employeeCode',
      editable: false,
      sortable: true,
      minWidth: 100,
      valueParser: (d) => d.value,
    },
    {
      headerName: 'Start date',
      field: 'startDate',
      editable: false,
      sortable: true,
      minWidth: 100,
      valueParser: (d) => d.value,
    },
    {
      headerName: 'End date',
      field: 'endDate',
      editable: false,
      sortable: true,
      minWidth: 100,
      valueParser: (d) => d.value,
    },
    {
      headerName: 'Value',
      field: 'value',
      editable: false,
      sortable: true,
      minWidth: 100,
      valueParser: (d) => d.value,
    },
  ]
  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs)
    if (gridApi) gridApi.redrawRows({ force: true })
  }, [data, changedCells, validation_errors])

  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        onGridReady={onGridReady}
        frameworkComponents={frameworkComponents}
        immutableData={true}
        getRowNodeId={(data) => data.employeeCode}
        rowClass={styles.rowClass}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={{
          editable: is_editable,
          cellClass: setCellStyle,
          cellRendererSelector: (props) =>
            props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(' ')
              ? { component: 'Cell' }
              : undefined,
          flex: 1,
          valueParser: (d) => (!isNaN(Number(d.newValue)) ? Number(d.newValue) : d.newValue),
        }}></AgGridReact>
    </div>
  )
}

const AdditionalWBADays = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)

  return (
    <>
      <DataObject data={props.data} upload="xlsx" title={intl.t(props.data.type)} onSave={props.onSave} nofilter>
        {<AdditionalWBADaysTable data={props.data} />}
      </DataObject>
    </>
  )
}

export default AdditionalWBADays
