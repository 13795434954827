import { startOf, endOf } from 'shared/LuxonHelpers'

const CrewFilter = (data, filter) => {
  const {
    displaySafeCare,
    filterBlockhours,
    filterNightsAway,
    displayDutyTimes,
    displayAirportTimes,

    filterScore,
    searchFlight,
    searchFlightNumber,
    searchActivityCode,
    searchPlCode,
    searchSpecialCode,
    issueEmployeeCode,
    searchHomeBase,
    searchSecondBase,
    includeSecondBase,
    searchFunction,
    searchQualifications,
    searchAirportQualifications,
    filterIssueType,
    filterSeverityWarning,
    filterSeverityError,
    filterSeverityBlocking,
    filterIgnoredPreAssignedPairingIssues,
    filterTypeOfActivity,
    filterCrewOnFlightId,
    selectedDay,
    timezone,
  } = filter

  let t0 = 0
  let t1 = 36000000000000

  if (selectedDay) {
    t0 = startOf(selectedDay, timezone, 'day')
    t1 = endOf(selectedDay, timezone, 'day')
  }

  let c = data.slice()

  // filter Crew Id
  if (searchPlCode.length) {
    c = data.filter((d) => searchPlCode.includes(d.employeeCode))
  }

  // filter Home Base
  if (searchHomeBase.length) {
    c = c.filter((i) => searchHomeBase.includes(i.homeBase))
  }

  // filter Second Base
  if (searchSecondBase.length) {
    c = c.filter((i) => i.contractBase.some((val) => searchSecondBase.includes(val.slice(0, 3))))
  }

  // filter based on Crew Function
  if (searchFunction.length) {
    c = c.filter((i) => searchFunction.includes(i.crewFunction) || i.crewFunction.toLowerCase() === 'plane')
  }

  // filter based on Qualifications
  if (searchQualifications.length > 0) {
    c = c.filter(
      (i) =>
        searchQualifications.some((r) => i.aircraftQualifications.map((d) => d.qualificationCode).includes(r)) ||
        i.crewFunction.toLowerCase() === 'plane'
    )
  }
  // filter based on Airport Qualifactions
  if (searchAirportQualifications.length > 0) {
    c = c.filter(
      (i) =>
        searchAirportQualifications.some((r) => i.airportQualifications.map((d) => d.airport).includes(r)) ||
        i.crewFunction.toLowerCase() === 'plane'
    )
  }
  if (searchFlight?.length > 0) {
    //filter data
    c = c.filter((d) =>
      d.activities.some((a) => {
        if (a.from && a.to && a.flightNumber)
          return (
            a.startTime >= t0 && a.endTime <= t1 + 1 && (a.from + '-' + a.to).indexOf(searchFlight.toUpperCase()) >= 0
          )
        return false
      })
    )
  }

  if (filterTypeOfActivity.length) {
    c = c.filter(
      (d) =>
        d.activities?.some(
          (a) => a.endTime >= t0 && a.endTime <= t1 + 1 && filterTypeOfActivity.includes(a.typeOfActivity)
        ) ||
        d.flight_duties?.some(
          (a) => a.endTime >= t0 && a.endTime <= t1 + 1 && filterTypeOfActivity.includes(a.typeOfActivity)
        ) ||
        d.pairings?.some(
          (a) => a.endTime >= t0 && a.endTime <= t1 + 1 && filterTypeOfActivity.includes(a.typeOfActivity)
        ) ||
        d.crewFunction?.toLowerCase() === 'plane'
    )
  }
  if (displaySafeCare) {
    if (filterScore > 1)
      c = c.filter((d) => {
        return d.safeCare.maximumFatigue >= filterScore
      })
  }

  if (filterBlockhours !== false) {
    const treshold = 1
    c = c.filter((d) => {
      const delta =
        d.equalDistributionMetrics?.blockHoursTotal?.target - d.equalDistributionMetrics?.blockHoursTotal?.actual
      if (filterBlockhours < -treshold && delta < filterBlockhours) return true
      if (filterBlockhours > treshold && delta > filterBlockhours) return true
      if (Math.abs(filterBlockhours) <= treshold && Math.abs(delta) <= treshold) return true
      return false
    })
  }
  if (filterNightsAway !== false) {
    const treshold = 1
    c = c.filter((d) => {
      const delta =
        d.equalDistributionMetrics?.hotelStaysTotal?.target - d.equalDistributionMetrics?.hotelStaysTotal?.actual
      if (filterNightsAway < -treshold && delta < filterNightsAway) return true
      if (filterNightsAway > treshold && delta > filterNightsAway) return true
      if (Math.abs(filterNightsAway) <= treshold && Math.abs(delta) <= treshold) return true
      return false
    })
  }
  if (filterCrewOnFlightId) {
    c = c.filter(
      (el) => el.crewFunction === 'Plane' || el.activities.some((e) => e.activityID === filterCrewOnFlightId)
    )
  }

  // Search for flight number
  if (searchFlightNumber?.length > 0) {
    c = c.filter((d) =>
      d.activities.some((a) => {
        return (
          a.startTime >= t0 &&
          a.endTime <= t1 + 1 &&
          searchFlightNumber.map((d) => d.toUpperCase()).includes(a.flightNumber?.toUpperCase())
        )
      })
    )
  }

  if (searchSpecialCode?.length > 0) {
    c = c.filter((d) => d.specialFunctionsAtStartOfPublication.some((s) => searchSpecialCode.includes(s)))
  }

  if (searchActivityCode?.length > 0) {
    c = c.filter((d) =>
      d.activities.some((a) => {
        return (
          ((a.startTime >= t0 && a.startTime <= t1 + 1) || (a.endTime >= t0 && a.endTime <= t1 + 1)) &&
          (searchActivityCode.includes(a.standByCode) || searchActivityCode.includes(a.activityCode))
        )
      })
    )
  }

  if (filterIgnoredPreAssignedPairingIssues) {
    c = c.filter((d) => d.issues.some((i) => i.issueType === 'IgnoredPreAssignedPairing'))
  } else if (filterIssueType.length > 0)
    c = c.filter((d) => {
      let curatedIssueTypes = filterIssueType.slice()
      const i = curatedIssueTypes.findIndex((d) => d === 'FlightCoverageIssue')
      if (i >= 0) curatedIssueTypes.splice(i, 1)

      if (curatedIssueTypes?.length > 0) {
        return d.issues.some((i) => curatedIssueTypes.includes(i.issueType))
      }
      return true
    })

  if (filterSeverityBlocking || filterSeverityWarning || filterSeverityError)
    c = c.filter((d) =>
      d.issues.some(
        (i) =>
          (filterSeverityBlocking && i.severity === 'blocking') ||
          (filterSeverityWarning && i.severity === 'warning') ||
          (filterSeverityError && i.severity === 'error')
      )
    )

  return c.map((d, i) => {
    return { ...d, odd: i % 2 }
  })
}

export default CrewFilter
