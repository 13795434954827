import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import CMainLeftNavBar from './MainLeftNavBar'
import { HotkeysProvider } from '@blueprintjs/core'
import { Cols, Col, Rows, Row } from 'shared//PanelLayout/PanelLayout'

import Publication from './publication/Publication'
import Publications from './publication/Publications'
import styles from 'MainLeftNavBar.module.scss'
import authApi from 'auth/api'
import airportApi from 'shared/AirportDropdown/api'
import backendMetaApi from 'shared/BackendMeta/api'
import usersApi from 'users/api'
import Bidding from 'bidding/Roster'
import Settings from 'Settings'
import Motassign from 'Motassign'
import Dashboard from 'dashboard'
import { FocusStyleManager } from '@blueprintjs/core'
import NotificationBar from './NotificationBar'
import config from 'config'
import Navigator from './Navigator'
FocusStyleManager.onlyShowFocusOnTabs()

const ProfilePage = lazy(() => import('profile/Profile'))
const HelpPage = lazy(() => import('./help/Help'))

const App = () => {
  useEffect(() => {
    const silent = async () => {
      await airportApi.getAirports()
    }
    silent()
  }, [])

  useEffect(() => {
    const silent = async () => {
      await backendMetaApi.getBackendMeta()
    }
    silent()
  }, [])

  return (
    <Cols className={styles.main}>
      <Col fixed shadow collapse="left" dark z={99}>
        <CMainLeftNavBar />
      </Col>
      <Col>
        <Suspense fallback={<div>loading</div>}>
          <NotificationBar visible={config.nonProductionBanner} text={config.nonProductionBanner} permanent>
            <Routes>
              {false && <Route path="/" exact element={<Navigator />} />}
              <Route path="publications" element={<Publications />} />
              <Route path="publications/:name/*" element={<Publication />} />
              <Route path="settings/*" element={<Settings />} />
              <Route path="motassignClient/*" element={<Motassign />} />
              <Route path="myprofile" element={<ProfilePage />} />
              {config.features.isHelpPageEnabled && <Route path="help" element={<HelpPage />} />}
              <Route path="bidding" element={<Bidding />} />
              <Route path="dashboard/*" element={<Dashboard />} />
            </Routes>
          </NotificationBar>
        </Suspense>
      </Col>
    </Cols>
  )
}

export default App
