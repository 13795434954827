import store from 'store/index'
import api from 'auth/api'
import contentDisposition from 'content-disposition'
import handleErrors from './handleErrors'
import Cookie from 'js-cookie'
import { useAuth } from 'auth/auth'
const myFetchBlob = async (url, configuration) => {
  const auth = useAuth()
  if (!auth) await api.trySilentRefresh()
  const token = Cookie.get('accessToken')
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  })
  const newConfiguration = {
    ...configuration,
    headers,
  }

  return fetch(url, newConfiguration).then((res) => {
    let blob = res.blob()
    let cd = contentDisposition.parse(res.headers.get('content-disposition'))
    return { blobPromise: blob, filename: cd.parameters.filename }
  })
  //return fetch(url, newConfiguration).then(res => res.blob());
}

export default myFetchBlob
