import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'

import store from 'store'
import { addFlightDepArrChangedToRepository, addCmEventToSaveQueue } from '../actions'
import moment from 'moment'
import { openFlightEditMode } from '../Flight/actions'

import { Row, Rows, Col, Cols } from 'shared/PanelLayout/PanelLayout'
import { Tag, Navbar, Button, Icon, Alignment, Tabs, Tab } from '@blueprintjs/core'
import EditFlight from './EditFlight'
const Table = (props) => {
  const [eventsCollapsed, setEventsCollapsed] = useState(true)
  const [flightToEdit, setFlightToEdit] = useState(null)
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const prepTableData = (data) => {
    return {
      columnDefs: [
        { headerName: 'id', field: 'id', editable: false, width: 100 },
        {
          headerName: 'aircraft',
          field: 'aircraft',
          editable: false,
          width: 100,
        },
        {
          headerName: 'airline',
          field: 'airline',
          editable: false,
          width: 100,
        },
        {
          headerName: 'flightNumber',
          field: 'flightNumber',
          editable: false,
          width: 100,
        },
        {
          headerName: 'departureAirport',
          field: 'departureAirport',
          editable: false,
          width: 130,
        },
        {
          headerName: 'arrivalAirport',
          field: 'arrivalAirport',
          editable: true,
          width: 130,
        },
        {
          headerName: 'Departure',
          field: 'departureAndArrivalTimestamp.start',
          valueFormatter: (d) => moment(parseInt(d.value)).format('LLL'),
          editable: false,

          enableCellChangeFlash: true,
        },
        {
          headerName: 'Arrival',
          field: 'departureAndArrivalTimestamp.end',
          valueFormatter: (d) => moment(parseInt(d.value)).format('LLL'),
          editable: false,
          valueSetter: (data) => {
            let diff = (parseInt(data.newValue) - parseInt(data.oldValue)) / 60000
            let d = {
              flightId: data.data.id,
              departureUpdateInMinutes: 0,
              arrivalUpdateInMinutes: diff,
            }

            store.dispatch(addFlightDepArrChangedToRepository(d))
          },
          enableCellChangeFlash: true,
        },
      ],
      rowData: data,
    }
  }
  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }
  const deselectRows = () => {
    if (gridApi) gridApi.deselectAll()
  }
  const onSelectionChanged = () => {
    const selectedRows = gridApi ? gridApi.getSelectedRows() : null
    if (!selectedRows) return null
    if (selectedRows.length > 0) {
      setEventsCollapsed(false)
      setFlightToEdit(selectedRows.pop().id)
    }
  }

  const { data, id } = props
  const tableData = prepTableData(data)
  return (
    <Cols>
      <Col>
        <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            columnDefs={tableData.columnDefs}
            rowData={tableData.rowData}
            suppressCellSelection={true}
            onSelectionChanged={() => onSelectionChanged()}
            rowSelection="single"
            immutableData={true}
            onGridReady={onGridReady}
            getRowNodeId={(row) => row.id}></AgGridReact>
        </div>
      </Col>
      <Col fixed dark width={400} collapsed={eventsCollapsed} collapse="right">
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group>
                <Navbar.Heading>Edit Flight</Navbar.Heading>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                <Button
                  minimal
                  icon="cross"
                  onClick={() => {
                    setEventsCollapsed(true)
                    deselectRows()
                  }}
                />
              </Navbar.Group>
            </Navbar>
          </Row>

          {flightToEdit && (
            <Row padding={10}>
              <EditFlight id={flightToEdit} />
            </Row>
          )}
        </Rows>
      </Col>
    </Cols>
  )
}

const mapStateToProps = (state) => ({
  data: state.motassign.Repository.flights,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
