import React, { useState, useEffect } from 'react'
import { useDroppable } from '@dnd-kit/core'
import CreateOrSaveDialog from './DataObjectItemDialog'
import { Button, Checkbox, Position, Card, FormGroup } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import styles from './Data.module.scss'
import Validate from './Validate'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import store from 'store'
import api from './api'
import { CSS } from '@dnd-kit/utilities'

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { addContextDataObject } from './actions'
import * as _ from 'lodash'

import { InputText } from 'shared/Forms'

const newChecklistItem = {
  key: null,
  value: false,
  name: undefined,
  description: undefined,
}

const CreateNewChecklistItem = (props) => {
  return (
    <CreateOrSaveDialog
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Checklist item"
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={newChecklistItem}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData, error) => (
        <>
          <FormGroup
            contentClassName={styles.full}
            label={<span style={{ width: 100, display: 'inline-block' }}>Item</span>}
            inline>
            <InputText
              fill
              placeholder="Name"
              error={error?.name}
              value={data?.name}
              onChange={(name) =>
                setData({
                  ...data,
                  key: data.key === null ? new Date().getTime() + '' : data.key,
                  name,
                })
              }
            />
          </FormGroup>
          <FormGroup
            contentClassName={styles.full}
            label={<span style={{ width: 100, display: 'inline-block' }}>Description</span>}
            inline>
            <InputText
              area
              fill
              error={error?.description}
              value={data?.description}
              placeholder="Description"
              style={{ height: 200 }}
              onChange={(description) =>
                setData({
                  ...data,
                  description,
                })
              }
            />
          </FormGroup>
        </>
      )}
    </CreateOrSaveDialog>
  )
}
const SortableItem = ({ id, item, items, onChange, editable, is_editable, onEdit, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const toggleCheckbox = () => {
    let id = item.id
    let value = !item.value
    let newData = JSON.parse(JSON.stringify(items))
    let newItem = newData.find((d) => d.key === item.key)
    newItem.value = value
    newData.dirty = true
    onChange(newData)
  }

  return (
    <div key={item.id} ref={setNodeRef} style={style}>
      <Card elevation={1} interactive={is_editable} style={{ padding: 10, marginBottom: 5 }}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={is_editable ? () => (editable ? onEdit(item) : toggleCheckbox()) : undefined}>
          {editable && (
            <div {...attributes} {...listeners}>
              <Button small style={{ marginRight: 16 }} minimal icon="drag-handle-vertical" />
            </div>
          )}
          {!editable && (
            <span
              onClick={(e) => {
                e.stopPropagation()
              }}>
              <Checkbox
                checked={item.value}
                style={{ marginBottom: 0 }}
                disabled={!is_editable}
                onChange={(e) => {
                  toggleCheckbox()
                }}
              />
            </span>
          )}
          <Tooltip2
            openOnTargetFocus={false}
            portalContainer={document.getElementById('main')}
            content={<div style={{ maxWidth: 400 }}>{item.description}</div>}
            position={Position.BOTTOM_LEFT}>
            {item.name}
          </Tooltip2>
          <span style={{ flex: 1 }} />
          {editable && (
            <Button
              style={{ float: 'right' }}
              icon="trash"
              minimal
              intent="danger"
              onClick={(e) => {
                e.stopPropagation()
                onDelete(item.key)
              }}
            />
          )}
        </div>
      </Card>
    </div>
  )
}

const SortableList = ({ data, items, editable, is_editable, onChange, onEdit, onDelete }) => {
  const { setNodeRef } = useDroppable({ id: 'test' })

  const sensors = useSensors(useSensor(PointerSensor))

  const handleDragEnd = (event) => {
    const { active, over } = event
    const oldIndex = items.findIndex((d) => d.key === active.id)
    const newIndex = items.findIndex((d) => d.key === over.id)
    const newItems = arrayMove(items, oldIndex, newIndex)

    const o = { ...data, payload: { items: newItems }, dirty: true }
    store.dispatch(addContextDataObject(o))
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={items.map((d, i) => {
          return { ...d, id: d.key }
        })}
        strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef}>
          {items
            .map((d, i) => {
              return { ...d, id: d.key }
            })
            .map((value, index) => (
              <SortableItem
                id={value.id}
                disabled={!editable}
                is_editable={is_editable}
                key={`item-${value.key}`}
                index={index}
                item={value}
                items={items}
                editable={editable}
                onEdit={(d) => onEdit(d, index)}
                onChange={onChange}
                onDelete={onDelete}
              />
            ))}
        </div>
      </SortableContext>
    </DndContext>
  )
}

const Checklist = ({ data, publication, is_editable, onClick }) => {
  return (
    <div style={{ padding: 20 }}>
      <SortableList
        disabled={publication !== null}
        useDragHandle={true}
        is_editable={is_editable}
        editable={publication === null}
        items={data.payload.items}
        lockAxis="y"
        data={data}
        helperContainer={document.getElementById('main')}
        onEdit={(d, i) => {
          onClick(i)
        }}
        onChange={(newItems) => {
          const o = { ...data, payload: { items: newItems }, dirty: true }

          store.dispatch(addContextDataObject(o))
        }}
        onDelete={(key) => {
          let newItems = _.cloneDeep(data.payload.items)
          let index = newItems.findIndex((d) => d.key === key)
          newItems.splice(index, 1)
          const o = { ...data, payload: { items: newItems }, dirty: true }

          store.dispatch(addContextDataObject(o))
        }}
      />
    </div>
  )
}
const Comp = (props) => {
  const [dialog, setDialog] = useState(null)

  const { payload, is_editable, publication } = props.data

  return (
    <>
      <CreateNewChecklistItem
        isOpen={dialog}
        dataObject={props.data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={props.data}
        upload="json"
        title={intl.t('checklist')}
        onSave={props.onSave}
        is_editable={is_editable}
        onAdd={publication === null ? () => setDialog('new') : undefined}
        nofilter>
        <Checklist publication={publication} data={props.data} is_editable={is_editable} onClick={setDialog} />
      </DataObject>
    </>
  )
}

export default Comp
