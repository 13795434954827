export const ReEventTypes = Object.freeze({
  PING: 1,
  SESSION_CREATED: 2,
  LIST_OF_EVENTS: 3,
  ASSIGNMENT_CREATED: 100,
  RESOURCE_CREATED: 101,
  ACTIVITY_CREATED: 102,
  ASSIGNMENT_DELETED: 200,
  RESOURCE_DELETED: 201,
  ACTIVITY_DELETED: 202,
  ACTIVITY_TIMEFRAME_CHANGED: 300,
})

export const ReEvents = {
  1: 'RE_PING',
  2: 'RE_SESSION_CREATED',
  3: 'RE_LIST_OF_EVENTS',
  100: 'RE_ASSIGNMENT_CREATED',
  101: 'RE_RESOURCE_CREATED',
  102: 'RE_ACTIVITY_CREATED',
  200: 'RE_ASSIGNMENT_DELETED',
  201: 'RE_RESOURCE_DELETED',
  202: 'RE_ACTIVITY_DELETED',

  300: 'RE_ACTIVITY_TIMEFRAME_CHANGED',
}
