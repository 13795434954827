import store from 'store'
import { takeEvery } from 'redux-saga/effects'

const undoReducer = (config) => {
  const initialState = {
    past: [],
    future: [],
  }
  const uniqueId = new Date().getTime()
  const SAGA_ADD = 'SAGA/ADD/' + uniqueId
  const SagaUndoAdd = (payload) => ({
    type: SAGA_ADD,
    payload,
  })
  const SAGA_UNDO = 'SAGA/UNDO/' + uniqueId
  const SagaUndo = (payload) => ({
    type: SAGA_UNDO,
    payload,
  })
  const SAGA_REDO = 'SAGA/REDO/' + uniqueId
  const SagaRedo = (payload) => ({
    type: SAGA_REDO,
    payload,
  })
  let past = []
  let future = []
  const { newUndoableActions, undoAction, redoAction } = config
  function* add() {
    yield takeEvery(newUndoableActions, ({ payload }) => {
      past.push(payload)
      future = []
      store.dispatch(SagaUndoAdd({ past, future }))
      store.dispatch(payload.redo)
    })
  }
  function* undo() {
    yield takeEvery(undoAction, ({ payload }) => {
      let action = past.pop()
      future.unshift(action)
      store.dispatch(SagaUndo({ past, future }))
      store.dispatch(action.undo)
    })
  }
  function* redo() {
    yield takeEvery(redoAction, ({ payload }) => {
      let action = future.shift()
      past.push(action)
      store.dispatch(SagaRedo({ past, future }))
      store.dispatch(action.redo)
    })
  }
  const outreducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SAGA_ADD:
      case SAGA_UNDO:
      case SAGA_REDO:
        return {
          past: action.payload.past,
          future: action.payload.future,
        }
      default:
        return state
    }
  }

  return { reducer: outreducer, add, undo, redo }
}

export default undoReducer
