import { GET_ALL_USERS, ADD_MYPROFILE } from './actions'

const initialState = {
  items: [],
  loading: false,
  error: null,
}

export default function documentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        items: action.payload,
      }

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
