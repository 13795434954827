import api from './api'
import {
  ADD_RESOURCE_TO_REPOSITORY,
  ADD_ACTIVITY_TO_REPOSITORY,
  ADD_ASSIGNMENT_TO_REPOSITORY,
  ADD_VIOLATION_TO_REPOSITORY,
  DELETE_ASSIGNMENT_FROM_REPOSITORY,
  ADD_RE_EVENT_TO_HISTORY,
  ACTIVITY_TIMEFRAME_CHANGED,
  INITIALIZE_RE_REPOSITORY,
} from './actions'
import { produce, produceWithPatches, enablePatches, applyPatches } from 'immer'

const initialState = {
  Resources: [],
  Activities: [],
  Assignments: [],
  Violations: [],
  events: [],
}

const addOrReplaceInArray = (sourceArray, item, prop) => {
  let newArray = [...sourceArray]
  let index = newArray.findIndex((d) => d[prop] === item[prop])

  if (index >= 0) {
    newArray[index] = item
  } else {
    newArray.push(item)
  }
  return newArray
}
const Reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case INITIALIZE_RE_REPOSITORY:
        return { ...draft, ...action.payload }
      case ADD_RESOURCE_TO_REPOSITORY:
        return {
          ...state,
          Resources: addOrReplaceInArray(state.Resources, action.payload, 'id'),
        }
      case ADD_ACTIVITY_TO_REPOSITORY:
        return {
          ...state,
          Activities: addOrReplaceInArray(state.Activities, action.payload, 'id'),
        }
      case ADD_ASSIGNMENT_TO_REPOSITORY:
        return {
          ...state,
          Assignments: addOrReplaceInArray(state.Assignments, action.payload, 'id'),
        }
      case ADD_VIOLATION_TO_REPOSITORY: {
        let index = draft.Violations.findIndex((d) => d.identifier === action.payload.identifier)

        if (index >= 0) {
          if (action.payload.state === 'D') draft.Violations.splice(index, 1)
          if (action.payload.state === 'A') draft.Violations[index] = action.payload
        } else {
          draft.Violations.push(action.payload)
        }

        return draft
      }
      case ACTIVITY_TIMEFRAME_CHANGED:
        return {
          ...state,
          Activities: produce(state.Activities, (draftState) => {
            let index = draftState.findIndex((d) => d.id === action.payload.reference)
            draftState[index].timeframe = action.payload.newTimeframe
            return draftState
          }),
        }
      case DELETE_ASSIGNMENT_FROM_REPOSITORY:
        return {
          ...state,
          Assignments: produce(state.Assignments, (draftState) => {
            let index = draftState.findIndex((d) => d.id === action.payload.reference)
            draftState.splice(index, 1)
            return draftState
          }),
        }
      default:
        return draft
    }
  })

export default Reducer
