import React, { Children, useState } from 'react'
import { Dialog, Classes, Button } from '@blueprintjs/core'

const YesNoDialog = (props) => {
  return (
    <Dialog
      {...props}
      title={'Confirm'}
      portalContainer={document.getElementById('main')}
      onClose={() => {
        props.onClose()
      }}>
      <div className={Classes.DIALOG_BODY}>{props.children}</div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="cross"
            intent="danger"
            onClick={() => {
              props.onClose()
            }}
            text="No"
          />
          <Button
            icon="tick"
            intent="primary"
            onClick={() => {
              props.onClose()
              props.onConfirm()
            }}
            text="Yes"
          />
        </div>
      </div>
    </Dialog>
  )
}

export default YesNoDialog
