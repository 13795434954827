import { combineReducers } from 'redux'
import userReducer from 'auth/reducer'
import usersReducer from 'users/reducer'
import rosterReducer from 'publication/reducer'
import reportReducer from 'dashboard/reducer'
import biddingReducer from 'bidding/reducer'
import dataObjectsReducer from 'DataObjectEditor/reducer'
import motassignReducer from 'Motassign/reducer'
import logReducer from 'log/reducer'
import airportReducer from 'shared/AirportDropdown/reducer'
import backendMetaReducer from 'shared/BackendMeta/reducer'
import { DataObjectEditor } from 'DataObjectEditor/DataObjectEditor'

export default () =>
  combineReducers({
    log: logReducer,
    user: userReducer,
    users: usersReducer,
    rosters: rosterReducer,
    bidding: biddingReducer,
    dataObjects: dataObjectsReducer,
    motassign: motassignReducer,
    airports: airportReducer,
    reports: reportReducer,
    backendMeta: backendMetaReducer,
  })
