import React from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'

const Table = (props) => {
  const prepTableData = (data) => {
    return {
      columnDefs: [
        { headerName: 'Crew Member', field: 'crewMember', editable: false },
        { headerName: 'Date', field: 'date', editable: false },
      ],
      rowData: data,
    }
  }

  const { data, id } = props
  const tableData = prepTableData(data)
  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact columnDefs={tableData.columnDefs} rowData={tableData.rowData}></AgGridReact>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.motassign.Repository.crewMemberAbsences,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
