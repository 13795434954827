import React from 'react'
import store from 'store'
import { Link, Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom'
import PrivateRoute from 'PrivateRoute'

import { connect } from 'react-redux'
import styles from './Repository.module.scss'
import { Row, Rows } from 'shared/PanelLayout/PanelLayout'
import { Button, Tab, Tabs, Navbar, Alignment } from '@blueprintjs/core'
import {
  CrewMembers,
  Airlines,
  Airports,
  Aircrafts,
  Ranks,
  AircraftTypes,
  Flights,
  CrewMemberFlightAssignments,
  CrewMemberAbsences,
} from './Tables'
import { undo, redo } from './reducer'
import api from './api'

const Repository = (props) => {
  const navigate = useNavigate()
  const { table } = useParams()

  const onChange = (link) => {
    navigate(link)
  }
  /*
  shouldComponentUpdate() {
    if (api.sessionInitialized) return true
    return false
  }
*/
  return (
    <Rows>
      <Row fixed>
        <Navbar>
          <Navbar.Group>
            <Tabs selectedTabId={table} onChange={onChange}>
              <Tab id="crewmembers" title="Crew Members" />
              <Tab id="airlines" title="Airlines" />
              <Tab id="airports" title="Airports" />
              <Tab id="aircrafts" title="Aircrafts" />
              <Tab id="aircrafttypes" title="AircraftTypes" />
              <Tab id="ranks" title="Ranks" />
              <Tab id="flights" title="Flights" />
              <Tab id="flightassignments" title="Flight Assignments" />
              <Tab id="absences" title="Absences" />
            </Tabs>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}></Navbar.Group>
        </Navbar>
      </Row>
      <Row>
        <Routes>
          <Route path="/" element={<Navigate to="crewmembers" />} />
          <Route path={`crewmembers`} element={<CrewMembers />} />

          <Route path={`airlines`} element={<Airlines />} />

          <Route path={`airports`} element={<Airports />} />

          <Route path={`aircrafts`} element={<Aircrafts />} />

          <Route path={`aircrafttypes`} element={<AircraftTypes />} />

          <Route path={`ranks`} element={<Ranks />} />

          <Route path={`flights`} element={<Flights />} />

          <Route path={`flightassignments`} element={<CrewMemberFlightAssignments />} />

          <Route path={`absences`} element={<CrewMemberAbsences />} />
        </Routes>
      </Row>
    </Rows>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Repository)
