import styles from './Cursor.module.scss'
import { DateTime } from 'luxon'
import React, { useEffect, useState, useCallback } from 'react'

const Cursor = (props) => {
  const [position, setPosition] = useState()

  const { timezone, xScale } = props
  const onMouseMove = useCallback((e) => {
    setPosition(e.clientX)
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    return () => window.removeEventListener('mousemove', onMouseMove)
  }, [])
  if (!position) return null
  if (position - props.left < 0) return null

  return (
    <>
      <div className={styles.cursorLabel} style={{ transform: `translateX(${position - 25 - props.left}px)` }}>
        {DateTime.fromJSDate(xScale.invert(position - props.left + props.x), { zone: timezone }).toFormat('HH:mm')}
      </div>
      <div
        className={styles.cursorLine}
        style={{
          transform: `translateX(${position - props.left}px)`,
        }}
      />
    </>
  )
}

export default Cursor
