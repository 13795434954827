import {
  ADD_RANK_TO_REPOSITORY,
  ADD_FLIGHT_TO_REPOSITORY,
  ADD_CREWMEMBER_TO_REPOSITORY,
  ADD_AIRCRAFT_TO_REPOSITORY,
  ADD_AIRCRAFTTYPE_TO_REPOSITORY,
  ADD_AIRPORT_TO_REPOSITORY,
  ADD_AIRLINE_TO_REPOSITORY,
  ADD_CREWMEMBER_FLIGHT_ASSIGNMENT_TO_REPOSITORY,
  ADD_CREWMEMBER_ABSENCE_TO_REPOSITORY,
  ADD_FLIGHT_DEPARR_CHANGED_TO_REPOSITORY,
  DELETE_CREWMEMBER_FROM_REPOSITORY,
  DELETE_FLIGHT_ASSIGNMENT_FROM_REPOSITORY,
  DELETE_FLIGHT_FROM_TO_REPOSITORY,
  ADD_EVENT_TO_HISTORY,
  INITIALIZE_CM_REPOSITORY,
} from './actions'

import { produce } from 'immer'

import { CmEventTypes } from './constants'

const changes = {}
let currentVersion = -1
const noOfVersionsSupported = 100
const undoableActions = [INITIALIZE_CM_REPOSITORY]

const initialState = {
  crewMembers: [],
  aircrafts: [],
  aircraftTypes: [],
  airlines: [],
  airports: [],
  flights: [],
  crewMemberFlightAssignments: [],
  crewMemberAbsences: [],
  ranks: [],
  events: [],
}

const addOrReplaceInArray = (sourceArray, item, prop) => {
  let newArray = [...sourceArray]
  let index = newArray.findIndex((d) => d[prop] === item[prop])
  if (index >= 0) {
    newArray[index] = item
  } else {
    newArray.push(item)
  }
  return newArray
}

const Reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_EVENT_TO_HISTORY:
        return {
          ...state,
          events: [...state.events, action.payload],
        }
      case INITIALIZE_CM_REPOSITORY:
        return {
          ...state,
          ...action.payload,
        }
      case ADD_RANK_TO_REPOSITORY:
        return {
          ...state,
          ranks: addOrReplaceInArray(state.ranks, action.payload, 'id'),
        }
      case ADD_FLIGHT_TO_REPOSITORY:
        return {
          ...state,
          flights: addOrReplaceInArray(state.flights, action.payload, 'id'),
        }
      case ADD_CREWMEMBER_TO_REPOSITORY:
        return {
          ...state,
          crewMembers: addOrReplaceInArray(state.crewMembers, action.payload, 'id'),
        }
      case ADD_AIRLINE_TO_REPOSITORY:
        return {
          ...state,
          airlines: addOrReplaceInArray(state.airlines, action.payload, 'id'),
        }
      case ADD_AIRPORT_TO_REPOSITORY:
        return {
          ...state,
          airports: addOrReplaceInArray(state.airports, action.payload, 'id'),
        }
      case ADD_AIRCRAFT_TO_REPOSITORY:
        return {
          ...state,
          aircrafts: addOrReplaceInArray(state.aircrafts, action.payload, 'id'),
        }
      case ADD_AIRCRAFTTYPE_TO_REPOSITORY:
        return {
          ...state,
          aircraftTypes: addOrReplaceInArray(state.aircraftTypes, action.payload, 'id'),
        }
      case ADD_CREWMEMBER_FLIGHT_ASSIGNMENT_TO_REPOSITORY:
        return {
          ...state,
          crewMemberFlightAssignments: addOrReplaceInArray(
            state.crewMemberFlightAssignments,
            action.payload,
            'crewMember'
          ),
        }
      case ADD_CREWMEMBER_ABSENCE_TO_REPOSITORY:
        return {
          ...state,
          crewMemberAbsences: addOrReplaceInArray(state.crewMemberAbsences, action.payload, 'crewMember'),
        }
      case ADD_FLIGHT_DEPARR_CHANGED_TO_REPOSITORY: {
        let d = draft.flights.find((d) => d.id === action.payload.flightId)
        d.departureAndArrivalTimestamp.start = (
          parseInt(d.departureAndArrivalTimestamp.start) +
          action.payload.departureUpdateInMinutes * 60 * 1000
        ).toString()
        d.departureAndArrivalTimestamp.end = (
          parseInt(d.departureAndArrivalTimestamp.end) +
          action.payload.arrivalUpdateInMinutes * 60 * 1000
        ).toString()
        draft.events.push({
          type: CmEventTypes.FLIGHT_DEPARTURE_AND_ARRIVAL_CHANGED,
          flightDepartureAndArrivalUpdate: action.payload,
        })
        return draft
      }
      case DELETE_CREWMEMBER_FROM_REPOSITORY:
      case DELETE_FLIGHT_ASSIGNMENT_FROM_REPOSITORY:
        return {
          ...state,
          crewMemberFlightAssignments: produce(state.crewMemberFlightAssignments, (draftState) => {
            let index = draftState.findIndex((d) => d.id === action.payload)
            draftState.splice(index, 1)
            return draftState
          }),
        }
      case DELETE_FLIGHT_FROM_TO_REPOSITORY:
        return draft
      default:
        return draft
    }
  })

export default Reducer
