import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { intl } from 'i18n/IntlGlobalProvider'
import {
  EditableText,
  FormGroup,
  Card,
  H5,
  H6,
  Dialog,
  Tooltip,
  Button,
  InputGroup,
  Tag,
  Position,
  Tab,
  Icon,
  Alignment,
  Switch,
} from '@blueprintjs/core'

import { Rows, Row, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import Search from 'shared/search'

import styles from './Data.module.scss'
import DataObject from './DataObject.js'

import CreateOrSaveDialog from './DataObjectItemDialog'
import Data from './DataObjectEditor'
import { validateAirportCode } from './Validators'

import { InputText, InputCost, InputTime, InputDuration } from 'shared/Forms'
import SearchDropdown from '../shared/SearchDropdown/SearchDropdown'
import TimezoneDropdown from 'shared/TimezoneDropdown/TimezoneDropdown'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import CurrencyDropdown from 'shared/CurrencyDropdown/CurrencyDropdown'
import Validate from './Validate.js'

const EditHotel = (props) => {
  const rate = props.data.nightlyRate
  const name = props.data.name
  const travelTime = props.data.travelTimeFromAirport
  const travelCost = props.data.travelCostFromAirport
  const currency = props.data.currency
  const currency_info = props.currency

  let availableCurrencies = []
  if (currency_info) {
    availableCurrencies = [currency_info?.default_currency, ...currency_info?.exchange_rates.map((d) => d.from)]
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
      <InputText
        placeholder="Hotel name"
        value={name}
        onChange={(value) => {
          props.onChange({ ...props.data, name: value })
        }}
      />

      <CurrencyDropdown
        error={props.validation_errors?.currency}
        activeItem={currency}
        style={{ width: 100, marginRight: 10, textAlign: 'right' }}
        items={availableCurrencies}
        onChange={(d) => {
          if (d.key) {
            props.onChange({ ...props.data, currency: d.key })
          }
        }}
      />

      <InputCost
        error={props.validation_errors?.nightlyRate}
        currency={currency}
        value={rate}
        placeholder="Rate"
        onChange={(value) => {
          props.onChange({ ...props.data, nightlyRate: value })
        }}
      />

      <InputDuration
        validation_errors={props.validation_errors?.travelTimeFromAirport}
        leftIcon={<Icon icon="taxi" />}
        style={{ width: 80, marginRight: 10, textAlign: 'right' }}
        value={travelTime}
        onChange={(value) => {
          props.onChange({ ...props.data, travelTimeFromAirport: value })
        }}
      />

      <InputCost
        error={props.validation_errors?.travelCostFromAirport}
        currency={currency}
        value={travelCost}
        onChange={(value) => {
          props.onChange({ ...props.data, travelCostFromAirport: value })
        }}
      />

      {!props.preventDelete && (
        <span style={{ flex: '0 0 auto' }}>
          <Button small icon="cross" minimal onClick={() => props.onDelete()} />
        </span>
      )}
    </div>
  )
}
const CreateNewAirport = (props) => {
  const airports = useSelector((state) => state.airports.items)
  const currency_info = props.configuration?.currency_info
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]
  const newHotel = {
    name: '',
    nightlyRate: '',
    currency: currency_info?.default_currency,
    travelTimeFromAirport: null,
    travelCostFromAirport: '',
  }

  const newAirport = {
    iata: null,
    hotels: [newHotel],
  }

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <CreateOrSaveDialog
      style={{ width: 800 }}
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Hotel"
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={newAirport}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => (
        <>
          <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Airport</span>} inline>
            <AirportDropdown
              error={error?.iata}
              intent={error?.iata ? 'danger' : undefined}
              onChange={({ iata }) => {
                setData({ ...data, iata: iata })
              }}
              activeItem={data?.iata}
            />

            <span style={{ marginLeft: 10, flex: 0, alignSelf: 'center' }}>
              {airports.find((d) => d.iata === data?.iata)?.name}
            </span>
          </FormGroup>
          <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Timezone</span>} inline>
            <TimezoneDropdown disabled activeItem={airports.find((d) => d.iata === data?.iata)?.tz} />
          </FormGroup>

          <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Hotels</span>} inline>
            {data?.hotels &&
              data?.hotels.map((hotel, i) => (
                <EditHotel
                  key={i}
                  validation_errors={error?.hotels?.[i]}
                  data={hotel}
                  currency={currency_info}
                  onChange={(d) => {
                    let hotels = [...data?.hotels]
                    hotels.splice(i, 1, d)
                    setData({ ...data, hotels: hotels })
                  }}
                  onDelete={() => {
                    let hotels = [...data?.hotels]
                    hotels.splice(i, 1)

                    setData({ ...data, hotels: hotels })
                    setTabIndex(0)
                  }}
                  preventDelete={i === 0}
                />
              ))}

            <Button
              icon="plus"
              disabled={error}
              text="Add Hotel"
              onClick={() => {
                let hotels = [...(data?.hotels || [])]
                hotels.push(newHotel)
                setTabIndex(hotels.length - 1)
                setData({ ...data, hotels: hotels })
              }}
            />
          </FormGroup>
        </>
      )}
    </CreateOrSaveDialog>
  )
}

const Hotels = ({ payload, is_editable, search, onClick }) => {
  const airports = useSelector((state) => state.airports.items)
  return (
    <>
      {payload.map((item, i) => (
        <Card
          key={i}
          className={styles.card}
          elevation={1}
          interactive={is_editable}
          onClick={
            is_editable
              ? () => {
                  onClick(i)
                }
              : undefined
          }
          style={{
            marginBottom: 10,
            position: 'relative',
            display: search ? (item.iata.toUpperCase().includes(search.toUpperCase()) ? 'block' : 'none') : 'block',
          }}>
          <H5>{airports.find((d) => d.iata === item.iata)?.name}</H5>
          <H6>
            <span className={styles.muted}>
              {airports.find((d) => d.iata === item.iata)?.iata} - {airports.find((d) => d.iata === item.iata)?.icao}
            </span>
          </H6>
        </Card>
      ))}
    </>
  )
}

const Comp = (props) => {
  const [search, setSearch] = useState('')
  const { data } = props
  const { is_editable } = data
  const payload = data.payload.items
  let filteredData = payload

  if (search) filteredData = filteredData.filter((d) => d.iata.toLowerCase().includes(search.toLowerCase()))

  const [dialog, setDialog] = useState(null)

  return (
    <>
      <CreateNewAirport
        configuration={props.configuration}
        validation_errors={data.validation_errors?.items}
        isOpen={dialog}
        dataObject={data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={data}
        upload="json"
        title={intl.t('hotels')}
        is_editable={is_editable}
        search={<Search placeholder="Search" fill value={search} onChange={(value) => setSearch(value)} />}
        onSave={props.onSave}
        onAdd={() => setDialog('new')}
        nofilter>
        <Hotels payload={payload} is_editable={is_editable} onClick={setDialog} search={search} />
      </DataObject>
    </>
  )
}

export default Comp
