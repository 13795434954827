import React, { useState } from 'react'
import TooltipButton from './TooltipButton'
import YesNoDialog from './YesNoDialog'

const ConfirmButton = (props) => {
  const [waiting, setWaiting] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <YesNoDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onCancel={() => {
          setIsOpen(false)
          props.onCancel()
        }}
        onConfirm={() => {
          if (props.loading) setWaiting(true)
          setIsOpen(false)
          props.onConfirm()
          if (props.loading) setWaiting(false)
        }}>
        {props.dialogText}
      </YesNoDialog>
      <TooltipButton
        {...props}
        icon={waiting ? <Spinner size={15} /> : props.icon}
        onClick={() => {
          setIsOpen(true)
        }}
      />
    </>
  )
}

export default ConfirmButton
