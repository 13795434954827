import myFetch from 'api/myFetch'
import store from 'store/index'
import { fetchBackendMeta } from './actions'
import config from 'config'

const servicesFactory = (myFetch) => ({
  async getBackendMeta() {
    let json = await myFetch(`${config.api}/meta`)
    store.dispatch(fetchBackendMeta(json))
    return json
  },
})

const api = servicesFactory(myFetch)
export default api
