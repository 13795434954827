import React from 'react'
import { Navbar, Button, Icon, Alignment } from '@blueprintjs/core'
import { Row } from 'shared/PanelLayout/PanelLayout'
const HeaderRow = (props) => {
  return (
    <Row fixed z={props.z}>
      <Navbar>
        <Navbar.Group>
          {props.onClick && props.icon && (
            <Button icon={props.icon} minimal onClick={() => props.onClick()} style={{ marginRight: 10 }} />
          )}
          {!props.onClick && props.icon && <Icon icon={props.icon} intent="primary" style={{ marginRight: 10 }} />}
          <Navbar.Heading>{props.title}</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>{props.children}</Navbar.Group>
      </Navbar>
    </Row>
  )
}

export default HeaderRow
