export const CmEventTypes = Object.freeze({
  PING: 1,
  SESSION_CREATED: 2,
  LIST_OF_EVENTS: 3,
  SESSION_INITIALIZED: 4,
  AIRCRAFT_CREATED: 100,
  AIRCRAFT_TYPE_CREATED: 101,
  AIRLINE_CREATED: 102,
  AIRPORT_CREATED: 103,
  CREW_MEMBER_CREATED: 104,
  CREW_MEMBER_ABSENCE_CREATED: 105,
  CREW_MEMBER_FLIGHT_ASSIGNMENT_CREATED: 106,
  FLIGHT_CREATED: 107,
  RANK_CREATED: 108,
  CREW_MEMBER_DELETED: 200,
  FLIGHT_DELETED: 201,
  CREW_MEMBER_FLIGHT_ASSIGNMENT_DELETED: 202,
  FLIGHT_DEPARTURE_AND_ARRIVAL_CHANGED: 300,
})

export const CmEvents = {
  1: 'CM_PING',
  2: 'CM_SESSION_CREATED',
  3: 'CM_LIST_OF_EVENTS',
  4: 'CM_SESSION_INITIALIZED',
  100: 'CM_AIRCRAFT_CREATED',
  101: 'CM_CM_AIRCRAFT_TYPE_CREATED',
  102: 'CM_AIRLINE_CREATED',
  103: 'CM_AIRPORT_CREATED',
  104: 'CM_CREW_MEMBER_CREATED',
  105: 'CM_CREW_MEMBER_ABSENCE_CREATED',
  106: 'CM_CREW_MEMBER_FLIGHT_ASSIGNMENT_CREATED',
  107: 'CM_FLIGHT_CREATED',
  108: 'CM_RANK_CREATED',
  200: 'CM_CREW_MEMBER_DELETED',
  201: 'CM_FLIGHT_DELETED',
  202: 'CM_CREW_MEMBER_FLIGHT_ASSIGNMENT_DELETED',
  300: 'CM_FLIGHT_DEPARTURE_AND_ARRIVAL_CHANGED',
}
