import React, { useEffect, useState, useRef } from 'react'
import { IssueTag } from '../CrewMember/Issue/Issue'
import styles2 from '../CrewMember/CrewMember.module.scss'
import store from 'store'
import { useSelector } from 'react-redux'
import styles from './CrewMemberLabel.module.scss'

import { Button, Position } from '@blueprintjs/core'
import { Tooltip2, Popover2InteractionKind } from '@blueprintjs/popover2'
import tooltipStyles from '../CrewMember/Activity/Tooltips.module.scss'
import { iconCrew, iconBed } from 'icons'
import { Icon } from '@blueprintjs/core'
import * as d3 from 'd3'
import { setSelectedCrewMembers, setExpanded } from 'publication/actions'

const Label = ({ label, text }) => (
  <div>
    <span className={tooltipStyles.label}>{label}</span>
    <span>{text}</span>
  </div>
)

const PairingsTooltip = ({ d }) => (
  <div className={tooltipStyles.tooltipLong}>
    <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <strong style={{ flex: 1 }}>{d.employeeCode} </strong>
    </span>

    {[...d.rosterIssues, ...d.dataIssues].length > 0 && (
      <>
        <div className={tooltipStyles.line} />
        {[...d.rosterIssues, ...d.dataIssues].map((d, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            <IssueTag d={d} />
          </div>
        ))}
      </>
    )}
  </div>
)

const CrewMemberLabel = (props) => {
  const d = props.data
  const selectedCrew = useSelector((state) => state.rosters.selectedCrewMembers)

  return (
    <div
      onClick={() => {
        store.dispatch(setSelectedCrewMembers(d.employeeCode))
        if (props.onClick) props.onClick(d)
      }}
      style={{ position: 'relative', left: 0, height: 34, right: 0 }}
      className={[
        styles.rosterLabel,
        props.expanded ? styles2.expanded : undefined,
        d.odd ? styles2.odd : styles2.even,
        props.hover ? styles2.hover : undefined,

        selectedCrew.includes(d.employeeCode) ? styles.selected : undefined,
      ].join(' ')}
      onMouseEnter={() => props.onHover(d.employeeCode)}
      onMouseLeave={() => props.onHover(null)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'self-start',
          width: '100%',
          top: 0,
          height: '100%',
        }}>
        <span style={{ marginLeft: 5 }}>
          <div
            style={{
              fontWeight: 'bold',
              marginRight: 15,
              width: 30,
              fontSize: 12,
              marginBottom: 4,

              color: [...(d.rosterIssues || []), ...(d.dataIssues || [])].length > 0 ? 'red' : undefined,
            }}>
            {d.employeeCode}
          </div>

          <div style={{ color: '#aaa' }}>{d.crewFunction}</div>
        </span>

        <span style={{ flex: '0 0 auto', marginRight: 10, width: 50 }}></span>
      </div>
      <Tooltip2
        usePortal={true}
        disabled={props.dragging}
        portalContainer={document.getElementById('main')}
        popoverProps={{ className: styles.hoverLabel }}
        content={<PairingsTooltip d={d} placement={Position.RIGHT} />}
        renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => (
          <div
            {...tooltipProps}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}></div>
        )}
      />
    </div>
  )
}
export default React.memo(CrewMemberLabel)
