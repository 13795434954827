import { TOGGLE_THEME, USER_LOGIN, USER_LOGOUT, ADD_MYPROFILE } from './actions'

const initialState = {
  user: null,
  profile: null,
  error: null,

  theme: 'theme-light',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        theme: state.theme === 'theme-light' ? 'theme-dark' : 'theme-light',
      }
    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
      }
    case ADD_MYPROFILE:
      return {
        ...state,
        profile: action.payload,
      }

    case USER_LOGOUT:
      return {
        ...state,
        user: null,
      }
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
