import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import api from 'auth/api'
import { useNavigate, useLocation, useResolvedPath } from 'react-router-dom'
import { Button, Menu, Icon, MenuItem } from '@blueprintjs/core'
import { Rows, Row } from 'shared//PanelLayout/PanelLayout'
import SidebarMenu from 'shared//SidebarMenu/SidebarMenu'
import styles from './MainLeftNavBar.module.scss'
import logo from 'images/motuplan-logo.png'
import config from 'config'
import profileApi from 'profile/api'

const RouterMenuItem = (props) => {
  let navigate = useNavigate()
  let location = useLocation()

  return (
    <MenuItem
      className={styles.menuItem}
      labelElement={props.labelElement}
      icon={<Icon className="fw" icon={props.icon} />}
      active={location.pathname.startsWith(useResolvedPath(props.to || 'a').pathname)}
      text={
        <span>
          <span className={styles.menuLabel}>{props.text}</span>
          {props.desc && <div className={styles.menuLabelDescription}>{props.desc}</div>}
        </span>
      }
      onClick={props.onClick ? props.onClick : () => navigate(props.to)}
    />
  )
}

const resultRenderer = ({ first_name, last_name }) => (
  <div>
    {first_name} {last_name}
  </div>
)

resultRenderer.propTypes = {
  title: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
}

const CMainLeftNavBar = () => {
  const theme = useSelector((state) => state?.user?.profile?.preferences?.ui?.mode) || 'theme-light'
  const profile = useSelector((state) => state.user.profile)
  const [preferences, setPreferences] = useState(profile.preferences)
  const [visible, setVisible] = useState(false)
  const backendMeta = useSelector((state) => state.backendMeta)

  return (
    <React.Fragment>
      <div className={styles.accentBar} />

      <SidebarMenu collapsed={!visible} height={200}>
        <Rows>
          <Row fixed height={100}>
            <img
              src={logo}
              style={{
                width: 160,
                position: 'absolute',
                left: 40,
                top: 8,
              }}
              alt="Motuplan logo"
            />
            <div
              style={{
                width: 150,
                fontSize: 10,
                color: 'rgb(186 189 192)',
                position: 'absolute',
                textAlign: 'right',
                left: 40,
                top: 89,
              }}>
              ui:{config.version} &#183; api:{backendMeta.data.release}
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', listStyleType: 'none' }}>
              <MenuItem
                className={styles.menuItem}
                style={{ width: 40 }}
                icon={<Icon className="fw" icon={visible ? 'drawer-right' : 'drawer-left'} />}
                onClick={() => setVisible(!visible)}
              />
              <MenuItem
                className={styles.menuItem}
                style={{ width: 40 }}
                icon={<Icon className="fw" icon={theme === 'theme-light' ? 'moon' : 'flash'} />}
                onClick={() => {
                  profileApi.saveMyProfile({
                    ...profile,
                    preferences: {
                      ...preferences,
                      ui: { mode: theme === 'theme-light' ? 'theme-dark' : 'theme-light' },
                    },
                  })
                }}
              />
            </div>
          </Row>

          <Row noscroll>
            <Menu className={styles.mainMenu}>
              <MenuItem className={styles.menuItemVersion} disabled="true" text="" />
              <RouterMenuItem to="/publications" icon={<Icon className="fw" icon="list" />} text="Publications" />
              <RouterMenuItem to="/dashboard" icon={<Icon className="fw" icon="dashboard" />} text="Dashboard" />
              <div
                style={{
                  flex: '1 1 auto',
                }}
              />
              <RouterMenuItem to="/settings" icon="cog" text="Settings" />
              {config.features.isHelpPageEnabled && <RouterMenuItem to="/help" icon="help" text="Help" />}
              <RouterMenuItem to="/myprofile" icon="user" text="My profile" />
              <RouterMenuItem onClick={api.logout} icon="power" text="Sign Out" />
            </Menu>
          </Row>
        </Rows>
      </SidebarMenu>
    </React.Fragment>
  )
}

export default CMainLeftNavBar
