import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Routes, Navigate, matchPath, useNavigate, useParams } from 'react-router-dom'
import { BroadcastChannel } from 'broadcast-channel'

import PrivateRoute from './../PrivateRoute'
import styles from './Motassign.module.scss'
import store from 'store'
import moment from 'moment'
import { Tag, Navbar, Button, Icon, Alignment, Tabs, Tab } from '@blueprintjs/core'
import Repository from './CrewMgmt'
import RulesEngineRepository from './RulesEngine'
import EditFlight from './CrewMgmt/Tables/EditFlight'
import { crewmgmt, rulesengine } from 'proto/compiled.js'
import { CmEvents, CmEventTypes } from './CrewMgmt/constants'
import {
  addResourceToRepository,
  addActivityToRepository,
  addAssignmentToRepository,
  deleteAssignmentFromRepository,
  changeActivityTimeframeInRepository,
  initializeReRepository,
} from './RulesEngine/actions'

import { Row, Rows, Col, Cols } from 'shared/PanelLayout/PanelLayout'

import CmApi from './CrewMgmt/api'
import ReApi from './RulesEngine/api'
import { initializeCmRepository } from './CrewMgmt/actions'
import { AppToaster } from 'shared/Toaster/Toaster'

const clientId = 'motuplan-ui-client-' + new Date().getTime()

const IN = 0
const OUT = 1
const channel = new BroadcastChannel('slave')
const channel2 = new BroadcastChannel('master')
channel2.onmessage = (d) => {
  AppToaster.show({
    intent: 'success',

    timeout: 1000,
    icon: 'tick',
    message: 'Dropped',
  })
}

let allEvents = []
const Motassign = (props) => {
  const navigate = useNavigate()
  const { page } = useParams()
  let initialLoad = true
  const [activeEvent, setActiveEvent] = useState(null)
  const [eventsCollapsed, setEventsCollapsed] = useState(true)

  let windows = {}
  const convertToRulesEngine = (data) => {
    const {
      crewMembers,
      flights,
      crewMemberFlightAssignments,
      aircrafts,
      crewMemberFlightAssignmentsDeleted,
      flightsChanged,
    } = data

    const convertFlightToActivity = (flight, aircrafts) => {
      let aircraftType = aircrafts.find((d) => d.id === flight.aircraft).aircraftType
      return {
        //return activity
        id: flight.id,
        activityType: 'Flight',
        timeframe: {
          start: flight.departureAndArrivalTimestamp.start,
          end: flight.departureAndArrivalTimestamp.end,
        },
        properties: [`S|FlightNumber|${flight.flightNumber}|`, `S|AircraftType|${aircraftType}|`],
      }
    }
    const convertCrewMemberToResource = (crewmember) => {
      let id = crewmember.id
      let properties = []

      if (crewmember.birthDate) properties.push(`D|Birthdate|${crewmember.birthDate}|`)
      if (crewmember.homeBase) properties.push(`S|HomeBase|${crewmember.homeBase}|`)
      if (crewmember.crewFunctions) {
        let AirCrafTypeAndRanks = crewmember.crewFunctions.map((e) => {
          const [id, rank, aircraftType] = e.split('|')
          return `M|AircraftTypeAndRank|S|AircraftType|${aircraftType}|S|Rank|${rank}|`
        })
        properties.push(...AirCrafTypeAndRanks)
      }
      return {
        id: id,
        name: id,
        properties: properties,
      }
    }
    const convertFlightAssignmentToAssignment = (flightAssignment) => {
      let id = flightAssignment.crewMember
      let activityId = flightAssignment.flight
      return {
        //return assignment
        id: `${id}|${activityId}`,
        activityId: `${activityId}`,
        resourceId: `${id}`,
      }
    }

    const convertFlightChangetoActivityChange = (newData, flights) => {
      const flight = store.getState().motassign.RulesEngine.Activities.find((d) => d.id === newData.flightId)
      return {
        newTimeframe: {
          start: parseInt(flight.timeframe.start) + newData.departureUpdateInMinutes * 60 * 1000,
          end: parseInt(flight.timeframe.end) + newData.arrivalUpdateInMinutes * 60 * 1000,
        },
        reference: newData.flightId,
      }
    }

    return {
      Resources: crewMembers.map((cm) => convertCrewMemberToResource(cm)),
      Activities: flights.map((f) => convertFlightToActivity(f, aircrafts)),
      Assignments: crewMemberFlightAssignments.map((f) => convertFlightAssignmentToAssignment(f)),
      DeletedAssignments: crewMemberFlightAssignmentsDeleted,
      ChangedActivities: flightsChanged.map((f) => convertFlightChangetoActivityChange(f, flights)),
    }
  }

  const parseCmEvent = (str) => {
    let event = JSON.parse(str)
    if (event.type === CmEventTypes.SESSION_INITIALIZED) {
      CmApi.sessionInitialized = true
      const CmObjects = CmApi.convertEventsToObjects(allEvents)
      store.dispatch(initializeCmRepository(CmObjects))
      let ReObjects = convertToRulesEngine(CmObjects)
      store.dispatch(initializeReRepository(ReObjects))
      ReApi.checkViolations(ReObjects)
    }
    if (event.type === CmEventTypes.LIST_OF_EVENTS) {
      if (!CmApi.sessionInitialized) {
        allEvents.push(...event.events)
      } else {
        const CmObjects = CmApi.convertEventsToObjects(event.events)
        CmApi.addToRepository(CmObjects)
        let ReObjects = convertToRulesEngine(CmObjects)
        ReApi.addToRepository(ReObjects)
        ReApi.checkViolations(ReObjects)
      }
    }
  }

  useEffect(() => {
    ReApi.initializeConnection(clientId, 120)
    CmApi.initializeConnection(
      ({ data }) => {
        parseCmEvent(data)
      },
      clientId,
      120
    )
  }, [])

  const onChange = (link) => {
    navigate(link)
  }

  let events = []
  //let events = this.props.events
  events.sort((a, b) => a.ts < b.ts)
  return (
    <Rows>
      <Row fixed>
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading>{clientId}</Navbar.Heading>

            <Tabs selectedTabId={page} onChange={onChange}>
              <Tab id="repository" title="Cm Repository" />
              <Tab
                id="rulesengine"
                title={
                  <span>
                    Re Repo
                    <Tag intent="danger" minimal round>
                      {props.Violations}
                    </Tag>
                  </span>
                }
              />
            </Tabs>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button
              text="undo"
              onClick={() => store.dispatch({ type: 'USER_UNDO' })}
              disabled={!props.canUndo}></Button>
            <Button
              text="redo"
              onClick={() => store.dispatch({ type: 'USER_REDO' })}
              disabled={!props.canRedo}></Button>
            <Button text="save" disabled={!props.canSave}></Button>
            <Button
              text="Pairings"
              disabled={windows.test && !windows['test'].closed}
              onClick={() => {
                if (Object.prototype.hasOwnProperty.call(windows, 'test'))
                  if (!windows['test'].closed) {
                    windows['test'].focus()
                    return
                  }
                windows['test'] = window.open('/test', 'test', 'resizable')
              }}></Button>
            <Button
              text="popup"
              onClick={() => {
                window.open('/test', 'test', 'resizable,noopener')
              }}></Button>

            {eventsCollapsed && <Button minimal icon="panel-stats" onClick={() => setEventsCollapsed(false)} />}
          </Navbar.Group>
        </Navbar>
      </Row>
      <Row>
        <Routes>
          <Route path="/" element={<Navigate to="repository" />} />

          <Route path="repository/:table" element={<Repository />} />

          <Route path="rulesengine/:table" element={<RulesEngineRepository />} />
        </Routes>
      </Row>
    </Rows>
  )
}

const mapStateToProps = (state) => ({
  Violations: state.motassign.RulesEngine.Violations.length,
  canUndo: state.motassign.undo.past.length,
  canRedo: state.motassign.undo.future.length,
  canSave: state.motassign.Repository.events.length,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Motassign)
