import { DateTime, Interval } from 'luxon'
import { startOf, endOf, getTicksInInterval } from 'shared/LuxonHelpers'
import React, { useEffect, useState, useRef } from 'react'
import styles from './Cursor.module.scss'

const DaySeparator = (props) => {
  return (
    <div
      className={styles.daySeparator}
      style={{
        transform: `translateX(${props.data}px)`,
      }}
    />
  )
}
const DaySeparators = (props) => {
  const xScale = props.xScale
  const { timezone } = props
  const int = React.useMemo(
    () =>
      getTicksInInterval(
        Interval.fromDateTimes(
          DateTime.fromJSDate(xScale.domain()[0], { zone: timezone }),
          DateTime.fromJSDate(xScale.domain()[1], { zone: timezone })
        ),
        'day'
      ),
    [timezone, xScale]
  )

  const days = React.useMemo(
    () =>
      int
        .map((d) => xScale(d))
        .filter((d) => d > props.x)
        .filter((d) => d - props.x < props.width),
    [xScale, props.x, timezone]
  )

  return (
    <>
      {days.map((d) => (
        <DaySeparator key={d} data={d} x={props.x} />
      ))}
    </>
  )
}

export default React.memo(DaySeparators)
