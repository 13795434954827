import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import * as _ from 'lodash'
import styles from './Data.module.scss'
import api from './api'
import store from 'store'
import { addContextDataObject } from './actions'

import { Cell, setCellStyle } from './AgGridCellValidator'

const StandByPredictionsTable = (props) => {
  const { data } = props
  const { is_editable, changedCells, validation_errors } = data

  const [gridApi, setGridApi] = useState(null)

  const frameworkComponents = {
    Cell: Cell,
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
  }
  const colDefs = [
    { field: 'date', editable: false, valueParser: (d) => d.value, minWidth: 90 },
    {
      field: 'CP',
      children: [
        { field: 'CP_ESE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'CP_LSE', cellRendererParams: { validation_errors, changedCells } },
      ],
    },
    {
      field: 'FO',
      children: [
        { field: 'FO_ESE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'FO_LSE', cellRendererParams: { validation_errors, changedCells } },
      ],
    },
    {
      field: 'CCM',
      children: [
        { field: 'CCM_ESE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'CCM_LSE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'CCM_S4E', cellRendererParams: { validation_errors, changedCells } },
        { field: 'CCM_S4L', cellRendererParams: { validation_errors, changedCells } },
      ],
    },
    {
      field: 'SCC',
      children: [
        { field: 'SCC_ESE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'SCC_LSE', cellRendererParams: { validation_errors, changedCells } },
        { field: 'SCC_S4E', cellRendererParams: { validation_errors, changedCells } },
        { field: 'SCC_S4L', cellRendererParams: { validation_errors, changedCells } },
      ],
    },
  ]

  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs)
    if (gridApi) gridApi.redrawRows({ force: true })
  }, [data, changedCells, validation_errors])

  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        onGridReady={onGridReady}
        frameworkComponents={frameworkComponents}
        onCellValueChanged={(e) => {
          gridApi.clearFocusedCell()

          let newData = _.cloneDeep(data)
          let newItems = _.cloneDeep(data.payload.items)
          newItems[e.rowIndex] = e.data
          let errors = _.cloneDeep(validation_errors)
          if (errors?.items?.[e.rowIndex]?.[e.colDef.field]) errors.items[e.rowIndex][e.colDef.field] = []

          let newChangedCells
          if (!changedCells) newChangedCells = {}
          else newChangedCells = _.cloneDeep(changedCells)
          if (!newChangedCells[e.rowIndex]) newChangedCells[e.rowIndex] = {}
          if (!newChangedCells[e.rowIndex][e.colDef.field]) newChangedCells[e.rowIndex][e.colDef.field] = true

          store.dispatch(
            addContextDataObject({
              ...newData,
              changedCells: newChangedCells,
              validation_errors: errors,
              dirty: true,
              payload: { items: newItems },
            })
          )
        }}
        immutableData={true}
        getRowNodeId={(data) => data.date}
        rowClass={styles.rowClass}
        rowData={data.payload.items}
        columnDefs={colDefs}
        defaultColDef={{
          editable: is_editable,
          cellClass: setCellStyle,
          cellRendererSelector: (props) =>
            props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(' ')
              ? { component: 'Cell' }
              : undefined,
          flex: 1,
          valueParser: (d) => (!isNaN(Number(d.newValue)) ? Number(d.newValue) : d.newValue),
        }}></AgGridReact>
    </div>
  )
}
const StandbyPredictions = (props) => {
  const { data } = props
  const { is_editable } = data
  return (
    <DataObject
      data={data}
      upload="csv"
      title={intl.t('standby_predictions')}
      onSave={props.onSave}
      is_editable={is_editable}
      onChange={(data) => store.dispatch(addContextDataObject(data))}
      onUndo={() => api.getDataObject(data.id)}
      nofilter>
      <StandByPredictionsTable {...props} />
    </DataObject>
  )
}
export default StandbyPredictions
