import React, { useEffect, useState, useRef } from 'react'
import { IssueTag } from '../Issue/Issue'
import styles2 from '../CrewMember.module.scss'
import store from 'store'
import { useSelector } from 'react-redux'
import styles from './CrewMemberLabel.module.scss'
import { SAFEcolorScale } from '../SafeCare'
import { Button, Position } from '@blueprintjs/core'
import { Tooltip2, Popover2InteractionKind } from '@blueprintjs/popover2'
import tooltipStyles from '../Activity/Tooltips.module.scss'
import { iconCrew, iconBed } from 'icons'
import { Icon } from '@blueprintjs/core'
import * as d3 from 'd3'
import { setSelectedCrewMembers, setExpanded } from 'publication/actions'
import { ELEVATION_0 } from '@blueprintjs/core/lib/esm/common/classes'

const Label = ({ label, text }) => (
  <div>
    <span className={tooltipStyles.label}>{label}</span>
    <span>{text}</span>
  </div>
)

const EmployeeTooltip = ({ d }) => (
  <div className={tooltipStyles.tooltipLong}>
    <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <strong style={{ flex: 1 }}>{d.employeeCode} </strong>
      <span>
        <span style={{ marginRight: 5 }}>{d.crewFunction}</span>
        <Icon size={24} icon={iconCrew[d.crewFunction]} />
      </span>
    </span>
    <div className={tooltipStyles.line} />
    <Label label="Home Base" text={d.homeBase} />
    <Label label="Contract Bases" text={d.contractBase?.join(', ')} />
    {d.specialFunctionsAtStartOfPublication && (
      <Label label="Special Functions" text={d.specialFunctionsAtStartOfPublication.join(', ')} />
    )}
    <Label
      label="Aircraft Qualifications"
      text={[...new Set(d.aircraftQualifications?.map((d) => d.qualificationCode))]
        .map((code) => d.aircraftQualifications?.find((e) => e.qualificationCode === code))

        .sort((e) => (e.recent === false ? -1 : 1))

        .map((e, i) => (
          <span key={i} className={e.recent ? undefined : styles.qualCodeNonRecent}>
            {i > 0 ? ' - ' : ''}
            {e.qualificationCode}
          </span>
        ))}
    />
    {d.safeCare?.averageFatigue > 0 && <Label label="Average Fatigue" text={d.safeCare.averageFatigue} />}
    {d.safeCare?.maximumFatigue > 0 && <Label label="Maximum Fatigue" text={d.safeCare.maximumFatigue} />}
    {
      <Label
        label="Block Hours"
        text={
          d.equalDistributionMetrics?.blockHoursTotal?.actual >= 0
            ? `${d3.format('1.1f')(d.equalDistributionMetrics?.blockHoursTotal?.actual)} (Target was ${d3.format(
                '1.1f'
              )(d.equalDistributionMetrics?.blockHoursTotal?.target)})`
            : '-'
        }
      />
    }
    {
      <Label
        label="Hotel Stays"
        text={
          d.equalDistributionMetrics?.hotelStaysTotal?.actual >= 0
            ? `${d3.format('1.1f')(d.equalDistributionMetrics?.hotelStaysTotal?.actual)} (Target was ${d3.format(
                '1.1f'
              )(d.equalDistributionMetrics?.hotelStaysTotal?.target)})`
            : '-'
        }
      />
    }
    {[...d.rosterIssues, ...d.dataIssues].length > 0 && (
      <>
        <div className={tooltipStyles.line} />
        {[...d.rosterIssues, ...d.dataIssues].map((d, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            <IssueTag d={d} />
          </div>
        ))}
      </>
    )}
  </div>
)

const CrewMemberLabel = (props) => {
  const d = props.data
  const selectedCrew = useSelector((state) => state.rosters.selectedCrewMembers)

  return (
    <div
      onClick={() => {
        store.dispatch(setSelectedCrewMembers(d.employeeCode))
        if (props.onClick) props.onClick(d)
      }}
      style={{ position: 'relative', left: 0, height: 34, right: 0 }}
      className={[
        styles.rosterLabel,
        props.expanded ? styles2.expanded : undefined,
        d.odd ? styles2.odd : styles2.even,
        props.hover ? styles2.hover : undefined,

        selectedCrew.includes(d.employeeCode) ? styles.selected : undefined,
      ].join(' ')}
      onMouseEnter={() => props.onHover(d.employeeCode)}
      onMouseLeave={() => props.onHover(null)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'self-start',
          width: '100%',
          top: 0,
          height: '100%',
        }}>
        <span style={{ marginLeft: 5 }}>
          <div
            style={{
              fontWeight: 'bold',
              marginRight: 15,
              width: 30,
              fontSize: 12,
              marginBottom: 4,

              color: [...(d.rosterIssues || []), ...(d.dataIssues || [])].length > 0 ? 'red' : undefined,
            }}>
            {d.employeeCode}
          </div>

          <div style={{ color: '#aaa' }}>{d.crewFunction}</div>
        </span>
        <span style={{ flex: '0 0 auto', marginRight: 10, width: 70 }}>
          <div style={{ marginBottom: 4 }}>{d.homeBase}</div>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <i>{d.contractBase?.filter((b) => b !== d.homeBase).join(', ')}</i> &nbsp;
          </div>
        </span>
        <span style={{ flex: '0 0 auto', marginRight: 5, width: 50 }}>
          <div style={{ color: '#aaa', marginBottom: 4 }}>BH</div>
          <div>
            {d.equalDistributionMetrics?.blockHoursTotal?.actual >= 0 && (
              <>
                <strong>{d3.format('1.1f')(d.equalDistributionMetrics?.blockHoursTotal?.actual)}</strong>/
                {d3.format('1.1f')(d.equalDistributionMetrics?.blockHoursTotal?.target)}
              </>
            )}
            &nbsp;
          </div>
        </span>

        <span style={{ flex: '0 0 auto', marginRight: 5, width: 50 }}>
          <div style={{ color: '#aaa', marginBottom: 4 }}>HS</div>
          <div>
            {d.equalDistributionMetrics?.hotelStaysTotal?.actual >= 0 && (
              <>
                <strong>{d3.format('1.1f')(d.equalDistributionMetrics?.hotelStaysTotal?.actual)}</strong>/
                {d3.format('1.1f')(d.equalDistributionMetrics?.hotelStaysTotal?.target)}
              </>
            )}
            &nbsp;
          </div>
        </span>
        <span style={{ flex: '0 0 auto', marginRight: 10, width: 50 }}>
          <div style={{ color: '#aaa', marginBottom: 4, display: 'flex' }}>
            SC
            {d.safeCare?.averageFatigue > 0 && (
              <span
                style={{
                  flex: '0 0 auto',
                  borderTopLeftRadius: 4.5,
                  borderBottomLeftRadius: 4,
                  marginLeft: 4,
                  width: 4,
                  height: 8,
                  backgroundColor: SAFEcolorScale(d.safeCare?.averageFatigue),
                }}
              />
            )}
            {d.safeCare?.maximumFatigue > 0 && (
              <span
                style={{
                  flex: '0 0 auto',
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  width: 4,
                  height: 8,
                  backgroundColor: SAFEcolorScale(d.safeCare?.maximumFatigue),
                }}
              />
            )}
          </div>
          <div>
            {d.safeCare?.averageFatigue > 0 && (
              <>
                {d3.format('1.1f')(d.safeCare?.averageFatigue)}/{d3.format('1.1f')(d.safeCare?.maximumFatigue)}
              </>
            )}
            &nbsp;
          </div>
        </span>
        <span style={{ flex: '0 0 auto', marginRight: 10, width: 50 }}></span>
      </div>
      <Tooltip2
        usePortal={true}
        disabled={props.dragging}
        portalContainer={document.getElementById('main')}
        popoverProps={{ className: styles.hoverLabel }}
        content={<EmployeeTooltip d={d} placement={Position.RIGHT} />}
        renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => (
          <div
            {...tooltipProps}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}></div>
        )}
      />
    </div>
  )
}
export default React.memo(CrewMemberLabel)
