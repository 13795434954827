import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Position } from '@blueprintjs/core'
import { useSelector } from 'react-redux'
import store from 'store'
import { changeFilter } from '../../../actions'
import { Tooltip2 } from '@blueprintjs/popover2'
import styles from './Activity.module.scss'
import ActivityTooltipHtml from './Tooltips'
import { useDraggable, DragOverlay } from '@dnd-kit/core'
import { restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
/**
 * The total number of uncovered roles from a FlightCoverageIssue's description.
 * @param {string} description
 * @returns {number}
 */
const numUncoveredRoles = (description) => {
  // From a string "Impossible to schedule: 1 CCM, 2 SCC", extract the total number of
  // roles by summing the individual components.
  const re1 = /^Impossible to schedule: (.+)$/
  const re2 = /^Missing crew: (.+)$/
  const rem = re2.exec(description) || re1.exec(description)
  if (rem) {
    return rem[1]
      .split(',')
      .map((d) => {
        return d.trim().split(' ')[0]
      })
      .reduce((acc, curr) => acc + Number(curr), 0)
  }
  return 0
}

const Activity = (props) => {
  const [open, setOpen] = useState(false)
  const { zone, errors, filteredCrew } = props

  const d = props.data
  const flightID = d?.flightID
  const error = useMemo(() => errors?.find((d) => d.flight.activityID === props.data.activityID), [errors])
  const errorStyle = useMemo(() => {
    if (error?.issueType === 'FlightCoverageIssue') {
      const n = numUncoveredRoles(error.description)
      if (n == 1) {
        return styles.numUncoveredRolesLow
      } else if (n > 1 && n <= 3) {
        return styles.numUncoveredRolesMed
      } else if (n > 3) {
        return styles.numUncoveredRolesHigh
      }
    } else if (error) {
      return styles.error
    }
  }, [error])

  // show tooltip on mouse over, unless dragging
  const onMouseEnter = useCallback(() => setOpen(true), [])

  const onMouseLeave = useCallback(() => setOpen(false), [])

  const onClick = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (!flightID) return

      if (e.detail === 2) {
        // setScrollState({ x, y })
        let newSelectedCrew = props.rosterData
          .filter((d) => d.crewFunction !== 'Plane')
          .filter((d) => d.activities.some((e) => e.flightID === flightID || e.activityID === flightID))
          .map((d) => d.employeeCode)

        if (e.ctrlKey) {
          newSelectedCrew = [...new Set(filteredCrew.concat(newSelectedCrew))]
          //add crew to selection
        }

        store.dispatch(
          changeFilter({
            searchPlCode: newSelectedCrew,
          })
        )
      }
    },
    [d.flightID, filteredCrew]
    //[d.flightID]
  )
  // remove tooltip when dragging
  // useEffect(() => {
  //   if (props.dragging && open) setOpen(false)
  // }, [props.dragging])

  const className = useMemo(
    () =>
      [
        styles.activity,
        errorStyle,
        d.preAssigned && props.expanded ? styles.preAssignedFlag : undefined,
        styles[d.typeOfActivity],
        d.crewRole !== d.crewFunction ? styles.striped : undefined,

        d.isExtendedDuty
          ? d.hasInFlightRest
            ? styles.extendedDutyWithInFlightRest
            : styles.extendedDutyWithoutInFlightRest
          : undefined,
      ].join(' '),
    [styles, props.expanded, errorStyle, d]
  )

  const enoughPlaceForActivityCode = React.useMemo(
    () => d.endTimeX - d.startTimeX > 50 && d.activityCode,
    [d.startTimeX, d.endTimeX, d.activityCode]
  )

  const enoughPlaceForActivityLabel = React.useMemo(
    () =>
      d.endTimeX - d.startTimeX > 30 &&
      d.crewFunction &&
      d.activityLabel === 'Plane'[(d.startTimeX, d.endTimeX, d.crewFunction, d.activityLabel)]
  )

  const style = useMemo(() => {
    return {
      transform: `translateX(${d.startTimeX}px)`,

      position: 'absolute',
      pointerEvents: props.dragging ? 'none' : 'auto',
      //pointerEvents: props.dragging ? 'none' : 'unset',
      width: d.endTimeX - d.startTimeX,
      cursor: props.onClick ? 'pointer' : undefined,
    }
  }, [d, props.onClick, props.dragging])

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} className={className} style={style}>
      {enoughPlaceForActivityCode && <span className={styles.activityLabel}>{d.activityCode}</span>}
      {enoughPlaceForActivityLabel || props.edit ? d.activityLabel : null}
      {open && !props.dragging && !props.draggedItem && (
        <Tooltip2
          autoFocus={false}
          usePortal={true}
          portalContainer={document.getElementById('main')}
          portalClassName={styles.noevents}
          content={<ActivityTooltipHtml d={d} zone={zone} error={error} />}
          defaultIsOpen
          position={Position.BOTTOM}
          targetClassName={styles.tooltip}
          renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => <div style={{ height: 6 }} {...tooltipProps} />}
        />
      )}
    </div>
  )
}

export default React.memo(Activity)
