import store from 'store'
import React from 'react'
import api from 'auth/api'
import * as _ from 'lodash'
import { AppToaster } from 'shared/Toaster/Toaster'
import handleErrors from './handleErrors'
import Cookie from 'js-cookie'
import { useAuth } from 'auth/auth'
import { Text, Pre } from '@blueprintjs/core'
const myFileUpload = async (url, configuration) => {
  const auth = useAuth()
  if (!auth) await api.trySilentRefresh()
  const formData = new FormData()
  formData.append('file', new Blob([configuration.body], { type: configuration.type }))
  const token = Cookie.get('accessToken')
  const headers = new Headers({
    Authorization: `bearer ${token}`,
  })

  const newConfiguration = {
    body: formData,
    headers,
    method: 'POST',
  }
  const result = await fetch(url, newConfiguration)
  if (result.ok) {
    AppToaster.show({
      intent: 'success',
      autoFocus: false,
      icon: 'error',
      timeout: 1000,
      message: <span>File uploaded</span>,
    })
    return result.json()
  } else {
    const json = await result.json()
    if (_.isString(json.detail)) {
      AppToaster.show({
        intent: 'danger',
        className: 'not-found-toast',
        autoFocus: false,
        icon: 'error',
        timeout: 0,
        message: <span>{json.detail}</span>,
      })
    } else {
      AppToaster.show({
        intent: 'danger',
        className: 'not-found-toast',
        autoFocus: false,
        icon: 'error',
        timeout: 0,
        message: (
          <>
            <Text>{json.message || 'Error uploading file'}</Text>
            <Pre>{JSON.stringify(json.detail, null, 1)}</Pre>
          </>
        ),
      })
    }
    return null
  }
}

export default myFileUpload
