import React, { useState, useEffect } from 'react'

import { FormGroup, Card, Callout, H5, H6, Tag, Icon, Classes, Button, Divider, Switch } from '@blueprintjs/core'
import InputText from 'shared/Forms/InputText'
import InputNumber from 'shared/Forms/InputNumber'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import T19 from './defaults/T19.json'
import T37 from './defaults/T37.json'
import T38 from './defaults/T38.json'
import T88 from './defaults/T88.json'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'

const flightDefaults = {
  T19: T19,
  T37: T37,
  T38: T38,
  T88: T88,
}

const updateCrewComplement = (data, crewFunction, crewNumber) => {
  const newData = JSON.parse(JSON.stringify(data || []))
  let d = newData.find((d) => d.crewFunction === crewFunction)
  if (d) d.crewNumber = parseInt(crewNumber)

  return newData
}
const AirplaneDialog = (props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(null)

  useEffect(() => {
    props.data ? setIsOpen(true) : setIsOpen(null)
  }, [props.data])

  return (
    <CreateOrSaveDialog
      usePortal={false}
      icon="timeline-events"
      title="Aircraft"
      edit={props.data === 'new' ? null : props.data}
      resetObject={{}}
      onDelete={props.onDelete}
      onClose={(d) => {
        setIsOpen(null)
        props.onClose ? props.onClose(d) : undefined
      }}
      onCreate={(data) => {
        setIsOpen(null)
        const newData = []

        for (let i = 0; i < (data.count || 1); i++)
          newData.push({
            ...data,
            crewFunction: 'Plane',
            personalID: null,

            employeeCode: data.aircraftRegistration + (i + 1),
            aircraftRegistration: data.aircraftRegistration + (i + 1),
            aircraftType: data.aircraftType,
            activities: [],
            flight_duties: [],
            rosterIssues: [],
            dataIssues: [],
            labels: [],
            flightDutyLabels: [],
            airportQualifications: [],
            aircraftQualifications: [],
          })

        props.onCreate(newData)
      }}
      onSave={props.onSave}
      isOpen={isOpen}>
      {(data, setData) => {
        return (
          <>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Type</span>} inline>
              <SelectDropdown
                width={100}
                items={Object.keys(flightDefaults)}
                hideFilter
                activeItem={data?.aircraftType}
                onChange={(value) => {
                  setData({ ...data, ...flightDefaults[value] })
                }}
              />
              <InputText disabled style={{ width: 100 }} value={data?.aircraftIATAType} />
              <InputText disabled style={{ width: 100 }} value={data?.aircraftIATASubType} />
            </FormGroup>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Registration</span>} inline>
              <InputText
                style={{ width: 100 }}
                value={data?.aircraftRegistration}
                onChange={(value) => setData({ ...data, aircraftRegistration: value })}
              />
            </FormGroup>

            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Home Base</span>} inline>
              <AirportDropdown
                activeItem={data?.homeBase}
                onChange={(base) => setData({ ...data, homeBase: base.iata })}
              />
            </FormGroup>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Crew Complement</span>} inline>
              <div>
                <InputNumber
                  small
                  leftElement={<Button disabled text="CP" />}
                  style={{ width: 30 }}
                  value={data?.crewComplement?.find((d) => d.crewFunction === 'CP')?.crewNumber}
                  onChange={(value) =>
                    setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'CP', value) })
                  }
                />
                <InputNumber
                  small
                  leftElement={<Button disabled text="FO" />}
                  style={{ width: 30 }}
                  value={data?.crewComplement?.find((d) => d.crewFunction === 'FO')?.crewNumber}
                  onChange={(value) =>
                    setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'FO', value) })
                  }
                />

                <InputNumber
                  small
                  leftElement={<Button text="RP" disabled />}
                  style={{ width: 30 }}
                  value={data?.crewComplement?.find((d) => d.crewFunction === 'RP')?.crewNumber}
                  onChange={(value) =>
                    setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'RP', value) })
                  }
                />
              </div>
              <InputNumber
                small
                leftElement={<Button disabled text="SCC" />}
                style={{ width: 30 }}
                value={data?.crewComplement?.find((d) => d.crewFunction === 'SCC')?.crewNumber}
                onChange={(value) =>
                  setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'SCC', value) })
                }
              />
              <InputNumber
                small
                leftElement={<Button disabled text="ASC" />}
                style={{ width: 30 }}
                value={data?.crewComplement?.find((d) => d.crewFunction === 'ASC')?.crewNumber}
                onChange={(value) =>
                  setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'ASC', value) })
                }
              />
              <InputNumber
                small
                leftElement={<Button disabled text="CCM" />}
                style={{ width: 30 }}
                value={data?.crewComplement?.find((d) => d.crewFunction === 'CCM')?.crewNumber}
                onChange={(value) =>
                  setData({ ...data, crewComplement: updateCrewComplement(data?.crewComplement, 'CCM', value) })
                }
              />
            </FormGroup>
            {props.data === 'new' && (
              <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Count</span>} inline>
                <InputText
                  style={{ width: 30 }}
                  value={data?.count || 1}
                  onChange={(c) => setData({ ...data, count: c })}
                />
              </FormGroup>
            )}
          </>
        )
      }}
    </CreateOrSaveDialog>
  )
}

export default AirplaneDialog
