import React, { useState } from 'react'
import { Button, Spinner } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

const tooltip = (tooltip, button) =>
  tooltip ? (
    <Tooltip2
      intent={button.props.intent ? button.props.intent : 'primary'}
      content={tooltip}
      portalContainer={document.getElementById('main')}
      placement="auto">
      {button}
    </Tooltip2>
  ) : (
    button
  )

const PowerButton = (props) => {
  const [waiting, setWaiting] = useState(false)

  return tooltip(
    props.tooltip,
    <Button
      {...props}
      icon={waiting ? <Spinner size={15} /> : props.icon}
      disabled={waiting ? true : props.disabled}
      onClick={(e) => {
        if (props.disable_on_click) setWaiting(true)
        props.onClick(e)
      }}
    />
  )
}

export default PowerButton
