import React, { useRef, useState } from 'react'
import { Card } from '@blueprintjs/core'
import Plot from 'react-plotly.js'
import * as d3 from 'd3'

import ReactDOMServer from 'react-dom/server'
const boxLabels = ['median', 'q1', 'q3', 'min', 'max', 'upper fence']
const MpPlotWrapper = ({ children }) => (
  <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', paddingLeft: 20, paddingTop: 20 }}>{children}</div>
)
const mousemove = (e) => {
  d3.select('#tooltip')
    .style('left', 10 + e.pageX + 'px')
    .style('top', 10 + e.pageY + 'px')
}

const getTooltip = (p, i, l) => {
  switch (p.data.type) {
    case 'bar':
      return p.data.label + ': ' + p.data.y[p.pointIndex] || 0
    case 'histogram':
      return p.x + ' employees: ' + p.data.customdata[Math.floor(p.y)]
    case 'box':
      return (l === 1 ? 'value: ' : boxLabels[i]) + ': ' + p.y || 0
    default:
      return 'test'
  }
}
const MpPlot = (props) => {
  const [revision, setRevision] = useState(0)
  const mainEl = useRef(null)
  const newProps = {
    ...props,

    layout: {
      ...props.layout,
      paper_bgcolor: '#ffffff00',
      plot_bgcolor: '#ffffff00',
      margin: { l: 20, r: 0, t: 30, b: 40, pad: 20 },
      showlegend: false,

      legend: { y: 0, x: 0.5, xanchor: 'center', orientation: 'h' },
      modebar: {
        remove: ['lasso', 'pan', 'autoscale', 'zoom', 'zoomin', 'zoomout', 'resetViews', 'select', 'resetScale2d'],
      },
    },
  }

  return (
    <Card ref={mainEl} style={{ minWidth: '300px', flex: '1 1 0', marginRight: 20, marginBottom: 20 }}>
      <div
        style={{
          color: '#aaa',
          fontWeight: 'bold',
          fontSize: 16,
          textTransform: 'uppercase',
          marginBottom: -20,
          zIndex: 1,
        }}>
        {props.title}
      </div>

      {newProps.data?.map((d) => d.y).flat().length === 0 && (
        <div
          style={{
            height: 300,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#aaa',
          }}>
          {props.no_data_message || 'Select Data'}
        </div>
      )}
      {newProps.data?.map((d) => d.y).flat().length > 0 && (
        <Plot
          {...newProps}
          onPurge={(figure, div) => {
            div.removeEventListener('mousemove', mousemove)
          }}
          onInitialized={(figure, div) => {
            div.addEventListener('mousemove', mousemove)
          }}
          useResizeHandler={true}
          revision={revision}
          style={{ width: '100%', height: '100%', zIndex: 0 }}
          onHover={(data) => {
            if (props.tooltip) {
              let stringToRender = ReactDOMServer.renderToStaticMarkup(<props.tooltip data={data} />)

              d3.select('#tooltip').style('display', 'block').html(stringToRender)
            }
            data.points.forEach((p) => {
              p.data.marker.opacity =
                p.data.type === 'histogram'
                  ? Array.from({ length: p.fullData.ybins.end }, (v, k) => 0.7)
                  : Array.from({ length: p.data.y.length }, (v, k) => 0.7)

              p.data.marker.opacity[p.data.type === 'histogram' ? p.binNumber : p.pointIndex] = 1
            })

            setRevision(revision + 1)
          }}
          onUnhover={(data) => {
            if (props.tooltip) {
              d3.select('#tooltip').style('display', 'none')
            }
            data.points.forEach((p) => {
              p.data.marker.opacity = 0.7
            })
            setRevision(revision + 1)
          }}
        />
      )}
      <div
        style={{
          position: 'fixed',
          padding: 5,
          borderRadius: 5,
          boxShadow: '3px 3px 3px #00000055',
          backgroundColor: '#ffffffdd ',
          pointerEvents: 'none',
          display: 'none',
          zIndex: 1000,
          border: '1px solid #00000080',
        }}
        id="tooltip"></div>
    </Card>
  )
}

export { MpPlotWrapper, MpPlot }
