import React, { useState, useEffect } from 'react'
import { Dialog, Classes, Button, Callout } from '@blueprintjs/core'

const AsyncFunction = (async () => {}).constructor

const CreateOrSaveDialog = (props) => {
  const [data, setData] = useState(props.edit || props.resetObject)
  const [error, setError] = useState(null)

  useEffect(() => {
    setData(props.edit || props.resetObject)
  }, [props.edit, props.isOpen])

  const closeAction = () => {
    setError(null)

    if (props.edit) setData(props.edit)
    else setData(props.resetObject)
    props.onClose()
  }
  const deleteAction = () => {
    props.onDelete(props.edit)
    closeAction()
  }
  const saveAction = () => {
    setError(null)
    if (props.onCreate instanceof AsyncFunction && AsyncFunction !== Function) {
      props
        .onSave(data)
        .then((err) => {
          if (!err) props.onClose()
        })
        .catch((err) => {
          setError(err)
        })
    } else {
      props.onSave(data)
      props.onClose()
    }
  }
  const createAction = () => {
    setError(null)
    // if (true) {
    Promise.resolve(props.onCreate(data))
      .then((err) => {
        if (!err) props.onClose()
      })
      .catch((err) => {
        setError(err)
      })
  }

  return (
    <Dialog
      {...props}
      usePortal={true}
      portalContainer={document.getElementById('main')}
      isOpen={props.isOpen !== null}
      canEscapeKeyClose={false}
      onClose={() => {
        setError(false)
        return props.onClose()
      }}
      title={props.edit ? 'Edit ' + props.title : 'Create new ' + props.title}>
      <div className={Classes.DIALOG_BODY}>
        {props.children(data, setData, error)}
        {error && <Callout intent="danger">Validation Error. Please review the highlighted fields</Callout>}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {props.edit && props.onDelete && (
            <Button
              intent="danger"
              style={{ marginRight: 'auto', marginLeft: 0 }}
              icon="trash"
              onClick={deleteAction}
              text="Delete"
            />
          )}
          <Button onClick={closeAction} text="Cancel" />
          <Button
            icon="tick"
            disabled={props.validate ? !props.validate(data) : false}
            intent="primary"
            onClick={() => {
              props.edit ? saveAction() : createAction()
            }}
            text={props.edit ? 'Save' : 'Create'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateOrSaveDialog
