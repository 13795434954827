import React, { useState, useEffect } from 'react'

import DataObject from './DataObject.js'
import { intl } from 'i18n/IntlGlobalProvider'
import { Card, Icon, Button, H5, InputGroup, FormGroup, Dialog, Classes } from '@blueprintjs/core'

import Search from 'shared/search'
import styles from './Data.module.scss'
import CreateOrSaveDialog from './DataObjectItemDialog'
import Validate from './Validate.js'
import InputEmployee from 'shared/Forms/InputEmployee'
import * as _ from 'lodash'
import ErrorBoundary from 'log/ErrorBoundary.js'
import store from 'store'
import { addContextDataObject } from './actions'
import api from './api'
const newWeekendTogether = {
  person1: '',
  person2: '',
}

const CreateNewRequest = (props) => {
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]
  const [isInvalid, setIsInvalid] = useState(false)
  useEffect(() => setIsInvalid(props.isOpen === 'new'), props.isOpen)
  return (
    <CreateOrSaveDialog
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Weekend together"
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      isInvalid={isInvalid}
      resetObject={newWeekendTogether}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => (
        <div>
          <FormGroup label="Employee 1" inline>
            <InputEmployee
              error={error?.person1}
              value={data?.person1}
              onChange={(person1, invalid) => {
                let newData = { ...data, person1 }
                setData(newData)
                setIsInvalid(newData.person1.length !== 3 || newData.person2.length !== 3)
              }}
            />
          </FormGroup>
          <FormGroup label="Employee 2" inline>
            <InputEmployee
              error={error?.person2}
              value={data?.person2}
              onChange={(person2, invalid) => {
                let newData = { ...data, person2 }
                setData(newData)
                setIsInvalid(newData.person1.length !== 3 || newData.person2.length !== 3)
              }}
            />
          </FormGroup>
        </div>
      )}
    </CreateOrSaveDialog>
  )
}

const WeekendTogether = ({ is_editable, data, search, i, onClick }) => {
  return (
    <>
      {data.payload.items.map((d, i) => (
        <Card
          style={{
            margin: 10,
            display:
              d.person1?.toUpperCase().includes(search.toUpperCase()) ||
              d.person2?.toUpperCase().includes(search.toUpperCase())
                ? 'block'
                : 'none',
          }}
          className={styles.card}
          key={i}
          onClick={
            is_editable
              ? (e) => {
                  e.stopPropagation()
                  onClick(i)
                }
              : undefined
          }
          interactive={is_editable}>
          <H5 style={{ display: 'inline-block', margin: 0 }}>
            {d.person1?.toUpperCase()} - {d.person2?.toUpperCase()}
          </H5>
        </Card>
      ))}
    </>
  )
}
const Comp = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)
  const { data } = props
  const { is_editable } = data

  return (
    <>
      <CreateNewRequest
        validation_errors={data.validation_errors?.items}
        isOpen={dialog}
        dataObject={data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={data}
        upload="json"
        title={intl.t('weekends_together')}
        search={<Search placeholder="Search" value={search} onChange={(value) => setSearch(value)} />}
        onSave={props.onSave}
        onAdd={() => setDialog('new')}
        onChange={(data) => store.dispatch(addContextDataObject(data))}
        onUndo={() => api.getDataObject(data.id)}
        is_editable={is_editable}
        nofilter>
        <WeekendTogether is_editable={is_editable} data={data} search={search} onClick={setDialog} />
      </DataObject>
    </>
  )
}

export default Comp
