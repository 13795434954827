import React, { useEffect, useState } from 'react'

import { MpPlot, MpPlotWrapper } from '../MpPlot'
import { useSelector } from 'react-redux'
import costDetails from './costDetails'
import { Card } from '@blueprintjs/core'
import MultiSearchDropdown from '../../shared/SearchDropdown/MultiSearchDropdown'
import * as d3 from 'd3'
import { barData, alphaMap } from '../barData'
import { current } from 'immer'

import { BarTooltip } from 'dashboard/Tooltip'

const Cost = () => {
  const selectedCostFields = useSelector((state) => state.reports.filter.costFields)
  const [totalsData, setTotalsData] = useState({})
  const [detailsData, setDetailsData] = useState({})
  const reports = useSelector((state) => state.reports)
  const { filter } = reports
  const rep = reports.activeReports.slice()
  const costReportsData = []
  const totalReportsData = []
  const activeReports = rep
  const softCostFields = selectedCostFields.filter((d) => costDetails[d].isSoft)
  const businessCostFields = selectedCostFields.filter((d) => !costDetails[d].isSoft)

  const totalPreassignedSoft = softCostFields.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.preAssigned ? report.payload.crewStatistics[costDetails[field].preAssigned] : 0
    )
    return {
      type: 'bar',
      hoverinfo: 'none',
      textposition: 'outside',
      offsetgroup: 1,
      label: 'pre-assigned',
      meta: { publication: activeReports.map((d) => d.title) },
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: { opacity: 0.7, color: activeReports.map((d) => d.color) },
      xaxis: 'x' + (i + 1),
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })

  const totalScheduledSoft = softCostFields.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.scheduled ? report.payload.crewStatistics[costDetails[field].scheduled] : 0
    )
    return {
      type: 'bar',
      hoverinfo: 'none',
      base: totalPreassignedSoft[i].y,
      isSoft: costDetails[field].isSoft,
      meta: { publication: activeReports.map((d) => d.title) },
      textposition: 'outside',
      offsetgroup: 1,
      label: 'optimized',
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        pattern: { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 },
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      xaxis: 'x' + (i + 1),
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })
  const totalPreassignedBusiness = businessCostFields.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.preAssigned ? report.payload.crewStatistics[costDetails[field].preAssigned] : 0
    )
    return {
      type: 'bar',
      hoverinfo: 'none',
      isSoft: costDetails[field].isSoft,
      textposition: 'outside',
      meta: { publication: activeReports.map((d) => d.title) },
      offsetgroup: 1,
      label: 'pre-assigned',
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      xaxis: 'x' + (i + 1),
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })

  const totalScheduledBusiness = businessCostFields.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.scheduled ? report.payload.crewStatistics[costDetails[field].scheduled] : 0
    )
    return {
      type: 'bar',
      hoverinfo: 'none',
      base: totalPreassignedBusiness[i].y,
      isSoft: costDetails[field].isSoft,
      textposition: 'outside',
      meta: { publication: activeReports.map((d) => d.title) },
      label: 'optimized',
      offsetgroup: 1,
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        pattern: { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 },
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      xaxis: 'x' + (i + 1),
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })
  const total = [...totalPreassignedSoft, ...totalScheduledSoft, ...totalPreassignedBusiness, ...totalScheduledBusiness]
  const totalSoft2 = totalPreassignedSoft.map((e, f) => e.y.map((d, i) => d + totalScheduledSoft[f].y[i]))
  const totalSoft = activeReports.map((report, r) => {
    return totalSoft2.reduce((sum, item) => (sum += item[r]), 0)
  })

  const totalBusiness2 = totalPreassignedBusiness.map((e, f) => e.y.map((d, i) => d + totalScheduledBusiness[f].y[i]))
  const totalBusiness = activeReports.map((report, r) => {
    return totalBusiness2.reduce((sum, item) => (sum += item[r]), 0)
  })

  const totalCostPreAssigned = activeReports.map((report) =>
    selectedCostFields.reduce((sum, field) => {
      return sum + (filter.preAssigned ? report.payload.crewStatistics[costDetails[field].preAssigned] : 0)
    }, 0)
  )
  const totalCostScheduled = activeReports.map((report) =>
    selectedCostFields.reduce((sum, field) => {
      return sum + (filter.scheduled ? report.payload.crewStatistics[costDetails[field].scheduled] : 0)
    }, 0)
  )

  return (
    <div>
      <MpPlotWrapper>
        {activeReports.map((d, i) => (
          <Card style={{ fontWeight: 'bold', fontSize: 30, marginRight: 15, color: d.color }} key={i}>
            <div style={{ color: d.color, fontSize: 14, marginBottom: 10 }}>{d.title}</div>
            <hr />

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <span style={{ flex: '1 1 0' }}>
                <div style={{ fontSize: 22 }}>{d3.format('.3~s')(totalCostScheduled[i])}</div>
                <div style={{ fontSize: 12, color: '#aaa', textTransform: 'uppercase' }}>Optimized</div>
                <div style={{ fontSize: 22 }}>{d3.format('.3~s')(totalSoft[i])}</div>
                <div style={{ fontSize: 12, color: '#aaa', textTransform: 'uppercase' }}>Soft</div>
              </span>
              <span style={{ textAlign: 'center', flex: '1 1 0' }}>
                <div style={{ fontSize: 38 }}>{d3.format('.3~s')(totalCostScheduled[i] + totalCostPreAssigned[i])}</div>
                <div style={{ fontSize: 12, color: '#aaa', textTransform: 'uppercase' }}>total</div>
              </span>
              <span style={{ textAlign: 'right', flex: '1 1 0', width: 120 }}>
                <div style={{ fontSize: 22 }}>{d3.format('.3~s')(totalCostPreAssigned[i])}</div>
                <div style={{ fontSize: 12, color: '#aaa', textTransform: 'uppercase' }}>Pre-Assigned</div>
                <div style={{ fontSize: 22 }}>{d3.format('.3~s')(totalBusiness[i])}</div>
                <div style={{ fontSize: 12, color: '#aaa', textTransform: 'uppercase' }}>Business</div>
              </span>
            </div>
          </Card>
        ))}
      </MpPlotWrapper>
      <MpPlotWrapper>
        <MpPlot
          title={'Business Costs details'}
          tooltip={BarTooltip}
          data={[...totalPreassignedBusiness, ...totalScheduledBusiness]}
          layout={{
            ...Object.fromEntries(
              businessCostFields.map((d, i) => [
                'xaxis' + (i + 1),
                { showticklabels: false, zeroline: false, title: { font: { size: 12 }, text: d }, fixedrange: true },
              ])
            ),
            height: 400,
            yaxis: {
              showgrid: true,
              zeroline: true,
              fixedrange: true,
            },
            annotations: businessCostFields
              .map((d, i) => {
                return totalBusiness2[i].map((e, f) => {
                  return {
                    y: e,
                    xref: 'x' + (i + 1),
                    x: f,
                    font: { color: activeReports[f].color },
                    yanchor: 'bottom',
                    showarrow: false,
                    text: e < 10000 ? d3.format('d')(e) : d3.format('.3~s')(e),
                  }
                })
              })
              .flat(),
            grid: { rows: 1, columns: businessCostFields.length, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />
        <MpPlot
          title={'Soft Costs Penalties'}
          tooltip={BarTooltip}
          data={[...totalPreassignedSoft, ...totalScheduledSoft]}
          layout={{
            ...Object.fromEntries(
              softCostFields.map((d, i) => [
                'xaxis' + (i + 1),
                { showticklabels: false, zeroline: false, title: { font: { size: 12 }, text: d }, fixedrange: true },
              ])
            ),
            height: 400,
            yaxis: {
              showgrid: true,
              zeroline: true,
              fixedrange: true,
            },
            annotations: softCostFields
              .map((d, i) => {
                return totalSoft2[i].map((e, f) => {
                  return {
                    y: e,
                    xref: 'x' + (i + 1),
                    x: f,
                    font: { color: activeReports[f].color },
                    yanchor: 'bottom',
                    showarrow: false,
                    text: e < 10000 ? d3.format('d')(e) : d3.format('.3~s')(e),
                  }
                })
              })
              .flat(),
            grid: { horizontal_spacing: 1, rows: 1, columns: softCostFields.length, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />
      </MpPlotWrapper>
    </div>
  )
}

export default Cost
