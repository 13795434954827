import { DateTime, Interval } from 'luxon'
import { startOf, endOf, getTicksInInterval } from 'shared/LuxonHelpers'

import React, { useMemo, useState, useRef } from 'react'

const Weekend = (props) => {
  const x = props.xScale
  const d = props.data

  const width = React.useMemo(() => x(3600 * 24 * 1000 * 2) - x(0), [x])

  const style = useMemo(() => {
    return {
      transform: `translateX(${d}px)`,
      top: 0,
      //    willChange: 'transform',
      width: width,
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.05)',
      pointerEvents: 'none',
      zIndex: 1,
    }
  }, [x, d, props.x])
  return <div className="weekend" key={d} style={style} />
}

export default React.memo(Weekend)
