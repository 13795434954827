import React, { useState } from 'react'
import { Button, Spinner } from '@blueprintjs/core'

const LoadingButton = (props) => {
  const [waiting, setWaiting] = useState(false)

  return (
    <Button
      {...props}
      icon={waiting ? <Spinner size={15} /> : props.icon}
      disabled={waiting ? true : props.disabled}
      onClick={async () => {
        setWaiting(true)
        await props.onClick()
        setWaiting(false)
      }}
    />
  )
}

export default LoadingButton
