import React, { Component, useRef, useEffect, useCallback, useState } from 'react'
import { Card, Alignment, Icon, ControlGroup, Button } from '@blueprintjs/core'
import { Row, Rows } from 'shared/PanelLayout/PanelLayout'

import Search from 'shared/search'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'

/*
class Editor extends Component {
  state = { hits: null }
  ace = null
  data

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.data)) this.setData(nextProps.data)
    if (nextProps.search !== this.props.search) this.search(nextProps.search)
    if (nextProps.searchNext !== this.props.searchNext) this.ace.editor.findNext()
    if (nextProps.searchPrev !== this.props.searchPrev) this.ace.editor.findPrevious()
    return false
  }
  setData = (data) => {
    this.data = data

    try {
      if (this.ace) if (this.ace.editor) this.ace.editor.setValue(JSON.stringify(data, null, 2), -1)
    } catch (e) {
      console.log(e)
    }
  }

  handleChange = () => {
    try {
      let payload = JSON.parse(this.ace.editor.getValue())
      if (JSON.stringify(this.data) === JSON.stringify(payload)) {
        return
      }
      this.data = payload
      this.props.onChange(payload)
    } catch (err) {
      return
    }
  }
  search(d) {
    if (this.ace) {
      const hits = this.ace.editor.findAll(d, {
        backwards: false,
        wrap: false,
        caseSensitive: false,
        wholeWord: false,
        regExp: false,
      })

      this.setState({ hits: hits })
    }
  }
  render() {
    const { title, data, is_editable } = this.props

    return (
      <Rows>
        <Row>
          <AceEditor
            mode="json"
            theme="github"
            readOnly={!is_editable}
            debounceChangePeriod={0}
            onChange={this.handleChange}
            width="100%"
            height="100%"
            ref={(el) => {
              if (el) {
                this.ace = el
                this.ace.editor.setShowPrintMargin(false)
                this.ace.editor.focus()
                this.setData(data)
              }
            }}
          />
        </Row>
      </Rows>
    )
  }
}

export default Editor

*/

const Editor = (props) => {
  const ace = useRef()
  const [data, setData] = useState(JSON.stringify(props.data || {}, null, 2))
  // useEffect(() => setData(JSON.stringify(props.data || {}, null, 2)), [props.data])
  useEffect(() => {
    if (ace?.current) ace.current.editor.findNext()
  }, [props.searchNext])
  useEffect(() => {
    if (ace?.current) ace.current.editor.findPrevious()
  }, [props.searchPrev])
  useEffect(() => {
    if (ace?.current) {
      ace.current.editor.findAll(props.search, {
        backwards: false,
        wrap: false,
        caseSensitive: false,
        wholeWord: false,
        regExp: false,
      })
    }
  }, [props.search])

  const handleChange = (newValue) => {
    try {
      let newData = JSON.parse(newValue)
      if (newValue !== data) {
        setData(newValue)
        props.onChange(newData)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Rows>
      <Row>
        <AceEditor
          mode="json"
          theme="github"
          readOnly={!props.is_editable}
          debounceChangePeriod={0}
          onChange={handleChange}
          width="100%"
          height="100%"
          value={data}
          ref={(el) => {
            if (!ace.current && el) {
              ace.current = el
              ace.current.editor.setShowPrintMargin(false)
              ace.current.editor.focus()
            }
          }}
        />
      </Row>
    </Rows>
  )
}
export default React.memo(Editor)
