import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from '../api'
import DataObject from './DataObject.js'
import { intl } from 'i18n/IntlGlobalProvider'
import { Card, ButtonGroup, InputGroup, Tag, Button, H5, H6, FormGroup, Callout, Classes } from '@blueprintjs/core'
import moment from 'moment'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import Search from 'shared/search'
import CreateOrSaveDialog from './DataObjectItemDialog'
import styles from './Data.module.scss'
import SearchDropdown from './../shared/SearchDropdown/SearchDropdown'
import Validate from './Validate.js'
import TimezoneDropdown from 'shared/TimezoneDropdown/TimezoneDropdown'
import { InputDateRange, InputEmployee, InputWeekdays, InputTimeRange } from 'shared/Forms'
import config from 'config'
import { lux } from 'shared/LuxonHelpers'
const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const validateEmployee = (e1) => {
  var re = /^[a-zA-z]{3}$/
  return re.test(e1)
}

const newCourseConfig = {
  employeeCode: '',
  fromDate: null,
  toDate: null,
  fromTime: '',
  toTime: '',
  daysOfTheWeek: [],
  timezone: 'UTC',
  repetition: 'weekly',
}

const validateTime = (time) => {
  let re = /[0-9]{1-2}:[0-9]{1-2}/
  return re.match(time)
}

const checkIsInvalid = (data) => {
  if (data.fromDate === null) return true
  if (data.toDate === null) return true
  if (!validateEmployee(data.employeeCode)) return true
  return false
}
const CreateNewCourseRequest = (props) => {
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]

  const [isInvalid, setIsInvalid] = useState()
  useEffect(() => setIsInvalid(props.isOpen === 'new' ? true : false), [props.isOpen])
  return (
    <CreateOrSaveDialog
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Course Request"
      isInvalid={isInvalid}
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={newCourseConfig}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => (
        <>
          <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Employee</span>} inline>
            <InputEmployee
              validation_errors={error?.employeeCode}
              style={{ width: 100 }}
              value={data?.employeeCode}
              onChange={(employeeCode, invalid) => {
                const newData = {
                  ...data,
                  employeeCode,
                }
                setData(newData)
                setIsInvalid(checkIsInvalid(newData))
              }}
            />
          </FormGroup>
          <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Period </span>} inline>
            <InputDateRange
              error={[{ fromDate: error?.fromDate, toDate: error?.toDate }]}
              from={data?.fromDate}
              to={data?.toDate}
              minDate={
                config.ui.allowDatesInThePastForCourseRequests
                  ? undefined
                  : data?.fromDate
                  ? lux(data?.fromDate).toJSDate()
                  : new Date()
              }
              onChange={(dates) => {
                const newData = {
                  ...data,
                  fromDate: dates[0],
                  toDate: dates[1],
                }
                setData(newData)
                setIsInvalid(checkIsInvalid(newData))
              }}
            />
          </FormGroup>
          <FormGroup
            contentClassName={styles.full}
            label={<span style={{ width: 100, display: 'inline-block' }}>Weekdays</span>}
            inline>
            <InputWeekdays
              value={data?.daysOfTheWeek}
              onChange={(daysOfTheWeek) => setData({ ...data, daysOfTheWeek })}></InputWeekdays>
          </FormGroup>
          <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Between</span>} inline>
            <span style={{ display: 'inline-block', marginRight: 10 }}>
              <InputTimeRange
                error={{ from: error?.fromTime, to: error?.toTime }}
                from={data?.fromTime}
                to={data?.toTime}
                onChange={(from, to) => {
                  setData({ ...data, fromTime: from, toTime: to })
                }}
              />
            </span>
          </FormGroup>
          <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Timezone</span>} inline>
            <TimezoneDropdown
              text={intl.t}
              activeItem={data?.timezone}
              onChange={(d) => setData({ ...data, timezone: d.key })}
            />
          </FormGroup>
          <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Recurring</span>} inline>
            <SelectDropdown
              hideFilter
              activeItem={data?.repetition}
              width={150}
              onChange={(d) => {
                setData({ ...data, repetition: d })
              }}
              items={['weekly', 'biweekly']}
              text={(key) => intl.t(key)}
            />
          </FormGroup>
        </>
      )}
    </CreateOrSaveDialog>
  )
}
const CourseRequest = (props) => {
  const d = props.data
  const search = props.search.toUpperCase()
  const is_editable = props.is_editable

  return (
    <Card
      style={{
        display: search ? (d.employeeCode.toUpperCase().includes(search) ? 'block' : 'none') : 'block',
      }}
      onClick={() => props.onClick()}
      className={styles.card}
      elevation={1}
      interactive={is_editable}>
      <H5 style={{ flex: 0, alignSelf: 'flex-start ' }}>
        <strong>{d.employeeCode}</strong> from {d.fromTime} to {d.toTime} ({d.timezone})
      </H5>
      <H6 style={{ color: '#ccc' }}>
        {d.fromDate ? 'from ' + moment(d.fromDate, 'YYYY-MM-DD').format('D MMMM') : ''}
        {d.toDate ? ' to ' + moment(d.toDate, 'YYYY-MM-DD').format('D MMMM YYYY') : ''}
      </H6>

      <Tag style={{ marginRight: 5 }}>{intl.t(d.repetition)}</Tag>
      {d.daysOfTheWeek.map((w, i) => (
        <Tag key={i} minimal style={{ marginRight: 5 }}>
          {weekdays[w - 1]}
        </Tag>
      ))}
    </Card>
  )
}

const CourseRequests = ({ data, onClick, search }) => {
  return (
    <>
      {data.payload.items.length === 0 && (
        <Callout title="No items" style={{ margin: '20px auto', width: 300 }}>
          {data.is_editable && (
            <Button
              style={{ marginBottom: 10 }}
              fill
              icon="plus"
              text="Add New Course Request"
              onClick={() => {
                onClick('new')
              }}
            />
          )}
        </Callout>
      )}
      {data.payload.items.map((d, i) => (
        <CourseRequest
          is_editable={data.is_editable}
          data={d}
          search={search}
          key={i}
          onClick={() => {
            onClick(i)
          }}
        />
      ))}
    </>
  )
}
const Comp = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)
  const { data } = props
  const { payload, is_editable } = data

  return (
    <>
      <CreateNewCourseRequest
        validation_errors={data.validation_errors?.items}
        configuration={props.configuration}
        isOpen={dialog}
        dataObject={props.data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={props.data}
        upload="json"
        title={intl.t('course_requests')}
        is_editable={is_editable}
        search={<Search placeholder="Search" value={search} onChange={(value) => setSearch(value)} />}
        nofilter
        onSave={props.onSave}
        onAdd={() => setDialog('new')}>
        <CourseRequests data={data} onClick={(id) => (is_editable ? setDialog(id) : undefined)} search={search} />
      </DataObject>
    </>
  )
}

export default Comp
