import React, { useState, useEffect } from 'react'
import styles from './Data.module.scss'
import { Tooltip } from '@blueprintjs/core'
import api from './api'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import * as _ from 'lodash'
import { Cell, setCellStyle } from './AgGridCellValidator'
import store from 'store'
import { addContextDataObject } from './actions'

const ProductionPredictionsTable = (props) => {
  const { data } = props
  const { is_editable, changedCells, validation_errors } = data
  const [gridApi, setGridApi] = useState(null)

  const frameworkComponents = {
    Cell: Cell,
  }
  const onGridReady = (params) => {
    setGridApi(params.api)
  }

  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs)
    if (gridApi) gridApi.redrawRows({ force: true })
  }, [data, changedCells, validation_errors])
  const colDefs = [
    { field: 'date', editable: false, valueParser: (d) => d.value, minWidth: 90 },
    { field: 'CP', cellRendererParams: { validation_errors, changedCells } },
    { field: 'FO', cellRendererParams: { validation_errors, changedCells } },
    { field: 'CCM', cellRendererParams: { validation_errors, changedCells } },
    { field: 'SCC', cellRendererParams: { validation_errors, changedCells } },
  ]

  return (
    <>
      <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          context={{ validation_errors, changedCells }}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          onCellValueChanged={(e) => {
            gridApi.clearFocusedCell()
            let newData = _.cloneDeep(props.data)
            let newItems = _.cloneDeep(props.data.payload.items)

            newItems[e.rowIndex] = e.data

            let newChangedCells
            if (!changedCells) newChangedCells = {}
            else newChangedCells = _.cloneDeep(changedCells)
            if (!newChangedCells[e.rowIndex]) newChangedCells[e.rowIndex] = {}
            if (!newChangedCells[e.rowIndex][e.colDef.field]) newChangedCells[e.rowIndex][e.colDef.field] = true

            let errors = _.cloneDeep(validation_errors)
            if (errors?.items?.[e.rowIndex]?.[e.colDef.field]) errors.items[e.rowIndex][e.colDef.field] = []

            store.dispatch(
              addContextDataObject({
                ...newData,
                changedCells: newChangedCells,
                validation_errors: errors,
                dirty: true,
                payload: { items: newItems },
              })
            )
          }}
          immutableData={true}
          getRowNodeId={(data) => data.date}
          rowClass={styles.rowClass}
          rowData={data.payload.items}
          defaultColDef={{
            editable: is_editable,
            cellClass: setCellStyle,
            cellRendererSelector: (props) =>
              props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(
                ' '
              )
                ? { component: 'Cell' }
                : undefined,
            flex: 1,
            valueParser: (d) => (!isNaN(Number(d.newValue)) ? Number(d.newValue) : d.newValue),
          }}
          columnDefs={colDefs}
        />
      </div>
    </>
  )
}

const ProductionPredictions = (props) => {
  const { data } = props
  const { is_editable } = data
  return (
    <DataObject
      data={data}
      upload="json"
      title={intl.t('production_predictions')}
      onSave={props.onSave}
      is_editable={is_editable}
      onChange={(data) => store.dispatch(addContextDataObject(data))}
      onUndo={() => api.getDataObject(data.id)}
      nofilter>
      <ProductionPredictionsTable {...props} />
    </DataObject>
  )
}

export default ProductionPredictions
