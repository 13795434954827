import React, { useState, useEffect } from 'react'
import { InputGroup, Position, Tag } from '@blueprintjs/core'
import Validate from './Validate'

const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value)
}

const InputCost = (props) => {
  const { error, onChange, value, placeholder } = props
  const [internalValue, setInternalValue] = useState(value)
  useEffect(() => setInternalValue(value), [value])

  return (
    <Validate validation_errors={error} position={Position.TOP} fill={props.fill}>
      <InputGroup
        {...props}
        autoFocus={true}
        intent={error ? 'danger' : undefined}
        style={{ ...props.style, width: 100, marginRight: 10, textAlign: 'right' }}
        value={internalValue}
        placeholder={placeholder ? placeholder : 'cost'}
        onChange={({ target }) => {
          let value = isNumber(parseFloat(target.value)) ? parseFloat(target.value) : target.value
          setInternalValue(value)
          onChange(value)
        }}
      />
    </Validate>
  )
}

export default InputCost
