import React from 'react'
import styles from './Divider.module.scss'

const Divider = ({ text, style }) => (
  <div className={styles.container} style={style}>
    <span className={styles.line}>
      <hr className={styles.hr} />
    </span>
    {text && <span className={styles.text}>{text}</span>}
    {text && (
      <span className={styles.line}>
        <hr className={styles.hr} />
      </span>
    )}
  </div>
)

export default Divider
