import React from 'react'
import styles from './ThreeDots.module.scss'

const ThreeDots = (props) => {
  return (
    <div className={styles.bounce}>
      <div className={[styles.bounce1, styles.bounceDiv, props.className].join(' ')}></div>
      <div className={[styles.bounce2, styles.bounceDiv, props.className].join(' ')}></div>
      <div className={[styles.bounce3, styles.bounceDiv, props.className].join(' ')}></div>
    </div>
  )
}

export default ThreeDots
