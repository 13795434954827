import React from 'react'

const Tooltip = (props) => (
  <div style={{ padding: 10 }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          marginRright: 10,
          borderRadius: '50%',
          display: 'inline-block',
          width: 13,
          height: 13,
          backgroundColor: props.color,
          marginRight: 10,
        }}></span>
      <span style={{ fontWeight: 'bold', color: props.color }}>{props.title}</span>
    </div>
    <hr />
    {props.children}
  </div>
)

const BarTooltip = ({ data }) => (
  <Tooltip
    color={data?.points[0].data.marker.color[data.points[0].pointIndex] || 'red'}
    title={data?.points[0].data.meta?.publication[data.points[0].pointIndex] || 'title'}>
    <div style={{ fontFamily: 'monospace', width: 210 }}>
      {data?.points[0].data.label}: {data?.points[0].data.y[data.points[0].pointIndex]}
    </div>
  </Tooltip>
)

const HistogramTooltip = ({ data }) => (
  <Tooltip color={data?.points[0].data.marker.color || 'red'} title={data?.points[0].data.meta?.publication || 'title'}>
    {data.points[0].data.type === 'box' && <span></span>}
    {data.points[0].data.type !== 'box' && (
      <span>
        {data?.points[0].data.label}: {Math.floor(data?.points[0].y)}
        <br />
        count: {data?.points[0].x}
        <hr />
        <div style={{ fontFamily: 'monospace', width: 210 }}>
          {data?.points[0].pointIndices.map((i) => data?.points[0].fullData.customdata[i].employeeCode).join(' ')}
        </div>
      </span>
    )}
  </Tooltip>
)

export { BarTooltip, HistogramTooltip }
