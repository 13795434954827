export const STORE_REPORT = 'STORE_REPORT'
export const storeReport = (data) => ({
  type: STORE_REPORT,
  payload: data,
})
export const REMOVE_REPORT = 'REMOVE_REPORT'
export const removeReport = (data) => ({
  type: REMOVE_REPORT,
  payload: data,
})

export const CLEAR_REPORT = 'CLEAR_REPORT'
export const clearReport = () => ({
  type: CLEAR_REPORT,
  payload: [],
})

export const STORE_ALL_REPORT_IDS = 'GET_ALL_REPORT_IDS'
export const storeAllReportIds = (data) => ({
  type: STORE_ALL_REPORT_IDS,
  payload: data,
})

export const CHANGE_FILTER = 'CHANGE_FILTER'
export const changeFilter = (data) => ({
  type: CHANGE_FILTER,
  payload: data,
})
