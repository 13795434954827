import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './../Data.module.scss'
import { Card, H5, FormGroup, Tag, Button, Dialog, Callout, Classes, EditableText } from '@blueprintjs/core'
import Search from 'shared/search'
import DataObject from '../DataObject.js'
import { intl } from 'i18n/IntlGlobalProvider'
import CreateOrSaveDialog from '../DataObjectItemDialog'
import { InputEmployee, InputText, InputTextEditable } from 'shared/Forms'
import ConfirmButton from 'shared/ConfirmButton'
import { CrewMemberAbsences } from 'Motassign/CrewMgmt/Tables'
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group'

const EmployeeTag = (props) => {
  const [hover, setHover] = useState(false)
  const [deleteHover, setDeleteHover] = useState(false)
  const { is_editable } = props
  return (
    <span onMouseEnter={is_editable ? () => setHover(true) : undefined} onMouseLeave={() => setHover(false)}>
      <Tag
        minimal
        intent={deleteHover && is_editable ? 'danger' : undefined}
        icon={
          <Button
            disabled={!is_editable}
            onMouseEnter={is_editable ? () => setDeleteHover(true) : undefined}
            onMouseLeave={is_editable ? () => setDeleteHover(false) : undefined}
            small
            style={{ borderRadius: '100%' }}
            minimal
            intent={deleteHover && is_editable ? 'danger' : undefined}
            icon={hover && is_editable ? 'cross' : 'person'}
            onClick={props.onRemove && is_editable ? props.onRemove : undefined}
          />
        }
        large
        style={{ cursor: 'default', margin: 5, fontWeight: 'bold' }}>
        <span style={{ width: 40, display: 'inline-block' }}>{props.employeeCode}</span>
      </Tag>
    </span>
  )
}

const validateNonEmptyString = (employee) => {
  const r = /^(?!\s*$).+/
  const invalid = !r.test(employee)
  const errorMsg = 'Must not be empty'

  return invalid ? errorMsg : undefined
}

const validateFormat = (employee) => {
  const r = /^(([A-Z]{3})|(M\d{2}))$/
  const invalid = !r.test(employee)
  const errorMsg = 'Must be an uppercase 3-letter code'
  return invalid ? errorMsg : undefined
}

const validateUnique = (list) => (employee) => {
  const invalid = list?.map((d) => d.toUpperCase()).includes(employee.toUpperCase())
  const errorMsg = 'Is not unique'
  return invalid ? errorMsg : undefined
}

const Group = (props) => {
  const [hover, setHover] = useState(undefined)
  const [active, setActive] = useState(undefined)
  const [open, setOpen] = useState(false)
  const employeeValidators = [validateFormat, validateUnique(props.employees)]

  const [name, setName] = useState(props.item.groupName)
  const { item, data, onChange, onCancel, is_editable } = props
  const groupValidators = [
    validateNonEmptyString,
    validateUnique(data?.payload?.items?.map((d) => d.groupName).filter((d) => d)),
  ]

  return (
    <Card
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onDoubleClick={() => setActive(true)}
      intent="primary"
      style={{
        zIndex: onCancel ? 1001 : undefined,
        margin: 10,
        //display: item.name?.toUpperCase().includes(search.toUpperCase()) ? 'block' : 'none',
      }}
      className={[props.className, styles.card].join(' ')}
      key={props.key}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputTextEditable
          setInitialFocus={props.setInitialFocus}
          placeholder="Type group name...."
          disabled={!is_editable}
          value={name === undefined ? 'No Group Assigned' : name}
          static={name === undefined}
          validators={groupValidators}
          width={300}
          onEditStopped={() => {}}
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            color: 'black',
            marginBottom: 10,
          }}
          onChange={(e) => {
            let newData = { ...data }
            if (onCancel) newData.payload.items.unshift({ groupName: e, crew: [] })
            else {
              let i = newData.payload.items.findIndex((d) => d.groupName === item.groupName)
              newData.payload.items[i].groupName = e
            }
            onChange(newData.id, newData.payload)
          }}
        />

        <span style={{ flex: 1 }} />
        {onCancel && is_editable && (
          <Button
            icon="cross"
            text="Cancel"
            intent="danger"
            onClick={() => {
              onCancel()
            }}
          />
        )}
        {item.groupName !== undefined && hover && !onCancel && is_editable && (
          <ConfirmButton
            minimal
            icon="trash"
            dialogText={`Are you sure you want to delete ${item.groupName}`}
            intent="danger"
            onConfirm={() => {
              let newData = { ...data }
              let i = newData.payload.items.findIndex((d) => d.groupName === item.groupName)
              newData.payload.items.splice(i, 1)
              onChange(newData.id, newData.payload)
            }}
          />
        )}
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {item.crew
          .sort((a, b) => (a.employeeCode > b.employeeCode ? 1 : -1))
          .map((d, i) => (
            <EmployeeTag
              key={d.employeeCode}
              is_editable={is_editable}
              employeeCode={d.employeeCode}
              onRemove={(e) => {
                e.stopPropagation()
                e.preventDefault()
                let newData = { ...data }
                let index = newData.payload.items.findIndex((d) => d.groupName === item.groupName)
                newData.payload.items[index].crew.splice(i, 1)
                onChange(newData.id, newData.payload)
              }}
            />
          ))}
        <span style={{ margin: 5 }}>
          {!active && is_editable ? (
            <Button
              style={{ height: 34 }}
              icon="plus"
              disabled={onCancel}
              text={item.crew.length === 0 ? 'ADD MEMBERS' : undefined}
              minimal
              interactive
              intent="primary"
              onClick={() => {
                setActive(true)
                // setOpen(true)
              }}
            />
          ) : (
            <InputTextEditable
              toUpper
              autoFocus
              width={100}
              disabled={!is_editable}
              validators={employeeValidators}
              onEditStopped={() => setActive(false)}
              maxLength={3}
              onChange={(name) => {
                let newData = { ...data }
                let i = newData.payload.items.findIndex((d) => d.groupName === item.groupName)
                newData.payload.items[i].crew.push({ employeeCode: name })
                onChange(newData.id, newData.payload)
                setTimeout(() => setActive(true), 100)
              }}
            />
          )}
        </span>
      </div>
    </Card>
  )
}
const CrewMembersExcludedV2 = ({ data, search, onEmployeeRemove, ...props }) => {
  const employees = data?.payload?.items.map((d) => d.crew.map((d) => d.employeeCode)).flat()
  const [addGroup, setAddGroup] = useState(false)

  useEffect(() => {
    if (props.new) setAddGroup(true)
  }, [props.new])

  return (
    <>
      {addGroup && (
        <>
          <div
            style={{
              left: 0,
              top: 0,
              pointerEvents: 'all',
              position: 'fixed',
              width: '100%',
              zIndex: 1000,
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
          />
          <CSSTransition
            appear
            in
            key={1}
            classNames={{
              enter: styles.groupEnter,
              enterActive: styles.groupEnterActive,
              appear: styles.groupAppear,
              appearACtive: styles.appearActive,
            }}>
            <Group
              onCancel={() => setAddGroup(false)}
              setInitialFocus
              data={data}
              is_editable={props.is_editable}
              employees={employees}
              item={{ groupName: null, crew: [] }}
              onChange={(e, i) => {
                onEmployeeRemove(e, i)
                setAddGroup(false)
              }}
            />
          </CSSTransition>
        </>
      )}

      {data.payload.items
        .filter((d) =>
          search
            ? d.groupName?.toUpperCase().includes(search.toUpperCase()) ||
              d.crew.some((d) => d.employeeCode.includes(search.toUpperCase()))
            : true
        )
        .map((item) => (
          <Group
            is_editable={props.is_editable}
            data={data}
            employees={employees}
            key={item.groupName}
            item={item}
            onChange={onEmployeeRemove}
          />
        ))}
    </>
  )
}

const ExcludedCrewMembersV2 = (props) => {
  const [dialog, setDialog] = useState(null)

  const [search, setSearch] = useState('')
  const { data } = props

  const { is_editable } = data
  return (
    <DataObject
      data={props.data}
      upload="json"
      title={intl.t('excluded_crew_members')}
      search={
        <Search
          value={search}
          placeholder="Group or Employee"
          onChange={(value) => {
            setSearch(value)
          }}
        />
      }
      is_editable={is_editable}
      onAdd={() => {
        setDialog('new')
        setTimeout(() => setDialog(false), 10)
      }}
      addButtonText="Add Group"
      onSave={(id, d) => {
        props.onSave(id, d)
        setDialog(false)
      }}>
      <CrewMembersExcludedV2
        is_editable={is_editable}
        new={dialog}
        data={data}
        search={search}
        onEmployeeRemove={props.onSave}
      />
    </DataObject>
  )
}

export default ExcludedCrewMembersV2
