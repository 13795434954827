export const USER_LOGIN = 'USER_LOGIN'
export const userLogin = (data) => ({
  type: USER_LOGIN,
  payload: data,
})

export const USER_LOGOUT = 'USER_LOGOUT'
export const userLogout = (data) => ({
  type: USER_LOGOUT,
  payload: data,
})

export const ADD_MYPROFILE = 'ADD_MYPROFILE'
export const addMyProfile = (data) => ({
  type: ADD_MYPROFILE,
  payload: data,
})

export const TOGGLE_THEME = 'TOGGLE_THEME'
export const toggleTheme = (data) => ({
  type: TOGGLE_THEME,
  payload: data,
})
