import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styles from './FlightDuty.module.scss'
import styles2 from '../Activity/Activity.module.scss'
import { SAFEscoresToGradient, fatigueInRest } from '../SafeCare'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { FlightDutyTooltipHtml } from '../Activity/Tooltips'
import store from 'store'
import { changeFilter } from '../../../actions'

const FlightDuty = (props) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)
  const { displaySafeCare, displayDutyTimes } = props
  const x = props.xScale
  const d = props.data
  const onMouseEnter = useCallback(() => {
    if (!props.drag) setOpen(true)
  }, [props.drag])
  useEffect(() => {
    if (props.drag && open) setOpen(false)
  }, [props.drag])

  const className = useMemo(
    () =>
      [
        styles.flightDuty,
        d.preAssigned ? styles.preAssigned : 'undefined',
        d.preAssigned && props.expanded ? styles.preAssignedFlag : 'undefined',
        d.disruptiveSignOut || d.nightDuty ? styles.disruptiveAfter : undefined,
        d.disruptiveSignIn || d.nightDuty ? styles.disruptiveBefore : undefined,
      ].join(' '),
    [styles, d, props.expanded]
  )
  const style = useMemo(() => {
    return {
      //  marginLeft: d.startTimeX - props.margin,
      // transform: `translateX(${d.startTimeX}px)`,
      left: d.startTimeX,
      position: 'absolute',
      backgroundImage: displaySafeCare ? SAFEscoresToGradient(d, x) : undefined,
      width: d.endTimeX - d.startTimeX,
    }
  }, [d.startTimeX, props.margin, props.x, displaySafeCare, d.endTimeX])

  return (
    <>
      <div
        onClick={() => store.dispatch(changeFilter(d.flightDutyID))}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setOpen(false)}
        className={className}
        style={style}>
        {d.activityCode}
        {open && (
          <Tooltip2
            autoFocus={false}
            enforceFocus={false}
            portalContainer={document.getElementById('main')}
            portalClassName={styles.noevents}
            placement={Position.BOTTOM}
            content={<FlightDutyTooltipHtml d={d} zone={props.zone} />}
            defaultIsOpen
            targetClassName={styles2.tooltip}
            renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => <div {...tooltipProps} />}
          />
        )}
      </div>
      <span
        className={[
          styles.flightDutyRest,
          d.preAssigned ? styles.preAssigned : 'undefined',
          d.preAssigned && props.expanded ? styles.preAssignedFlag : 'undefined',
          d.disturbiveEndOfRest ? styles.disruptiveAfter : undefined,
        ].join(' ')}
        style={{
          transform: `translateX(${d.endTimeX}px)`,
          position: 'absolute',
          //  marginLeft: 0,
          width: d.endOfRestX - d.endTimeX,
          backgroundImage: displaySafeCare ? fatigueInRest(d, x) : undefined,
        }}
      />
    </>
  )
}

export default React.memo(FlightDuty)
