import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Divider from 'shared/Divider/Divider'
import { Button, ButtonGroup } from '@blueprintjs/core'
import api from '../api'

import { Rows, Row } from 'shared/PanelLayout/PanelLayout'
import { PubState, PubEventType } from '../actions'
import LoadingButton from 'shared/LoadingButton'
import ConfirmButton from 'shared/ConfirmButton'
import SharedFilter from '../Filter/Filter'
import { AppToaster } from 'shared/Toaster/Toaster'
import InvalidPublication from 'publication/InvalidPublication'
import { FileDownloadListItem, LegacyDownloadListItem } from '../Filter/Filter'
const Filter = (props) => {
  const navigate = useNavigate()

  let { activePublication, data } = props
  return (
    <Rows>
      <Row padding={10}>
        <SharedFilter
          activePublication={activePublication}
          data={data}
          issues={props.issues}
          allIssues={props.allIssues}
          additionalToggles={
            <>
              <Divider text="Download" style={{ marginTop: 20 }} />
              {activePublication?.output_documents
                ?.slice()
                .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                .map((d) => (
                  <FileDownloadListItem key={d.id} {...d} />
                ))}
              {activePublication && (
                <>
                  <LegacyDownloadListItem
                    name="Roster issues"
                    description="Roster issues in CSV format"
                    mime_type="text/csv"
                    onClick={() => api.downloadCoverageRosterIssuesCsv(activePublication.coverage_check_roster)}
                  />
                  <LegacyDownloadListItem
                    name="Data issues"
                    description="Data issues in CSV format"
                    mime_type="text/csv"
                    onClick={() => api.downloadCoverageDataIssuesCsv(activePublication.coverage_check_roster)}
                  />
                </>
              )}
            </>
          }
        />
      </Row>

      {activePublication.state === PubState.FCOV_CALC_READY && (
        <Row fixed padding={10}>
          <ButtonGroup vertical fill>
            <ConfirmButton
              intent="warning"
              text="Retry Coverage Calculation"
              dialogText="This will discard the current coverage result and go back to data configuration. There, a new coverage calculation can be requested. Are you sure you want to proceed?"
              icon="repeat"
              onConfirm={(e) =>
                api.createEvent(
                  PubEventType.CALC_FLIGHT_COV_RETRY,
                  activePublication.id,
                  { no_auto_trigger: true },
                  activePublication.context,
                  () => navigate('../data')
                )
              }
            />
            <ConfirmButton
              intent="warning"
              text="Retry IDPS Export"
              dialogText="This will discard the current coverage result and IDPS data export and go back to data configuration. There, a new IDPS export can be requested. Are you sure you want to proceed?"
              icon="repeat"
              onConfirm={(e) =>
                api.createEvent(
                  PubEventType.CALC_FLIGHT_COV_RETRY_IDPS_EXPORT_RETRY,
                  activePublication.id,
                  {},
                  activePublication.context,
                  () => navigate('../data')
                )
              }
            />
            <LoadingButton
              icon="play"
              text="Start Roster Calculation"
              intent="primary"
              onClick={() => {
                try {
                  api.createEvent(
                    PubEventType.CALC_ROSTER_START,
                    activePublication.id,
                    {},
                    activePublication.context,
                    () => navigate('../roster')
                  )
                } catch (err) {
                  AppToaster.show({
                    intent: 'danger',
                    autoFocus: false,
                    icon: 'error',
                    timeout: 1000,
                    message: <InvalidPublication data={err} />,
                  })
                }
              }}
            />
          </ButtonGroup>
        </Row>
      )}
    </Rows>
  )
}

export default Filter
