import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './../Data.module.scss'
import { Card, H5, FormGroup, Tag, Button, Dialog, Callout, Classes, EditableText } from '@blueprintjs/core'
import Search from 'shared/search'
import DataObject from '../DataObject.js'
import { intl } from 'i18n/IntlGlobalProvider'
import CreateOrSaveDialog from '../DataObjectItemDialog'
import { InputEmployee, InputText, InputTextEditable } from 'shared/Forms'

const CreateNewExludedCrewMember = (props) => {
  const error =
    props.isOpen === 'new'
      ? props.validation_errors?.[props.dataObject.payload.items.length]
      : props.validation_errors?.[props.isOpen]
  const [isInvalid, setIsInvalid] = useState(false)

  return (
    <CreateOrSaveDialog
      usePortal={false}
      payload={props.dataObject.payload}
      id={props.dataObject.id}
      icon="info-sign"
      title="Excluded Crewmember"
      isInvalid={isInvalid}
      edit={props.isOpen >= 0 && props.isOpen !== 'new'}
      resetObject={''}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => (
        <FormGroup label="Employee" inline>
          <InputEmployee
            value={data}
            onChange={(employee, invalid) => {
              setData(employee)
              if (invalid) setIsInvalid(true)
              else setIsInvalid(false)
            }}
          />
        </FormGroup>
      )}
    </CreateOrSaveDialog>
  )
}

const CrewMembersExcluded = ({ data, search, onClick }) => {
  return (
    <>
      {data.payload.items.map((item, i) => (
        <Card
          style={{
            margin: 10,
            display: item.toUpperCase().includes(search.toUpperCase()) ? 'block' : 'none',
          }}
          className={styles.card}
          key={i}
          onClick={
            data.is_editable
              ? (e) => {
                  e.stopPropagation()
                  onClick(i)
                }
              : undefined
          }
          interactive={data.is_editable}>
          <H5 style={{ display: 'inline-block', margin: 0 }}>{item.toUpperCase()}</H5>
        </Card>
      ))}
    </>
  )
}
const ExcludedCrewMembersV1 = (props) => {
  const [dialog, setDialog] = useState(null)

  const [search, setSearch] = useState('')
  const { data } = props
  const { is_editable } = data
  return (
    <>
      <CreateNewExludedCrewMember
        validation_errors={data.validation_errors?.items}
        isOpen={dialog}
        dataObject={props.data}
        onSave={props.onSave}
        onClose={() => {
          setDialog(null)
        }}
      />
      <DataObject
        data={props.data}
        upload="json"
        title={intl.t('excluded_crew_members')}
        search={
          <Search
            value={search}
            placeholder="Employee"
            onChange={(value) => {
              setSearch(value)
            }}
          />
        }
        is_editable={is_editable}
        onAdd={() => setDialog('new')}
        onSave={props.onSave}>
        <CrewMembersExcluded data={data} search={search} onClick={setDialog} />
      </DataObject>
    </>
  )
}

export default ExcludedCrewMembersV1
