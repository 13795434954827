import * as d3 from 'd3'
import ViolationTypes from './ViolationTypes'
const makeViolationsAnnotation = (activeReports, filter) =>
  ViolationTypes.map((violation, i) => {
    return activeReports.map((report, r) => {
      const data = report.payload.crewStatistics
      const y =
        (filter['preAssigned'] ? data[`preAssigned${violation.key}Violations`] || 0 : 0) +
        (filter['scheduled'] ? data[`scheduled${violation.key}Violations`] || 0 : 0)

      return {
        y: y,
        xref: 'x' + (i + 1),
        x: r,
        yref: 'y',
        font: { color: report.color },
        yanchor: 'bottom',
        showarrow: false,
        text: d3.format('.3~s')(y),
      }
    })
  }).flat()

export default makeViolationsAnnotation
