import React, { useEffect, useState, useRef } from 'react'
import { IssueTag } from '../Issue/Issue'
import styles2 from '../CrewMember.module.scss'
import store from 'store'
import { useSelector } from 'react-redux'
import styles from './CrewMemberLabel.module.scss'
import { SAFEcolorScale } from '../SafeCare'
import { Button, Position } from '@blueprintjs/core'
import { Tooltip2, Popover2InteractionKind } from '@blueprintjs/popover2'
import tooltipStyles from '../Activity/Tooltips.module.scss'
import { iconCrew, iconBed } from 'icons'
import { Icon } from '@blueprintjs/core'
import * as d3 from 'd3'
import { setSelectedCrewMembers, setExpanded } from 'publication/actions'

const Label = ({ label, text }) => (
  <div>
    <span className={tooltipStyles.label}>{label}</span>
    <span>{text}</span>
  </div>
)

const EmployeeTooltip = ({ d }) => <div className={tooltipStyles.tooltipLong}></div>

const CrewMemberLabel = (props) => {
  const d = props.data
  const selectedCrew = useSelector((state) => state.rosters.selectedCrewMembers)
  const [crewFunction, homeBase] = d.title.split('-')
  return (
    <div
      className={[styles.rosterLabel, d.odd ? styles2.odd : styles2.even, props.hover ? styles2.hover : undefined].join(
        ' '
      )}
      style={{ height: 20 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'self-start',
          width: '100%',
          top: 0,

          height: '100%',
        }}>
        <span style={{ marginLeft: 5 }}>
          <div
            style={{
              fontWeight: 'bold',
              marginRight: 15,
              width: 30,
              fontSize: 12,
              marginBottom: 4,
            }}>
            <span style={{ display: 'inline-block', width: 50 }}>{homeBase}</span> <span>{crewFunction}</span>
          </div>
        </span>
      </div>
    </div>
  )
}
export default React.memo(CrewMemberLabel)
