// NPM Modules
import moment from 'moment'
import { useIntl } from 'react-intl'
import messages from './resources'

let INTL

export function IntlGlobalProvider({ children }) {
  INTL = useIntl() // Keep the 'intl' service reference
  return children
}

// =========================================
// Class that exposes translations
// =========================================
var instance
class IntlTranslator {
  // Singleton
  constructor() {
    if (!instance) {
      instance = this
    }
    return instance
  }

  logToWindow(id) {
    if (!window.bla) window.bla = []
    if (!window.bla.find((item) => item.id === id))
      window.bla.push({
        id: id,
        defaultMessage: id,
      })
  }
  t(id, values) {
    if (!id) return ''

    if (Object.prototype.hasOwnProperty.call(messages, id)) return this.formatMessage(messages[id], values)

    if (process.env.NODE_ENV === 'development') this.logToWindow(id)

    return id
  }

  rel_date(date) {
    return moment(date).fromNow()
  }
  fixedtime(date) {
    return moment(date).format('YYYY-MM-DD')
  }
  calendar(date) {
    return moment(date).calendar()
  }
  mimeIcon(mimetype) {
    let [type, subtype] = mimetype.split('/')

    switch (type) {
      case 'text':
        return 'file text outline'
      case 'image':
        return 'file image outline'
      case 'audio':
        return 'file audio outline'
      case 'video':
        return 'file video outline'
      case 'application':
        switch (subtype) {
          case 'pdf':
            return 'file pdf outline'
          default:
            return 'file outline'
        }
      default:
        return 'file outline'
    }
  }
  // ------------------------------------
  // Formatting Functions
  // ------------------------------------
  formatMessage(message, values) {
    return INTL.formatMessage(message, values)
  }
}

export const intl = new IntlTranslator()
export default IntlGlobalProvider
