import { produce } from 'immer'
import { FETCH_BACKEND_META } from './actions'

const initialState = {
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BACKEND_META:
      return produce(state, (draftState) => {
        draftState.data = action.payload
      })

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
