import React, { useState, useEffect } from 'react'
import { ButtonGroup, Position, Button } from '@blueprintjs/core'
import Validate from './Validate'

const toggleWeekday = (values, weekday) => {
  let newDays = values.slice()
  let weekdayIndex = newDays.findIndex((d) => d === weekday)
  if (weekdayIndex === -1) newDays.push(weekday)
  else newDays.splice(weekdayIndex, 1)
  newDays.sort()
  return newDays
}

const InputWeekdays = (props) => {
  const { error, onChange, value } = props
  const [internalValue, setInternalValue] = useState(value || [])
  useEffect(() => setInternalValue(value || []), [value])

  return (
    <Validate validation_errors={error} position={Position.TOP}>
      <ButtonGroup fill>
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((text, day) => (
          <Button
            key={text}
            intent={internalValue.includes(day + 1) ? 'primary' : undefined}
            onClick={() => {
              let value = toggleWeekday(internalValue, day + 1)
              setInternalValue(value)
              onChange(value)
            }}
            text={text}
          />
        ))}
      </ButtonGroup>
    </Validate>
  )
}

export default InputWeekdays
