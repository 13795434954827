import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import api from '../RulesEngine/api'
import store from 'store'
import { addCmEvent } from './reducer'
import { changeActivityTimeframeInRepository } from '../RulesEngine/actions'
import { addFlightDepArrChangedToRepository } from './actions'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
/*function* fetchUser(action) {
   try {
      const user = yield call(Api.fetchUser, action.payload.userId);
      yield put({type: "USER_FETCH_SUCCEEDED", user: user});
   } catch (e) {
      yield put({type: "USER_FETCH_FAILED", message: e.message});
   }
}
*/
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* mySaga() {
  yield takeEvery('USER_CHANGED_FLIGHT', (data) => {
    const { id, diffStart, diffEnd, start, end } = data.payload
    let CmData = {
      flightId: id,
      departureUpdateInMinutes: diffStart,
      arrivalUpdateInMinutes: diffEnd,
    }

    let ReData = {
      newTimeframe: {
        start: start,
        end: end,
      },
      reference: id,
    }

    api.checkViolations({
      Resources: [],
      Activities: [],
      Assignments: [],
      DeletedAssignments: [],
      ChangedActivities: [ReData],
    })
    store.dispatch(addFlightDepArrChangedToRepository(CmData))
    store.dispatch(changeActivityTimeframeInRepository(ReData))
  })
}

export { mySaga }
