import React from 'react'
import store from 'store'
import api from 'auth/api'
import { useAuth } from 'auth/auth'
import Cookie from 'js-cookie'

import handleErrors from './handleErrors'

import { AppToaster } from './../shared/Toaster/Toaster'
const myFetch = async (url, configuration) => {
  const auth = useAuth()
  if (!auth) await api.trySilentRefresh()
  const token = Cookie.get('accessToken')
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  })
  const newConfiguration = {
    ...configuration,
    headers,
  }

  return fetch(url, newConfiguration).then(handleErrors)
}

export default myFetch
