import { barData, alphaMap, publicationColors } from '../barData'

const binSize = 1
const maxDutyHours = 120
const hoverWidth = 9

const makeDutyTimeData = (activeReports, filter) => {
  const out = []

  for (let report in activeReports) {
    var baseData = activeReports[report].payload.memberStatistics.filter(
      (member) => (filter.scheduled && member.scheduledDutyTime) || (filter.preAssigned && member.preAssignedDutyTime)
    )

    var histogram = baseData.map((member) => {
      const sum =
        (filter.scheduled ? member.scheduledDutyTime || 0 : 0) +
        (filter.preAssigned ? member.preAssignedDutyTime || 0 : 0)

      return sum
    })

    const graphData = {
      y: histogram,
      customdata: baseData,
      label: 'Duty Time',
      hoverinfo: 'none',
      type: 'histogram',
      yaxis: `y`,
      meta: { publication: activeReports[report].title, histogram: histogram },
      marker: { color: activeReports[report].color, pattern: {} },
      xaxis: `x${+2 * report + 2}`,
      opacity: 0.7,
      name: activeReports[report].title,
      ybins: { start: -0.5, size: binSize, end: 80 },
    }
    out.push(graphData)
    out.push({
      ...graphData,
      hovertemplate: null,
      type: 'box',
      xaxis: `x${+2 * report + 1}`,
      height: 10,
    })
  }
  return out
}

export default makeDutyTimeData
