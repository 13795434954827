import React, { useState, useEffect } from 'react'
import styles from './Data.module.scss'
import { Tooltip } from '@blueprintjs/core'
import api from './api'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import * as _ from 'lodash'
import { Cell, setCellStyle } from './AgGridCellValidator'
import store from 'store'
import { addContextDataObject } from './actions'

const OffDaysTable = (props) => {
  const { data } = props
  const { is_editable, changedCells, validation_errors } = data
  const [gridApi, setGridApi] = useState(null)

  const rowData = props.data.payload.items.map((d) => {
    return {
      employeeCode: d.employeeCode,
      Jan: d.monthlyOffDays.find((m) => m.month === 1)?.value,
      Feb: d.monthlyOffDays.find((m) => m.month === 2)?.value,
      Mar: d.monthlyOffDays.find((m) => m.month === 3)?.value,
      Apr: d.monthlyOffDays.find((m) => m.month === 4)?.value,
      May: d.monthlyOffDays.find((m) => m.month === 5)?.value,
      Jun: d.monthlyOffDays.find((m) => m.month === 6)?.value,
      Jul: d.monthlyOffDays.find((m) => m.month === 7)?.value,
      Aug: d.monthlyOffDays.find((m) => m.month === 8)?.value,
      Sep: d.monthlyOffDays.find((m) => m.month === 9)?.value,
      Oct: d.monthlyOffDays.find((m) => m.month === 10)?.value,
      Nov: d.monthlyOffDays.find((m) => m.month === 11)?.value,
      Dec: d.monthlyOffDays.find((m) => m.month === 12)?.value,
      days: d.remainingWeekendDays.value,
      date: d.remainingWeekendDays.date,
    }
  })
  const frameworkComponents = {
    Cell: Cell,
  }
  const onGridReady = (params) => {
    setGridApi(params.api)
    colDefs.find((d) => d.field === 'remainingWeekendDays').children[0].headerName =
      data?.payload?.items[0]?.remainingWeekendDays.date
    params.api.setColumnDefs(colDefs)
    params.api.refreshHeader()
  }
  // Assume that "year" is equal for all entries
  const year = `${data?.payload?.items[0]?.monthlyOffDays[0]?.year}`
  let colDefs = [
    {
      headerName: 'Employee',
      field: 'employeeCode',
      editable: false,
      sortable: true,
      minWidth: 80,
      valueParser: (d) => d.value,
    },
    {
      field: year,
      children: [
        { field: 'Jan', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Feb', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Mar', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Apr', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'May', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Jun', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Jul', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Aug', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Sep', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Oct', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Nov', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
        { field: 'Dec', cellRendererParams: { validation_errors, changedCells }, flex: 0, width: 70, sortable: true },
      ],
    },
    {
      headerName: 'Remaining weekend days',
      field: 'remainingWeekendDays',
      children: [{ field: 'days', headerName: 'Days', valueParser: (d) => d.days, sortable: true }],
    },
  ]
  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs)

    if (gridApi) gridApi.redrawRows({ force: true })
  }, [data, changedCells, validation_errors])

  return (
    <>
      <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          context={{ validation_errors, changedCells }}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          onCellValueChanged={(e) => {
            gridApi.clearFocusedCell()
            let newData = _.cloneDeep(props.data)
            let newItems = _.cloneDeep(props.data.payload.items)

            newItems[e.rowIndex] = e.data

            let newChangedCells
            if (!changedCells) newChangedCells = {}
            else newChangedCells = _.cloneDeep(changedCells)
            if (!newChangedCells[e.rowIndex]) newChangedCells[e.rowIndex] = {}
            if (!newChangedCells[e.rowIndex][e.colDef.field]) newChangedCells[e.rowIndex][e.colDef.field] = true

            let errors = _.cloneDeep(validation_errors)
            if (errors?.items?.[e.rowIndex]?.[e.colDef.field]) errors.items[e.rowIndex][e.colDef.field] = []

            store.dispatch(
              addContextDataObject({
                ...newData,
                changedCells: newChangedCells,
                validation_errors: errors,
                dirty: true,
                payload: { items: newItems },
              })
            )
          }}
          immutableData={true}
          getRowNodeId={(data) => data.employeeCode}
          rowClass={styles.rowClass}
          rowData={rowData}
          defaultColDef={{
            editable: false,
            cellClass: setCellStyle,
            cellRendererSelector: (props) =>
              props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(
                ' '
              )
                ? { component: 'Cell' }
                : undefined,
            flex: 1,
            valueParser: (d) => (!isNaN(Number(d.newValue)) ? Number(d.newValue) : d.newValue),
          }}
          columnDefs={colDefs}
        />
      </div>
    </>
  )
}

const Comp = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)

  return (
    <>
      <DataObject data={props.data} upload="csv" title={intl.t(props.data.type)} onSave={props.onSave} nofilter>
        {<OffDaysTable data={props.data} />}
      </DataObject>
    </>
  )
}

export default Comp
