import { Position, Toaster } from '@blueprintjs/core'
import { trimEnd } from 'lodash'

//import { createRoot } from 'react-dom/client'

export const AppToaster = Toaster.create(
  {
    position: Position.MIDDLE,
    className: 'not-found-toast',
    timeout: 1000,
  },
  document.getElementById('toast')
)

/* for react 18
const createToaster = (props) => {
  const containerElement = document.createElement('div')
  containerElement.classList.add('theme-light')

  document.body.appendChild(containerElement)
  const root = createRoot(containerElement)
  return new Promise((resolve, reject) => {
    root.render(
      <Toaster
        {...props}
        usePortal={false}
        ref={(instance) => {
          if (!instance) {
            reject(new Error('[Blueprint] Unable to create toaster.'))
          } else {
            resolve(instance)
          }
        }}
      />
    )
  })
}

export const AppToaster = await createToaster()
*/
