import React, { useState } from 'react'
import Loader from 'shared/Loader/Loader'
import { Spinner } from '@blueprintjs/core'
import styles from './Steps.module.scss'
import { Tooltip2 } from '@blueprintjs/popover2'

const Steps = (props) => {
  const [hoveredStep, setHoveredStep] = useState(null)
  const content = (item, i) => (
    <span
      key={i}
      onMouseEnter={() => setHoveredStep(i)}
      onMouseLeave={() => setHoveredStep(null)}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (props.onClick && i <= props.current) props.onClick(item?.link)
      }}
      className={[
        styles.step,

        i == hoveredStep - 1 && i < props.current ? styles.beforeHoveredStep : undefined,
        i == hoveredStep && i <= props.current ? styles.hoveredStep : undefined,
        i == props.selected ? styles.selectedStep : undefined,
        i < props.current ? styles.enabledStep : undefined,
        i === props.current ? styles.inprogressStep : undefined,
      ].join(' ')}
      style={{
        display: 'flex',

        alignItems: 'center',
        position: 'relative',
      }}>
      <span className={styles.bullet}>
        {i <= props.current && <span className={styles.expandingCircle} />}
        <span className={styles.bulletDot} />
        {i === props.active && <span className={styles.pulse} />}
      </span>
      {i < props.items.length && (
        <>
          {i < props.items.length - 1 && (
            <span
              className={styles.track1}
              style={{
                flex: '0 0 auto',
                height: 3,
              }}
            />
          )}
          <span className={styles.label}>
            <div className={[styles.mainLabel, props.items[i].error === true ? styles.errorLabel : ''].join(' ')}>
              {item.title}
            </div>
            <div className={styles.subLabel}>{item.subtitle}&nbsp;</div>
          </span>
          {i < props.items.length - 1 && (
            <span
              className={styles.track}
              style={{
                marginLeft: 0,
                marginRight: i === props.selected - 1 ? 5 : 0,
                flex: 1,
                height: 3,
              }}
            />
          )}
        </>
      )}
    </span>
  )
  return props.tooltips ? (
    <span className={styles.wrapper}>
      {props.items.map((item, i) => (
        <Tooltip2
          key={i}
          usePortal={true}
          portalContainer={document.getElementById('main')}
          portalClassName={styles.top}
          //disabled={true}
          // disabled={size.width > 300}
          content={item.title}>
          {content(item, i)}
        </Tooltip2>
      ))}
    </span>
  ) : (
    <span className={styles.wrapper}>{props.items.map(content)}</span>
  )

  /*
  return (
    <span className={styles.wrapper}>
      {props.items.map((item, i) => (
        <Tooltip2
          key={i}
          usePortal={true}
          portalContainer={document.getElementById('main')}
          portalClassName={styles.top}
          //disabled={true}
          // disabled={size.width > 300}
          content={item.title}>
          <span
            onMouseEnter={() => setHoveredStep(i)}
            onMouseLeave={() => setHoveredStep(null)}
            onClick={() => {
              if (props.onClick && i <= props.current) props.onClick(item?.link)
            }}
            className={[
              styles.step,

              i == hoveredStep - 1 && i < props.current ? styles.beforeHoveredStep : undefined,
              i == hoveredStep && i <= props.current ? styles.hoveredStep : undefined,
              i == props.selected ? styles.selectedStep : undefined,
              i < props.current ? styles.enabledStep : undefined,
              i === props.current ? styles.inprogressStep : undefined,
            ].join(' ')}
            style={{
              display: 'flex',

              alignItems: 'center',
              position: 'relative',
            }}>
            <span className={styles.bullet}>
              {i <= props.current && <span className={styles.expandingCircle} />}
              <span className={styles.bulletDot} />
              {i === props.active && <span className={styles.pulse} />}
            </span>
            {i < props.items.length && (
              <>
                {i < props.items.length - 1 && (
                  <span
                    className={styles.track1}
                    style={{
                      flex: '0 0 auto',
                      height: 3,
                    }}
                  />
                )}
                <span className={styles.label}>
                  <div className={[styles.mainLabel, props.items[i].error === true ? styles.errorLabel : ''].join(' ')}>
                    {item.title}
                  </div>
                  <div className={styles.subLabel}>{item.subtitle}&nbsp;</div>
                </span>
                {i < props.items.length - 1 && (
                  <span
                    className={styles.track}
                    style={{
                      marginLeft: 0,
                      marginRight: i === props.selected - 1 ? 5 : 0,
                      flex: 1,
                      height: 3,
                    }}
                  />
                )}
              </>
            )}
          </span>
        </Tooltip2>
      ))}
    </span>*/
}

export default Steps
