import React, { useEffect, useState } from 'react'
import Divider from 'shared/Divider/Divider'
import { useSelector } from 'react-redux'
import api from '../api'
import { Select } from '@blueprintjs/select'
import { ButtonGroup, Button, Checkbox, Switch } from '@blueprintjs/core'
import store from 'store/index'
import { changeFilter } from '../actions'
import { publicationColors } from '../barData'
import costDetails from '../Cost/costDetails'

import activityDetails from '../Distributions/activityDetails'
import MultiSearchDropdown from '../../shared/SearchDropdown/MultiSearchDropdown'
import SearchDropdown from '../../shared/SearchDropdown/SearchDropdown'

const Menu = (props) => {
  const reports = useSelector((state) => state.reports)
  const activeContext = useSelector((state) => state.user.profile.active_context)

  const [business, setBusiness] = useState(true)
  const [soft, setSoft] = useState(true)
  const [scheduled, setScheduled] = useState(true)
  const [preAssigned, setPreAssigned] = useState(true)

  const { publications, activeReports, filter } = reports
  const selectedEmployee = useSelector((state) => state.reports.filter.employee)

  const selectedCostFields = useSelector((state) => state.reports.filter.costFields)
  const selectedActivityFields = useSelector((state) => state.reports.filter.activityFields)
  const allEmployees = [
    ...new Set(activeReports.map((pub) => pub.payload.memberStatistics.map((d) => d.employeeCode)).flat()),
  ]
  const allQualifications = [
    ...new Set(activeReports.map((pub) => pub.payload.memberStatistics.map((d) => d.qualifications)).flat()),
  ]

  return (
    <div style={{ padding: 10 }}>
      <Divider text="Publications" />
      {activeReports?.map((pub, i) => (
        <div key={pub.id} style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              backgroundColor: pub.color,
              width: 13,
              height: 13,
              borderRadius: 8,
              flex: '0 0 auto',
              marginRight: 10,
            }}
          />
          <span style={{ flex: '1 1 0' }}>{pub.title}</span>
          <Button
            small
            minimal
            icon="cross"
            onClick={() => {
              api.removeCostReport(pub.id)
            }}
          />
        </div>
      ))}
      <SearchDropdown
        style={{ marginTop: 10 }}
        usePortal={false}
        activeItem={''}
        preventSelection={true}
        hideCloseButton
        width={380}
        placeholder="Select publication to add"
        text={(d) => d.publicationTitle}
        description={(d) => d.description}
        items={publications
          .filter((d) => d.context === activeContext)
          .filter((pub) => !activeReports.find((d) => d.id === pub.id))
          .sort((a, b) => new Date(a.last_modified_at) - new Date(b.last_modified_at))}
        onChange={(val) => {
          api.storeCostReport(val)
        }}
      />

      <Divider text="Show/Hide activity group" />
      <div style={{ marginLeft: 8, marginTop: 20 }}>
        <Checkbox
          inline={true}
          checked={scheduled}
          label="Optimized"
          onChange={() => {
            store.dispatch(changeFilter({ scheduled: !scheduled }))
            setScheduled(!scheduled)
          }}
        />
        <Checkbox
          inline={true}
          checked={preAssigned}
          label="Pre-assigned"
          onChange={() => {
            store.dispatch(changeFilter({ preAssigned: !preAssigned }))
            setPreAssigned(!preAssigned)
          }}
        />

        <div>
          {props.tabId === 'cost' && (
            <MultiSearchDropdown
              value={selectedCostFields}
              fill
              placeholder="Select cost details"
              items={Object.keys(costDetails).map((el) => Object.create({ key: el, value: el, text: el }))}
              onChange={(val) => {
                store.dispatch(changeFilter({ costFields: val }))
              }}
            />
          )}
          {props.tabId === 'distributions' && (
            <>
              <MultiSearchDropdown
                value={selectedActivityFields}
                fill
                placeholder="Select cost details"
                items={Object.keys(activityDetails).map((el) => Object.create({ key: el, value: el, text: el }))}
                onChange={(val) => {
                  store.dispatch(changeFilter({ activityFields: val }))
                }}
              />
              <Divider text="Highlight employee" />
              <SearchDropdown
                value={selectedEmployee}
                fill
                usePortal={false}
                placeholder="Find employee"
                text={(d) => d.text}
                items={allEmployees.map((el) => {
                  return { key: el, value: el, text: el }
                })}
                onChange={(val) => {
                  store.dispatch(changeFilter({ employee: val.key }))
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Menu
