import { useState, useEffect } from 'react'
import Cookie from 'js-cookie'
import jwt from 'jwt-decode'

const useAuth = () => {
  //returns true if a valid non-expired access token is available
  const access_token = Cookie.get('accessToken')

  try {
    let decodedToken = jwt(access_token)
    if (!decodedToken) return false
    if (new Date().getTime() < decodedToken.exp * 1000) return true
    return false
  } catch (err) {
    return false
  }
}

export { useAuth }
