// Import our depdendency
import myFetch from 'api/myFetch'
import myFetchBlob from 'api/myFetchBlob'
import myPost from 'api/myPost'
import myPut from 'api/myPut'
import authApi from 'auth/api'
import myDelete from 'api/myDelete'
import store from 'store/index'
import { fetchAirports } from './actions'
import config from 'config'

const servicesFactory = (myFetch, myPost, myDelete, myPut, myFetchBlob) => ({
  async getAirports() {
    let json = await myFetch(`${config.api}/airports/?ordering=iata`)
    let data = json.map((d) => {
      return { ...d, iata_code: d.iata, key: d.iata, text: d.iata }
    })
    store.dispatch(fetchAirports(data))
    return data
  },
  async getAirportData(iata) {
    let json = await myFetch(`${config.api}/airports/?iata=${iata}`)
    return json
    // store.dispatch(addContextDataObject(json))
  },
})

const api = servicesFactory(myFetch, myPost, myDelete, myPut, myFetchBlob)
export default api
