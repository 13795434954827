import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { intl } from 'i18n/IntlGlobalProvider'
import {
  EditableText,
  FormGroup,
  Card,
  H5,
  H6,
  Dialog,
  Classes,
  Button,
  InputGroup,
  Tag,
  Tabs,
  Tab,
  Icon,
  Alignment,
  Switch,
} from '@blueprintjs/core'

import CurrencyDropdown from 'shared/CurrencyDropdown/CurrencyDropdown'
import { InputFloat } from 'shared/Forms'

const Currency = (props) => {
  const [exchangeRates, setExchangeRates] = useState(props.data.exchange_rates)
  const [currency, setCurrency] = useState(props.data.default_currency)
  const { validation_errors } = props
  useEffect(() => setCurrency(props.data.default_currency), [props.data.default_currency])
  useEffect(() => setExchangeRates(props.data.exchange_rates), [props.data.exchange_rates])

  const { is_editable } = props
  return (
    <div>
      <FormGroup label={<span style={{ width: 120, display: 'inline-block' }}>Default Currency</span>} inline>
        <CurrencyDropdown
          disabled={!is_editable}
          activeItem={currency}
          onChange={(d) => {
            setCurrency(d.key)
            props.onChange({ ...props.data, default_currency: d.key })
          }}
        />
      </FormGroup>

      <FormGroup label={<span style={{ width: 120, display: 'inline-block' }}>Conversion Rates</span>} inline>
        {exchangeRates.map((rate, i) => (
          <div key={rate.from} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <CurrencyDropdown
              error={validation_errors?.exchange_rates?.[i]?.from}
              disabled={!is_editable}
              activeItem={rate.from}
              onChange={(d) => {
                let newRates = exchangeRates.slice()
                let newRate = { ...rate, from: d.key }
                newRates.splice(i, 1, newRate)
                setExchangeRates(newRates)
                props.onChange({ ...props.data, exchange_rates: newRates })
              }}
            />

            <InputFloat
              error={validation_errors?.exchange_rates?.[i]?.rate}
              value={rate.rate}
              placeholder="Rate"
              disabled={!is_editable}
              onChange={(value) => {
                let newRates = exchangeRates.slice()
                let newRate = { ...rate, rate: value }
                newRates.splice(i, 1, newRate)
                setExchangeRates(newRates)
                props.onChange({ ...props.data, exchange_rates: newRates })
              }}
              style={{ marginLeft: 10, textAlign: 'right', width: 200 }}
            />

            {is_editable && (
              <Button
                minimal
                icon="cross"
                onClick={() => {
                  let newRates = exchangeRates.slice()
                  newRates.splice(i, 1)
                  setExchangeRates(newRates)
                  props.onChange({ ...props.data, exchange_rates: newRates })
                }}
              />
            )}
          </div>
        ))}

        {is_editable && (
          <Button
            icon="plus"
            text="Add Conversion Rate"
            onClick={() => {
              let newRates = exchangeRates.slice()
              newRates.push({ from: null, rate: 1 })
              setExchangeRates(newRates)
              props.onChange({ ...props.data, exchange_rates: newRates })
            }}
          />
        )}
      </FormGroup>
    </div>
  )
}

export default Currency
