// Import our depdendency
import myFetch from 'api/myFetch'
import store from 'store/index'
import { addAllUsers } from './actions'
import config from 'config'
import { normalize, schema } from 'normalizr'

const user = new schema.Entity(
  'users',
  {},
  {
    idAttribute: 'resource_id',
  }
)

const userListSchema = [user]

const servicesFactory = (myFetch) => ({})

const users = servicesFactory(myFetch)
export default users
