import { DateTime } from 'luxon'

const convertISOTimestampToMillis = (d) => {
  if (d.startTime) d.startTime = DateTime.fromISO(d.startTime, { zone: 'UTC' }).toMillis()
  if (d.endOfRest) d.endOfRest = DateTime.fromISO(d.endOfRest, { zone: 'UTC' }).toMillis()
  if (d.endTime) d.endTime = DateTime.fromISO(d.endTime, { zone: 'UTC' }).toMillis()
}

const convertMillisToISOTimestamps = (d) => {
  if (d.startTime) d.startTime = DateTime.fromMillis(d.startTime, { zone: 'UTC' }).toISO()
  if (d.endOfRest) d.endOfRest = DateTime.fromMillis(d.endOfRest, { zone: 'UTC' }).toISO()
  if (d.endTime) d.endTime = DateTime.fromMillis(d.endTime, { zone: 'UTC' }).toISO()
}

const deserializeFlightDuties = (r) => {
  //initialize required field to not break ui
  if (!r.flight_duties) r.flight_duties = []
  r.preAssignedDuties = []

  // Process flight_duties and generate flight_duty labels
  r.flightDutyLabels = []
  r.flight_duties?.forEach(convertISOTimestampToMillis)
  r.flight_duties.forEach((d, i) => {
    d.key = r.crewRole + d.flightDutyID

    // add disruptive end of rest boolean if next flight duty starts less then 1 hour after end of rest
    // Required to colorize the end of rest marker
    if (i < r.flight_duties.length - 2)
      if (r.flight_duties[i + 1].startTime - d.endOfRest < 3600000) d.disturbiveEndOfRest = true

    //add duty sign in label
    r.flightDutyLabels.push({
      id: d.key,
      activity: d.activityIDs?.length > 0 ? d.activityIDs[0] : null,
      preAssigned: d.preAssigned,
      type: 'signIn',
      dutyTime: d.startTime,
      disruptive: d.disruptiveSignIn || d.nightDuty,
    })
    //add duty sign out label
    r.flightDutyLabels.push({
      id: d.key,
      activity: d.activityIDs?.length > 0 ? d.activityIDs[d.activityIDs.length - 1] : null,
      preAssigned: d.preAssigned,
      type: 'signOut',
      dutyTime: d.endTime,
      disruptive: d.disruptiveSignOut || d.nightDuty,
    })

    //add duty end of rest label
    r.flightDutyLabels.push({
      id: d.key,
      preAssigned: d.preAssigned,
      type: 'endOfRest',
      dutyTime: d.endOfRest,
      activityTime: d.endOfRest,
      disruptive: d.disturbiveEndOfRest,
    })
  })

  r.flightDutyLabels.sort((a, b) => (a.dutyTime < b.dutyTime ? -1 : a.dutyTime > b.dutyTime ? 1 : 0))
}

const serializeFlightDuties = (r) => {
  delete r.flightDutyLabels
  delete r.preAssignedDuties

  r.flight_duties?.forEach(convertMillisToISOTimestamps)
  r.flight_duties?.forEach((d, i) => {
    delete d.key
    delete d.disturbiveEndOfRest
  })
}

const deserializeActivities = (r) => {
  if (!r.activities) r.activities = []
  r.preAssignedActivities = []

  //Process activities
  r.activities?.forEach(convertISOTimestampToMillis)
  r.activities?.sort((a, b) => a.startTime - b.startTime)
  r.activities?.forEach((d, i) => {
    if (!d.activityID) d.activityID = Math.floor(Math.random() * 10000000)
    d.key = d.activityID
    // copy personal crewFunction over to activity
    d.crewFunction = r.crewFunction
    if (r.crewFunction !== 'Plane') {
      d.crewRole = d.crewRole ? d.crewRole : r.crewFunction
    }

    //copy aircraft info over to plane

    r.aircraftType == r.activities[0]?.aircraftType

    //check if activity belongs to a flight duty
    let fd = r.flight_duties.find((f) => f.activityIDs.includes(d.activityID))
    d.flightDutyEndTime = fd ? fd.endTime : null
    d.flightDutyStartTime = fd ? fd.startTime : null
    d.nightDuty = fd ? fd.nightDuty : false
    if (fd) d.singleActivity = fd.activityIDs.length === 1
    if (fd) d.firstActivity = d.crewFunction !== 'Plane' && fd.activityIDs.indexOf(d.activityID) === 0
    if (fd)
      d.lastActivity = d.crewFunction !== 'Plane' && fd.activityIDs.indexOf(d.activityID) === fd.activityIDs.length - 1
    if (fd) d.isExtendedDuty = fd.extended_duty || fd.isExtendedDuty
    if (fd) d.hasInFlightRest = fd.hasInFlightRest

    if (fd) {
      let label = r.flightDutyLabels.filter((f) => f.activity === d.activityID)
      if (label) label.forEach((l, i) => (l.activityTime = d.firstActivity && i == 0 ? d.startTime : d.endTime))
    }

    if (d.typeOfActivity === 'Taxi') d.activityCode = 'Taxi'
    if (d.typeOfActivity === 'Hotel') d.activityCode = d.location
    if (d.typeOfActivity === 'Standby') d.activityCode = d.standbyCode ? d.standbyCode : 'STBY'
  })

  //Generate activity labels

  r.preAssignedActivities = r.activities.filter((d) => d.preAssigned)
  r.scheduledActivities = r.activities.filter((d) => !d.preAssigned)

  const makeLabels = (activities) => {
    const labels = []

    activities.forEach((d, i) => {
      const isFlight =
        ['Flight', 'IataFlight', 'Taxi', 'TaxiBaseSwitch', 'TaxiOwnMeans', 'Deadhead'].includes(d.typeOfActivity) ||
        d.activityCode === 'F'
      d.activityLabel = isFlight ? d.flightNumber : d.activityCode
      const isPrevFlight =
        i > 0
          ? (['Flight', 'Taxi', 'TaxiBaseSwitch', 'TaxiOwnMeans', 'IataFlight', 'Deadhead'].includes(
              activities[i - 1].typeOfActivity
            ) ||
              activities[i - 1].activityCode === 'F') &&
            d.startTime - activities[i - 1].endTime < 6 * 3600 * 1000
          : false
      const prevAirport = i > 0 ? activities[i - 1].to : null
      const nextAirport = i < activities.length - 1 ? activities[i + 1].from : null
      const isNextFlight =
        i < activities.length - 1
          ? (['Flight', 'Taxi', 'TaxiBaseSwitch', 'TaxiOwnMeans', 'IataFlight', 'Deadhead'].includes(
              activities[i + 1].typeOfActivity
            ) ||
              activities[i + 1].activityCode === 'F') &&
            activities[i + 1].startTime - d.endTime < 6 * 3600 * 1000
          : false

      if (isFlight) {
        if (d.firstActivity || !isPrevFlight || d.from !== prevAirport)
          labels.push({
            type: d.crewFunction === 'Plane' ? 'plane' : d.typeOfActivity,
            preAssigned: d.preAssigned,
            isHomeBase: d.from === r.homeBase,
            id: [d.activityID],
            positioning: true,
            text: d.from,
            first: true,
            start: d.startTime,
          })
        let p = d.endTime
        if (!d.lastActivity && isNextFlight && d.to === nextAirport) {
          p = (d.endTime + r.activities[i + 1].startTime) / 2
        }
        labels.push({
          type: d.crewFunction === 'Plane' ? 'plane' : d.typeOfActivity,
          preAssigned: d.preAssigned,
          isHomeBase: d.to === r.homeBase,
          positioning: true,
          id: [d.activityID],
          text: d.to,
          last: d.lastActivity,
          start: p,
        })
      } else {
        labels.push({
          activityLabel: false,
          preAssigned: d.preAssigned,
          type: d.crewFunction === 'Plane' ? 'plane' : d.typeOfActivity,
          id: [d.activityID],
          isHomeBase: d.from === r.homeBase,
          text: d.from,
          standby: d.typeOfActivity === 'Standby',
          start: d.startTime,
        })
        labels.push({
          activityLabel: true,
          preAssigned: d.preAssigned,
          isHomeBase: d.to === r.homeBase,
          type: d.crewFunction === 'Plane' ? 'plane' : d.typeOfActivity,
          id: [d.activityID],
          text: d.activityCode,
          standby: d.typeOfActivity === 'Standby',
          start: (d.endTime + d.startTime) / 2,
        })
      }
    })
    labels.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0))
    return labels
  }

  r.labels = makeLabels(r.activities, r.homeBase)
}

const serializeActivities = (r) => {
  delete r.labels
  delete r.preAssignedLabels
  delete r.scheduledLabels
  delete r.preAssignedActivities
  delete r.scheduledActivities

  r.activities?.sort((a, b) => a.startTime - b.startTime)
  r.activities?.forEach(convertMillisToISOTimestamps)
  r.activities?.forEach((d, i) => {
    delete d.flightDutyStartTime
    delete d.flightDutyEndTime
    delete d.nightDuty
    delete d.singleActivity
    delete d.firstActivity
    delete d.lastActivity
    delete d.isExtendedDuty
    delete d.hasInFlightRest
  })
}

const deserializePairings = (r) => {
  if (!r.pairings) r.pairings = []

  r.pairings?.forEach(convertISOTimestampToMillis)
  r.pairings?.forEach((d, i) => {
    d.key = r.crewRole + ' ' + i
  })
}

const serializePairings = (r) => {
  r.pairings?.forEach(convertMillisToISOTimestamps)
  r.pairings?.forEach((d, i) => {
    delete d.key
  })
}

const deserializeNoContracts = (r) => {
  if (!r.noContract) r.noContract = []
  r.noContract?.forEach(convertISOTimestampToMillis)
  r.noContract?.forEach((d, i) => {
    d.key = r.crewRole + ' ' + i
  })
}

const serializeNoContracts = (r) => {
  r.noContract?.forEach(convertMillisToISOTimestamps)
  r.noContract?.forEach((d, i) => {
    delete d.key
  })
}

const deserializeIssues = (r) => {
  if (!r.rosterIssues) r.rosterIssues = []
  if (!r.dataIssues) r.dataIssues = []
  //process rosterIssues
  r.rosterIssues.forEach((d, i) => {
    d.key = 'roster' + r.crewRole + ' ' + i
    d.category = 'roster'

    d.employeeCode = r.employeeCode
  })

  //process dataIssues
  r.dataIssues.forEach((d, i) => {
    d.key = 'data' + r.crewRole + ' ' + i
    d.category = 'data'
    d.employeeCode = r.employeeCode
  })

  // combine roster and data issues into one issue list
  r.issues = r.rosterIssues.concat(r.dataIssues)
  r.issues?.forEach(convertISOTimestampToMillis)
}

const serializeIssues = (r) => {
  //process rosterIssues
  r.issues?.forEach(convertMillisToISOTimestamps)
  r.rosterIssues = r.issues
    ?.filter((i) => i.category === 'roster')
    .forEach((i) => {
      delete i.key
      delete i.category
      delete i.employeeCode
    })

  r.dataIssues = r.issues
    ?.filter((i) => i.category === 'data')
    .forEach((i) => {
      delete i.key
      delete i.category
      delete i.employeeCode
    })
  delete r.issues
}

const deserializeMetrics = (r) => {
  // For each entry `foobar` in equalDistributionMetrics a new entry `foobarTotal`
  // is created in equalDistributionMetrics which contains a combination of `foobar`
  // actuals and targets over the avaiable periods => the sum of `actual` values,
  // the sum of `target` values, minimum of `startDate` and maximum of `endDate`.
  if (r.equalDistributionMetrics) {
    // Get list of metrics prior to looping over data since equalDistributionMetrics
    // will be modified during the loop
    const metrics = Object.keys(r.equalDistributionMetrics)
    for (const metric of metrics) {
      r.equalDistributionMetrics[`${metric}Total`] = r.equalDistributionMetrics[metric].reduce(
        (a, b) => {
          return {
            actual: a.actual + b.actual,
            target: a.target + b.target,
            startDate: a.startDate < b.startDate ? a.startDate : b.startDate,
            endDate: a.endDate > b.endDate ? a.endDate : b.endDate,
          }
        },
        { actual: 0, target: 0, startDate: null, endDate: null }
      )
    }
  }
}

const serializeMetrics = (r) => {}

export {
  deserializeFlightDuties,
  serializeFlightDuties,
  deserializeActivities,
  serializeActivities,
  deserializePairings,
  deserializeNoContracts,
  deserializeIssues,
  deserializeMetrics,
}
