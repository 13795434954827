import { barData, alphaMap, publicationColors } from '../barData'
import * as d3 from 'd3'
const binSize = 1
const maxBlockHours = 80
const hoverWidth = 9

const makeBlockHourData = (activeReports, filter) => {
  const out = []

  for (let report in activeReports) {
    const baseData = activeReports[report].payload.memberStatistics.filter(
      (member) =>
        (filter.scheduled && member.scheduledBlockHours) || (filter.preAssigned && member.preAssignedBlockHours)
    )
    var histogram = baseData.map((member) => {
      const sum =
        (filter.scheduled ? member.scheduledBlockHours || 0 : 0) +
        (filter.preAssigned ? member.preAssignedBlockHours || 0 : 0)

      return sum
    })

    const graphData = {
      y: histogram,
      customdata: baseData,
      hoverinfo: 'text',
      yaxis: `y`,
      meta: { publication: activeReports[report].title, histogram: histogram },
      marker: { color: activeReports[report].color, pattern: {} },
      xaxis: `x${+2 * report + 2}`,
      label: 'Block hours',
      type: 'histogram',
      opacity: 0.7,

      ybins: { start: -0.5, size: binSize, end: 80 },
    }
    out.push(graphData)
    out.push({
      ...graphData,
      type: 'box',
      xaxis: `x${+2 * report + 1}`,
    })
  }

  return out
}

export default makeBlockHourData
