import React, { useState, useEffect } from 'react'
import InputDate from './InputDate'
import { DateRangeInput2 } from '@blueprintjs/datetime2'
import { LocaleUtils } from 'react-day-picker'
const myLocaleUtils = { ...LocaleUtils, getFirstDayOfWeek: () => 1 }
import Validate from './Validate'
import { DateTime } from 'luxon'
import { lux } from 'shared/LuxonHelpers'

const InputDateRange = (props) => {
  const { error, onChange, from, to, disabled, startDisabled, endDisabled, style, popoverProps } = props

  return (
    <DateRangeInput2
      disabled={props.disabled}
      minDate={props.minDate || DateTime.now().minus({ years: 10 }).toJSDate()}
      maxDate={props.maxnDate || DateTime.now().plus({ years: 2 }).toJSDate()}
      highlightCurrentDay
      fill
      shortcuts={false}
      localeUtils={myLocaleUtils}
      defaultTimezone="UTC"
      popoverProps={{
        usePortal: true,
        placement: 'bottom',
        portalContainer: document.getElementById('main'),
      }}
      onChange={(dates) => {
        props.onChange([lux(dates[0])?.toFormat('yyyy-MM-dd'), lux(dates[1])?.toFormat('yyyy-MM-dd')])
      }}
      formatDate={(date) => lux(date).toFormat('yyyy-MM-dd')}
      parseDate={(str) => lux(str)?.toJSDate()}
      value={[lux(from)?.toJSDate(), lux(to)?.toJSDate()]}
      defaultValue={props.defaultValue}
    />

    /*
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <InputDate
        style={style}
        popoverProps={popoverProps}
        disabled={disabled || startDisabled}
        error={error?.fromDate}
        placeholder="Start date"
        minDate={props.minDate || undefined}
        maxDate={internalToDate ? lux(internalToDate).toJSDate() : undefined}
        onChange={(date) => {
          setInternalFromDate(date)
          onChange(date, internalToDate)
        }}
        value={internalFromDate}
      />
      <span style={{ marginRight: 10, marginLeft: 10 }}>to</span>
      <InputDate
        style={style}
        popoverProps={popoverProps}
        disabled={disabled || endDisabled}
        error={error?.toDate}
        placeholder="End date"
        minDate={internalFromDate ? lux(internalFromDate).toJSDate() : undefined}
        initialMonth={internalFromDate ? lux(internalToDate || internalFromDate).toJSDate() : undefined}
        onChange={(date) => {
          setInternalToDate(date)
          onChange(internalFromDate, date)
        }}
        value={internalToDate}
      />
    </div>
    */
  )
}

export default InputDateRange
