import { Button, Menu, MenuItem } from '@blueprintjs/core'
import { MultiSelect2 } from '@blueprintjs/select'
import differenceBy from 'lodash/differenceBy'
import React, { useState, useEffect } from 'react'
import Loader from '../Loader/Loader'
import styles from './SearchDropdown.module.scss'
import { FixedSizeList as List } from 'react-window'
import { CHART_TOOL_PANEL_MENU_OPTIONS } from 'ag-grid-community'

const MultiSearchDropdown = (props) => {
  const [selected, setSelected] = useState([])
  const [query, setQuery] = useState('')

  function itemListRenderer(itemProps) {
    return (
      <Menu style={{ padding: 0, width: props.width, minWidth: props.width }}>
        {itemProps.filteredItems.length === 0 ? (
          <MenuItem disabled={true} text="No results." />
        ) : (
          <List
            height={Math.min(150, itemProps.filteredItems.length * 30) - 1}
            itemCount={itemProps.filteredItems.length}
            itemSize={30}
            width={props.width}>
            {({ index, style }) => <div style={style}>{itemProps.renderItem(itemProps.items[index], index)}</div>}
          </List>
        )}
      </Menu>
    )
  }

  const handleTagRemove = (_tag, index) => {
    let newSelected = props.items.filter((item) => props.value.indexOf(item.value) >= 0)
    newSelected.splice(index, 1)
    setSelected(newSelected)
    props.onChange(newSelected.map((d) => d.value))
  }
  const handleClear = () => {
    const newSelected = []
    setSelected(newSelected)
    props.onChange(newSelected.map((d) => d.value))
  }

  useEffect(() => {
    setSelected(items.filter((item) => (props.value || []).indexOf(item.value) >= 0) || [])
  }, [props.items, props.value])

  let { value, loading, placeholder, items, fill, style } = props

  const clearButton = selected.length > 0 ? <Button icon="cross" minimal onClick={handleClear} /> : undefined

  return (
    <MultiSelect2
      fill={fill}
      disabled={props.disabled}
      itemListRenderer={itemListRenderer}
      popoverContentProps={{}}
      popoverProps={{
        minimal: true,
        matchTargetWidth: true,
        //shouldReturnFocusOnClose: false,
        usePortal: false,
        portalContainer: document.getElementById('main'),

        position: 'auto-start',
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },

        popoverClassName: styles.popover,
      }}
      popoverTargetProps={{
        style: { ...style, maxHeight: 100, overflowY: 'auto' },
        className: fill ? styles.fill : undefined,
      }}
      tagRenderer={(item) => item.text}
      tagInputProps={{
        inputProps: { className: styles.queryColor },
        tagProps: {
          minimal: true,
        },
        onRemove: handleTagRemove,
        rightElement: loading ? <Button minimal disabled icon={<Loader type="dots" active={true} />} /> : clearButton,
      }}
      itemPredicate={(query, item, _index, exactMatch) => {
        return item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0
      }}
      openOnKeyDown={false}
      noResults={<MenuItem disabled={true} text="No results." />}
      selectedItems={selected}
      closeOnSelect={true}
      resetOnSelect={true}
      resetOnClose={true}
      placeholder={placeholder}
      query={query}
      onQueryChange={(e) => {
        let items = props.items.filter((d) => d.text.toLowerCase().includes(e.toLowerCase()))
        let item
        if (items?.length === 1) item = items[0]
        if (item) {
          const newSelected = [...selected, item]
          setQuery('')
          setSelected(newSelected)
          props.onChange(newSelected.map((d) => d.value))
        } else setQuery(e)
      }}
      onItemSelect={(item) => {
        const newSelected = [...selected, item]
        setSelected(newSelected)
        props.onChange(newSelected.map((d) => d.value))
      }}
      resetOnQuery={true}
      items={query ? items.filter((d) => d.text.toUpperCase().includes(query.toUpperCase())) : items}
      itemRenderer={(item, { handleClick, modifiers, query }) => {
        return item ? (
          <MenuItem
            key={item.key}
            text={item?.text}
            active={modifiers.active}
            disabled={modifiers.disabled}
            onClick={handleClick}
          />
        ) : null
      }}
      inputValueRenderer={(e) => e.text}
    />
  )
}

export default MultiSearchDropdown
