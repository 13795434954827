import React, { useState, useEffect } from 'react'
import api from './api'
import { Callout, Button } from '@blueprintjs/core'
import { useAuth } from './auth'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Cookie from 'js-cookie'

import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import App from '../App'
import logo from 'images/motuplan-logo.png'
import config from 'config'

const Unauthorized = () => {
  const auth = useAuth()

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(27, 38, 48)',
        color: 'white',
      }}>
      <div style={{ width: 500, textAlign: 'center' }}>
        <Callout style={{ padding: '20px 60px' }}>
          <img src={logo} style={{ width: 300 }} />
          <div style={{ fontSize: 18, margin: '20px 0px' }}>
            You are not authorized to use this application.
            <br />
            Sign in with different credentials or contact{' '}
            <a href={'mailto:' + config.supportEmail}>{config.supportEmail}</a> for assistance.
          </div>
          <Button large fill intent="primary" onClick={() => api.logout()} text="OK" />
        </Callout>
      </div>
    </div>
  )
}
export default Unauthorized
