import React, { useMemo } from 'react'
import styles from './DutyLabel.module.scss'
const DutyLabel = (props) => {
  const d = props.data
  const style = useMemo(() => {
    return {
      // marginLeft: d.startTimeX - props.margin,
      width: 30,
      top: props.expanded ? (d.preAssigned ? 36 : 3) : 3,
      transform: `translateX(${d.pos}px)`,
      borderBottom: d.planeswap ? '1px solid red' : undefined,
    }
  }, [d.pos, props.x, d.planeSwap, d.preAssigned, props.expanded])

  const className = useMemo(
    () =>
      [
        styles.flightDutyLabel,
        d.preAssigned ? styles.preAssignedLabel : undefined,
        d.disruptive ? styles.disruptive : undefined,
      ].join(' '),
    [styles, d.preAssigned, d.disruptive]
  )
  return (
    <div className={className} style={style}>
      {d.time}
    </div>
  )
}

export default DutyLabel
