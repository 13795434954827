import React from 'react'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import styles from './Validate.module.scss'

const Validate = ({ fill, data, validation_errors, children, position }) => {
  return !validation_errors ? (
    <span style={{ display: 'inline-block', width: fill ? '100%' : undefined }}>{children}</span>
  ) : (
    <Tooltip2
      small
      fill={fill}
      portalContainer={document.getElementById('main')}
      disabled={!validation_errors}
      intent="danger"
      className={fill ? styles.tooltipWrapper : undefined}
      //      targetProps={{ className: fill ? styles.tooltipWrapper : undefined }}

      content={validation_errors?.join(' ')}
      isOpen={validation_errors}
      position={position || Position.TOP}>
      {children}
    </Tooltip2>
  )
}

export default Validate
