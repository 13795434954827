import React, { useEffect, useState } from 'react'

import { MpPlot, MpPlotWrapper } from '../MpPlot'
import { useSelector } from 'react-redux'
import costDetails from './costDetails'
import { Card } from '@blueprintjs/core'
import MultiSearchDropdown from '../../shared/SearchDropdown/MultiSearchDropdown'
import * as d3 from 'd3'
import { barData, alphaMap } from '../barData'
import { current } from 'immer'

import { BarTooltip } from 'dashboard/Tooltip'

const Cost = () => {
  const selectedCostFields = useSelector((state) => state.reports.filter.costFields)
  const [totalsData, setTotalsData] = useState({})
  const [detailsData, setDetailsData] = useState({})
  const reports = useSelector((state) => state.reports)
  const { filter } = reports
  const rep = reports.activeReports.slice()
  const costReportsData = []
  const totalReportsData = []
  const activeReports = rep
  const softCostFields = selectedCostFields.filter((d) => costDetails[d].isSoft)
  const businessCostFields = selectedCostFields.filter((d) => !costDetails[d].isSoft)

  const totalSoft = ['scheduled', 'preAssigned'].map((field, i) => {
    const y = activeReports.map((report) => (filter[field] ? report.payload.crewStatistics[field + 'SoftCost'] : 0))
    return {
      type: 'bar',
      base: activeReports.map((report) =>
        filter.scheduled && filter.preAssigned && field === 'scheduled'
          ? report.payload.crewStatistics['preAssignedSoftCost']
          : 0
      ),
      hoverinfo: 'none',
      textposition: 'outside',
      offsetgroup: 1,
      label: field === 'preAssigned' ? 'pre-assigned' : 'optimized',
      meta: { publication: activeReports.map((d) => d.title) },
      textfont: { color: activeReports.map((d) => d.color), size: 14 },

      marker: {
        pattern:
          field === 'scheduled'
            ? { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 }
            : null,
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })
  const totalBusiness = ['scheduled', 'preAssigned'].map((field, i) => {
    const y = activeReports.map((report) => (filter[field] ? report.payload.crewStatistics[field + 'BusinessCost'] : 0))
    return {
      type: 'bar',
      base: activeReports.map((report) =>
        filter.scheduled && filter.preAssigned && field === 'scheduled'
          ? report.payload.crewStatistics['preAssignedBusinessCost']
          : 0
      ),
      hoverinfo: 'none',
      textposition: 'outside',
      offsetgroup: 1,
      label: field === 'preAssigned' ? 'pre-assigned' : 'optimized',
      meta: { publication: activeReports.map((d) => d.title) },
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        pattern:
          field === 'scheduled'
            ? { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 }
            : null,
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })
  const totalCost = ['scheduled', 'preAssigned'].map((field, i) => {
    const y = activeReports.map(
      (report) =>
        (filter[field] ? report.payload.crewStatistics[field + 'BusinessCost'] : 0) +
        (filter[field] ? report.payload.crewStatistics[field + 'SoftCost'] : 0)
    )
    return {
      type: 'bar',
      base: activeReports.map((report) =>
        filter.scheduled && filter.preAssigned && field === 'scheduled'
          ? report.payload.crewStatistics['preAssignedSoftCost'] +
            report.payload.crewStatistics['preAssignedBusinessCost']
          : 0
      ),
      hoverinfo: 'none',
      textposition: 'outside',
      offsetgroup: 1,
      label: field === 'preAssigned' ? 'pre-assigned' : 'optimized',
      meta: { publication: activeReports.map((d) => d.title) },
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        pattern:
          field === 'scheduled'
            ? { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 }
            : null,
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      yaxis: 'y',
      x: activeReports.map((d) => d.title),
      y: y,
    }
  })

  return (
    <div>
      <MpPlotWrapper>
        <MpPlot
          title={'Total Cost'}
          tooltip={BarTooltip}
          data={totalCost}
          layout={{
            height: 400,
            xaxis: {
              visible: false,

              fixedrange: true,
            },
            yaxis: {
              showgrid: true,
              zeroline: true,
              fixedrange: true,
            },
            annotations: activeReports.map((report, f) => {
              const y =
                (filter.scheduled ? report.payload.crewStatistics['scheduledBusinessCost'] : 0) +
                (filter.preAssigned ? report.payload.crewStatistics['preAssignedBusinessCost'] : 0) +
                (filter.scheduled ? report.payload.crewStatistics['scheduledSoftCost'] : 0) +
                (filter.preAssigned ? report.payload.crewStatistics['preAssignedSoftCost'] : 0)
              return {
                y,
                x: f,
                font: { color: report.color },
                yanchor: 'bottom',
                showarrow: false,
                text: y < 10000 ? y : d3.format('.3~s')(y),
              }
            }),
            grid: { rows: 1, columns: 1, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />
        <MpPlot
          title={'Total Business Cost'}
          tooltip={BarTooltip}
          data={totalBusiness}
          layout={{
            height: 400,
            yaxis: { zeroline: true, showgrid: true, fixedrange: true },
            xaxis: {
              visible: false,

              fixedrange: true,
            },
            annotations: activeReports.map((report, f) => {
              const y =
                (filter.scheduled ? report.payload.crewStatistics['scheduledBusinessCost'] : 0) +
                (filter.preAssigned ? report.payload.crewStatistics['preAssignedBusinessCost'] : 0)

              return {
                y,
                x: f,
                font: { color: report.color },
                yanchor: 'bottom',
                showarrow: false,
                text: y < 10000 ? y : d3.format('.3~s')(y),
              }
            }),
            grid: { rows: 1, columns: 1, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />{' '}
        <MpPlot
          title={'Total Soft Cost'}
          tooltip={BarTooltip}
          data={totalSoft}
          layout={{
            height: 400,
            xaxis: {
              visible: false,

              fixedrange: true,
            },
            yaxis: {
              showgrid: true,
              zeroline: true,
              fixedrange: true,
            },
            annotations: activeReports.map((report, f) => {
              const y =
                (filter.scheduled ? report.payload.crewStatistics['scheduledSoftCost'] : 0) +
                (filter.preAssigned ? report.payload.crewStatistics['preAssignedSoftCost'] : 0)
              return {
                y,
                x: f,
                font: { color: report.color },
                yanchor: 'bottom',
                showarrow: false,
                text: y < 10000 ? y : d3.format('.3~s')(y),
              }
            }),
            grid: { rows: 1, columns: 1, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />
      </MpPlotWrapper>
    </div>
  )
}

export default Cost
