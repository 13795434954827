import {
  ADD_CONTEXT_SETTINGS_DATA_OBJECTS,
  ADD_CONTEXT_SETTINGS_DATA_OBJECT,
  STORE_DATA_OBJECT_VALIDATION_ERRORS,
} from './actions'

const initialState = {
  items: [],
  loading: false,
}

export default function rosterReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CONTEXT_SETTINGS_DATA_OBJECTS:
      return {
        ...state,
        items: action.payload,
      }

    case ADD_CONTEXT_SETTINGS_DATA_OBJECT: {
      let newItems = [...state.items]
      let index = newItems.findIndex((d) => (action.payload ? d.id === action.payload.id : 0))
      newItems.splice(index, 1, action.payload)

      return {
        ...state,
        items: newItems,
      }
    }
    case STORE_DATA_OBJECT_VALIDATION_ERRORS: {
      let newItems = [...state.items]
      let item = newItems.find((d) => d.id === action.payload.id)
      item.validation_errors = action.payload.errors
      return {
        ...state,
        items: newItems,
      }
    }
    default:
      return state
  }
}
