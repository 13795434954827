import store from 'store'
import api from 'auth/api'
import handleErrors from './handleErrors'
import Cookie from 'js-cookie'
import { useAuth } from 'auth/auth'
const myDelete = async (url, configuration) => {
  const auth = useAuth()
  if (!auth) await api.trySilentRefresh()

  const token = Cookie.get('accessToken')
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  })
  const newConfiguration = {
    ...configuration,
    headers,
    method: 'DELETE',
  }
  return fetch(url, newConfiguration).then(handleErrors)
}

export default myDelete
