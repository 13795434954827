import React, { useEffect, useState, useRef } from 'react'
import { IssueTag } from '../Issue/Issue'
import styles2 from '../CrewMember.module.scss'
import groupBy from 'lodash/groupBy'
import store from 'store'
import { useSelector } from 'react-redux'
import styles from './CrewMemberLabel.module.scss'
import { SAFEcolorScale } from '../SafeCare'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import tooltipStyles from '../Activity/Tooltips.module.scss'
import { iconCrew, iconBed } from 'icons'
import { Icon } from '@blueprintjs/core'

const PlaneTooltip = ({ d }) => {
  const issues = [...d.rosterIssues, ...d.dataIssues]
  const groupedIssues = groupBy(issues, (d) => d.description)
  return (
    <div className={tooltipStyles.tooltipLong}>
      <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <strong style={{ flex: 1, minWidth: 100 }}>{d.employeeCode}</strong>

        <span>
          {d.aircraftQualifications?.[0]?.qualificationCode} &nbsp;
          <Icon size={16} icon="airplane" />
        </span>
      </span>

      {issues.length > 0 && (
        <>
          <div className={tooltipStyles.line} />
          {Object.keys(groupedIssues).map((d, i) => (
            <>
              <IssueTag
                count={groupedIssues[d].length}
                key={i}
                d={{ description: d, severity: groupedIssues[d][0].severity }}
                style={{ marginBottom: 5 }}
              />
            </>
          ))}
        </>
      )}
    </div>
  )
}

const PlaneLabel = (props) => {
  const d = props.data

  return (
    <Tooltip2
      portalContainer={document.getElementById('main')}
      targetClassName={styles.hoverLabel}
      disabled={props.dragging}
      content={<PlaneTooltip d={d} placement={Position.RIGHT} />}
      renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => (
        <div {...tooltipProps} style={{ position: 'absolute', left: 0, right: 0, height: 34, top: d.top }}>
          <div
            style={{ position: 'absolute', left: 0, right: 0, top: 0 }}
            onClick={() => {
              if (props.onClick) props.onClick(d)
            }}
            className={[
              styles.rosterLabel,

              d.odd ? styles2.odd : styles2.even,
              props.hover ? styles2.hover : undefined,
            ].join(' ')}
            onMouseEnter={() => props.onHover(d.employeeCode)}
            onMouseLeave={() => props.onHover(null)}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '100%' }}>
              <span style={{ marginLeft: 5 }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginRight: 15,
                    width: 30,
                    fontSize: 12,
                    marginBottom: 4,
                    color: [...(d.rosterIssues || []), ...(d.dataIssues || [])].length > 0 ? 'red' : undefined,
                  }}>
                  {d.employeeCode}
                </div>

                <div style={{ color: '#aaa' }}> {d.aircraftQualifications?.[0]?.qualificationCode}</div>
              </span>
              <span style={{ flex: '0 0 auto', marginRight: 10, width: 70 }}>
                <div style={{ marginBottom: 4 }}>{d.homeBase}</div>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <i>{d.contractBase?.filter((b) => b !== d.homeBase).join(', ')}</i> &nbsp;
                </div>
              </span>
            </div>
          </div>
        </div>
      )}
    />
  )
}
export default React.memo(PlaneLabel)
