import React, { Component } from 'react'
import { Callout, Collapse, Pre, Switch } from '@blueprintjs/core'
import AceEditor from 'react-ace'
import store from 'store'
import { connect } from 'react-redux'
import { addError } from './actions'
import 'ace-builds/src-noconflict/mode-ruby'

const mapStateToProps = (state) => ({
  errors: state.log.errors,
})

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, isOpen: false }
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    store.dispatch(addError({ error, info }))
    this.setState({ details: error })
    console.log(`Cause: ${error}.\nStackTrace: ${info.componentStack}`)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Callout
          intent="danger"
          title="Something went wrong!"
          style={{ width: 'auto', margin: 'auto', marginTop: 40, maxWidth: 600 }}>
          Unfortunately, there was problem rendering this component. This usually means that there is a problem with the
          underlying data.
          <Switch
            style={{ marginTop: 20 }}
            intent="danger"
            checked={this.state.isOpen}
            label="Show technical details"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          />
          {[this.state.details].map((d, i) => (
            <Collapse key={i} isOpen={this.state.isOpen}>
              <AceEditor
                mode="ruby"
                theme="github"
                readOnly={true}
                defaultValue={d?.stack}
                width="100%"
                height="400px"
              />
            </Collapse>
          ))}
        </Callout>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
