module.exports = {
  flights: { preAssigned: 'preAssignedFlights', scheduled: 'scheduledFlights', label: 'flight<br />assigments' },
  'nights away from base': {
    preAssigned: 'preAssignedNightsAwayFromBase',
    scheduled: 'scheduledNightsAwayFromBase',
    label: 'nights away<br />from base',
  },
  'non long haul nights away from base': {
    preAssigned: 'preAssignedNonLongHaulNightsAwayFromBase',
    scheduled: 'scheduledNonLongHaulNightsAwayFromBase',
    label: 'non long haul<br />nights away<br />from base',
  },
  standby: { preAssigned: 'preAssignedStandbys', scheduled: 'scheduledStandbys' },
  'flights from regional base': {
    preAssigned: 'preAssignedFlightsFromRegionalBase',
    scheduled: 'scheduledFlightsFromRegionalBase',
    label: 'flights from<br />regional base',
  },
  'hotel nights': { preAssigned: 'preAssignedHotelNights', scheduled: 'scheduledHotelNights' },
  grounds: { preAssigned: 'preAssignedGroundActivities', scheduled: 'scheduledGroundActivities' },
  taxis: { preAssigned: 'preAssignedTaxis', scheduled: 'scheduledTaxis' },
  'taxi own means': {
    preAssigned: 'preAssignedTaxiOwnMeans',
    scheduled: 'scheduledTaxiOwnMeans',
    label: 'taxi<br />own means',
  },
  'taxi long': {
    preAssigned: 'preAssignedLongTaxiRides',
    scheduled: 'scheduledLongTaxiRides',
    label: 'taxi<br />long',
  },
  deadheads: { preAssigned: 'preAssignedDeadheads', scheduled: 'scheduledDeadheads' },
  'flights below rank': {
    preAssigned: 'preAssignedFlightsBelowRank',
    scheduled: 'scheduledFlightsBelowRank',
    label: 'flights<br />below rank',
  },
  'iata flights': { preAssigned: 'preAssignedIataFlights', scheduled: 'scheduledIataFlights' },
}
