// Import our depdendency
import myFetch from 'api/myFetch'
import myPut from 'api/myPut'
import { addMyProfile } from 'auth/actions'
import config from 'config'
import store from 'store'
const servicesFactory = (myFetch, myPut) => ({
  async saveMyProfile(data) {
    let json = await myPut(`${config.api}/profile/`, {
      body: JSON.stringify(data),
    })
    const action = addMyProfile(json)

    store.dispatch(action)
  },
})

const users = servicesFactory(myFetch, myPut)
export default users
