import { combineReducers } from 'redux'
import CrewMgmtReducer from './CrewMgmt/reducer'
import RulesEngineReducer from './RulesEngine/reducer'
import undoReducer from './CrewMgmt/UndoReducer'

export const { reducer, add, undo, redo } = undoReducer({
  newUndoableActions: 'USER_ADD',
  undoAction: 'USER_UNDO',
  redoAction: 'USER_REDO',
})

export default combineReducers({
  undo: reducer,
  Repository: CrewMgmtReducer,
  RulesEngine: RulesEngineReducer,
})
