import React, { Component, useEffect } from 'react'
import styles from './Data.module.scss'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import api from '../api'
import { AgGridReact } from 'ag-grid-react'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import * as _ from 'lodash'

const setCellStyle = (props) => {
  return props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(' ')
    ? styles.error
    : props.colDef.cellRendererParams?.changedCells?.[props.rowIndex]?.[props.colDef.field]
    ? styles.changed
    : styles.normal
}

const Cell = (props) => {
  let error =
    props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(' ')

  return (
    <span style={{ lineHeight: '12px' }}>
      <Tooltip2
        disabled={!error}
        position={Position.BOTTOM}
        intent="danger"
        usePortal={false}
        content={error}
        modifiers={{ hide: true }}
        targetProps={{ style: { width: '100%', height: '100%', position: 'absolute', marginLeft: -11 } }}>
        <div
          style={{
            position: ' ',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}></div>
      </Tooltip2>
      {props.value}
    </span>
  )
}
export { Cell, setCellStyle }
