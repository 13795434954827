import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Pane from './Pane'

const Scroller = (props) => {
  const { data, LabelElement, RowElement } = props

  const filter = useSelector((state) => state.rosters.filter)

  const timezone = filter.timezone
  const expanded = useSelector((state) => state.rosters.expandedCrewMembers)
  const toggleSplitPreAssigned = useSelector((state) => state.rosters.filter.toggleSplitPreAssigned)
  const [hover, setHover] = useState(false)

  // select data in scrollY view
  const datalist = data

  return (
    <Pane
      {...props}
      onDragInProgress={props.onDragInProgress}
      scrollChange={props.scrollChange}
      x={props.x}
      labels={(d) => (
        <LabelElement
          expanded={expanded.includes(d.employeeCode) || toggleSplitPreAssigned}
          data={d}
          issues={props.issues}
          key={d.employeeCode}
          dragging={props.dragInProgress}
          hover={d.employeeCode === hover}
          onHover={setHover}
          onClick={
            props.onLabelAdd
              ? () => {
                  props.onLabelAdd(d)
                }
              : undefined
          }
        />
      )}
      data={datalist}
      row={(d, x, xScale, width, drag, y) => (
        <RowElement
          filteredCrew={props.filteredCrew}
          expanded={expanded.includes(d.employeeCode) || toggleSplitPreAssigned}
          edit={props.edit}
          key={d.employeeCode}
          onClick={props.onClick}
          rosterData={props.rosterData}
          onActivityClick={props.onActivityClick}
          timezone={timezone}
          hover={d.employeeCode === hover}
          onHover={setHover}
          droppable={props.droppable}
          draggable={props.draggable}
          draggedItem={props.draggedItem}
          dragging={props.dragInProgress}
          issues={props.issues}
          data={d}
          x={x}
          xScale={xScale}
          width={width}
          offset={y}
          displayAirportTimes={props.displayAirportTimes}
          displaySafeCare={props.displaySafeCare}
          displayDutyTimes={props.dutyTimes}
        />
      )}
    />
  )
}

export default React.memo(Scroller)
