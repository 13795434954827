import React, { useState, useEffect } from 'react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { Cell, setCellStyle } from '../AgGridCellValidator'
import { DateTime } from 'luxon'
import { lux, startOf, endOf } from 'shared/LuxonHelpers'
import { FormGroup, Collapse, Callout, H5, H6, Tag, Icon, Classes, Button, Divider, Switch } from '@blueprintjs/core'
import { InputText, InputDate, InputTime } from 'shared/Forms'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import { useSelector } from 'react-redux'
const ActivityDialog = (props) => {
  const [isOpen, setIsOpen] = useState(null)
  const timezone = useSelector((state) => state.rosters.filter.timezone)

  useEffect(() => {
    props.data ? setIsOpen(true) : setIsOpen(null)
  }, [props.data])

  return (
    <CreateOrSaveDialog
      style={{ width: 700 }}
      usePortal={false}
      //   payload={personalData}
      //   id={personalData}
      icon="timeline-events"
      title="Activity"
      edit={!isNaN(props.data) && props.data ? null : props.data}
      resetObject={
        !isNaN(props.data) && props.data
          ? {
              typeOfActivity: 'W',
              from: 'BRU',
              to: 'BRU',
              activityID: Math.floor(Math.random() * 10000000),
              startTime: new Date(startOf(props.data, 'UTC', 'day')).getTime(),
              endTime: new Date(endOf(props.data, 'UTC', 'day')).getTime(),
            }
          : null
      }
      onDelete={props.onDelete}
      onClose={(d) => {
        setIsOpen(null)
        props.onClose ? props.onClose(d) : undefined
      }}
      onSave={props.onSave}
      onCreate={props.onCreate}
      isOpen={isOpen}>
      {(data, setData) => {
        return (
          <div>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Code</span>} inline>
              <SelectDropdown
                items={['Flight', 'Taxi', 'Weekend', 'Hotel']}
                activeItem={'Flight'}
                onChange={(value) => setData({ ...data, typeOfActivity: value })}
              />
            </FormGroup>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Type</span>} inline>
              <InputText
                style={{ width: 80 }}
                value={data?.activityCode}
                onChange={(value) => setData({ ...data, activityCode: value })}
              />
            </FormGroup>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>From</span>} inline>
              <InputDate
                timezone={timezone}
                value={data?.startTime}
                onChange={(value) => setData({ ...data, startTime: value })}
              />
              <InputTime
                value={lux(data?.startTime, timezone)?.toFormat('HH:mm')}
                onChange={(value) => {
                  setData({
                    ...data,
                    startTime: DateTime.fromISO(
                      lux(data?.startTime, timezone).toFormat('yyyy-MM-dd') + 'T' + value + 'Z',
                      'yyyy-MM-dd HH:mm',
                      'UTC'
                    ).toMillis(),
                  })
                }}
              />
              <AirportDropdown activeItem={data?.from} onChange={(value) => setData({ ...data, from: value.iata })} />
            </FormGroup>
            <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>To</span>} inline>
              <InputDate value={data?.endTime} onChange={(value) => setData({ ...data, endTime: value })} />
              <InputTime
                value={lux(data?.endTime, timezone)?.toFormat('HH:mm')}
                onChange={(value) => {
                  setData({
                    ...data,
                    endTime: DateTime.fromISO(
                      lux(data?.endTime, timezone).toFormat('yyyy-MM-dd') + 'T' + value + 'Z',
                      'yyyy-MM-dd HH:mm',
                      'UTC'
                    ).toMillis(),
                  })
                }}
              />
              <AirportDropdown activeItem={data?.to} onChange={(value) => setData({ ...data, to: value })} />
            </FormGroup>
            {data?.typeOfActivity !== 'F' && !isNaN(props.data) && props.data && (
              <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Repeat for</span>} inline>
                <InputText
                  style={{ width: 40 }}
                  onChange={(value) =>
                    setData({
                      ...data,
                      repeat: parseInt(value),
                    })
                  }
                />
                consecutive days
              </FormGroup>
            )}
            {data?.typeOfActivity === 'Flight' && (
              <>
                <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Crew Role</span>} inline>
                  <SelectDropdown
                    items={['CP', 'FO', 'RP', 'SCC', 'ASC', 'CCM']}
                    style={{ width: 80 }}
                    hideFilter
                    activeItem={data?.crewRole}
                    onChange={(value) => setData({ ...data, crewRole: value })}
                  />
                </FormGroup>
                <FormGroup label={<span style={{ width: 100, display: 'inline-block' }}>Flight</span>} inline>
                  <InputText
                    style={{ width: 80 }}
                    placeholder={'ID'}
                    value={data?.flightID}
                    onChange={(value) => setData({ ...data, flightID: value })}
                  />
                  <InputText
                    style={{ width: 80 }}
                    placeholder={'Number'}
                    value={data?.flightNumber}
                    onChange={(value) => setData({ ...data, flightNumber: value })}
                  />
                </FormGroup>
              </>
            )}
          </div>
        )
      }}
    </CreateOrSaveDialog>
  )
}

export default ActivityDialog
