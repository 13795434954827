import { text } from 'd3'
import { publicationColors } from '../barData'
import ViolationTypes from './ViolationTypes'

const binSize = 2
const maxBlockHours = 80
const hoverWidth = 9

const makeTotalViolations = (activeReports, filter) => {
  return ViolationTypes.map((violation, i) => {
    return ['preAssigned', 'scheduled'].map((cat) => {
      let cost = []
      let totalcost = 0
      const y = activeReports.map((report) => {
        const data = report.payload.crewStatistics
        return filter[cat] ? data[`${cat}${violation.key}Violations`] || 0 : 0
      })
      return {
        x: activeReports.map((d) => d.title),
        y,
        base:
          cat === 'scheduled'
            ? activeReports.map((report) => {
                const data = report.payload.crewStatistics
                return filter.preAssigned ? data[`preAssigned${violation.key}Violations`] || 0 : 0
              })
            : 0,
        hoverinfo: 'none',
        xaxis: `x${i + 1}`,
        offsetgroup: 1,
        label: `${cat === 'scheduled' ? 'Optimized' : 'Pre-Assigned'} violations`,
        meta: { publication: activeReports.map((d) => d.title) },
        text: cost,
        totalcost,
        marker: {
          color: activeReports.map((d) => d.color),
          opacity: 0.7,
          pattern: { shape: cat === 'scheduled' ? '/' : '', fillmode: 'overlay', fgopacity: 0.4, solidity: 0.5 },
        },
        type: 'bar',
      }
    })
  })
    .flat()
    .sort((a, b) => a.totalcost > b.totalcost)
}

const makeTotalViolationsRadar = (activeReports, filter) => {
  return activeReports.map((report, i) => {
    const r = ViolationTypes.map((violation) => {
      const data = report.payload.crewStatistics
      return (filter.scheduled ? data[`scheduled${violation.key}Violations`] || 0 : 0) + filter.preAssigned
        ? data[`preAssigned${violation.key}Violations`] || 0
        : 0
    })
    return {
      theta: ViolationTypes.map((d) => d.key),
      r,

      fill: 'toself',
      marker: { color: report.color },
      type: 'scatterpolar',
    }
  })
}
export { makeTotalViolations, ViolationTypes, makeTotalViolationsRadar }
