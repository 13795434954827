import React, { useState, useEffect } from 'react'
import { Tag, Icon, MenuItem, FormGroup } from '@blueprintjs/core'

import { MultiSelect2 } from '@blueprintjs/select'
import styles from '../SearchDropdown/SearchDropdown.module.scss'

const InputTextArray = (props) => {
  const [items, setItems] = useState(props.value || [])

  useEffect(() => setItems(props.value || []), [props.value])
  const { onChange, placeholder } = props

  return (
    <MultiSelect2
      placeholder={placeholder}
      items={[]}
      fill={true}
      selectedItems={items}
      tagInputProps={{ inputProps: { className: styles.queryColor } }}
      tagRenderer={(str) => <Tag>{str}</Tag>}
      itemRenderer={(item, { handleClick, modifiers, query }) => {
        return item ? (
          <MenuItem
            key={item}
            text={item}
            active={modifiers.active}
            disabled={modifiers.disabled}
            onClick={handleClick}
          />
        ) : null
      }}
      resetOnSelect={true}
      onRemove={(item) => {
        const index = items.findIndex((d) => d === item)
        const newItems = [...items]
        newItems.splice(index, 1)
        setItems(newItems)
        onChange(newItems)
      }}
      onItemSelect={(item) => {
        if (item && !items.find((d) => d.toUpperCase() === item.toUpperCase())) {
          const newItems = [...items, item]
          setItems(newItems)
          onChange(newItems)
        }
      }}
      createNewItemFromQuery={(str) => str}
    />
  )
}

export default InputTextArray
