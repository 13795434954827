import React from 'react'
import { Callout } from '@blueprintjs/core'

const InvalidPublication = ({ data }) => (
  <span>
    {data.results
      .filter((d) => !d.is_valid)
      .map((d, i) => (
        <Callout key={i} style={{ marginBottom: 5 }}>
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              width: 200,
              display: 'inline-block',
            }}>
            {d.context}
          </span>{' '}
          {d.messages.join(' ')}
        </Callout>
      ))}
  </span>
)

export default InvalidPublication
