import { Alignment, Button, ControlGroup, Navbar, Tab, Tabs } from '@blueprintjs/core'
import * as moment from 'moment'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Counter from 'shared/Counter/Counter'
import Divider from 'shared/Divider/Divider'
import { Col, Cols, Row, Rows } from 'shared/PanelLayout/PanelLayout'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import api from './api'
import Bid from './BidTypes/Bid'
import EmployeeRoster from './RosterResource'

const mapStateToProps = (state) => {
  return {
    bidTypes: state.bidding.bidTypes,
    bids: state.bidding.bids,
  }
}

const CCalenderTimeline = (props) => {
  const [balance, setBalance] = useState(100)
  const [activeTab, setActiveTab] = useState('pending')
  const [time, setTime] = useState({ startTime: moment(new Date()), endTime: moment(new Date()) })
  const [filterVisible, setFilterVisible] = useState(true)
  const [newBidType, setNewBidType] = useState()
  const { bids, bidTypes } = props

  useEffect(() => {
    api.getEmployeeData()
    api.getAllBidTypes()
  }, [])

  const availableCredits = () => {
    return balance - bids.reduce((sum, d) => d.credits + sum, 0)
  }
  const handleTabChange = (d) => {
    setActiveTab(d)
  }

  return (
    <Cols>
      <Col>
        <Rows>
          <Row>
            <EmployeeRoster
              onChange={(e) => api.createNewBid(e)}
              onCreate={(e) => api.createNewBid(e)}
              onDelete={(id) => api.deleteBid(id)}
              bids={bids}
            />
            {!filterVisible && (
              <Button
                style={{
                  position: 'absolute',
                  right: 5,
                  top: 5,
                  zIndex: 100,
                }}
                minimal
                icon="filter"
                onClick={() => setFilterVisible(true)}
              />
            )}
          </Row>
        </Rows>
      </Col>
      <Col dark shadow collapse="right" collapsed={!filterVisible} width={320}>
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group>
                <Navbar.Heading>BIDS</Navbar.Heading>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                <Tabs large selectedTabId={activeTab} onChange={handleTabChange}>
                  <Tab id="pending" title="Pending" />
                  <Tab id="approved" title="Approved" />
                </Tabs>
                <Button minimal icon="cross" onClick={() => setFilterVisible(false)} />
              </Navbar.Group>
            </Navbar>
          </Row>
          {activeTab === 'pending' && (
            <React.Fragment>
              <Row fixed padding={10}>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50%',
                    float: 'left',
                  }}>
                  <span style={{ textAlign: 'left', fontSize: 40 }}>
                    <Counter intent={availableCredits() < 0 ? 'danger' : ''} value={availableCredits()} />
                  </span>
                  <br />
                  <span
                    style={{
                      textAlign: 'left',
                      fontSize: 20,
                      color: availableCredits() < 0 ? 'red' : 'white',
                    }}>
                    AVAILABLE
                  </span>
                </span>
                <span style={{ display: 'inline-block', width: '50%' }}>
                  <span
                    style={{
                      textAlign: 'right',
                      float: 'right',
                      fontSize: 40,
                    }}>
                    <Counter value={bids.reduce((sum, d) => d.credits + sum, 0)} />
                  </span>
                  <br />
                  <span
                    style={{
                      textAlign: 'left',
                      float: 'right',
                      fontSize: 20,
                      clear: 'both',
                    }}>
                    PENDING
                  </span>
                </span>
                <Divider />
              </Row>
              <Row padding={10}>
                <ControlGroup fill style={{ marginBottom: 20 }}>
                  <SelectDropdown
                    fill
                    hideFilter
                    placeholder="Select Bid"
                    items={bidTypes}
                    onChange={(key) => {
                      setNewBidType(key)
                    }}
                  />
                  <Button
                    disabled={!newBidType}
                    onClick={() => {
                      let d = {
                        id: Math.random(),
                        type: newBidType,
                      }
                      api.createNewBid(d)
                    }}
                    icon="plus"
                  />
                </ControlGroup>

                {bids.map((d, i) => (
                  <Bid
                    key={i}
                    max={100}
                    data={d}
                    onDelete={(id) => api.deleteBid(d.id)}
                    onChange={(d) => api.createNewBid(d)}
                  />
                ))}
              </Row>
            </React.Fragment>
          )}
          {activeTab === 'approved' && <Row fixed padding={10}></Row>}
        </Rows>
      </Col>
    </Cols>
  )
}

const CalenderTimeline = connect(mapStateToProps, null)(CCalenderTimeline)
export default CalenderTimeline

/*
<Chart div title="Treemap" data={data} margin={{
        left: 10,
        right: 10,
        top: 10,
        bottom: 10
      }} alerts={filteredAlerts}><Treemap/></Chart>
      */
