const handleErrors = async (response) => {
  if (!response.ok) {
    // Reject the promise if not ok
    if (typeof response.json === 'function') {
      //check if response contains json error
      const err = await response.json()
      return Promise.reject(err) // and reject promise with json content
    } else return Promise.reject(response) // otherwise return plain object
  } else return response.json() // resolve promise with json content
}
export default handleErrors
