import React from 'react'
import store from 'store'
import { Link, Route, Routes, Navigate, useNavigate, useParams } from 'react-router-dom'
import PrivateRoute from 'PrivateRoute'

import { connect } from 'react-redux'
import styles from './Repository.module.scss'
import { Row, Rows } from 'shared/PanelLayout/PanelLayout'
import { Tag, Tab, Tabs, Navbar } from '@blueprintjs/core'
import { Resources, Activities, Assignments, Violations } from './Tables'
import { VirtualList } from 'ag-grid-community'
import api from '../CrewMgmt/api'

const Repository = (props) => {
  const navigate = useNavigate()
  const { table } = useParams()

  const onChange = (link) => {
    navigate(link)
  }
  /*
  shouldComponentUpdate() {
    if (api.sessionInitialized) return true
    return false
  }
*/

  return (
    <Rows>
      <Row fixed>
        <Navbar>
          <Navbar.Group>
            <Tabs selectedTabId={table} onChange={onChange}>
              <Tab
                id="resources"
                title={
                  <span>
                    Resources
                    <Tag minimal round>
                      {props.Resources}
                    </Tag>
                  </span>
                }
              />
              <Tab
                id="activities"
                title={
                  <span>
                    Activities
                    <Tag minimal round>
                      {props.Activities}
                    </Tag>
                  </span>
                }
              />
              <Tab
                id="assignments"
                title={
                  <span>
                    Assignments
                    <Tag minimal round>
                      {props.Assignments}
                    </Tag>
                  </span>
                }
              />
              <Tab
                id="violations"
                title={
                  <span>
                    Violations
                    <Tag intent="danger" minimal round>
                      {props.Violations}
                    </Tag>
                  </span>
                }
              />
            </Tabs>
          </Navbar.Group>
        </Navbar>
      </Row>
      <Row>
        <Routes>
          <Route path="/" element={<Navigate to="resources" />} />
          <Route path="resources" element={<Resources />} />

          <Route path="activities" element={<Activities />} />

          <Route path="assignments" element={<Assignments />} />

          <Route path="violations" element={<Violations />} />
        </Routes>
      </Row>
    </Rows>
  )
}

const mapStateToProps = (state) => ({
  Resources: state.motassign.RulesEngine.Resources.length,
  Activities: state.motassign.RulesEngine.Activities.length,
  Assignments: state.motassign.RulesEngine.Assignments.length,
  Violations: state.motassign.RulesEngine.Violations.length,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Repository)
