import React, { useEffect, useState, useCallback } from 'react'
import { Icon, Tooltip, Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import tooltipStyles from '../Activity/Tooltips.module.scss'
import { IssueTag } from '../Issue/Issue'
import styles from './EmptyRosterDay.module.scss'
const EmptyRosterDay = (props) => {
  const [open, setOpen] = useState(false)
  const x = props.xScale
  const d = props.data
  const onMouseEnter = useCallback(() => {
    if (!props.drag) setOpen(true)
  }, [props.drag])
  return (
    <span
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setOpen(false)}
      className={styles.emptyRosterDay}
      style={{
        transform: `translateX(${x(d.startTime) + 2}px)`,
        width: x(d.endTime) - x(d.startTime) - 4,
      }}>
      <Icon icon="cross" />
      {open && (
        <Tooltip2
          usePortal={true}
          portalContainer={document.getElementById('main')}
          content={
            <div style={{ maxWidth: 300 }}>
              <IssueTag d={d} />
            </div>
          }
          autoFocus={false}
          enforceFocus={false}
          placement={Position.BOTTOM}
          defaultIsOpen
          targetClassName={styles.tooltip}
          renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => (
            <div style={{ height: 34 }} {...tooltipProps} />
          )}
        />
      )}
    </span>
  )
}

export default React.memo(EmptyRosterDay)
