import React, { useState, useEffect } from 'react'
import InputText from './InputText'

const InputEmployee = (props) => {
  const { error, label, onChange, value, onInputNotValid } = props
  const [internalValue, setInternalValue] = useState(value)
  const [localError, setLocalError] = useState(error)
  useEffect(() => setInternalValue((value + '').toUpperCase()), [value])
  useEffect(() => setLocalError(error), [error])

  return (
    <InputText
      label={label}
      placeholder="eg. XYZ"
      error={localError}
      value={internalValue}
      style={{ width: 100 }}
      errorposition="right"
      onChange={(value) => {
        let output = value ? value.toUpperCase() : value
        setInternalValue(output)
        const r = /^(([A-Z]{3})|(M\d{2}))$/
        let invalid = !r.test(output)
        if (invalid) {
          setLocalError(['Must be an uppercase 3-letter code.'])
          if (onInputNotValid) onInputNotValid(true)
        } else {
          setLocalError(null)
          if (onInputNotValid) onInputNotValid(false)
        }
        if (props.list?.includes(output)) {
          setLocalError(['Already exists'])
        }
        onChange(output, invalid || props.list?.includes(output))
      }}
    />
  )
}

export default InputEmployee
