import * as d3 from 'd3'

const makeDutyTimeAnnotations = (activeReports, filter) => {
  let a = activeReports.map((d) =>
    d.payload.memberStatistics
      .filter((d) => (filter.scheduled && d.scheduledDutyTime) || (filter.preAssigned && d.preAssignedDutyTime))
      .map(
        (d) => (filter.scheduled ? d.scheduledDutyTime || 0 : 0) + (filter.preAssigned ? d.preAssignedDutyTime || 0 : 0)
      )
      .sort()
  )

  const bins = a.map((d) =>
    d3
      .bin()
      .thresholds(Math.max(...d))(d)
      .map((d) => d.length)
  )
  const q1 = a.map((d) => d3.quantile(d, 0.25))
  const q2 = a.map((d) => d3.quantile(d, 0.5))
  const q3 = a.map((d) => d3.quantile(d, 0.75))

  let annotations = activeReports
    .map((pub, i) => {
      const item = pub.payload.memberStatistics.find((d) => d.employeeCode === filter.employee)
      if (!item) return null
      const y = Math.floor(
        (filter.scheduled ? item.scheduledDutyTime || 0 : 0) + (filter.preAssigned ? item.preAssignedDutyTime || 0 : 0)
      )
      if (y == 0) return null
      return {
        yref: 'y',
        x: 0,
        xref: 'x' + (i * 2 + 2),
        y: y,
        ax: -30,

        ay: 0,
        text: item.employeeCode,
      }
    })
    .filter((d) => d !== null)

  q1.forEach((q, i) =>
    annotations.push({
      yref: 'y',
      x: 0,
      font: { color: activeReports[i].color },
      xref: 'x' + (i * 2 + 1),
      y: q,
      showarrow: false,
      yshift: -10,
      xanchor: 'right',
      text: d3.format('.1f')(q),
    })
  )
  q2.forEach((q, i) =>
    annotations.push({
      yref: 'y',
      x: -0.3,
      font: { color: activeReports[i].color },
      showarrow: false,
      xref: 'x' + (i * 2 + 1),
      y: q,

      xanchor: 'right',
      text: d3.format('.1f')(q),
    })
  )
  q3.forEach((q, i) =>
    annotations.push({
      yref: 'y',
      x: 0,
      font: { color: activeReports[i].color },
      xref: 'x' + (i * 2 + 1),
      y: q,
      yshift: +10,
      showarrow: false,
      xanchor: 'right',
      text: d3.format('.1f')(q),
    })
  )
  return annotations
}

export default makeDutyTimeAnnotations
