import store from 'store'
import { myFetch } from 'api'

import { addMyProfile } from './actions'
import config from 'config'
import Cookie from 'js-cookie'
//import ReactPiwik from 'react-piwik'
import jwt from 'jwt-decode'

const scopes = ['openid']
//Generate a Random String
const getRandomString = () => {
  const randomItems = new Uint32Array(28)
  crypto.getRandomValues(randomItems)
  const binaryStringItems = randomItems.map((dec) => `0${dec.toString(16).substr(-2)}`)
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '')
}

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async (str) => {
  const PROTOCOL = 'SHA-256'
  const textEncoder = new TextEncoder()
  const encodedData = textEncoder.encode(str)
  return crypto.subtle.digest(PROTOCOL, encodedData)
}

const hashToBase64url = (arrayBuffer) => {
  const items = new Uint8Array(arrayBuffer)
  const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
  const decodedHash = btoa(stringifiedArrayHash)

  const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  return base64URL
}
const getAuthorizeHref = async () => {
  // Create PKCE code verifier
  var code_verifier = getRandomString()
  sessionStorage.setItem('code_verifier', code_verifier)
  const nonce = Math.random.toString(36).substring(2, 15)
  sessionStorage.setItem('state', nonce)
  // Create code challenge
  var arrayHash = await encryptStringWithSHA256(code_verifier)
  var code_challenge = hashToBase64url(arrayHash)
  sessionStorage.setItem('code_challenge', code_challenge)

  return `${config.OAuth.authorizeEndpoint}?response_type=code&client_id=${config.OAuth.clientId}&redirect_uri=${
    config.OAuth.redirectUrl
  }&scope=${scopes.join(
    '%20'
  )}&response_type=token&state=${nonce}&code_challenge_method=S256&code_challenge=${code_challenge}`
}

const servicesFactory = () => ({
  async OauthSignin() {
    window.open(await getAuthorizeHref(), '_self')
  },
  async getMyProfile() {
    try {
      let profile = await myFetch(`${config.api}/profile/`)
      //    ReactPiwik.push(['setUserId', json.username]);
      //    ReactPiwik.push(['trackPageView']);
      const json = { ...profile }
      const action = addMyProfile(json)
      return store.dispatch(action)
    } catch (err) {
      console.log(err)
    }
  },

  logout() {
    // Clear Access Token and ID Token from local storage
    //    ReactPiwik.push(['resetUserId'])
    //    ReactPiwik.push(['trackPageView'])

    Cookie.remove('accessToken')
    Cookie.remove('refreshToken')
    window.open(
      config.OAuth.logoutEndpoint + `?logout_uri=${config.baseUrl}/logout&client_id=${config.OAuth.clientId}`,
      '_self'
    )
  },

  async exchangeCode(code) {
    const data = new URLSearchParams()
    data.append('client_id', config.OAuth.clientId)
    data.append('grant_type', 'authorization_code')
    data.append('redirect_uri', config.OAuth.redirectUrl)
    data.append('response_type', 'token')
    data.append('code', code)
    data.append('code_verifier', sessionStorage.getItem('code_verifier'))

    return fetch(config.OAuth.tokenEndpoint, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText)
        }
        return res
      })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        Cookie.set('accessToken', data.access_token, { secure: true })
        this.setRefreshTimeout(data.access_token)
        Cookie.set('refreshToken', data.refresh_token, { secure: true })
      })
  },

  //automatically fetches a new access token when access token is about to expire
  setRefreshTimeout(access_token) {
    let decodedToken = jwt(access_token)
    let timeToTokenExpired = decodedToken.exp * 1000 - new Date().getTime()
    if (timeToTokenExpired > 0) setTimeout(() => this.trySilentRefresh(), timeToTokenExpired * 0.9)
  },

  async refreshAccessToken(refresh_token) {
    const data = new URLSearchParams()
    data.append('client_id', config.OAuth.clientId)
    data.append('grant_type', 'refresh_token')
    data.append('refresh_token', refresh_token)
    return fetch(config.OAuth.tokenEndpoint, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText)
        }
        return res
      })
      .then((res) => res.json())
  },

  //refresh access token based on refresh token
  async trySilentRefresh() {
    const refresh_token = Cookie.get('refreshToken')
    return this.refreshAccessToken(refresh_token)
      .then((data) => {
        Cookie.set('accessToken', data.access_token, { secure: true })
        if (data.refresh_token) {
          Cookie.set('refreshToken', data.refresh_token, { secure: true })
        }
        this.setRefreshTimeout(data.access_token)
      })
      .catch((err) => this.OauthSignin())
  },

  isValid(access_token) {
    try {
      let decodedToken = jwt(access_token)
      if (!decodedToken) return false
      if (new Date().getTime() < decodedToken.exp * 1000) return true
      return false
    } catch (err) {
      return false
    }
  },
})

export default servicesFactory()
