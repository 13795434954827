import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MpPlot, MpPlotWrapper } from '../MpPlot'
import { BarTooltip, HistogramTooltip } from 'dashboard/Tooltip'

import MultiSearchDropdown from '../../shared/SearchDropdown/MultiSearchDropdown'

import activityDetails from './activityDetails'
import { barData, alphaMap } from '../barData'

import makeActivityData from './makeActivityData'
import makeTotalData from './makeTotalData'
import makeBlockHourData from './makeBlockHourData'
import makeBlockHourAnnotations from './makeBlockHourAnnotations'
import makeDutyTimeData from './makeDutyTimeData'
import makeDutyTimeAnnotations from './makeDutyTimeAnnotations'
import makeHotelNightsData from './makeHotelNightsData'
import makeHotelNightsAwayAnnotations from './makeHotelNightsAwayAnnotations'
import * as d3 from 'd3'

const activities = Object.keys(activityDetails)

const Distributions = () => {
  var tally = {}
  const reports = useSelector((state) => state.reports)

  const { activeReports, filter } = reports

  const activeReportsCumulated = JSON.parse(JSON.stringify(activeReports))
  if (activeReports.length > 1) {
    activeReportsCumulated.push({
      title: 'Cumulated',
      color: 'red',
      payload: { memberStatistics: activeReports.map((d) => d.payload.memberStatistics).flat() },
    })
  }

  const selectedActivites = filter.activityFields
  const activityData = []
  const totalData = []
  const timeData = []

  const blockHourData = makeBlockHourData(activeReportsCumulated, filter)
  const dutyTimeData = makeDutyTimeData(activeReportsCumulated, filter)
  const hotelNightsData = makeHotelNightsData(activeReportsCumulated, filter)

  const totalPreassigned = selectedActivites.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.preAssigned ? report.payload.crewStatistics[activityDetails[field].preAssigned] : 0
    )
    return {
      type: 'bar',
      hoverinfo: 'none',
      label: 'pre-assigned',
      textposition: 'outside',
      offsetgroup: 1,
      meta: { publication: activeReports.map((d) => d.title) },
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: { opacity: 0.7, color: activeReports.map((d) => d.color) },
      xaxis: 'x' + (i + 1),
      axiys: 'y',

      x: activeReports.map((d) => d.title),
      y: y,
    }
  })

  const totalScheduled = selectedActivites.map((field, i) => {
    const y = activeReports.map((report) =>
      filter.scheduled ? report.payload.crewStatistics[activityDetails[field].scheduled] || 0 : 0
    )
    return {
      type: 'bar',
      base: totalPreassigned[i].y.map((d) => d || 0),
      label: 'Optimized',
      textposition: 'outside',
      meta: { publication: activeReports.map((d) => d.title) },
      hoverinfo: 'none',
      offsetgroup: 1,
      textfont: { color: activeReports.map((d) => d.color), size: 14 },
      marker: {
        pattern: { shape: '/', fillmode: 'overlay', bgopacity: 0.7, fgopacity: 0.4, solidity: 0.5 },
        opacity: 0.7,
        color: activeReports.map((d) => d.color),
      },
      xaxis: 'x' + (i + 1),
      yaxis: 'y',

      x: activeReports.map((d) => d.title),
      y: y,
    }
  })
  const annotations = selectedActivites
    .map((d, i) => {
      return totalPreassigned[i].y.map((e, f) => {
        return {
          y: (e || 0) + (totalScheduled[i].y[f] || 0),
          xref: 'x' + (i + 1),
          x: f,
          font: { color: activeReports[f].color },
          yanchor: 'bottom',
          showarrow: false,
          text: d3.format('.3~s')((e || 0) + (totalScheduled[i].y[f] || 0)),
        }
      })
    })
    .flat()

  return (
    <div>
      <MpPlotWrapper>
        <MpPlot
          title="activity breakdown"
          tooltip={BarTooltip}
          data={[...totalPreassigned, ...totalScheduled]}
          layout={{
            ...Object.fromEntries(
              selectedActivites.map((d, i) => [
                'xaxis' + (i + 1),
                {
                  zeroline: false,
                  showticklabels: false,
                  title: { font: { size: 12 }, text: activityDetails[d].label || d },
                  fixedrange: true,
                },
              ])
            ),
            annotations,
            yaxis: {
              showgrid: true,
              visible: true,
              zeroline: true,
              fixedrange: true,
            },
            height: 220,
            grid: { rows: 1, columns: selectedActivites.length, yaxes: 'y' },
            bargap: 0.1,

            autosize: true,
          }}
        />
      </MpPlotWrapper>
      <MpPlotWrapper>
        <MpPlot
          title="Block hour distribution"
          tooltip={HistogramTooltip}
          data={blockHourData}
          layout={{
            autosize: true,
            annotations: makeBlockHourAnnotations(activeReportsCumulated, filter),
            yaxis: {
              tickfont: { color: '#aaa' },
              ticklabelposition: 'inside',
              fixedrange: true,
              showgrid: true,
              rangemode: 'tozero',
            },
            bargap: 0.1,
            height: 220,
            grid: {
              columns: 2 * activeReportsCumulated.length,
              rows: 1,
              yaxes: ['y'],
              roworder: 'bottom to top',
              pattern: 'independent',
            },
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + (2 * i + 1),
                { showticklabels: false, visible: false, fixedrange: true, zeroline: false, showgrid: false },
              ])
            ),
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + 2 * (i + 1),
                {
                  showticklabels: false,
                  visible: false,
                  rangemode: 'tozero',
                  fixedrange: true,
                  zeroline: false,
                  showgrid: false,
                },
              ])
            ),
          }}
        />
        <MpPlot
          title="Duty time distribution"
          data={dutyTimeData}
          tooltip={HistogramTooltip}
          layout={{
            autosize: true,
            annotations: makeDutyTimeAnnotations(activeReportsCumulated, filter),
            yaxis: {
              tickfont: { color: '#aaa' },

              ticklabelposition: 'inside',
              fixedrange: true,

              rangemode: 'tozero',

              showgrid: true,
            },
            bargap: 0.1,
            height: 220,
            grid: {
              columns: 2 * activeReportsCumulated.length,
              rows: 1,
              yaxes: ['y'],
              roworder: 'bottom to top',
              pattern: 'independent',
            },
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + (2 * i + 1),
                { visible: false, fixedrange: true, zeroline: false, showgrid: false },
              ])
            ),
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + 2 * (i + 1),
                {
                  showticklabels: false,
                  visible: false,
                  rangemode: 'tozero',
                  fixedrange: true,
                  zeroline: false,
                  showgrid: false,
                },
              ])
            ),
          }}
        />
        <MpPlot
          title="Hotel Nights distribution"
          data={hotelNightsData}
          tooltip={HistogramTooltip}
          layout={{
            autosize: true,
            annotations: makeHotelNightsAwayAnnotations(activeReportsCumulated, filter),
            yaxis: {
              tickfont: { color: '#aaa' },
              ticklabelposition: 'inside',
              fixedrange: true,
              showgrid: true,
              rangemode: 'tozero',
            },
            bargap: 0.1,
            height: 220,
            grid: {
              columns: 2 * activeReportsCumulated.length,
              rows: 1,
              yaxes: ['y'],
              roworder: 'bottom to top',
              pattern: 'independent',
            },
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + (2 * i + 1),
                { visible: false, fixedrange: true, zeroline: false, showgrid: false },
              ])
            ),
            ...Object.fromEntries(
              activeReportsCumulated.map((d, i) => [
                'xaxis' + 2 * (i + 1),
                { visible: false, rangemode: 'tozero', fixedrange: true, zeroline: false, showgrid: false },
              ])
            ),
          }}
        />
      </MpPlotWrapper>
    </div>
  )
}

export default Distributions
