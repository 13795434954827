import React, { useState, useEffect } from 'react'
import styles from './Data.module.scss'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import { Cell, setCellStyle } from './AgGridCellValidator'
import { AgGridReact } from 'ag-grid-react'
import { sampleSize } from 'lodash'

const VCWListTable = (props) => {
  const { data } = props
  const { is_editable, changedCells, validation_errors } = data
  const [gridApi, setGridApi] = useState(null)

  const rowData = props.data.payload.employees?.map((e) => {
    const employeeData = {
      employeeNumber: e.employeeNumber,
      employeeCode: e.employeeCode,
    }
    // Explicitly make copies ({ ...foo }) of the data fields to prevent new fields
    // being added to the original data when derived values are calculated for the table
    // (e.g: remainingValue). Without making explicit copies modified JSON data could be
    // sent back to the API causing a validation error on the unknown new fields.
    const entitlementData = {
      V: { ...e.entitlements.find((e) => e.code === 'V') },
      C: { ...e.entitlements.find((e) => e.code === 'C') },
      W: { ...e.entitlements.find((e) => e.code === 'W') },
      JVA: { ...e.entitlements.find((e) => e.code === 'JVA') },
      DYS: { ...e.entitlements.find((e) => e.code === 'DYS') },
      VAN: { ...e.entitlements.find((e) => e.code === 'VAN') },
      WBA: { ...e.entitlements.find((e) => e.code === 'WBA') },
    }
    for (const sample of Object.values(entitlementData)) {
      sample.remainingValue = sample.targetValue - sample.currentValue
    }
    return { ...employeeData, ...entitlementData }
  })

  const frameworkComponents = {
    Cell: Cell,
  }
  const onGridReady = (params) => {
    setGridApi(params.api)
  }
  const startDate = `${data?.payload?.startDate}`
  const endDate = `${data?.payload?.endDate}`
  const currentDate = `${data?.payload?.currentDate}`
  // Cell properties commonly used for numerical cells
  const commonCellProps = {
    sortable: true,
    type: 'numericColumn',
    suppressMovable: true,
    cellClass: (c) => {
      return c.value < 0 ? [styles.vcwListNegativeValue, styles.vcwRightAlignedCell] : styles.vcwRightAlignedCell
    },
  }
  const firstColProps = { cellStyle: { borderLeft: '.5px dotted' } }
  let colDefs = [
    {
      headerName: 'Empl.',
      headerTooltip: 'Employee code',
      field: 'employeeCode',
      editable: false,
      sortable: true,
      minWidth: 80,
      valueParser: (d) => d.value,
      cellClass: styles.vcwListEmployeeCode,
      filter: 'agTextColumnFilter',
      tooltipField: 'employeeNumber',
      lockPosition: 'left',
    },
    {
      headerName: `Holiday entitlement [${startDate} - ${endDate}]`,
      marryChildren: true,
      children: [
        { headerName: 'V', headerTooltip: 'V', field: 'V.targetValue', ...commonCellProps, ...firstColProps },
        { headerName: 'C', headerTooltip: 'C', field: 'C.targetValue', ...commonCellProps },
        { headerName: 'W', headerTooltip: 'W', field: 'W.targetValue', ...commonCellProps },
        { headerName: 'JVA', headerTooltip: 'JVA', field: 'JVA.targetValue', ...commonCellProps },
        { headerName: 'DYS', headerTooltip: 'DYS', field: 'DYS.targetValue', ...commonCellProps },
        { headerName: 'VAN', headerTooltip: 'VAN', field: 'VAN.targetValue', ...commonCellProps },
        { headerName: 'WBA', headerTooltip: 'WBA', field: 'WBA.targetValue', ...commonCellProps },
      ],
    },
    {
      headerName: `Scheduled on ${currentDate}`,
      marryChildren: true,
      children: [
        { headerName: 'V', headerTooltip: 'V', field: 'V.currentValue', ...commonCellProps, ...firstColProps },
        { headerName: 'C', headerTooltip: 'C', field: 'C.currentValue', ...commonCellProps },
        { headerName: 'W', headerTooltip: 'W', field: 'W.currentValue', ...commonCellProps },
        { headerName: 'JVA', headerTooltip: 'JVA', field: 'JVA.currentValue', ...commonCellProps },
        { headerName: 'DYS', headerTooltip: 'DYS', field: 'DYS.currentValue', ...commonCellProps },
        { headerName: 'VAN', headerTooltip: 'VAN', field: 'VAN.currentValue', ...commonCellProps },
        { headerName: 'WBA', headerTooltip: 'WBA', field: 'WBA.currentValue', ...commonCellProps },
      ],
    },
    {
      headerName: 'Still to schedule',
      marryChildren: true,
      children: [
        { headerName: 'V', headerTooltip: 'V', field: 'V.remainingValue', ...commonCellProps, ...firstColProps },
        { headerName: 'C', headerTooltip: 'C', field: 'C.remainingValue', ...commonCellProps },
        { headerName: 'W', headerTooltip: 'W', field: 'W.remainingValue', ...commonCellProps },
        { headerName: 'JVA', headerTooltip: 'JVA', field: 'JVA.remainingValue', ...commonCellProps },
        { headerName: 'DYS', headerTooltip: 'DYS', field: 'DYS.remainingValue', ...commonCellProps },
        { headerName: 'VAN', headerTooltip: 'VAN', field: 'VAN.remainingValue', ...commonCellProps },
        { headerName: 'WBA', headerTooltip: 'WBA', field: 'WBA.remainingValue', ...commonCellProps },
      ],
    },
  ]
  useEffect(() => {
    if (gridApi) gridApi.setColumnDefs(colDefs)
    if (gridApi) gridApi.redrawRows({ force: true })
  }, [data, changedCells, validation_errors])

  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        onGridReady={onGridReady}
        frameworkComponents={frameworkComponents}
        immutableData={true}
        getRowNodeId={(data) => data.employeeCode}
        rowClass={styles.rowClass}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={{
          editable: is_editable,
          cellClass: setCellStyle,
          cellRendererSelector: (props) =>
            props.colDef.cellRendererParams?.validation_errors?.items?.[props.rowIndex]?.[props.colDef.field]?.join(' ')
              ? { component: 'Cell' }
              : undefined,
          flex: 1,
          valueParser: (d) => (!isNaN(Number(d.newValue)) ? Number(d.newValue) : d.newValue),
        }}
        enableBrowserTooltips={true}></AgGridReact>
    </div>
  )
}

const VCWList = (props) => {
  const [search, setSearch] = useState('')
  const [dialog, setDialog] = useState(null)

  return (
    <>
      <DataObject data={props.data} upload="xlsx" title={intl.t(props.data.type)} onSave={props.onSave} nofilter>
        {<VCWListTable data={props.data} />}
      </DataObject>
    </>
  )
}

export default VCWList
