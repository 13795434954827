import { Button, InputGroup } from '@blueprintjs/core'
import React, { useState, useCallback } from 'react'
import styles from './SearchDropdown/SearchDropdown.module.scss'
import _debounce from 'lodash/debounce'
const Search = ({ value, style, onChange, placeholder }) => {
  const debounceFn = useCallback(_debounce(onChange, 250, { trailing: true }), [])
  const [internalVal, setInternalVal] = useState(value)

  return (
    <InputGroup
      className={styles.dropdown}
      placeholder={placeholder}
      style={style}
      rightElement={
        value ? (
          <Button
            minimal
            icon="cross"
            onClick={() => {
              setInternalVal('')
              onChange('')
            }}
          />
        ) : (
          <Button
            minimal
            style={{
              pointerEvents: 'none',
            }}
            icon="search"
          />
        )
      }
      value={internalVal}
      onChange={(event) => {
        setInternalVal(event.target.value)
        debounceFn(event.target.value)
      }}
    />
  )
}

export default Search
