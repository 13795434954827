import { ControlGroup, InputGroup } from '@blueprintjs/core'
import { DateInput2 } from '@blueprintjs/datetime2'
import moment from 'moment'
import React, { useState } from 'react'

const Bid = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { data } = props

  return (
    <ControlGroup fill>
      <InputGroup
        placeholder="Destination"
        onChange={(val) => {
          let d = { ...data }
          d.destination = val
          props.onChange(d)
        }}
      />

      <DateInput2
        placeholder="to"
        value={moment.utc(data.endTime).toDate()}
        formatDate={(date) => moment.utc(date).format('YYYY/MM/DD')}
        parseDate={(str) => new Date(str)}
        onChange={(e) => {
          let d = { ...data }
          d.endTime = moment(e).valueOf()
          props.onChange(d)
        }}
      />
    </ControlGroup>
  )
}
export default Bid
