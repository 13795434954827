import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MpPlot, MpPlotWrapper } from '../MpPlot'
import { BarTooltip } from 'dashboard/Tooltip'

import * as d3 from 'd3'
import * as data from './distribution.json'

const Distributions = () => {
  var tally = {}
  const reports = useSelector((state) => state.reports)

  const { activeReports, filter } = reports
  const selectedActivites = filter.activityFields

  const scatterData = {
    type: 'scatter',
    mode: 'markers',
    x: data.default.map((d) => d.tgt),
    y: data.default.map((d) => d.act),
    xaxis: 'x',
    yaxis: 'y',
    marker: {
      size: 3,
      color: data.default.map((d) => (d.act === 0 ? '#ff000088' : '#00000088')),
    },
  }

  var fit_to = Math.max(...data.default.map((d) => d.tgt))
  var diagonal = {
    x: [0, fit_to],
    y: [0, fit_to],
    mode: 'lines',
    type: 'scatter',
    xaxis: 'x',
    yaxis: 'y',
  }

  const targetHist = {
    type: 'histogram',

    x: data.default.map((d) => d.tgt),
    xaxis: 'x',
    yaxis: 'y2',
    marker: {
      opacity: 0.7,
      color: 'blue',
    },
  }
  const actualHist = {
    type: 'histogram',
    width: 40,
    y: data.default.map((d) => d.act),
    xaxis: 'x2',
    yaxis: 'y',
    marker: {
      opacity: 0.7,
      color: 'blue',
    },
  }
  return (
    <div>
      <MpPlotWrapper>
        <MpPlot
          title="activity breakdown"
          //   tooltip={BarTooltip}
          data={[scatterData, targetHist, actualHist, diagonal]}
          layout={{
            shapes: [
              {
                type: 'rect',
                xref: 'paper',
                yref: 'paper',
                x0: 0.1,
                y0: 0.1,
                x1: 0.8,
                y1: 0.8,

                line: {
                  color: '#ccc',
                  width: 1,
                },
              },
            ],
            xaxis: {
              domain: [0.1, 0.8],
              visible: true,
              title: { text: 'target', standoff: 0 },
              anchor: 'free',
              position: 0.1,
              showgrid: true,
              fixedrange: true,
            },
            xaxis2: {
              domain: [0.8, 1],
              anchor: 'y',
              fixedrange: true,
              showgrid: false,
              visible: false,
            },
            yaxis: {
              title: { text: 'actual', standoff: 0 },
              domain: [0.1, 0.8],

              anchor: 'free',
              position: 0.1,
              showgrid: true,
              fixedrange: true,
            },
            yaxis2: {
              domain: [0.8, 1],
              anchor: 'x',
              fixedrange: true,
              showgrid: false,
              visible: false,
            },
            width: 320,
            height: 320,
            grid: { rows: 2, columns: 2, xaxes: ['x', 'x2'], yaxes: ['y2', 'y'] },
            bargap: 0.1,
            autosize: false,
          }}
        />
      </MpPlotWrapper>
    </div>
  )
}

export default Distributions
