import activityDetails from './activityDetails'
import { barData, alphaMap } from '../barData'

const activities = Object.keys(activityDetails)

const makeActivityData = (inputData, tally, activeReports, selectedActivites, filter) => {
  for (let report in activeReports) {
    tally[report] = {}
    const preAssignedData = barData(report, activeReports, 'preAssigned')
    const scheduledData = barData(report, activeReports, 'scheduled')
    if (activeReports[report] && activeReports[report].payload) {
      for (let field in activities) {
        const scheduledTally =
          activeReports[report].payload.crewStatistics[activityDetails[activities[field]].scheduled] ?? 0
        const preAssignedTally =
          activeReports[report].payload.crewStatistics[activityDetails[activities[field]].preAssigned] ?? 0

        tally[report][activities[field]] = scheduledTally + preAssignedTally

        if (selectedActivites.includes(activities[field])) {
          if (filter.preAssigned) {
            preAssignedData.y.push(
              activeReports[report].payload.crewStatistics[activityDetails[activities[field]].preAssigned]
            )
            preAssignedData.x.push(`${activities[field]} ${alphaMap[report]}`)
            preAssignedData.text.push('pre-ass')
          }
          if (filter.scheduled) {
            scheduledData.y.push(
              activeReports[report].payload.crewStatistics[activityDetails[activities[field]].scheduled]
            )
            scheduledData.x.push(`${activities[field]} ${alphaMap[report]}`)
            scheduledData.text.push('scheduled')
          }
        }
      }
      inputData.push(scheduledData, preAssignedData)
    }
  }

  for (let entry in inputData) {
    const report = Math.floor(entry / 2)
    var dataSet = inputData[entry]
    for (let idx in dataSet.x) {
      const activity = dataSet.x[idx].split(' ').slice(0, -1).join(' ')
      const activityTotal = tally[report][activity]
      const currentVal = dataSet.y[idx]
      dataSet.y[idx] = currentVal / activityTotal
    }
  }
}

export default makeActivityData
