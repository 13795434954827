import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { intl } from 'i18n/IntlGlobalProvider'
import {
  EditableText,
  FormGroup,
  Card,
  H5,
  H6,
  Dialog,
  Classes,
  Button,
  InputGroup,
  Tag,
  Tabs,
  Tab,
  Icon,
  Alignment,
  Switch,
} from '@blueprintjs/core'

import CurrencyDropdown from 'shared/CurrencyDropdown/CurrencyDropdown'

const StandBy = (props) => {
  const [data, setData] = useState(props.data)

  useEffect(() => setData(props.data), [props.data])

  const { is_editable } = props
  return (
    <Card style={{ margin: 20 }}>
      <FormGroup>
        <Switch checked={data?.include} label="Enable" />
      </FormGroup>
    </Card>
  )
}

export default StandBy
