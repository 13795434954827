import React, { useEffect, useState, useRef } from 'react'
import { Tag, Icon, InputGroup, Button } from '@blueprintjs/core'
import Validate from './Validate'
import TooltipButton from 'shared/TooltipButton'

const InputText = (props) => {
  const { error, onChange, placeholder, area, errorposition } = props
  const [focus, setFocus] = useState(false)
  const [changed, setChanged] = useState(false)
  const [localError, setLocalError] = useState(undefined)
  const [initialValue, setInitialValue] = useState(props.value)
  const [value, setValue] = useState(props.value)
  const inputRef = useRef()

  useEffect(() => {
    if (props.setInitialFocus) {
      setFocus(true)
      inputRef.current.focus()
    }
  }, [])

  const cancel = () => {
    setTimeout(() => setFocus(false), 100)

    setValue(initialValue)
    props.onEditStopped()
    inputRef.current.blur()
  }
  const confirm = () => {
    if (localError) return
    setTimeout(() => setFocus(false), 100)
    props.onEditStopped()
    onChange(value)
    inputRef.current.blur()
  }
  const componentProps = {
    ...props,
    autoFocus: props.autoFocus,
    placeholder: placeholder ?? '',
    intent: localError ? 'danger' : 'undefined',
    value: value || '',
    inputRef: inputRef,
    style: {
      ...props.style,
      width: props.width || 140,
      textTransform: props.toUpper ? 'uppercase' : undefined,
      boxShadow: focus ? undefined : 'none',
      border: focus ? undefined : 'none !important',
    },
    rightElement: focus ? (
      <>
        <TooltipButton tooltip="cancel" style={{ marginRight: 0 }} minimal icon="cross" onClick={cancel} />
        {changed && (
          <>
            {!localError && value ? (
              <TooltipButton
                tooltip="save"
                minimal
                intent="success"
                icon="tick"
                style={{ marginLeft: 0 }}
                onClick={confirm}
              />
            ) : (
              <TooltipButton
                placement="right"
                tooltip={localError}
                defaultIsOpen={true}
                style={{ marginLeft: 0 }}
                minimal
                intent="danger"
                icon="issue"
              />
            )}
          </>
        )}
      </>
    ) : undefined,
    onKeyDown: (e) => {
      switch (e.key) {
        case 'Enter':
          console.log(value)
          if (!localError && value) confirm()
          break
        case 'Escape':
          cancel()
      }
    },
    onChange: (e) => {
      let text = e.target.value
      if (props.toUpper) text = text.toUpperCase()
      setChanged(true)
      setFocus(true)

      const errors = props.validators.map((validator) => validator(text)).filter((d) => d)

      if (errors.length > 0) setLocalError(errors[0])
      else setLocalError(undefined)
      setValue(text)
    },
    onBlur: () => {
      props.onEditStopped()
      setTimeout(() => setFocus(false), 100)
    },
    onFocus: () => setFocus(true),
  }
  if (props.static) return <span style={props.style}>{value}</span>
  return <InputGroup {...componentProps} />
}

export default InputText
