import React, { useEffect, useState, useRef } from 'react'
import { Tooltip, Tag } from '@blueprintjs/core'
import styles from './Issue.module.scss'
import tooltipStyles from '../Activity/Tooltips.module.scss'
import store from 'store'
import { changeFilter } from '../../../actions'
import { useSelector } from 'react-redux'

const activePublicationSelector = (state) => state.rosters.activePublication

function getSeverityIcon(severity) {
  switch (severity) {
    case 'blocking':
      return { icon: 'error', intent: 'danger' }
    case 'error':
      return { icon: 'warning-sign', intent: 'warning' }
    case 'warning':
      return { icon: 'info-sign', intent: 'primary' }
    default:
      return { icon: 'question-sign', intent: 'primary' }
  }
}

const IssueTag = ({ d, count, style }) => {
  const { icon, intent } = getSeverityIcon(d.severity)
  return (
    <div style={style}>
      <Tag intent={intent} icon={icon} multiline style={{ fontWeight: 'bold' }}>
        {d.description}
        {count > 1 && <span style={{ marginLeft: 10 }}>(x{count})</span>}
      </Tag>
    </div>
  )
}
const Issue = (props) => {
  const activePublication = useSelector(activePublicationSelector)

  let rosterData = activePublication?.rosterData || []

  const [open, setOpen] = useState(false)
  const { zone } = props
  const x = props.xScale
  const d = props.data

  return (
    <div
      className={[styles.issue, styles.rosterIssue, styles['issue_' + d.severity]].join(' ')}
      onDoubleClick={(e) => {
        if (!d.flight?.flightID) return
        props.onDoubleClick(!d.flight?.flightID)
      }}
      style={{
        marginLeft: x(d.startTime) - (props.margin ? x(props.margin) : props.x),

        width: x(d.endTime) - x(d.startTime),
      }}>
      <Tooltip
        intent={'danger'}
        content={
          <div className={tooltipStyles.tooltip} style={{ maxWidth: 400 }}>
            {d.description}
          </div>
        }
        targetClassName={styles.tooltip}
        target={<div style={{ position: 'absolute', top: -4, left: -4, right: -4, bottom: -4 }} />}
      />
    </div>
  )
}

export default React.memo(Issue)
export { IssueTag }
