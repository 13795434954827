import { myFetch, myPost, myPut, myFileUpload } from '../api'
import store from 'store/index'
import config from 'config'
import { addContextDataObject, addContextDataObjects, storeDataObjectvalidation_errors } from './actions'

const servicesFactory = (myFetch, myPost, myPut, myFileUpload) => ({
  async uploadCsvFile(id, file) {
    let json = await myFileUpload(`${config.api}/data_objects/${id}/upload_csv/`, {
      body: file,
      type: 'text/csv',
    })
    if (json) {
      const action = addContextDataObject(json)
      store.dispatch(action)
    }
    return json
  },
  async uploadXlsxFile(id, file) {
    let json = await myFileUpload(`${config.api}/data_objects/${id}/upload_xlsx/`, {
      body: file,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    if (json) {
      const action = addContextDataObject(json)
      store.dispatch(action)
    }
    return json
  },
  async getAllPublicationDataObjects(id) {
    let json = await myFetch(`${config.api}/publications/${id}/`)
    const action = addContextDataObjects(json.data_objects)
    return store.dispatch(action)
  },
  async getAllContextDataObjects(context) {
    let json = await myFetch(`${config.api}/data_objects/?context=${context}&with_payload=true&has_publication=false`)
    const action = addContextDataObjects(json)
    return store.dispatch(action)
  },

  async putContextDataObject(id, payload) {
    try {
      let json = await myPut(`${config.api}/data_objects/${id}/`, {
        body: JSON.stringify({ payload }),
      })

      const action = addContextDataObject(json)
      store.dispatch(action)
      return json
    } catch (errors) {
      const action = storeDataObjectvalidation_errors({ id, errors })
      store.dispatch(action)
      return Promise.reject(errors)
    }
  },
  async getDataObject(id) {
    let json = await myFetch(`${config.api}/data_objects/${id}/`)

    const action = addContextDataObject(json)
    store.dispatch(action)
  },
})

const api = servicesFactory(myFetch, myPost, myPut, myFileUpload)
export default api
