import React, { useState, useRef, useEffect } from 'react'
import { InputGroup, Position, Icon } from '@blueprintjs/core'
import Validate from './Validate'

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index)
}

function isValidTimeStringFormat(inputString) {
  const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  return pattern.test(inputString)
}
function isValidTimeNumberFormat(inputString) {
  const pattern = /^[0-2]{1}[0-3]{1}[0-5]{1}[0-9]{1}$/
  return pattern.test(inputString)
}

const InputText = (props) => {
  const [internalValue, setInternalValue] = useState('')
  const { validation_errors, value, onChange, onInputNotValid } = props
  const [localError, setLocalError] = useState(validation_errors)
  useEffect(() => {
    if (value === null) setInternalValue('')
    setInternalValue(value)
  }, [value])

  const inputRef = useRef()

  return (
    <Validate validation_errors={validation_errors} position={Position.TOP}>
      <InputGroup
        autoFocus={true}
        ref={inputRef}
        value={internalValue}
        intent={validation_errors || localError ? 'danger' : undefined}
        style={{ width: 60 }}
        placeholder={props.placeholder || 'time'}
        onChange={({ target }) => {
          let v = target.value
          setInternalValue(v)

          if (props.nullAllowed && !v) {
            setLocalError(null)
            return onChange(null)
          }
          if (isValidTimeStringFormat(v)) {
            setLocalError(null)
            if (onInputNotValid) onInputNotValid(false)
            return onChange(v)
          }

          setLocalError(true)
        }}
      />
    </Validate>
  )
}

export default InputText
