import React, { useState, useEffect } from 'react'

import { Rows, Row, Cols, Col } from 'shared/PanelLayout/PanelLayout'
import { Icon, Button, Menu, MenuItem, NonIdealState, Navbar, Alignment } from '@blueprintjs/core'

import { Route, Routes, useNavigate, Navigate, useLocation, useResolvedPath } from 'react-router-dom'
import * as _ from 'lodash'
import ErrorBoundary from 'log/ErrorBoundary'
import PrivateRoute from '../PrivateRoute'
import Loader from 'shared/Loader/Loader'
import { useSelector } from 'react-redux'
import Sandbox from './Sandbox'
import styles from './Data.module.scss'
import Checklist from './Checklist'
import Taxis from './Taxis'
import Hotels from './Hotels'
import CourseRequest from './CourseRequest'
import BasicConfiguration from './BasicConfiguration'
import IDPSExportConfig from './IDPSExportConfig'
import CrewMembersExcluded from './ExcludedCrewMembers'
import ManualPairings from './ManualPairings'
import StandbyPredictions from './StandbyPredictions'
import ProductionPredictions from './ProductionPredictions'
import WeekendsTogether from './WeekendsTogether'
import PreAssignedWeekends from './PreAssignedWeekends'
import HolidayPredictions from './HolidayPredictions'
import AdditionalWBADays from './AdditionalWBADays'
import VCWList from './VCWList'
import { intl } from 'i18n/IntlGlobalProvider'
import { join } from 'lodash'
import store from 'store'
import TooltipButton from 'shared/TooltipButton'

import api from './api'
import { config } from 'process'
// Only data objects with these keys will be shown in the publication's data view
const components = (data, configuration, onSave, global) => {
  switch (data.type) {
    case 'hotels':
      return <Hotels onSave={onSave} configuration={configuration} data={data} global={global} />
    case 'taxis':
      return <Taxis configuration={configuration} data={data} onSave={onSave} global={global} />
    case 'checklist':
      return <Checklist data={data} onSave={onSave} global={global} />
    case 'motuplan_config':
      return <BasicConfiguration data={data} onSave={onSave} global={global} />
    case 'course_requests':
      return <CourseRequest data={data} onSave={onSave} global={global} />
    case 'excluded_crew_members':
      return <CrewMembersExcluded data={data} onSave={onSave} global={global} />

    case 'idps_export_config':
      return <IDPSExportConfig data={data} onSave={onSave} global={global} />
    case 'manual_pairings':
      return <ManualPairings configuration={configuration} data={data} onSave={onSave} global={global} />
    case 'pre_assigned_weekends':
      return <PreAssignedWeekends data={data} onSave={onSave} global={global} />
    case 'production_predictions':
      return <ProductionPredictions data={data} onSave={onSave} global={global} />
    case 'standby_predictions':
      return <StandbyPredictions data={data} onSave={onSave} global={global} />
    case 'weekends_together':
      return <WeekendsTogether data={data} onSave={onSave} global={global} />
    case 'off_days_ccm':
      return <HolidayPredictions data={data} onSave={onSave} global={global} />
    case 'off_days_fcm':
      return <HolidayPredictions data={data} onSave={onSave} global={global} />

    case 'manual_input':
      return <Sandbox data={data} onSave={onSave} global={global} />
    case 'additional_wba_days':
      return <AdditionalWBADays data={data} onSave={onSave} global={global} />
    case 'vcw_list':
      return <VCWList data={data} onSave={onSave} global={global} />
  }
}

const RouterMenuItem = (props) => {
  const navigate = useNavigate()
  let location = useLocation()

  return (
    <MenuItem
      className={[styles.menuItem, !props.odd ? styles.odd : styles.even].join(' ')}
      {...props}
      active={location.pathname === useResolvedPath(props.to).pathname}
      text={
        <span>
          <span className={styles.menuLabel}>{props.text}</span>
          {props.desc && <div className={styles.menuLabelDescription}>{props.desc}</div>}
        </span>
      }
      onClick={props.onClick ? props.onClick : () => navigate(props.to)}
    />
  )
}

const Data = (props) => {
  const navigate = useNavigate()
  let { data } = props
  const { global } = props
  data = data.filter((d) => d)

  const config_data_object = data.find((d) => d.type === 'motuplan_config')

  const configuration = config_data_object ? config_data_object.payload : {}

  const loading = useSelector((state) => state.rosters.loading)

  const sortedDataObjects = data
    .filter((d) => d.type !== 'checklist')
    .sort((a, b) => {
      return a.type < b.type ? -1 : 1
    })

  let checklist = data.find((d) => d.type === 'checklist')
  return (
    <Cols>
      <Col content fixed collapse="left" responsive width={300} collapsed={false} shadow>
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group>
                <Navbar.Heading>Publication data</Navbar.Heading>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                {data.some((d) => d.dirty) && (
                  <TooltipButton
                    tooltip="Save All"
                    minimal
                    icon="floppy-disk"
                    onClick={() => {
                      data.filter((d) => d.dirty).map((d) => api.putContextDataObject(d.id, d.payload))
                    }}
                  />
                )}
              </Navbar.Group>
            </Navbar>
          </Row>
          <Row>
            {loading && !data && <NonIdealState icon={<Loader type="spinner" active color="#888888" size={60} />} />}
            {sortedDataObjects && (
              <Menu
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}>
                {sortedDataObjects
                  //  .filter((d) => d.type in components)
                  .map((d, i) => (
                    <RouterMenuItem
                      key={i}
                      odd={i % 2}
                      to={d.type}
                      text={intl.t(d.type)}
                      labelElement={
                        d.is_editable === false ? (
                          <Icon icon="lock" />
                        ) : d.saving ? (
                          <Icon icon="floppy-disk" />
                        ) : d.dirty ? (
                          <Icon icon="clean" />
                        ) : undefined
                      }
                    />
                  ))
                  .sort((a, b) => a.type < b.type)}
                <li style={{ flex: '1 1 auto' }} />
                <RouterMenuItem
                  key={999}
                  to="checklist"
                  text={intl.t('checklist')}
                  labelElement={
                    checklist?.is_editable === false ? (
                      <Icon icon="lock" />
                    ) : checklist?.saving ? (
                      <Icon icon="floppy-disk" />
                    ) : checklist?.dirty ? (
                      <Icon icon="clean" />
                    ) : undefined
                  }
                />
              </Menu>
            )}
          </Row>
          <Row fixed padding={10}>
            {props.children}
          </Row>
        </Rows>
      </Col>
      <Col>
        <Routes>
          <Route path="/" element={<Navigate to="checklist" />} />

          {data.map((d, i) => (
            <Route key={d.id} path={d.type} element={components(d, configuration, props.onSave, global)} />
          ))}
        </Routes>
      </Col>
    </Cols>
  )
}

export default Data
