import React, { useState } from 'react'
import { Button } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

const TooltipButton = (props) => (
  <Tooltip2
    intent={props.intent ? props.intent : undefined}
    content={props.tooltip}
    isOpen={props.defaultIsOpen || false}
    usePortal={true}
    portalContainer={document.getElementById('main')}
    placement={'right'}
    modifiers={{ flip: { enabled: false } }}>
    <Button
      style={props.style}
      alignText={props.alignText}
      minimal={props.minimal}
      small={props.small}
      loading={props.loading}
      fill={props.fill}
      large={props.large}
      disabled={props.disabled}
      intent={props.intent}
      text={props.text}
      icon={props.icon}
      onClick={props.onClick}
    />
  </Tooltip2>
)
export default TooltipButton
