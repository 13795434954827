import React, { Component } from 'react'
import CountUp from 'react-countup'
import debounce from 'lodash/debounce'
import styles from './Counter.module.scss'

class Counter extends Component {
  value = 0
  constructor(props) {
    super(props)
    this.value = props.value || 0
  }

  componentDidUpdate = debounce(() => {
    if (this.ref) this.ref.update(this.props.value)
  }, 50)

  render() {
    const { decimals, value, sign } = this.props

    return (
      <span className={value < 0 ? styles.dangser : ''}>
        <CountUp
          {...this.props}
          formattingFn={(d) => (sign ? (d > 0 ? '+' : '') : '') + d}
          ref={(el) => (this.ref = el)}
          duration={0.3}
          decimals={decimals || 0}
          end={this.value}
        />
        <span className="unit">{this.props.unit}</span>
      </span>
    )
  }
}

export default Counter
