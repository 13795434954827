import React, { useState, useEffect } from 'react'
import store from 'store'
import { Navbar, Alignment, Classes, Button, Divider, Switch } from '@blueprintjs/core'
import { InputText, InputDate, InputDateRange } from 'shared/Forms'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import ActivityDialog from './ActivityDialog'
import AircraftActivityDialog from './AircraftActivityDialog'
import AirplaneDialog from './AirplaneDialog'
import CrewDialog from './CrewDialog'
import { useSelector } from 'react-redux'
import { Cols, Col, Row, Rows } from 'shared/PanelLayout/PanelLayout'
import SplitPane from 'publication/roster/SplitPane'
import CrewFilter from 'publication/roster/CrewFilter'

import { resetFilter } from 'publication/actions'
import { lux, startOf, endOf } from 'shared/LuxonHelpers'

import Filter from 'publication/coverage/CoverageFilter'

const activePublicationSelector = (state) => state.rosters.activePublication
const FlightToCover = (props) => {
  return (
    <CreateOrSaveDialog
      style={{ width: 800 }}
      usePortal={false}
      //   payload={personalData}
      //   id={personalData}
      icon="airplane"
      title="Flight"
      edit={props.data}
      //resetObject={newTaxi}
      onDelete={props.onDelete}
      onClose={props.onClose}
      onSave={props.onSave}
      isOpen={props.isOpen}>
      {(data, setData) => {
        return (
          <div style={{ display: 'flex' }}>
            <InputText value={data?.employeeCode} onChange={(value) => setData({ ...data, employeeCode: value })} />
            <InputText value={data?.aircraftType} onChange={(value) => setData({ ...data, aircraftType: value })} />
          </div>
        )
      }}
    </CreateOrSaveDialog>
  )
}

const newPersonal = {
  personalID: null,
  pLcode: null,
  crewFunction: null,
  homeBase: null,
  dateOfBirth: null,
  seniority: 0,
  slack: false,
  specialFunctions: [],
  baseSwitches: [],
  aircraftQualifications: [],
  crewContracts: [],
  specialAirportQualifications: [],
  historicalMonthlyData: [],
  visa: [],
  parentalLeave: [],
  expiries: [],
  activities: [],
  employeeCode: '',
  flight_duties: [],
  rosterIssues: [],
  dataIssues: [],
  contractBase: [],
  labels: [],
  flightDutyLabels: [],
}
const SandboxRoster = (props) => {
  const [selectedPlane, setSelectedPlane] = useState(null)
  const [data, setData] = useState(props.data || [])
  const [filterVisible, setFilterVisible] = useState(false)

  const [activity, setActivity] = useState(null)
  const [aircraft, setAircraft] = useState(null)

  const [aircraftActivity, setAircraftActivity] = useState(null)
  const [crewActivity, setCrewActivity] = useState(null)
  const [airplane, setAirplane] = useState(null)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const activePublication = useSelector(activePublicationSelector)

  useEffect(() => {
    if (props.data) setData(props.data)
  }, [props.data])
  const deleteActivity = (activities, crew) => {}

  const addorChangeActivity = (activities, crew) => {
    let newData = JSON.parse(JSON.stringify(data))

    let crewMember = newData.find((d) => d.employeeCode === crew.employeeCode)

    activities.forEach((activity) => {
      let newActivity = JSON.parse(JSON.stringify(activity))

      delete newActivity.crewComplement

      //check whether this activity already exists with Crew member
      let existing = crewMember.activities.find((a) => a.activityID === activity.activityID)
      if (!existing) {
        //if not existing, add it to the activity list
        newActivity.crewRole = crew.crewFunction
        crewMember.activities.push(newActivity)
        crewMember.activities.sort((a, b) => a.startTime - b.startTime)
      } else {
        //if already exists update it
        existing = { ...existing, ...newActivity }
      }

      if (crewMember.crewFunction === 'Plane') {
        //check assigned crew

        for (let cf in newActivity.assignedCrew) {
          const crewOnFlight = newActivity.assignedCrew[cf]

          newData
            .filter((c) => c.crewFunction !== 'Plane')
            .forEach((c) => {
              let activityIndex = c.activities.findIndex(
                (a) => a.activityID === activity.activityID && a.crewRole === cf
              )
              if (crewOnFlight.includes(c.employeeCode)) {
                //check if corresponding crew activity is present, and role is correct
                if (activityIndex < 0) {
                  //crew should be with role on flight, but is not present
                  c.activities.push({ ...newActivity, crewRole: cf })
                }
              } else {
                //crew should not be on flight, hence remove existing activities
                if (activityIndex >= 0) {
                  c.activities.splice(activityIndex, 1)
                }
              }
            })
        }
      }
    })
    props.onChange(newData)
    setData(newData)
  }

  return (
    <Cols>
      <Col>
        <Rows>
          <Row noscroll>
            <SplitPane
              manual={true}
              navigate={(url) => console.log(url)}
              edit={true}
              rosterData={data}
              topData={data.filter((d) => d.crewFunction !== 'Plane')}
              topDataFilter={CrewFilter}
              onTopAdd={(d) => {
                setSelectedEmployee(d)
              }}
              onBottomAdd={(d) => {
                setAirplane(d)
              }}
              bottomData={data.filter((d) => d.crewFunction === 'Plane')}
              pubStart={'2022-09-27'}
              pubEnd={'2022-10-28'}
              onAddActivity={addorChangeActivity}
              onTopRowClick={(d, x) => {
                setCrewActivity(x)
                setAircraft(d)
                // setActivity(x)
              }}
              onBottomRowClick={(d, x) => {
                setAircraft(d)
                setAircraftActivity(x)
              }}
              onTopActivityClick={(d, a) => {
                setAircraft(a)
                setCrewActivity(d)
              }}
              onBottomActivityClick={(d, a) => {
                console.log(d, a)
                setAircraft(a)
                setAircraftActivity(d)
              }}
            />
            {!filterVisible && (
              <Button
                style={{ position: 'absolute', right: 5, top: 5, zIndex: 100 }}
                minimal
                icon="filter"
                onClick={() => setFilterVisible(true)}
              />
            )}
          </Row>
          <ActivityDialog
            data={crewActivity}
            onDelete={(d) => {
              const editedData = JSON.parse(JSON.stringify(aircraft))
              const index = editedData.activities.findIndex(
                (a) => a.startTime === d.startTime && a.endTime === d.endTime
              )
              editedData.activities.splice(index, 1)
              const newData = JSON.parse(JSON.stringify(data))
              let i = data.findIndex((d) => d.pLcode === editedData.pLcode)
              newData[i] = editedData
              props.onChange(newData)
              setCrewActivity(null)
              setAircraft(null)
              setData(newData)
            }}
            onCreate={(d) => {
              const repeat = d.repeat || 1
              delete d.repeat
              let activity = {
                ...d,
                activityType: d.typeOfActivity,
                typeOfActivity: 'PreAssigned',
              }

              const activities = []
              for (let i = 0; i < repeat; i++) {
                activities.push({ ...activity })
                activity.startTime = lux(activity.startTime).plus({ days: 1 }).toMillis()
                activity.endTime = lux(activity.endTime).plus({ days: 1 }).toMillis()
              }

              addorChangeActivity(activities, aircraft)
            }}
            onSave={(d) => {
              addorChangeActivity([d], aircraft)
              setActivity(null)
            }}
            onClose={() => setActivity(null)}
          />
          <AircraftActivityDialog
            data={aircraftActivity}
            aircraft={aircraft}
            employees={data}
            onCreate={(d) => {
              const repeat = d.repeat || 1
              delete d.repeat
              let activity = {
                ...d,
                activityType: d.typeOfActivity,
                typeOfActivity: 'PreAssigned',
              }

              const activities = []
              for (let i = 0; i < repeat; i++) {
                activities.push({ ...activity })
                activity.startTime = lux(activity.startTime).plus({ days: 1 }).toMillis()
                activity.endTime = lux(activity.endTime).plus({ days: 1 }).toMillis()
              }

              addorChangeActivity(activities, aircraft)
            }}
            onSave={(d) => {
              addorChangeActivity([d], aircraft)
              setActivity(null)
            }}
            onClose={() => setAircraftActivity(null)}
          />
          <AirplaneDialog
            data={airplane}
            onSave={(editedData) => {
              const newData = JSON.parse(JSON.stringify(data))
              let i = data.findIndex((d) => d.pLcode === editedData.pLcode)
              newData[i] = editedData
              setData(newData)
              props.onChange(newData)
            }}
            onCreate={(newData) => {
              setData([...data, ...newData])
              props.onChange([...data, ...newData])
            }}
            onClose={() => setAirplane(null)}
            onDelete={(editedData) => {
              const newData = JSON.parse(JSON.stringify(data))
              let i = data.findIndex((d) => d.pLcode === editedData.pLcode)
              newData.splice(i, 1)
              setData(newData)
              props.onChange(newData)
            }}
          />
          <CrewDialog
            data={selectedEmployee}
            onSave={(editedData) => {
              const newData = JSON.parse(JSON.stringify(data))
              let i = data.findIndex((d) => d.pLcode === editedData.pLcode)

              newData[i] = editedData
              setData(newData)
              props.onChange(newData)
            }}
            onCreate={(newData) => {
              setData([...data, ...newData])
              props.onChange([...data, ...newData])
            }}
            onClose={() => setSelectedEmployee(null)}
            onDelete={(editedData) => {
              const newData = JSON.parse(JSON.stringify(data))
              let i = data.findIndex((d) => d.pLcode === editedData.pLcode)
              newData.splice(i, 1)
              setData(newData)
              props.onChange(newData)
            }}
          />

          <FlightToCover
            isOpen={selectedPlane}
            data={selectedPlane}
            onClose={() => {
              setSelectedPlane(null)
            }}
            onDelete={() => {}}
          />
        </Rows>
      </Col>
      <Col dark shadow collapse="right" collapsed={!filterVisible} width={300}>
        <Rows>
          <Row fixed>
            <Navbar>
              <Navbar.Group>
                <Navbar.Heading>Filter</Navbar.Heading>
              </Navbar.Group>
              <Navbar.Group align={Alignment.RIGHT}>
                <Button text="Reset All" onClick={() => store.dispatch(resetFilter())} />
                <Button minimal icon="cross" onClick={() => setFilterVisible(false)} />
              </Navbar.Group>
            </Navbar>
          </Row>
          <Row>
            <Filter data={data} activePublication={activePublication}></Filter>
          </Row>
        </Rows>
      </Col>
    </Cols>
  )
}
export { SandboxRoster }
