import * as d3 from 'd3'

import React, { useEffect, useState, useCallback } from 'react'

import styles2 from './CrewMember.module.scss'
import store from 'store'
import FlightDuty from './FlightDuty/FlightDuty'
import Flight from './Activity/Flight'
import Issue from './Issue/Issue'
import EndLabel from './EndLabel/EndLabel'
import StartLabel from './EndLabel/StartLabel'
import EmptyRosterDay from './EmptyRosterDay/EmptyRosterDay'
import RosterError from './RosterError/RosterError'
import { format } from 'shared/LuxonHelpers'
import { useDroppable, DragOverlay } from '@dnd-kit/core'
import { restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
import { DraggableActivity } from './Draggable'
import { setExpanded } from 'publication/actions'

const PlaneMember = (props) => {
  const [mousePos, setMousePos] = useState(null)
  const { timezone } = props
  const [hide, setHide] = useState(false)
  const [hiding, setHiding] = useState(false)

  const x = props.xScale

  const preppedActivities = React.useMemo(
    () =>
      props.data.activities.map((d, i) => {
        return { ...d, key: i, endTimeX: x(d.endTime), startTimeX: x(d.startTime) }
      }),
    [props.data.activities, x]
  )

  const activities = React.useMemo(
    () =>
      preppedActivities?.filter((d) => d.endTimeX - props.x > 0).filter((d) => d.startTimeX - props.x < props.width),
    [preppedActivities, props.x, props.width]
  )

  const preppedEndLabels = React.useMemo(() => {
    let p = props.data.labels?.map((d, i) => {
      return {
        ...d,
        key: i,
        modified: 0,
        origPos: x(d.start),
        pos: x(d.start),
        startTimeX: x(d.start) - 12,
        endTimeX: x(d.start) + 12,
      }
    })

    return p
  }, [props.data.labels, x, props.expanded])

  const distance = React.useMemo(() => x(3600000) - x(0), [x])
  let endLabels = React.useMemo(() => {
    let p = preppedEndLabels
      ?.filter((d) => !d.activityLabel)
      ?.filter((d) => d.endTimeX - props.x > 0)
      .filter((d) => d.startTimeX - props.x < props.width)
    // .filter((d) => distance > 4)

    //.filter((d) => !d.isHomeBase)

    for (let e = 0; e < 2; e++) {
      p = p.filter((d) => {
        if (d.isHomeBase && (d.overlapAfter || d.overlapBefore)) return false
        return true
      })
      let c = 0
      p.forEach((d, i) => {
        const prev = i > 0 ? p[i - 1] : { endTimeX: -Infinity }
        const next = i < p.length - 1 ? p[i + 1] : { startTimeX: +Infinity }
        d.labelSpaceBefore = d.startTimeX - prev.endTimeX
        d.labelSpaceAfter = next.startTimeX - d.endTimeX
        d.overlapBefore = d.labelSpaceBefore < 0
        d.overlapAfter = d.labelSpaceAfter < 0
      })

      p.forEach((d) => {
        const deltaLeft = Math.abs(d.labelSpaceAfter / 2)
        if (d.overlapAfter) {
          //console.log(d.text, ' shift L ', deltaLeft)
          if (d.modified + deltaLeft <= 12) {
            d.pos -= deltaLeft
            d.startTimeX -= deltaLeft
            d.endTimeX -= deltaLeft
            d.modified += deltaLeft
          }
          c++
        }
        const deltaAfter = Math.abs(d.labelSpaceBefore / 2)
        if (d.overlapBefore) {
          // console.log(d.text, ' shift R ', deltaAfter)
          if (d.modified + deltaAfter <= 12) {
            d.pos += deltaAfter
            d.startTimeX += deltaAfter
            d.endTimeX += deltaAfter
            d.modified += deltaAfter
          }
          c++
        }
        return d
      })
    }

    return p
  }, [preppedEndLabels, props.x, props.width, distance])

  const d = props.data

  const allErrors = React.useMemo(
    () => d.rosterIssues?.filter((d) => d.employeeCode === props.data.employeeCode),
    [d.rosterIssues, props.data]
  )

  const errors = React.useMemo(() => allErrors.filter((d) => d.issueType === 'FlightCoverageIssue'), [allErrors])

  const allErrorsExceptEmpty = React.useMemo(
    () => allErrors?.filter((d) => d.issueType !== 'EmptyRosterDay'),
    [allErrors]
  )

  const className = React.useMemo(
    () => [styles2.CrewMember, d.odd ? styles2.odd : styles2.even, props.hover ? styles2.hover : undefined].join(' '),
    [styles2, d.odd, props.hover]
  )

  return (
    <div
      onMouseDown={(e) => setMousePos(e.clientX)}
      onMouseUp={(e) => {
        //if (e.target !== e.currentTarget) return

        if (e.button === 0 && e.clientX === mousePos && props.onClick) props.onClick(d, x.invert(e.offsetX + props.x))
        setMousePos(e.clientX)
      }}
      //onClick={(e) => console.log('ddd')}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      className={className}
      style={{
        pointerEvents: props.dragging ? 'none' : 'all',
        position: 'absolute',
        top: d.top,
        height: 34,
        width: x.range()[1],

        //  backgroundColor: isOver ? 'rgba(255,0,0,0.1)' : undefined,
      }}
      onMouseEnter={!props.drag ? () => props.onHover(d.employeeCode) : undefined}
      onMouseLeave={!props.drag ? () => props.onHover(null) : undefined}>
      <div style={{ position: 'absolute', top: 2, width: '100%', display: 'flex' }}>
        {activities.map((d, i) => (
          <Flight
            // id={props.draggable ? 'plane' + d.startTime : undefined}
            expanded={props.expanded}
            errors={errors}
            edit={props.edit}
            zone={timezone}
            filteredCrew={props.filteredCrew}
            rosterData={props.rosterData}
            dragging={hide}
            onClick={props.onActivityClick ? (a) => props.onActivityClick(a, props.data) : undefined}
            onDoubleClick={props.onDoubleClick}
            key={d.key}
            data={d}
            x={props.x}
            drag={props.drag}
            draggedItem={props.draggedItem}
          />
        ))}
      </div>

      {allErrorsExceptEmpty.map((d, i) => (
        <RosterError key={d.key} data={d} xScale={x} x={props.x} />
      ))}
      {endLabels?.map((d, i) => (
        <EndLabel expanded={props.expanded} key={d.key} drag={props.drag} data={d} x={props.x} />
      ))}
    </div>
  )
}

export default React.memo(PlaneMember)
