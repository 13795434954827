import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import api from './api'
import DataObject from './DataObject'
import { intl } from 'i18n/IntlGlobalProvider'
import ManualPairings from './ManualPairing/ManualPairing'
import { Button, RadioGroup, Radio } from '@blueprintjs/core'

import store from 'store'
import { addContextDataObject } from './actions'

import { SandboxRoster } from './Simulation/PersonalData'
import {
  serializeActivities,
  serializeFlightDuties,
  deserializeActivities,
  deserializeFlightDuties,
} from 'publication/motuplanJsonHelpers'

const Sandbox = (props) => {
  useEffect(() => {
    api.getDataObject(props.data.id)
  }, [props.data.id])
  const { is_editable } = props.data

  let data = props.data

  let m = JSON.parse(JSON.stringify(data.payload))?.personelData

  m?.forEach((r) => {
    deserializeFlightDuties(r)
    deserializeActivities(r)
  })

  m?.forEach((r) => {
    r.airportQualifications = r.SpecialAirportQualifications || []

    if (!r.aircraftQualifications) r.aircraftQualifications = []
    r.aircraftQualifications.forEach((a) => (a.qualificationCode = a.aircraftType))

    r.activities?.forEach((d) => {
      d.manual = true
    })
  })

  // Calculate crew coverage for flights
  m?.filter((d) => d.crewFunction === 'Plane').forEach((d) => {
    let issues = []

    d.activities.forEach((a) => {
      a.assignedCrew = { CP: [], FO: [], RP: [], SCC: [], ASC: [], CCM: [] }
      Object.keys(a.assignedCrew).forEach((f) => {
        const crew = m
          ?.filter((d) => d.crewFunction !== 'Plane')
          .filter((d) => d.activities.some((d) => d.activityID === a.activityID && d.crewRole === f))
          .map((d) => {
            return d.employeeCode
          })

        a.assignedCrew[f] = crew
      })
      const requiredFCM = d.crewComplement
        ?.filter((d) => ['CP', 'FO', 'RP'].includes(d.crewFunction))
        .reduce((sum, val) => sum + val.crewNumber, 0)

      const requiredCCM = d.crewComplement
        ?.filter((d) => ['SCC', 'ASC', 'CCM'].includes(d.crewFunction))
        .reduce((sum, val) => sum + val.crewNumber, 0)

      const assignedFCM = [...(a.assignedCrew?.CP || []), ...(a.assignedCrew?.FO || []), ...(a.assignedCrew?.RP || [])]
        .length
      const assignedCCM = [
        ...(a.assignedCrew?.SCC || []),
        ...(a.assignedCrew?.ASC || []),
        ...(a.assignedCrew?.CCM || []),
      ].length

      const neededFCM = requiredFCM - assignedFCM
      const neededCCM = requiredCCM - assignedCCM
      if (neededFCM !== 0 || neededCCM !== 0) {
        issues.push({
          employeeCode: d.employeeCode,
          issueType: 'FlightCoverageIssue',
          description: `Missing crew: ${neededFCM > 0 ? `${neededFCM} FCM` : ''},${
            neededCCM > 0 ? `${neededCCM} CCM` : ''
          }`,
          severity: 'error',
          flight: a,
        })
      }
      // console.log(issues)
    })
    d.rosterIssues = issues
  })

  return (
    <>
      <DataObject
        data={data}
        title="Sandbox"
        //onAdd={() => setDialog('new')}
        is_editable={is_editable}
        onSave={props.onSave}
        onChange={(data) => {
          //    console.log(data)
          // store.dispatch(addContextDataObject(data))
        }}
        onUndo={() => api.getDataObject(data.id)}
        nofilter>
        <SandboxRoster
          data={m}
          onChange={(payload) => {
            let d = JSON.parse(JSON.stringify(payload))

            d?.forEach((r) => {
              serializeFlightDuties(r)
              serializeActivities(r)
            })
            //props.onSave(d)

            store.dispatch(addContextDataObject({ ...data, payload: d, dirty: true }))
          }}
        />
      </DataObject>
    </>
  )
}

export default Sandbox
