import * as d3 from 'd3'

const SAFEcolorScale = d3
  .scaleLinear()
  .domain([0, 0.99, 1, 2.43, 3.87, 5.3, 5.31, 7])
  .range(['grey', 'grey', 'green', 'yellow', 'orange', 'red', 'magenta', 'magenta'])

const SAFEscoresToGradient = (d, x) => {
  if (!d.fatigueArray) return 'linear-gradient(to right,green,green)'
  const fatigueArray = d.fatigueArray.slice()
  let startTime = d.startTime
  let x0 = x(d.startTime)
  if (d.fatigueArray.length === 1) fatigueArray.push(d.fatigueArray[0])
  let gradient = fatigueArray.map((score, i) => {
    let s = SAFEcolorScale(score) + ' ' + (x(startTime) - x0) + 'px'
    startTime = startTime + 15 * 60 * 1000
    return s
  })
  return 'linear-gradient(to right,' + gradient.join(',') + ')'
}

const fatigueInRest = (d, x) => {
  // safe/care value in rest is just a calculated linear interpolation
  if (!d.fatigueArray) return 'linear-gradient(to right,green,green)'
  const fatigueArray = d.fatigueArray.slice()
  if (d.fatigueArray.length === 1) fatigueArray.push(d.fatigueArray[0])
  let endScore = fatigueArray[fatigueArray.length - 1]
  let c = SAFEcolorScale(endScore)

  if (endScore > 5.3)
    return `linear-gradient(to right, ${c} 0%,red ${(100 * (endScore - 5.3)) / (endScore - 1)}%, orange ${
      (100 * (endScore - 3.87)) / (endScore - 1)
    }%, yellow ${(100 * (endScore - 2.43)) / (endScore - 1)}%,green 100%)`
  if (endScore > 3.87)
    return `linear-gradient(to right, ${c} 0%,orange ${(100 * (endScore - 3.87)) / (endScore - 1)}%, yellow ${
      (100 * (endScore - 2.43)) / (endScore - 1)
    }%,green 100%)`
  if (endScore > 2.43)
    return `linear-gradient(to right, ${c} 0%, yellow ${(100 * (endScore - 2.43)) / (endScore - 1)}%,green 100%)`
  return `linear-gradient(to right, ${c} 0%,green 100%)`
}
export { SAFEcolorScale, SAFEscoresToGradient, fatigueInRest }
