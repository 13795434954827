import React, { useState, useEffect } from 'react'
import Loader from '../Loader/Loader'
import { Button, Icon, MenuItem, Menu, NonIdealState, Classes } from '@blueprintjs/core'
import { Suggest2 } from '@blueprintjs/select'
import styles from './SearchDropdown.module.scss'
import { FixedSizeList as List } from 'react-window'

function SuggestExample(props) {
  function itemListRenderer(itemProps) {
    return (
      <Menu style={{ padding: 0, width: props.width, minWidth: props.width }}>
        {itemProps.filteredItems.length === 0 ? (
          <MenuItem disabled={true} text="No results." />
        ) : (
          <List
            height={Math.min(150, itemProps.filteredItems.length * 35)}
            itemCount={itemProps.items.length}
            itemSize={35}
            width={props.width}>
            {({ index, style }) => <div style={style}>{itemProps.renderItem(itemProps.items[index], index)}</div>}
          </List>
        )}
      </Menu>
    )
  }

  return (
    <Suggest2
      {...props}
      itemListRenderer={itemListRenderer}
      itemRenderer={(item, { handleClick, modifiers, query }) => {
        return (
          <MenuItem
            key={item.key}
            text={item.text}
            active={modifiers.active}
            disabled={modifiers.disabled}
            onClick={handleClick}
          />
        )
      }}
    />
  )
}

const SuggestDropdown = (props) => {
  const [query, setQuery] = useState()

  const [selected, setSelected] = useState()
  const [activeItem, setActiveItem] = useState()

  let items = React.useMemo(() => {
    const getText = (d) => {
      if (!d) return ''
      return props.text ? props.text(d) : d
    }
    return props.items.map((d) => {
      return { ...d, text: getText(d) }
    })
  }, [props.items, props.text])

  useEffect(() => {
    if (props.activeItem) {
      let itemFound = items.find((d) => d.key === props.activeItem)
      let item = itemFound ? JSON.parse(JSON.stringify(itemFound)) : undefined

      setSelected(item)
    } else {
      setSelected(null)
    }
  }, [props.activeItem])

  const handleClear = () => {
    setSelected(null)
    props.onChange({
      key: null,
    })
  }

  const { loading, placeholder, fill, style, className } = props
  const clearButton = loading ? (
    <Button minimal disabled icon={<Loader type="dots" active={true} />} />
  ) : selected && !props.disabled && !props.hideCloseButton ? (
    <Button icon="cross" minimal onClick={handleClear} tabIndex="-1" />
  ) : undefined

  return (
    <SuggestExample
      itemsEqual="key"
      inputValueRenderer={(item) => item.text}
      items={query ? items.filter((d) => d.text.toUpperCase().includes(query.toUpperCase())) : items}
      onItemSelect={(item) => {
        if (!props.preventSelection) setSelected(item)
        props.onChange(item)
      }}
      disabled={props.disabled}
      popoverProps={{
        minimal: true,
        //shouldReturnFocusOnClose: false,
        captureDismiss: true,
        matchTargetWidth: true,
        usePortal: true,
        //usePortal: false,
        portalContainer: document.getElementById('main'),
        position: 'bottom',
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
        popoverClassName: styles.popover,
      }}
      popoverTargetProps={{
        style: { ...style, maxHeight: 100, overflowY: 'auto' },
        className: fill ? styles.fill : undefined,
      }}
      query={query}
      onQueryChange={function (e) {
        //  let items = props.items.filter((d) => d.text.toLowerCase().includes(e.toLowerCase()))
        //   let item
        //  if (items?.length === 1) item = items[0]
        setQuery(e)
        //  if (item) {
        //    setSelected(item)
        //
        //   props.onChange(item)
        // /}
      }}
      itemPredicate={(query, item, _index, exactMatch) => {
        return item.text
          ? item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0
          : props.text(item).toLowerCase().indexOf(query.toLowerCase()) >= 0
      }}
      noResults={<MenuItem disabled={true} text="No results." />}
      selectedItem={selected}
      scrollToActiveItem={true}
      // closeOnSelect={true}
      //resetOnSelect={props.resetOnSelect || false}
      // resetOnQuery={false}
      activeItem={null}
      resetOnClose
      width={props.width || 200}
      inputProps={{
        style: { width: props.width, textOverflow: 'ellipsis' },
        placeholder: placeholder,
        rightElement: props.activeItem !== props.noclear ? clearButton : undefined,
        intent: props.intent,
      }}
    />
  )
}

export default SuggestDropdown
