import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Position } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import styles from './Activity.module.scss'
import ActivityTooltipHtml from './Tooltips'
import { useSelector } from 'react-redux'
import { changeFilter } from '../../../actions'
import store from 'store'
import { useDraggable, DragOverlay } from '@dnd-kit/core'
import { restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
/**
 * The total number of uncovered roles from a FlightCoverageIssue's description.
 * @param {string} description
 * @returns {number}
 */
const numUncoveredRoles = (description) => {
  // From a string "Impossible to schedule: 1 CCM, 2 SCC", extract the total number of
  // roles by summing the individual components.
  const re1 = /^Impossible to schedule: (.+)$/
  const re2 = /^Missing crew: (.+)$/
  const rem = re2.exec(description) || re1.exec(description)
  if (rem) {
    return rem[1]
      .split(',')
      .map((d) => {
        return d.trim().split(' ')[0]
      })
      .reduce((acc, curr) => acc + Number(curr), 0)
  }
  return 0
}

const Flight = (props) => {
  const [open, setOpen] = useState(false)
  const { zone, errors, filteredCrew } = props
  const { timer, setTimer } = useState(undefined)

  const d = props.data
  const flightID = d.flightID
  /*
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: props.data,
    delay: 1000,
    disabled: !props.edit && !props.dragging,
  })
*/
  const onDoubleClick = useCallback(
    (e) => {
      // setScrollState({ x, y })
      let newSelectedCrew = props.rosterData
        .filter((d) => d.crewFunction !== 'Plane')
        .filter((d) => d.activities.some((e) => e.flightID === flightID || e.activityID === flightID))
        .map((d) => d.employeeCode)

      if (e.ctrlKey) {
        newSelectedCrew = [...new Set(filteredCrew.concat(newSelectedCrew))]
        //add crew to selection
      }

      store.dispatch(
        changeFilter({
          searchPlCode: newSelectedCrew,
        })
      )
    },
    [props.rosterData, filteredCrew]
  )

  const error = useMemo(() => errors?.find((d) => d.flight.activityID === props.data.activityID), [errors])
  const errorStyle = useMemo(() => {
    if (error?.issueType === 'FlightCoverageIssue') {
      const n = numUncoveredRoles(error.description)
      if (n == 1) {
        return styles.numUncoveredRolesLow
      } else if (n > 1 && n <= 3) {
        return styles.numUncoveredRolesMed
      } else if (n > 3) {
        return styles.numUncoveredRolesHigh
      }
    } else if (error) {
      return styles.error
    }
  }, [error])

  // show tooltip on mouse over, unless dragging
  const onMouseEnter = useCallback(() => {
    if (!props.dragging) setOpen(true)
  }, [props.dragging])
  const onMouseLeave = useCallback(() => setOpen(false), [])

  // remove tooltip when dragging
  useEffect(() => {
    if (props.dragging && open) setOpen(false)
  }, [props.dragging])

  const className = useMemo(
    () =>
      [
        styles.activity,

        d.preAssigned ? styles.preAssignedFlag : undefined,
        styles.Plane,
        d.typeOfActivity === 'Standby' ? styles.Standby : undefined,
        errorStyle,
      ].join(' '),
    [styles, errorStyle, d]
  )
  const style = useMemo(() => {
    return {
      transform: `translateX(${d.startTimeX}px)`,
      position: 'absolute',
      pointerEvents: props.dragging ? 'none' : 'unset',
      width: d.endTimeX - d.startTimeX,
      cursor: props.onClick ? 'pointer' : undefined,
    }
  }, [d.startTimeX, d.endTimeX, props.onClick, props.dragging])

  const enoughPlaceForActivityCode = React.useMemo(
    () => d.endTimeX - d.startTimeX > 50 && d.activityCode,
    [d.startTimeX, d.endTimeX, d.activityCode]
  )

  const enoughPlaceForActivityLabel = React.useMemo(() => d.endTimeX - d.startTimeX > 30, [(d.startTimeX, d.endTimeX)])

  const labelMin = React.useMemo(() => Math.max(d.startTimeX, props.x), [d.startTimeX, props.x])
  const labelWidth = React.useMemo(() => d.endTimeX - labelMin, [labelMin, d.endTimeX])

  return (
    <div
      //ref={setNodeRef}
      // {...attributes}
      //  onClick={props.onClick}
      //{...listeners}
      onPointerDown={(e) => {
        // listeners.onPointerDown(e)
        props.onClick
          ? (e) => {
              props.onClick(d)
            }
          : undefined
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      className={className}
      style={style}>
      {enoughPlaceForActivityCode && <span className={styles.activityLabel}>{d.activityCode}</span>}
      {enoughPlaceForActivityLabel || props.edit ? <span>{d.activityLabel}</span> : null}

      {props.draggedItem === 'plane' + d.startTime &&
        d.crewFunction === 'Plane' &&
        !props.dragging(
          <DragOverlay modifiers={[restrictToVerticalAxis]} dropAnimation={null}>
            <div
              className={styles.activity}
              style={{
                width: d.endTimeX - d.startTimeX,
                height: 10,
                backgroundColor: 'red',
                top: '-10px !important',
              }}
            />
          </DragOverlay>
        )}

      {open && !props.dragging && !props.draggedItem && (
        <Tooltip2
          autoFocus={false}
          usePortal={true}
          portalContainer={document.getElementById('main')}
          portalClassName={styles.noevents}
          content={<ActivityTooltipHtml d={d} zone={zone} error={error} />}
          defaultIsOpen
          position={Position.BOTTOM}
          targetClassName={styles.tooltip}
          renderTarget={({ isOpen: isTooltipOpen, ...tooltipProps }) => (
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} {...tooltipProps} />
          )}
        />
      )}
    </div>
  )
}

export default React.memo(Flight)
