import { DateTime, Interval } from 'luxon'
import { startOf, endOf, getTicksInInterval } from 'shared/LuxonHelpers'

import React from 'react'
import Weekend from './Weekend'

const Weekends = (props) => {
  const x = props.xScale

  const { timezone } = props

  const int = React.useMemo(
    () =>
      getTicksInInterval(
        Interval.fromDateTimes(
          DateTime.fromJSDate(x.domain()[0], { zone: timezone }),
          DateTime.fromJSDate(x.domain()[1], { zone: timezone })
        ),
        'day'
      )
        .filter((d) => DateTime.fromJSDate(d, { zone: timezone }).weekday === 6)
        .map((d) => d.getTime()),
    [timezone, x]
  )

  const days = React.useMemo(
    () => int.filter((d) => x(d + 3600 * 24 * 1000 * 2) > props.x).filter((d) => x(d) - props.x < props.width),
    [int, props.width, x, props.x]
  )
  const positions = React.useMemo(() => days.map((d) => x(d)), [days, x])

  return (
    <>
      {positions.map((d) => (
        <Weekend key={d} data={d} xScale={props.xScale} x={props.x} />
      ))}
    </>
  )
}

export default React.memo(Weekends)
