import React from 'react'
import styles from './Spinner.module.scss'

const Loader = ({ className, active, size = 20, color, absolute, ...props }) => {
  const width = size / 5
  return (
    <div
      className={absolute ? className : styles.loader}
      style={{
        width: size,
        height: size,
        borderColor: color + '33',
        borderOpacity: 0.3,
        borderLeftColor: color,
        borderWidth: width,
      }}
    />
  )
}

export default Loader
