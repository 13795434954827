import React from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
const Table = (props) => {
  const prepTableData = (data) => {
    return {
      columnDefs: [
        { headerName: 'id', field: 'id', editable: false, width: 100 },
        {
          headerName: 'activityType',
          field: 'activityType',
          editable: false,
          width: 100,
        },
        {
          headerName: 'start',
          valueFormatter: (d) => moment(parseInt(d.value)).format('LLL'),
          field: 'timeframe.start',
          editable: false,
          enableCellChangeFlash: true,
        },
        {
          headerName: 'end',
          valueFormatter: (d) => moment(parseInt(d.value)).format('LLL'),
          field: 'timeframe.end',
          editable: false,
          enableCellChangeFlash: true,
        },
        {
          headerName: 'properties',
          field: 'properties',
          editable: false,
          width: 300,
        },
      ],
      rowData: data,
    }
  }

  const { data, id } = props
  const tableData = prepTableData(data)
  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        columnDefs={tableData.columnDefs}
        rowData={tableData.rowData}
        immutableData={true}
        getRowNodeId={(row) => row.id}></AgGridReact>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.motassign.RulesEngine.Activities,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
