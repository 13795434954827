import { ControlGroup, HTMLSelect } from '@blueprintjs/core'
import { DateInput2 } from '@blueprintjs/datetime2'
import moment from 'moment'
import React, { useState } from 'react'

const Bid = (props) => {
  const [range, setRange] = useState('after')
  const { data } = props
  const adjustTimes = (selectedTime, val) => {
    let d = { ...data }

    if (selectedTime) d.selectedTime = moment.utc(selectedTime).valueOf()

    if (val === 'after') {
      d.startTime = moment.utc(d.selectedTime).clone().valueOf()
      d.endTime = moment.utc(d.selectedTime).endOf('d').valueOf()
    } else {
      d.startTime = moment.utc(d.selectedTime).clone().startOf('d').valueOf()
      d.endTime = moment.utc(d.selectedTime).clone().valueOf()
    }

    props.onChange(d)
  }

  return (
    <ControlGroup fill>
      <HTMLSelect
        value={range}
        style={{ width: 100 }}
        onChange={(event) => {
          let val = event.currentTarget.value
          setRange(val)
          adjustTimes(null, val)
        }}>
        <option value="before">Before</option>
        <option value="after">After</option>
      </HTMLSelect>

      <DateInput2
        placeholder="date/time"
        value={data.selectedTime ? moment.utc(data.selectedTime).toDate() : null}
        formatDate={(date) => moment.utc(date).format('YYYY/MM/DD - HH:mm')}
        parseDate={(str) => moment.utc(str).toDate()}
        timePrecision="minute"
        onChange={(e) => {
          adjustTimes(moment.utc(e), range)
        }}
      />
    </ControlGroup>
  )
}
export default Bid
