import React, { useState, useEffect } from 'react'
import { InputGroup, Position, Tag } from '@blueprintjs/core'
import Validate from './Validate'

const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value)
}
const InputNumber = (props) => {
  const { error, onChange, value, placeholder } = props
  const [internalValue, setInternalValue] = useState(value)
  const [localError, setLocalError] = useState(error)
  useEffect(() => setInternalValue(value), [value])

  return (
    <Validate validation_errors={error} position={Position.TOP}>
      <InputGroup
        {...props}
        autoFocus={true}
        intent={error || localError ? 'danger' : undefined}
        style={{ width: 100, marginRight: 10, textAlign: 'right' }}
        value={internalValue}
        placeholder={placeholder ? placeholder : 'number'}
        onChange={({ target }) => {
          let value = isNumber(parseInt(target.value)) ? parseInt(target.value) : target.value
          if (!isNumber(parseInt(target.value))) {
            setLocalError(true)
          } else {
            setLocalError(null)
            setInternalValue(value)
            onChange(value)
          }
        }}
      />
    </Validate>
  )
}

export default InputNumber
