import ExcludedCrewMembersV1 from './ExcludedCrewMembersV1'
import ExcludedCrewMembersV2 from './ExcludedCrewMembersV2'

const formatSelector = (props) => {
  const formatVersion = props?.data?.payload?.formatVersion
  switch (formatVersion) {
    case 2:
      return <ExcludedCrewMembersV2 {...props} />
    default:
      return <ExcludedCrewMembersV1 {...props} />
  }
}
export default formatSelector
