import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SearchDropdown from 'shared/SearchDropdown/SearchDropdown'
import * as worldTimezones from './timezones.json'

const f = (d) => d.name
const timezones = Object.values(worldTimezones.default)
  .map((d) => {
    return { ...d, key: d.name, text: d.name }
  })
  .sort((a, b) => {
    if (a.name === 'UTC') return -1
    if (b.name === 'UTC') return 1
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

const TimezoneDropdown = (props) => {
  const items = React.useMemo(
    () => (props.items ? timezones.filter((d) => props.items.includes(d.iata_code)) : timezones),
    [timezones, props.items]
  )

  return (
    <SearchDropdown
      activeItem={props.activeItem}
      disabled={props.disabled}
      noclear="UTC"
      items={items}
      popoverProps={{ usePortal: true }}
      width={props.width ? props.width : 100}
      placeholder="Timezone"
      text={f}
      onChange={(d) => {
        if (d.key === null) props.onChange(timezones.find((d) => d.name === 'UTC'))
        else props.onChange(d)
      }}
      //  {...props}
    />
  )
}

export default React.memo(TimezoneDropdown)
