import React from 'react'
import { Card } from '@blueprintjs/core'
import { intl } from 'i18n/IntlGlobalProvider'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import styles from './log.module.scss'

const allowedEvent = [
  { id: 'idps_export:start' },
  { id: 'idps_export:stop' },
  { id: 'idps_export:ready' },
  { id: 'idps_export:error' },
  { id: 'idps_export:retry' },
  { id: 'calc:flight_cov:start' },
  { id: 'calc:flight_cov:stop' },
  { id: 'calc:flight_cov:ready' },
  { id: 'calc:flight_cov:error' },
  { id: 'calc:flight_cov:retry' },
  { id: 'calc:flight_cov:retry:idps_export:retry' },
  { id: 'calc:roster:start' },
  { id: 'calc:roster:stop' },
  { id: 'calc:roster:ready' },
  { id: 'calc:roster:error:retry' },
  { id: 'calc:roster:retry:flight_cov:retry' },
  { id: 'idps_import:start' },
  { id: 'idps_import:stop' },
  { id: 'idps_import:ready' },
  { id: 'idps_import:error' },
  { id: 'idps_import:retry' },
  { id: 'end' },
]
const Log = () => {
  const activePublication = useSelector((state) => state.rosters.activePublication)

  const rowStyle = { padding: 10, width: '100%', display: 'flex' }
  const headerRowStyle = { ...rowStyle, fontWeight: 'bold', padding: 10, borderBottom: '1px solid #ccc' }
  const fixedCellStyle80 = { width: '80px', flex: '0 0 80px' }
  const fixedCellStyle100 = { width: '100px', flex: '0 0 100px' }
  const flexCellStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
  const flexCellStyle1 = {
    ...flexCellStyle,
    flex: 1,
    maxWidth: '200px',
    paddingRight: '8px',
  }
  const flexCellStyle2 = {
    ...flexCellStyle,
    flex: 2,
  }

  return (
    <div style={{ padding: 10 }}>
      <Card>
        <h2>Event log</h2>
        <div style={headerRowStyle}>
          <span style={fixedCellStyle100}>Date</span>
          <span style={fixedCellStyle80}>UTC</span>
          <span style={flexCellStyle1}>Created by</span>
          <span style={flexCellStyle1}>Type</span>
          <span style={flexCellStyle2}>Message</span>
        </div>
        {activePublication.events
          .filter((d) => allowedEvent.findIndex((d2) => d2.id === d.type) >= 0)
          .sort((a, b) => (DateTime.fromISO(a).toMillis() > DateTime.fromISO(b).toMillis() ? 1 : -1))
          .map((d, i) => (
            <div key={d.created_at} style={rowStyle} className={i % 2 ? styles.even : undefined}>
              <span style={fixedCellStyle100}>
                {DateTime.fromISO(d.created_at, { zone: 'UTC' }).toFormat('yyyy-MM-dd')}
              </span>
              <span style={fixedCellStyle80}>
                {DateTime.fromISO(d.created_at, { zone: 'UTC' }).toFormat('HH:mm:ss')}
              </span>
              <span style={flexCellStyle1}>{d.created_by_name}</span>
              <span style={flexCellStyle1}>{intl.t(d.type)}</span>
              <span style={flexCellStyle2}>{d.result.message}</span>
            </div>
          ))}
      </Card>
    </div>
  )
}

export default Log
