import { ControlGroup, InputGroup } from '@blueprintjs/core'
import { DateInput2 } from '@blueprintjs/datetime2'
import moment from 'moment'
import React from 'react'

const Bid = (props) => {
  const { data } = props
  return (
    <ControlGroup fill>
      <InputGroup
        placeholder="Employee"
        onChange={(val) => {
          let d = { ...data }
          d.employee = val
          props.onChange(d)
        }}
      />
      <InputGroup
        placeholder="Flight Nr"
        onChange={(val) => {
          let d = { ...data }
          d.flightNr = val
          props.onChange(d)
        }}
      />

      <DateInput2
        placeholder="to"
        value={moment.utc(data.endTime).toDate()}
        formatDate={(date) => moment.utc(date).format('YYYY/MM/DD')}
        parseDate={(str) => new Date(str)}
        onChange={(e) => {
          let d = { ...data }
          d.startTime = moment.utc(e).startOf('d').valueOf()
          d.endTime = moment.utc(e).endOf('d').valueOf()
          props.onChange(d)
        }}
      />
    </ControlGroup>
  )
}
export default Bid
