import React, { useState, useEffect } from 'react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { Cell, setCellStyle } from '../AgGridCellValidator'
import { DateTime } from 'luxon'
import { lux, startOf, endOf } from 'shared/LuxonHelpers'
import { FormGroup, Collapse, Callout, H5, H6, Tag, Icon, Classes, Button, Divider, Switch } from '@blueprintjs/core'
import { InputText, InputDate, InputTime } from 'shared/Forms'
import CreateOrSaveDialog from 'shared/CreateOrSaveDialog'
import AirportDropdown from 'shared/AirportDropdown/AirportDropdown'
import MultiSearchDropdown from 'shared/SearchDropdown/MultiSearchDropdown'

const newFlightActivity = {
  flightNumber: '',
  typeOfActivity: 'Flight',
  activityCode: 'F',
  from: 'BRU',
  to: 'BRU',
  assignedCrew: { CP: [], FO: [], RP: [], CCM: [], ASC: [], SCC: [] },
}

const ActivityDialog = (props) => {
  const [isOpen, setIsOpen] = useState(null)
  const { aircraft } = props
  useEffect(() => {
    props.data ? setIsOpen(true) : setIsOpen(null)
  }, [props.data])

  return (
    <CreateOrSaveDialog
      style={{ width: 700 }}
      usePortal={false}
      //   payload={personalData}
      //   id={personalData}
      icon="timeline-events"
      title={'flight for ' + props.aircraft?.employeeCode}
      edit={!isNaN(props.data) && props.data ? null : props.data}
      resetObject={
        !isNaN(props.data) && props.data
          ? {
              typeOfActivity: 'Flight',
              activityCode: 'F',
              activityID: Math.floor(Math.random() * 10000000),
              from: 'BRU',
              to: 'AAC',
              crewFunction: 'Plane',
              flightNumber: 'XXXX',
              crewComplement: aircraft?.crewComplement,
              assignedCrew: { CP: [], FO: [], RP: [], CCM: [], ASC: [], SCC: [] },
              startTime: props.data,
              endTime: lux(props.data).endOf('day').toMillis(),
            }
          : null
      }
      onDelete={props.onDelete}
      onClose={(d) => {
        setIsOpen(null)
        props.onClose ? props.onClose(d) : undefined
      }}
      onSave={props.onSave}
      onCreate={props.onCreate}
      isOpen={isOpen}>
      {(data, setData) => {
        return (
          <div>
            <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>Flight Nr</span>} inline>
              <InputText
                style={{ width: 80 }}
                value={data?.flightNumber}
                onChange={(value) => setData({ ...data, flightNumber: value })}
              />
            </FormGroup>
            <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>From</span>} inline>
              <InputDate value={data?.startTime} onChange={(value) => setData({ ...data, startTime: value })} />
              <InputTime
                value={lux(data?.startTime, 'UTC')?.toFormat('HH:mm')}
                onChange={(value) => {
                  setData({
                    ...data,
                    startTime: DateTime.fromISO(
                      lux(data?.startTime, 'UTC').toFormat('yyyy-MM-dd') + 'T' + value + 'Z',
                      'yyyy-MM-dd HH:mm',
                      'UTC'
                    ).toMillis(),
                  })
                }}
              />
              <AirportDropdown activeItem={data?.from} onChange={(value) => setData({ ...data, from: value.iata })} />
            </FormGroup>
            <FormGroup label={<span style={{ width: 70, display: 'inline-block' }}>To</span>} inline>
              <InputDate value={data?.endTime} onChange={(value) => setData({ ...data, endTime: value })} />
              <InputTime
                value={lux(data?.endTime, 'UTC')?.toFormat('HH:mm')}
                onChange={(value) => {
                  setData({
                    ...data,
                    endTime: DateTime.fromISO(
                      lux(data?.endTime, 'UTC').toFormat('yyyy-MM-dd') + 'T' + value + 'Z',
                      'yyyy-MM-dd HH:mm',
                      'UTC'
                    ).toMillis(),
                  })
                }}
              />
              <AirportDropdown activeItem={data?.to} onChange={(value) => setData({ ...data, to: value.iata })} />
            </FormGroup>
            {data?.crewComplement?.map((c, i) => (
              <div key={i}>
                <FormGroup
                  helperText={
                    !c.crewNumber
                      ? 'Not required'
                      : c.crewNumber > 0 && data?.assignedCrew?.[c.crewFunction].length < c.crewNumber
                      ? c.crewNumber - data?.assignedCrew?.[c.crewFunction].length + ' more required'
                      : 'OK'
                  }
                  label={<span style={{ width: 70, display: 'inline-block' }}>{c.crewFunction}</span>}
                  inline>
                  <MultiSearchDropdown
                    key={i}
                    value={data?.assignedCrew?.[c.crewFunction]}
                    fill
                    disabled={!c.crewNumber}
                    style={{ width: 400 }}
                    placeholder="Employee Code"
                    items={props.employees
                      .filter((d) => d.crewFunction !== 'Plane')
                      .filter((d) => {
                        switch (c.crewFunction) {
                          case 'CP':
                            return ['CP'].includes(d.crewFunction)
                          case 'FO':
                            return ['CP', 'FO'].includes(d.crewFunction)
                          case 'RP':
                            return ['CP', 'FO'].includes(d.crewFunction)
                          case 'SCC':
                            return ['SCC'].includes(d.crewFunction)
                          case 'ASC':
                            return ['SCC', 'ASC'].includes(d.crewFunction)
                          default:
                            return ['SCC', 'ASC', 'CCM'].includes(d.crewFunction)
                        }
                      })
                      .map((d) => {
                        return {
                          key: d.employeeCode,
                          value: d.employeeCode,
                          text: `${d.employeeCode} - ${d.personalID}`,
                        }
                      })}
                    onChange={(value) => {
                      setData({
                        ...data,
                        assignedCrew: {
                          ...data.assignedCrew,
                          [c.crewFunction]: value,
                        },
                      })
                    }}
                  />
                </FormGroup>
              </div>
            ))}
          </div>
        )
      }}
    </CreateOrSaveDialog>
  )
}

export default ActivityDialog
