import React, { useState } from 'react'
import { Divider, Icon, Tabs, Tab, Navbar, Button, Alignment } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'

const buttonStyle = {
  margin: 10,
}

const NavBar = (props) => {
  const [isTab, setTab] = useState(props.tabId)
  const navigate = useNavigate()
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>Dashboard</Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Tabs
          id="TabsExample"
          large
          onChange={(tabId) => {
            setTab(tabId)
            props.onChangeTab(tabId)
            navigate(tabId)
          }}
          selectedTabId={isTab}>
          <Tab
            id="cost"
            large
            title={
              <span>
                <Icon icon="euro" style={{ marginRight: 10 }} />
                Cost
              </span>
            }
          />
          <Tab
            id="distributions"
            title={
              <span>
                <Icon icon="doughnut-chart" style={{ marginRight: 10 }} />
                Distribution
              </span>
            }
          />
          <Tab
            id="violations"
            title={
              <span>
                <Icon icon="offline" style={{ marginRight: 10 }} />
                Violation
              </span>
            }
          />{' '}
          <Tab
            id="summary"
            title={
              <span>
                <Icon icon="dashboard" style={{ marginRight: 10 }} />
                Summary
              </span>
            }
          />
        </Tabs>
        <Button minimal style={{ marginLeft: 20 }} icon="filter" onClick={props.onChange} />
      </Navbar.Group>
    </Navbar>
  )
}

export default NavBar
