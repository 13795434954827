import { produce } from 'immer'
import { FETCH_AIRPORTS } from './actions'

const initialState = {
  items: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AIRPORTS:
      return produce(state, (draftState) => {
        draftState.items = action.payload
      })

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
