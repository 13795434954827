import React from 'react'
import styles from './SidebarMenu.module.scss'

const SidebarMenu = ({ collapsed, duration = 100, children }) => (
  <div
    className={styles.sidebar}
    style={{
      width: collapsed ? 41 : 200,
    }}>
    {children}
  </div>
)
export default SidebarMenu
