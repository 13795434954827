// Import our depdendency
import { myFetch, myPost, myPut, myDelete } from 'api'
import store from 'store'
import config from 'config'
import {
  addAircraftToRepository,
  addAircrafTypeToRepository,
  addAirlineToRepository,
  addAirportToRepository,
  addCrewMemberToRepository,
  addFlightToRepository,
  addRankToRepository,
  addCrewMemberFlightAssignmentToRepository,
  addCrewMemberAbsenceToRepository,
  addFlightDepArrChangedToRepository,
  deleteFlightAssignmentFromRepository,
  deleteCrewMemberFromRepository,
  deleteFlightFromRepository,
  addEventToHistory,
} from './actions'
import { CmEventTypes, CmEvents } from './constants'

const CM_PING_EVENT = (clientId) =>
  JSON.stringify({
    type: CmEventTypes.PING,
    ref: clientId,
  })

const CM_SESSION_CREATED_EVENT = (clientId) =>
  JSON.stringify({
    type: CmEventTypes.SESSION_CREATED,
    session: {
      clientReference: clientId,
      repositoryFilter: {},
      repositoryName: 'tuipoc-repository',
    },
  })
const servicesFactory = (myFetch, myPost, myDelete, myPut) => ({
  sessionInitialized: false,
  convertEventsToObjects(events) {
    return {
      crewMembers: events.filter((e) => e.type === CmEventTypes.CREW_MEMBER_CREATED).map((d) => d.crewMember),
      flights: events.filter((e) => e.type === CmEventTypes.FLIGHT_CREATED).map((d) => d.flight),
      aircrafts: events.filter((e) => e.type === CmEventTypes.AIRCRAFT_CREATED).map((d) => d.aircraft),
      airlines: events.filter((e) => e.type === CmEventTypes.AIRLINE_CREATED).map((d) => d.airline),
      aircraftTypes: events.filter((e) => e.type === CmEventTypes.AIRCRAFT_TYPE_CREATED).map((d) => d.aircraftType),
      airports: events.filter((e) => e.type === CmEventTypes.AIRPORT_CREATED).map((d) => d.airport),
      crewMemberAbsences: events
        .filter((e) => e.type === CmEventTypes.CREW_MEMBER_ABSENCE_CREATED)
        .map((d) => d.CrewMemberAbsence),
      ranks: events.filter((e) => e.type === CmEventTypes.RANK_CREATED).map((d) => d.rank),
      crewMemberDeletes: events.filter((e) => e.type === CmEventTypes.CREW_MEMBER_DELETED).map((d) => d.crewMember),
      flightDeletes: events.filter((e) => e.type === CmEventTypes.FLIGHT_DELETED).map((d) => d.flight),
      crewMemberFlightAssignments: events
        .filter((e) => e.type === CmEventTypes.CREW_MEMBER_FLIGHT_ASSIGNMENT_CREATED)
        .map((d) => d.crewMemberFlightAssignment),
      crewMemberFlightAssignmentsDeleted: events
        .filter((e) => e.type === CmEventTypes.CREW_MEMBER_FLIGHT_ASSIGNMENT_DELETED)
        .map((d) => d.ref),
      flightsChanged: events
        .filter((e) => e.type === CmEventTypes.FLIGHT_DEPARTURE_AND_ARRIVAL_CHANGED)
        .map((d) => d.flightDepartureAndArrivalUpdate),
    }
  },
  addToRepository(data) {
    const {
      crewMembers,
      flights,
      crewMemberFlightAssignments,
      aircrafts,
      aircraftTypes,
      airlines,
      crewMemberFlightAssignmentsDeleted,
      flightsChanged,
      crewMemberAbsences,
      crewMemberDeletes,
      flightDeletes,
      ranks,
      airports,
    } = data
    aircrafts.forEach((d) => store.dispatch(addAircraftToRepository(d)))
    aircraftTypes.forEach((d) => store.dispatch(addAircrafTypeToRepository(d)))
    airlines.forEach((d) => store.dispatch(addAirlineToRepository(d)))
    airports.forEach((d) => store.dispatch(addAirportToRepository(d)))
    crewMembers.forEach((d) => store.dispatch(addCrewMemberToRepository(d)))
    flights.forEach((d) => store.dispatch(addFlightToRepository(d)))
    ranks.forEach((d) => store.dispatch(addRankToRepository(d)))
    crewMemberAbsences.forEach((d) => store.dispatch(addCrewMemberAbsenceToRepository(d)))
    crewMemberFlightAssignments.forEach((d) => store.dispatch(addCrewMemberFlightAssignmentToRepository(d)))
    flightsChanged.forEach((d) => store.dispatch(addFlightDepArrChangedToRepository(d)))
    crewMemberFlightAssignmentsDeleted.forEach((d) => store.dispatch(deleteFlightAssignmentFromRepository(d)))
    crewMemberDeletes.forEach((d) => store.dispatch(deleteCrewMemberFromRepository(d)))
    flightDeletes.forEach((d) => store.dispatch(deleteFlightFromRepository(d)))
  },

  keepAlive(clientId, interval) {
    setInterval(() => {
      this.postMessageToServer(CM_PING_EVENT(clientId))
    }, interval * 1000)
  },

  waitForSocketConnection(callback) {
    setTimeout(() => {
      if (this.ws.readyState === 1) {
        if (callback != null) {
          callback()
        }
      } else {
        this.waitForSocketConnection(callback)
      }
    }, 5) // wait 5 milisecond for the connection...
  },
  async postMessageToServer(body) {
    let json = await fetch('http://localhost:8098/crewmgmt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        CrewMgmtSession: this.clientId,
        'Content-Type': 'application/json',
      },
      credentials: 'include',

      body,
    })
  },
  initializeConnection(onmessage, clientId, interval) {
    this.clientId = clientId
    this.ws = new WebSocket('ws://localhost:8098/crewmgmtws')
    this.ws.onmessage = onmessage
    this.waitForSocketConnection(async () => {
      this.ws.send(CM_SESSION_CREATED_EVENT(clientId))
      this.postMessageToServer(CM_SESSION_CREATED_EVENT(clientId))
      this.postMessageToServer(CM_PING_EVENT(clientId))
      this.keepAlive(clientId, interval)
    })
  },
})

const api = servicesFactory(myFetch, myPost, myDelete, myPut)
export default api
