import { STORE_EMPLOYEE_DATA, STORE_EMPLOYEE_BIDS, CREATE_NEW_BID, DELETE_BID, STORE_BID_TYPES } from './actions'

const initialState = {
  bidTypes: [],
  employeeData: undefined,
  bids: [],
}

export default function rosterReducer(state = initialState, action) {
  let i
  switch (action.type) {
    case STORE_BID_TYPES:
      return {
        ...state,
        bidTypes: action.payload,
      }
    case STORE_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload,
      }
    case STORE_EMPLOYEE_BIDS:
      return {
        ...state,
        bids: action.payload,
      }
    case CREATE_NEW_BID: {
      let bids = [...state.bids]

      i = state.bids.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        bids[i] = action.payload
      } else bids.push(action.payload)
      return {
        ...state,
        bids: bids,
      }
    }
    case DELETE_BID: {
      i = state.bids.findIndex((e) => e.id === action.payload)
      let bids
      if (i > -1) {
        bids = [...state.bids]
        bids.splice(i, 1)
      }

      return {
        ...state,
        bids: i > -1 ? bids : state.bids,
      }
    }
    default:
      // ALWAYS have a default case in a reducer
      return state
  }
}
