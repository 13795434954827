import React from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'

const Table = (props) => {
  const prepTableData = (data) => {
    return {
      columnDefs: [
        { headerName: 'state', field: 'state', editable: false, width: 50 },
        {
          headerName: 'identifier',
          field: 'identifier',
          editable: false,
          width: 400,
        },
        {
          headerName: 'description',
          field: 'description',
          editable: false,
          width: 800,
        },
        {
          headerName: 'properties',
          field: 'properties',
          editable: false,
          width: 200,
        },
      ],
      rowData: data,
    }
  }

  const { data, id } = props
  const tableData = prepTableData(data)
  return (
    <div className="mp-ag-grid" style={{ height: '100%', width: '100%' }}>
      <AgGridReact columnDefs={tableData.columnDefs} rowData={tableData.rowData}></AgGridReact>
    </div>
  )
}

const mapStateToProps = (state) => ({
  data: state.motassign.RulesEngine.Violations,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
