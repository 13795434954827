import { useRef, useState, useCallback, useEffect } from 'react'

const useDrag = (elem, onDragStart, onDrag, onDragEnd) => {
  const [drag, _setDrag] = useState(undefined)
  const dragRef = useRef(drag)
  const inititalDragRef = useRef()

  useEffect(() => {
    const el = elem.current

    if (!el) return
    const setDrag = (x) => {
      dragRef.current = x
      _setDrag(x)
    }

    const updatePosition = (e) => {
      if (inititalDragRef.current)
        if (e.clientX !== inititalDragRef.current.x) {
          //  setDrag(true)
          onDragStart({ x: inititalDragRef.current.x, y: inititalDragRef.current.y })
          inititalDragRef.current = undefined
        }
      if (e.clientX !== dragRef.current.x) {
        onDrag({
          dx: !dragRef.current ? 0 : e.clientX - dragRef.current.x,
          dy: !dragRef.current ? 0 : e.clientY - dragRef.current.y,
          movementX: e.movementX,
          movementY: e.movementY,
        })
      }
    }

    const mouseDown = (e) => {
      document?.addEventListener('mousemove', updatePosition, false)

      dragRef.current = { x: e.clientX, y: e.clientY }
      inititalDragRef.current = { x: e.clientX, y: e.clientY }
    }
    const mouseUp = (e) => {
      document?.removeEventListener('mousemove', updatePosition, false)
      onDragEnd({ x: e.clientX, y: e.clientY })

      setDrag(undefined)
    }

    const addListeners = (el) => {
      el.addEventListener('mousedown', mouseDown, false)
      document.addEventListener('mouseup', mouseUp, false)
    }

    const removeListeners = (el) => {
      el?.removeEventListener('mousedown', mouseDown, false)
      document.removeEventListener('mouseup', mouseUp, false)
    }

    addListeners(el)
    return () => {
      removeListeners(el)
    }
  }, [elem.current])

  return drag
}

export { useDrag }
