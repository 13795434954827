import React, { useState, useEffect } from 'react'
import { TextArea, InputGroup } from '@blueprintjs/core'
import Validate from './Validate'
const InputText = (props) => {
  const { error, onChange, placeholder, value, area, errorposition } = props
  const [localError, setLocalError] = useState([])

  const validationError = [...(error || []), ...localError]
  const componentProps = {
    ...props,
    autoFocus: true,
    placeholder: placeholder ?? '',
    intent: validationError.length > 0 ? 'danger' : 'undefined',
    value: value || '',
    onChange: ({ target }) => {
      const errors = props.validators?.map((validator) => validator(target.value)).filter((d) => d)
      if (errors?.length > 0) setLocalError(errors)
      else setLocalError([])
      onChange(target.value, errors?.length > 0)
    },
  }

  return (
    <Validate validation_errors={validationError} position={errorposition || 'right'} fill={props.fill}>
      {!area && <InputGroup {...componentProps} />}
      {area && <TextArea {...componentProps} />}
    </Validate>
  )
}

export default InputText
