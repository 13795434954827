import React, { useState } from 'react'
import SearchDropdown from 'shared/SearchDropdown/SearchDropdown'
import * as WorldCurrencies from './currencies.json'
import { Position } from '@blueprintjs/core'
import Validate from 'shared/Forms/Validate'

const currencies = Object.values(WorldCurrencies.default).map((d) => {
  return { ...d, key: d.code, text: d.code }
})

const CurrencyDropdown = (props) => {
  const items = props.items ? currencies.filter((d) => props.items.includes(d.code)) : currencies

  const { error } = props
  return (
    <Validate validation_errors={error} position={Position.TOP}>
      <SearchDropdown
        hideCloseButton
        intent={props.intent}
        activeItem={props.activeItem}
        text={(d) => d.key}
        disabled={props.disabled}
        placeholder="Currency"
        items={items}
        width={props.fill ? null : 80}
        onChange={(d) => {
          props.onChange(d)
        }}
        style={props.style}
      />
    </Validate>
  )
}

export default CurrencyDropdown
