import React, { useEffect, useState, useRef } from 'react'
import { DateTime, Interval } from 'luxon'
import styles from './Header.module.scss'
import { endOf, startOf, getTicksInInterval, format } from 'shared/LuxonHelpers'
import store from 'store'
import { changeFilter } from 'publication/actions'
import { useSelector } from 'react-redux'

const Header = (props) => {
  const filter = useSelector((state) => state.rosters.filter)
  const { selectedDay, timezone } = filter
  const daysRef = useRef()

  const d = props.data
  const { width, xScale } = props

  const widthOfOneDay = React.useMemo(() => xScale(3600 * 24 * 1000) - xScale(0), [xScale])

  const int = React.useMemo(
    () =>
      getTicksInInterval(
        Interval.fromDateTimes(
          DateTime.fromJSDate(xScale.domain()[0], { zone: timezone }),
          DateTime.fromJSDate(xScale.domain()[1], { zone: timezone })
        ),
        'day'
      ).map((d) => {
        return {
          ts: d.getTime(),
          text:
            widthOfOneDay > 60
              ? widthOfOneDay > 120
                ? format(d, timezone, 'cccc dd')
                : format(d, timezone, 'ccc dd')
              : format(d, timezone, 'dd'),
          selected: d.getTime() === selectedDay ? true : false,
          pos: xScale(d),
        }
      }),
    [timezone, xScale, selectedDay]
  )

  const days = React.useMemo(
    () => int.filter((d) => d.pos + widthOfOneDay - props.x >= 0).filter((d) => d.pos - props.x < width),

    [int, width, props.x, widthOfOneDay]
  )

  const monthsTicks = React.useMemo(() => {
    const ticks = getTicksInInterval(
      Interval.fromDateTimes(
        DateTime.fromJSDate(xScale.domain()[0], { zone: timezone }),
        DateTime.fromJSDate(xScale.domain()[1], { zone: timezone })
      ),
      'month'
    )
    return ticks.map((d, i) => ({
      ts: d.getTime(),
      text: format(d, timezone, 'MMMM'),
      pos: xScale(d),
      end: i < ticks.length - 1 ? xScale(ticks[i + 1]) : xScale(endOf(d, 'UTC', 'month')),
    }))
  }, [timezone, xScale])

  const months = React.useMemo(
    () =>
      monthsTicks
        .filter((d) => d.end - props.x >= 0)
        .filter((d) => d.pos - props.x < width)
        .map((d) => ({
          ...d,
          left: d.pos < props.x && d.end > props.x,
          right: d.pos > props.x && d.end > props.x + width,
        })),
    [timezone, props.x, width]
  )
  return (
    <>
      {!props.noheader && (
        <div className={styles.rosterHeader}>
          {months.map((d, i) => (
            <div
              key={d.ts}
              className={styles.topLabel}
              style={{
                transform: `translateX(${Math.max(d.pos - props.x, 0)}px)`,
                justifyContent: i == 0 ? 'right' : 'left',
                width: Math.min(width - 340, d.end - props.x) - Math.max(d.pos - props.x, 0),
              }}>
              <span style={{ flex: 1, marginLeft: 5, marginRight: 5 }}> {d.text}</span>
            </div>
          ))}
          <div ref={daysRef} style={{ display: 'flex', top: 25, position: 'absolute', width: '100%' }}>
            {days.map((d, i) => (
              <div
                onClick={() => {
                  store.dispatch(
                    changeFilter({
                      selectedDay: selectedDay === d.ts ? null : d.ts,
                    })
                  )
                }}
                key={d.ts}
                className={[styles.bottomLabel, d.selected ? styles.selectedDayLabel : undefined].join(' ')}
                style={{
                  transform: `translateX(${d.pos - props.x}px)`,
                  width: widthOfOneDay,
                }}>
                {d.text}
              </div>
            ))}
          </div>
          {months.map((d, i) => (
            <div
              style={{
                transform: `translateX(${d.pos - props.x}px)`,
                width: 1,
                position: 'absolute',
                height: '100%',
                borderLeft: '1px solid #ccc',
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default React.memo(Header)
