import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'
import {
  Card,
  Callout,
  Button,
  Label,
  Icon,
  InputGroup,
  Position,
  FormGroup,
  Checkbox,
  ControlGroup,
  Tag,
} from '@blueprintjs/core'
import DataObject from './DataObject'
import { produce } from 'immer'
import { intl } from 'i18n/IntlGlobalProvider'
import SelectDropdown from 'shared/SearchDropdown/SelectDropdown'
import { InputText, InputDate, InputDateRange } from 'shared/Forms'
import { DateRangeInput2 } from '@blueprintjs/datetime2'
import { LocaleUtils } from 'react-day-picker'
const myLocaleUtils = { ...LocaleUtils, getFirstDayOfWeek: () => 1 }

import { lux } from 'shared/LuxonHelpers'

import store from 'store'
import { addContextDataObject } from './actions'
import api from './api'
import { Switch } from '@blueprintjs/core'
import { DateTime, Interval } from 'luxon'
import MixcloudPlayer from 'react-player/mixcloud'
import { active } from 'd3'

const Comp = (props) => {
  const airline = props.data.payload.airline || 'JAF'
  const type = props.data.payload.publication_type
  const { data, global } = props
  const { validation_errors } = data
  const activePublication = useSelector((state) => state.rosters.activePublication)
  const [manualOverride, setManualOverride] = useState(false)
  const [derivedDates, setDerivedDates] = useState({})

  const isFCMChecked = data.payload['publication_type'].indexOf('FCM') >= 0
  const isCCMChecked = data.payload['publication_type'].indexOf('CCM') >= 0

  const handleCrewMasterExpiryCodesChange = async (codes) => {
    let newData = produce(data, (draftState) => {
      draftState.payload['CrewMaster_ExpiryCodes'] = codes
    })
    store.dispatch(addContextDataObject(newData))
    props.onSave(newData.id, newData.payload)
  }
  const handlePublicationType = async (type) => {
    let newData = produce(data, (draftState) => {
      let value
      if (type === 'CCM' && isCCMChecked && isFCMChecked) value = 'FCM'
      if (type === 'CCM' && !isCCMChecked && isFCMChecked) value = 'FCM & CCM'
      if (type === 'CCM' && isCCMChecked && !isFCMChecked) value = 'CCM'
      if (type === 'CCM' && !isCCMChecked && !isFCMChecked) value = 'CCM'

      if (type === 'FCM' && isFCMChecked && isCCMChecked) value = 'CCM'
      if (type === 'FCM' && !isFCMChecked && isCCMChecked) value = 'FCM & CCM'
      if (type === 'FCM' && isFCMChecked && !isCCMChecked) value = 'FCM'
      if (type === 'FCM' && !isFCMChecked && !isCCMChecked) value = 'FCM'

      draftState.payload['publication_type'] = value
    })
    store.dispatch(addContextDataObject(newData))
    props.onSave(newData.id, newData.payload)
  }

  const handleDateChange = async (date, field) => {
    let newData = produce(data, (draftState) => {
      draftState.payload[field + '_Start-date'] = lux(date[0]).toFormat('yyyy-MM-dd')
      draftState.payload[field + '_End-date'] = lux(date[1]).toFormat('yyyy-MM-dd')
    })
    store.dispatch(addContextDataObject(newData))
    props.onSave(newData.id, newData.payload)
    setDerivedDates({
      ...derivedDates,
      [field + '_Start-date']: lux(date[0]).toJSDate(),
      [field + '_End-date']: lux(date[0]).toJSDate(),
    })
  }

  const format = (dates) => {
    return {
      'Flight_Start-date': lux(dates['Flight_Start-date']).toFormat('yyyy-MM-dd'),
      'Flight_End-date': lux(dates['Flight_End-date']).toFormat('yyyy-MM-dd'),

      'CrewRoster_Start-date': lux(dates['CrewRoster_Start-date']).toFormat('yyyy-MM-dd'),
      'CrewRoster_End-date': lux(dates['CrewRoster_End-date']).toFormat('yyyy-MM-dd'),

      'CrewDuty_Start-date': lux(dates['CrewDuty_Start-date']).toFormat('yyyy-MM-dd'),
      'CrewDuty_End-date': lux(dates['CrewDuty_End-date']).toFormat('yyyy-MM-dd'),
    }
  }

  const clearDates = async () => {
    const newData = produce(data, (draftState) => {
      draftState.payload['Flight_Start-date'] = null
      draftState.payload['Flight_End-date'] = null
      draftState.payload['CrewRoster_Start-date'] = null
      draftState.payload['CrewRoster_End-date'] = null
      draftState.payload['CrewDuty_Start-date'] = null
      draftState.payload['CrewDuty_End-date'] = null
    })
    store.dispatch(addContextDataObject(newData))
    props.onSave(newData.id, newData.payload)
  }

  const setDates = (activePublication) => {
    const fdop = lux(activePublication.first_day)
    const ldop = lux(activePublication.last_day)
    // TODO: ugly hack: flexible minimal runs don't require full crew history, which
    // would take too long to extract from IDPS.
    // This should be configurable in the admin panel and the UI should be able to adapt,
    // for now it is hardcoded both here & in the API.
    const nDaysCrewHistory = activePublication.calculation_mode === 'flexible' ? 28 : 3 * 28
    return {
      'Flight_Start-date': fdop.minus({ days: 7 }).toJSDate(),
      'Flight_End-date': ldop.plus({ days: 14 }).toJSDate(),

      'CrewRoster_Start-date': fdop.minus({ days: nDaysCrewHistory }).toJSDate(),
      'CrewRoster_End-date': ldop.plus({ days: 14 }).toJSDate(),

      'CrewDuty_Start-date': fdop.minus({ days: nDaysCrewHistory }).toJSDate(),
      'CrewDuty_End-date': ldop.plus({ days: 35 }).toJSDate(),
    }
  }

  useEffect(() => {
    if (global) return
    if (
      data.payload['Flight_Start-date'] === null ||
      data.payload['Flight_End-date'] === null ||
      data.payload['CrewDuty_Start-date'] === null ||
      data.payload['CrewDuty_End-date'] === null ||
      data.payload['CrewRoster_Start-date'] === null ||
      data.payload['CrewRoster_End-date'] === null
    )
      setDerivedDates(setDates(activePublication))
    else {
      setDerivedDates({
        ['Flight_Start-date']: lux(data.payload['Flight_Start-date']).toJSDate(),
        ['Flight_End-date']: lux(data.payload['Flight_End-date']).toJSDate(),
        ['CrewDuty_Start-date']: lux(data.payload['CrewDuty_Start-date']).toJSDate(),
        ['CrewDuty_End-date']: lux(data.payload['CrewDuty_End-date']).toJSDate(),
        ['CrewRoster_Start-date']: lux(data.payload['CrewRoster_Start-date']).toJSDate(),
        ['CrewRoster_End-date']: lux(data.payload['CrewRoster_End-date']).toJSDate(),
      })
      setManualOverride(true)
    }
  }, [activePublication, data, global])

  return (
    <DataObject
      data={data}
      upload="json"
      title={intl.t('idps_export_config')}
      onSave={props.onSave}
      onChange={(data) => store.dispatch(addContextDataObject(data))}
      onUndo={() => api.getDataObject(data.id)}
      nofilter>
      <Card style={{ margin: 15 }}>
        {/* TODO: make sure at least one is selected */}
        {!global && (
          <FormGroup label={<div style={{ width: 180 }}>Crew functions</div>} inline helperText="(select at least one)">
            <Checkbox
              disabled={!data.is_editable}
              inline
              label="Cockpit / FCM"
              checked={isFCMChecked}
              onChange={() => handlePublicationType('FCM')}
            />
            <Checkbox
              disabled={!data.is_editable}
              inline
              label="Cabin / CCM"
              onChange={() => handlePublicationType('CCM')}
              checked={isCCMChecked}
            />
          </FormGroup>
        )}

        {!global && (
          <>
            {data.is_editable && (
              <FormGroup
                label={<div style={{ width: 180 }}>Manual override</div>}
                inline
                helperText="Enables manual selection of IDPS data export dates and codes">
                <Switch
                  checked={manualOverride}
                  onChange={() => {
                    if (!manualOverride) {
                      const newData = { ...data, payload: { ...data.payload, ...format(setDates(activePublication)) } }
                      store.dispatch(addContextDataObject(newData))
                      props.onSave(newData.id, newData.payload)
                    } else {
                      clearDates()
                    }

                    setManualOverride(!manualOverride)
                  }}
                />
              </FormGroup>
            )}

            <FormGroup label={<div style={{ width: 180 }}>Flight dates</div>} inline>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DateRangeInput2
                  disabled={!data.is_editable || !manualOverride}
                  minDate={DateTime.now().minus({ years: 10 }).toJSDate()}
                  maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                  highlightCurrentDay
                  shortcuts={false}
                  defaultTimezone="UTC"
                  localeUtils={myLocaleUtils}
                  popoverProps={{
                    usePortal: true,

                    position: 'bottom',
                    portalContainer: document.getElementById('main'),
                  }}
                  onChange={(dates) => handleDateChange(dates, 'Flight')}
                  formatDate={(date) => lux(date).toISODate()}
                  parseDate={(str) => {
                    return lux(str)?.toJSDate()
                  }}
                  value={[derivedDates['Flight_Start-date'], derivedDates['Flight_End-date']]}
                />
              </div>
            </FormGroup>

            <FormGroup label={<div style={{ width: 180 }}>Crew duty dates</div>} inline>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DateRangeInput2
                  disabled={!data.is_editable || !manualOverride}
                  minDate={DateTime.now().minus({ years: 10 }).toJSDate()}
                  maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                  highlightCurrentDay
                  shortcuts={false}
                  defaultTimezone="UTC"
                  localeUtils={myLocaleUtils}
                  popoverProps={{
                    usePortal: true,

                    position: 'bottom',
                    portalContainer: document.getElementById('main'),
                  }}
                  onChange={(dates) => handleDateChange(dates, 'CrewDuty')}
                  formatDate={(date) => lux(date).toISODate()}
                  parseDate={(str) => {
                    return lux(str)?.toJSDate()
                  }}
                  value={[derivedDates['CrewDuty_Start-date'], derivedDates['CrewDuty_End-date']]}
                />
              </div>
            </FormGroup>

            <FormGroup label={<div style={{ width: 180 }}>Crew roster dates</div>} inline>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DateRangeInput2
                  disabled={!data.is_editable || !manualOverride}
                  minDate={DateTime.now().minus({ years: 10 }).toJSDate()}
                  maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                  highlightCurrentDay
                  shortcuts={false}
                  localeUtils={myLocaleUtils}
                  defaultTimezone="UTC"
                  popoverProps={{
                    usePortal: true,

                    position: 'bottom',
                    portalContainer: document.getElementById('main'),
                  }}
                  onChange={(dates) => handleDateChange(dates, 'CrewRoster')}
                  formatDate={(date) => lux(date).toISODate()}
                  parseDate={(str) => {
                    return lux(str)?.toJSDate()
                  }}
                  value={[derivedDates['CrewRoster_Start-date'], derivedDates['CrewRoster_End-date']]}
                />
              </div>
            </FormGroup>
          </>
        )}
        <FormGroup label={<div style={{ width: 180 }}>Crew master expiry codes</div>} inline>
          <InputText
            error={validation_errors?.['CrewMaster_ExpiryCodes-date']}
            style={{ width: 400 }}
            disabled={!data.is_editable && !global}
            value={data.payload['CrewMaster_ExpiryCodes']}
            onChange={(value) => handleCrewMasterExpiryCodesChange(value)}
          />
        </FormGroup>
      </Card>
    </DataObject>
  )
}

export default Comp
