import React, { useState, useEffect } from 'react'
import InputTime from './InputTime'

const InputText = (props) => {
  const { error, onChange, from, to } = props
  const [internalFromTime, setInternalFromTime] = useState(from)
  const [internalToTime, setInternalToTime] = useState(to)

  useEffect(() => setInternalFromTime(from), [from])
  useEffect(() => setInternalToTime(to), [to])
  return (
    <>
      <InputTime
        error={error?.from}
        placeholder="Start time"
        onChange={(time) => {
          setInternalFromTime(time)
          onChange(time, internalToTime)
        }}
        value={internalFromTime}
      />
      <span style={{ marginRight: 10, marginLeft: 10 }}>to</span>
      <InputTime
        error={error?.to}
        placeholder="End time"
        onChange={(time) => {
          setInternalToTime(time)
          onChange(internalFromTime, time)
        }}
        value={internalToTime}
      />
    </>
  )
}

export default InputText
