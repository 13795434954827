import React, { useRef, useState, useEffect, useCallback, createContext, useContext } from 'react'
import { useMouse } from 'publication/roster/mouseHooks'
import { useDrag } from 'publication/roster/useDrag'
import styles from './SplitPane.module.scss'

const PaneContext = createContext()

const validate = (s) => {
  if (!s) return false
  if (s?.length === 0) return false
  if (!s.some((d) => d.id)) return false
  return true
}

const SplitPane = (props) => {
  const ref = useRef()

  const [dragging, setDrag] = useState(false)
  const [s, setS] = useState([])
  const [height, setHeight] = useState()
  const [width, setWidth] = useState()
  const count = React.Children.toArray(props.children).filter((d) => !d.props.hide).length

  useEffect(() => {
    const state = props.id ? JSON.parse(localStorage.getItem(`splitPane_${props.id}`)) : null
    console.log(state)
    setS(
      React.Children.toArray(props.children).map((d, i) => ({
        hide: d.props.hide,
        id: Math.random() + '',
        collapsed: false,
        value: 100,
        heightPct: 1 / count,
        ...state?.[i],
      }))
    )
  }, [])

  const onResize = () => {
    if (!ref.current) return
    const { height, width } = ref.current.getBoundingClientRect()
    setHeight(height)
    setWidth(width)
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [ref])

  useEffect(() => {
    if (props.id) localStorage.setItem(`splitPane_${props.id}`, JSON.stringify(s))
  }, [props.id, s, height])

  return (
    <PaneContext.Provider value={{ s, setS, dragging, setDrag, height, width, count }}>
      <div
        id={props.id}
        ref={ref}
        style={{
          top: 0,
          bottom: 0,
          cursor: dragging ? 'ns-resize' : 'unset',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'absolute',
          width: '100%',
        }}>
        {React.Children.map(props.children, (child, index) =>
          React.cloneElement(child, {
            ...child.props,
            id: s[index]?.id,
            heightPct: s[index]?.heightPct,
          })
        )}
      </div>
    </PaneContext.Provider>
  )
}

const Pane = (props) => {
  const { minSize } = props
  const state = useContext(PaneContext)
  const stateRef = useRef()
  const heightRef = useRef()
  const idRef = useRef()
  stateRef.current = state.s
  heightRef.current = state.height
  idRef.current = props.id
  const { s, setS, height, width } = state
  const splitterRef = useRef()

  const normalize = (newp) => {
    const totalHeight = newp.filter((d) => !d.collapsed && !d.hide).reduce((acc, s) => (acc += s.heightPct), 0)
    //const totalHeight = newp.filter((d) => !d.collapsed).reduce((acc, s) => (acc += s.heightPct), 0)
    const collapsedHeight = newp
      .filter((d) => d.collapsed && !d.hide)
      .reduce((acc, s) => (acc += props.collapsedSize), 0)
    const availableHeight = 1 - collapsedHeight / heightRef.current
    const scaleFactor = availableHeight / totalHeight
    newp
      //   .filter((d) => !d.collapsed && !d.hide)
      .filter((d) => !d.collapsed)
      .forEach((d) => (d.heightPct *= scaleFactor))
    return newp
  }

  const onDragStart = useCallback(() => {}, [splitterRef])
  const onDragEnd = useCallback(() => {}, [splitterRef])

  const onDrag = (e) => {
    if (e.movementY !== 0 && stateRef.current) {
      let newp = JSON.parse(JSON.stringify(stateRef.current))
      const delta = e.movementY || 0

      if (delta === 0) return

      let i = newp.findIndex((d) => d.id === idRef.current)

      // stop resizing when all above panes are collapsed or at minsize
      const t =
        newp.filter((d, index) => d.collapsed && index < i).length +
        newp.filter((d, index) => d.minSize && index < i).length

      if (t === i && delta < 0) return

      let item = newp[i]

      //push down
      let scope = newp.filter((d, index) => !d.collapsed && index >= i)
      if (delta > 0) scope = scope.filter((d) => !d.minSize)

      scope = scope.slice(0, props.propagateDown)
      let c = scope.length

      scope.forEach((d) => {
        d.heightPct -= delta / heightRef.current / c
        if (d.heightPct <= minSize / heightRef.current) {
          d.minSize = true
          d.heightPct = minSize / heightRef.current
        } else {
          d.minSize = false
        }
      })

      //push up
      scope = newp.filter((d, index) => !d.collapsed && index < i)
      if (delta < 0) scope = scope.filter((d) => !d.minSize)

      scope = scope.slice(-props.propagateUp)
      c = scope.length

      scope.forEach((d) => {
        d.heightPct += delta / heightRef.current / c
        if (d.heightPct <= minSize / heightRef.current) {
          d.minSize = true
          d.heightPct = minSize / heightRef.current
        } else {
          d.minSize = false
        }
      })

      newp = normalize(newp)
      setS(newp)
    }
  }

  const drag = useDrag(splitterRef, onDragStart, onDrag, onDragEnd)

  useEffect(() => {
    let newp = JSON.parse(JSON.stringify(s))

    newp = normalize(newp)
    setS(newp)
  }, [height])

  const item = s.find((d) => d.id === props.id)
  const itemIndex = s.findIndex((d) => d.id === props.id)
  const openPanelsBefore = s.filter((d, i) => i < itemIndex && !d.collapsed && !d.hide).length
  const onCollapseChange = useCallback(
    (c) => {
      let newp = JSON.parse(JSON.stringify(s))
      let item = newp.find((d) => d.id === props.id)
      if (item) {
        item.collapsed = c
        if (c) item.minSize = false
        item.heightPct = c ? props.collapsedSize / height : 400 / height
      }
      newp = normalize(newp)
      setS(newp)
    },
    [s]
  )
  //console.log(s)
  if (props.hide) return null
  if (!item) return null

  return (
    <div
      className={styles.pane}
      style={{
        flex: `0 0 ${item.collapsed ? `${props.collapsedSize}px` : item.heightPct * 100 + '%'}`,
      }}>
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          onCollapseChange,
          collapsed: s.find((d) => d.id === props.id)?.collapsed,
          height: item.heightPct * heightRef.current,
          width,
        })
      )}

      <div
        ref={splitterRef}
        className={styles.splitter}
        style={{ display: s.findIndex((d) => d.id === props.id) > 0 && openPanelsBefore > 0 ? 'block' : 'none' }}>
        <div className={[styles.splitterBar, drag ? styles.hover : undefined].join(' ')}></div>
      </div>
    </div>
  )
}
Pane.defaultProps = {
  hide: false,
  collapsedSize: 30,
  minSize: 75,
  propagateUp: 1,
  propagateDown: 1,
}

const Comp = (props) => {
  const { collapsed } = props
  return (
    <div>
      {!collapsed && <button onClick={() => props.onCollapseChange(true)}>-</button>}
      {collapsed && <button onClick={() => props.onCollapseChange(false)}>+</button>}
    </div>
  )
}

const Test = (props) => {
  return (
    <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
      <SplitPane id="test">
        <Pane minSize={200}>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
        <Pane>
          <Comp />
        </Pane>
      </SplitPane>
    </div>
  )
}

export default Test
export { SplitPane, Pane }
