import api from './api'

export const ADD_RE_EVENT_TO_HISTORY = 'ADD_RE_EVENT_TO_HISTORY'
export const addReEventToHistory = (data) => ({
  type: ADD_RE_EVENT_TO_HISTORY,
  payload: data,
})

export const ADD_RESOURCE_TO_REPOSITORY = 'ADD_RESOURCE_TO_REPOSITORY'
export const addResourceToRepository = (data) => ({
  type: ADD_RESOURCE_TO_REPOSITORY,
  payload: data,
})

export const ADD_ACTIVITY_TO_REPOSITORY = 'ADD_ACTIVITY_TO_REPOSITORY'
export const addActivityToRepository = (data) => ({
  type: ADD_ACTIVITY_TO_REPOSITORY,
  payload: data,
})
export const ADD_ASSIGNMENT_TO_REPOSITORY = 'ADD_ASSIGNMENT_TO_REPOSITORY'
export const addAssignmentToRepository = (data) => ({
  type: ADD_ASSIGNMENT_TO_REPOSITORY,
  payload: data,
})
export const ADD_VIOLATION_TO_REPOSITORY = 'ADD_VIOLATION_TO_REPOSITORY'
export const addViolationToRepository = (data) => ({
  type: ADD_VIOLATION_TO_REPOSITORY,
  payload: data,
})

export const DELETE_ASSIGNMENT_FROM_REPOSITORY = 'DELETE_ASSIGNMENT_FROM_REPOSITORY'
export const deleteAssignmentFromRepository = (data) => ({
  type: DELETE_ASSIGNMENT_FROM_REPOSITORY,
  payload: data,
})

export const ACTIVITY_TIMEFRAME_CHANGED = 'ACTIVITY_TIMEFRAME_CHANGED'
export const changeActivityTimeframeInRepository = (data) => ({
  type: ACTIVITY_TIMEFRAME_CHANGED,
  payload: data,
})
export const INITIALIZE_RE_REPOSITORY = 'INITIALIZE_RE_REPOSITORY'
export const initializeReRepository = (data) => ({
  type: INITIALIZE_RE_REPOSITORY,
  payload: data,
})
