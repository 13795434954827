import React, { Children } from 'react'
import { format } from 'shared/LuxonHelpers'
import styles from './Tooltips.module.scss'
import { Tag, Icon } from '@blueprintjs/core'
import {
  iconPlaneTakeOff,
  iconPlaneLanding,
  iconBed,
  iconCrew,
  iconCoffee,
  iconAlarm,
  iconBaseSwitch,
  iconHome,
} from 'icons'
import { IssueTag } from '../Issue/Issue'
const dateFormat = 'yyyy/MM/dd - HH:mm'
const Label = ({ icon, label, text, width }) => (
  <div>
    {icon && (
      <span style={{ marginRight: 10 }}>
        <Icon icon={icon} style={{ display: 'inline-block' }} />
      </span>
    )}

    <span className={styles.label} style={{ width: width ? width : undefined }}>
      {label}
    </span>

    <span>{text}</span>
  </div>
)

const Tooltip = ({ children, icon, title, from, fromIcon, toIcon, to, zone, dep, arr, error, rightLabel }) => (
  <div className={styles.tooltip}>
    <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <strong style={{ flex: 1 }}>{title}</strong>

      <span>
        {rightLabel}
        <Icon size={16} icon={icon} style={{ marginLeft: 5 }} />
      </span>
    </span>
    <div className={styles.line} />

    {from && <Label icon={fromIcon} width={40} label={dep} text={format(from, zone, dateFormat)} />}
    {to && <Label icon={toIcon} width={40} label={arr} text={format(to, zone, dateFormat)} />}
    {children}
    {error && (
      <>
        <div className={styles.line} />

        <IssueTag d={error} />
      </>
    )}
  </div>
)

const DefaultTooltipHtml = ({ d, zone = 'UTC' }) => (
  <Tooltip dep="From" arr="To" from={d.startTime} to={d.endTime} zone={zone} title={<span>{d.typeOfActivity}</span>} />
)
const WeekendTooltipHtml = ({ d, zone = 'UTC' }) => (
  <Tooltip
    icon={iconCoffee}
    dep="From"
    arr="To"
    from={d.startTime}
    to={d.endTime}
    zone={zone}
    title={
      <>
        {d.typeOfActivity} - {d.standbyCode || d.activityCode}
      </>
    }
  />
)
const StandByTooltip = ({ d, zone = 'UTC', error }) => (
  <Tooltip
    icon={iconAlarm}
    from={d.startTime}
    dep="From"
    arr="To"
    to={d.endTime}
    zone={zone}
    error={error}
    title={
      <>
        {d.typeOfActivity} - {d.standbyCode || d.activityCode}
      </>
    }></Tooltip>
)
const BaseSwitchTooltip = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    fromIcon={iconHome}
    toIcon="arrow-right"
    dep={d.from}
    arr={d.to}
    to={d.endTime}
    zone={zone}
    title={<>{d.typeOfActivity}</>}
  />
)
const TaxiBaseSwitchTooltip = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    fromIcon={iconHome}
    toIcon="arrow-right"
    dep={d.from}
    arr={d.to}
    to={d.endTime}
    zone={zone}
    title={<>Change of base - COB</>}
  />
)
const HotelTooltipHtml = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    icon={iconBed}
    dep="From"
    arr="To"
    to={d.endTime}
    zone={zone}
    title={
      <>
        {d.typeOfActivity} in {d.location}
      </>
    }
  />
)
const PreassignedTooltipHtml = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    to={d.endTime}
    dep="From"
    arr="To"
    zone={zone}
    title={
      <span>
        {d.typeOfActivity} - {d.activityCode}
      </span>
    }
  />
)
const FlightTooltipHtml = ({ d, zone = 'UTC', error }) => (
  <Tooltip
    icon={'airplane'}
    from={d.startTime}
    fromIcon={iconPlaneTakeOff}
    rightLabel={(d.aircraftRegistration || '') + ' - ' + (d.aircraftType || '')}
    dep={d.from}
    toIcon={iconPlaneLanding}
    to={d.endTime}
    arr={d.to}
    zone={zone}
    error={error}
    title={
      <>
        <span style={{ flex: 1 }}>
          {d.preAssigned ? 'Pre-assigned ' : ''}
          {d.flightNumber} {d.isExtendedDuty ? (d.hasInFlightRest ? ' - EX RC1' : '- EX') : ''}{' '}
          {d.crewFunction !== 'Plane' && d.crewRole !== d.crewFunction ? '[ as ' + d.crewRole + ' ]' : ''}
        </span>
      </>
    }>
    {d.crewComplement && (
      <span>
        <span style={{ width: 65, marginTop: 10, display: 'inline-block' }}>Crew:</span>
        {d.crewComplement
          .filter((c) => c.crewNumber > 0)
          .map((c) =>
            Array.apply(null, Array(c.crewNumber)).map((dd, i) => {
              if (i < d.assignedCrew[c.crewFunction].length)
                return (
                  <span key={i}>
                    <Icon icon={iconCrew[c.crewFunction]} />
                  </span>
                )
              return (
                <span key={i} style={{ opacity: 0.5 }}>
                  <Icon icon={iconCrew[c.crewFunction]} />
                </span>
              )
            })
          )}
      </span>
    )}
  </Tooltip>
)

const TaxiTooltip = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    icon={'taxi'}
    dep={d.from}
    arr={d.to}
    to={d.endTime}
    zone={zone}
    title={
      <>
        {d.preAssigned ? 'Pre-assigned ' : ''}
        {d.typeOfActivity}
      </>
    }
  />
)

const BlancDayFillerTooltipHtml = ({ d, zone = 'UTC' }) => (
  <Tooltip
    from={d.startTime}
    to={d.endTime}
    dep="From"
    arr="To"
    zone={zone}
    title={<span>No scheduled duty - {d.activityCode}</span>}
  />
)
export const FlightDutyTooltipHtml = ({ d, zone = 'UTC' }) => {
  return (
    <Tooltip
      icon={'airplane'}
      from={d.startTime}
      to={d.endTime}
      dep={'In'}
      arr={'Out'}
      zone={zone}
      title={(d.preAssigned ? 'Pre-assigned ' : '') + 'Flight Duty'}>
      <Label width={40} label={'Max SC'} text={d.maxScore} />
    </Tooltip>
  )
}

const ActivityTooltipHtml = ({ d, zone = 'UTC', error }) => {
  switch (d.typeOfActivity) {
    case 'Standby':
      return <StandByTooltip d={d} zone={zone} error={error} />
    case 'P7F':
    case 'Weekend':
      return <WeekendTooltipHtml d={d} zone={zone} />
    case 'Hotel':
      return <HotelTooltipHtml d={d} zone={zone} />
    case 'PreAssigned':
      return <PreassignedTooltipHtml d={d} zone={zone} />
    case 'Flight':
    case 'Deadhead':
    case 'IataFlight':
      return <FlightTooltipHtml d={d} zone={zone} error={error} />
    case 'Taxi':
    case 'TaxiOwnMeans':
      return <TaxiTooltip d={d} zone={zone} />
    case 'BaseSwitch':
      return <BaseSwitchTooltip d={d} zone={zone} />
    case 'TaxiBaseSwitch':
      return <TaxiBaseSwitchTooltip d={d} zone={zone} />
    case 'BlancDayFiller':
      return <BlancDayFillerTooltipHtml d={d} zone={zone} />

    default:
      return <DefaultTooltipHtml d={d} zone={zone} />
  }
}
export default React.memo(ActivityTooltipHtml)
