import React from 'react'
import styles from './Comets.module.scss'

const Loader = ({ className, active, size, width, color, absolute, ...props }) => {
  return (
    <div
      className={[styles.loader, className].join(' ')}
      style={{ width: size, height: size, color: color, borderWidth: width }}
    />
  )
}

export default Loader
