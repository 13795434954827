import { myFetch } from '../api'
import store from 'store/index'
import config from 'config'

import { removeReport, storeReport, clearReport, storeAllReportIds } from './actions'

const servicesFactory = (myFetch) => ({
  async removeCostReport(id) {
    store.dispatch(removeReport(id))
  },
  async storeCostReport(report) {
    try {
      myFetch(`${config.api}/data_objects/${report.id}/`).then((json) => {
        json.title = report.publicationTitle

        store.dispatch(storeReport(json))
      })
    } catch (e) {
      console.error(e)
    }
  },
  async clearCostReport() {
    try {
      store.dispatch(clearReport())
    } catch (e) {
      console.error(e)
    }
  },
  async storeAllPublications(context) {
    try {
      var reports = await myFetch(`${config.api}/data_objects/?type=cost_report&has_publication=1&context=${context}`)
      const publications = (await myFetch(`${config.api}/publications/?context=${context}`)).map((el) => {
        return Object.create({
          title: el.name,
          id: el.id,
        })
      })
      for (let report in reports) {
        for (let pub in publications) {
          if (reports[report].publication === publications[pub].id) {
            reports[report].publicationTitle = publications[pub].title
          }
        }
      }
      store.dispatch(storeAllReportIds(reports))
    } catch (e) {
      console.error(e)
    }
  },
})

const api = servicesFactory(myFetch)

export default api
